import {MiscActionTypes} from "../constants/MiscActionTypes";

const initialState = {
    appInit: null,
    singleVideo: null,
    campaignAnswered: null,
}

export const MiscReducer = (state = initialState, action) => {
  switch (action.type) {
      case MiscActionTypes.SET_APP_INIT:
          return {...state, appInit: action.payload};
      case MiscActionTypes.SET_SINGLE_VIDEO:
          return {...state, singleVideo: action.payload};
      case MiscActionTypes.SET_CAMPAIGN_ANSWERED:
          return {...state, campaignAnswered: action.payload};
      default:
          return state;
  }
}