import {useEffect, useState} from "react";
import CalendarContainerItem from "./CalendarContainerItem";
import moment from "moment";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {useSearchParams} from 'react-router-dom';

const CalendarContainer = (props) => {
    const {selectedDay, isMobile} = props;

    const [dateCollection, setDateCollection] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(searchParams.get('date') || props.selectedDate)


    const updateDateCollection = (numberOfDays, clickType) => {
        let tempCollection = [];

        // console.log('selected date in calendar item =', props.selectedDate,searchParams.get('date'))
        if (clickType === "left") {
            //   console.log("clickType", clickType);
            let firstDate = moment(selectedDate);
            if (dateCollection.length > 0) {
                firstDate = dateCollection[0];
            }
            for (let i = 1; i > 0; i--) {
                tempCollection.push(moment(firstDate).subtract(i, "d")._d);
            }
            // console.log("tempCOllection =", tempCollection);
            dateCollection.pop();
            setDateCollection((dateCollection) => [
                tempCollection[0],
                ...dateCollection,
            ]);
        } else if (clickType === "right") {
            let lastDate = moment(selectedDate);
            if (dateCollection.length > 0) {
                lastDate = dateCollection[dateCollection.length - 1];
            }
            for (let i = 1; i < 2; i++) {
                tempCollection.push(moment(lastDate).add(i, "d")._d);
            }
            // console.log("tempCOllection =", tempCollection);
            dateCollection.shift();
            setDateCollection((dateCollection) => [
                ...dateCollection,
                tempCollection[0],
            ]);
        }

        // pushes the number of days minus current date
        if (!clickType) {
            for (let i = numberOfDays; i > 0; i--) {
                tempCollection.push(moment(selectedDate || Date.now()).subtract(i, "d")._d);
            }

            // pushes the current date
            tempCollection.push(moment(selectedDate || Date.now())._d);

            // pushes the number of days after current date
            for (let i = 1; i <= numberOfDays; i++) {
                tempCollection.push(moment(selectedDate || Date.now()).add(i, "d")._d);
            }

            setDateCollection(...dateCollection, tempCollection);
        }
    };


    const itemClicked = (e) => {
        console.log(moment(e).format("YYYY-MM-DD"));
        selectedDay(e);
        setSelectedDate(e)
        localStorage.setItem('selectedDate', e)
    };

    useEffect(() => {
        if (dateCollection === "") {
            // enter number of days to have a range in calendar
            // if 2 selected the range will be from (currentDate - 2 to currentDate + 2)
            updateDateCollection(2);
        }
    }, [props.selectedDate,searchParams.get('date')]);

    return (
        <>
            <div className={`container bg-white p-4`}>
                {/*{moment((selectedDate || new Date())).format('MMMM')}*/}
                <div className="row">
                    <div
                        className="col-1 d-flex align-items-center justify-content-start"
                        style={{ cursor: 'pointer'}}

                        onClick={() => {
                            updateDateCollection(0, "left");
                        }}
                    >
                        <div className={'bg-background'}
                             style={{borderRadius: '50%'}}
                        >
                            <RiArrowLeftSLine style={{margin: "5px"}}/>
                        </div>
                    </div>
                    <div className="col-10">
                        <div className={`${isMobile ? 'slider-mobile-news-container' : "row"}`}>
                            {dateCollection != "" &&
                                dateCollection.map((value, index) => (
                                    <CalendarContainerItem
                                        key={index}
                                        date={value}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        itemClicked={itemClicked}
                                        isMobile={isMobile}
                                    />
                                ))}
                        </div>
                    </div>
                    <div
                        className="col-1 d-flex align-items-center justify-content-end"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            updateDateCollection(0, "right");
                        }}
                    >
                        <div className={'bg-background'}
                             style={{borderRadius: '50%'}}
                        >
                            <RiArrowRightSLine style={{margin: "5px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarContainer;
