import {useEffect, useState} from "react";
import {get} from "../../Axios/get";
import {FANZINE} from "../../Axios/EndPoints";
import {SCREEN_SIZE} from "../../Configs/Constants";
import Slider from 'react-slick';
import GoogleAd from "../../Components/SnackAds/GoogleAd";

function TargetNextArrow(props) {
    const {className, style, onClick, arrowColor, showArrows} = props;
    return (
        <div
            className={`${className} ${arrowColor}`}
            style={{
                ...style,
                display: showArrows,
            }}
            onClick={onClick}
        />
    );
}

function TargetPrevArrow(props) {
    const {className, style, onClick, arrowColor, showArrows} = props;
    return (
        <div
            className={`${className} ${arrowColor}`}
            style={{
                ...style,
                display: showArrows,
            }}
            onClick={onClick}
        />
    );
}

const FanzineBanners = () => {

    const [banners, setBanners] = useState([]);

    const fetchBanners = () => {
        get(FANZINE.BannersEndPoint)
            .then((response) => {
                setBanners(response.data.data);
            });
    }

    useEffect(() => {
        fetchBanners();
    }, []);

    useEffect(() => {
        // console.log('banners', banners);
    }, [banners]);

    const renderText = (banner) => {
        return (
            <div className={`col-7`}>
                <div>
                    <span
                        dangerouslySetInnerHTML={{__html: banner.title}}
                    />
                </div>
                <div>
                    <span
                        dangerouslySetInnerHTML={{__html: banner.sub_title}}
                    />
                </div>
                <div>
                    <span
                        dangerouslySetInnerHTML={{__html: banner.description}}
                    />
                </div>
                <div>
                    <a href={banner.btn_click_action} className={'btn btn-fanzine text-white sfProDisplayFontBold rounded-pill'}>{banner.button_text}</a>
                </div>
            </div>
        );
    }

    const renderImage = (banner) => {
        return (
            <div className={`col-4`}>
                <img src={banner.image} alt={banner.title}/>
            </div>
        );
    }

    const render = () => {

        let showArrows = 'block';
        let isMobile = false;
        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            showArrows = "none";
            isMobile = true;
        }

        let settings = {
            infinite: false,
            dots: false,
            slidesToShow: 1,
            cssEase: 'linear',
            centerPadding: '15',
            arrows: true,
            slidesToScroll: 1,
            lazyLoad: false,
            autoplay: false,
            prevArrow: <TargetPrevArrow arrowColor={'fanzine-slider-arrow'} showArrows={showArrows}/>,
            nextArrow: <TargetNextArrow arrowColor={'fanzine-slider-arrow'} showArrows={showArrows}/>,
        };

        return (
            <div className={'container mt-5'}>
                <div className="image-carousel">
                    <Slider {...settings}>
                        {banners?.map((banner) => (
                            <div key={`banner_item_${banner.id}`} className={isMobile ? 'slide ms-5' : 'slide ms-5 me-5 ps-5 pe-5'}>
                                <div className={`row`}>
                                    {banner.image_position === 'left' && renderImage(banner)}
                                    {renderText(banner)}
                                    {banner.image_position === 'right' && renderImage(banner)}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }

    return (
        <>
            {
                banners && banners.length ? render() :
                    <div className="d-flex justify-content-center align-items-center h1">
                        <GoogleAd id={'4009154580'}/>
                    </div>
            }
        </>
    );
}

export default FanzineBanners;