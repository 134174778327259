import {combineReducers} from "redux";
import footballLeagueReducer from "./FootballLeagueReducer";
import ShopifyReducer from "./ShopifyReducer";
import CartReducer from "./CartReducer";
import {TimeZoneReducer} from "./TimeZoneReducer";
import {MiscReducer} from "./MiscReducer";

const reducers = combineReducers({
    football: footballLeagueReducer,
    shopify: ShopifyReducer,
    cart: CartReducer,
    timezone: TimeZoneReducer,
    misc: MiscReducer,
});

export default reducers;