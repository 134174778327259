import React from "react";
import NewsCard from "../Cards/NewsCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faSortDown} from "@fortawesome/free-solid-svg-icons";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {Button} from "react-bootstrap";
import {getHostname} from "../../CustomFunctions/getHostname";
import {FONT_COLOR, NAV_CLASS} from "../../Configs/Targets";

class Divider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    renderMobile() {
        return (
            <span className={'mt-4 mb-4'}>
                <span className={'col text-dark text-end acuminFont text-nowrap btn'}>
                    {this.props.textShort ? this.props.textShort : this.props.text}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faAngleRight} size={'1x'}/>
                </span>
            </span>
        );
    }

    render() {
        let screenWidth = this.state.width;
        if (screenWidth <= SCREEN_SIZE.MOBILE){
            return this.renderMobile();
        }
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const fontColor = FONT_COLOR[target];

        return (
            <div className={'row mt-4'}>
                <div className={'col text-light-gray text-center'}>
                    {/*<hr className={'mt-see-more'}/>*/}
                </div>
                <div className={'col text-light-gray text-center bebasFont text-nowrap'}>
                    <Button className={`btn ${NAV_CLASS[target]} rounded-custom-1 border-0 font-20 ps-4 pe-4 pt-2 pb-2 ${fontColor}`}>
                        {this.props.text}
                    </Button>
                    {/*&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faSortDown} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                </div>
                <div className={'col text-light-gray text-center'}>
                    {/*<hr className={'mt-see-more'}/>*/}
                </div>
            </div>
        );
    }

}

export default Divider;
