import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import './styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import PropTypes from "prop-types";
import {TEXT_COLOR} from "../../Configs/Targets";
import {NavLink} from "react-router-dom";
import {getHostname} from "../../CustomFunctions/getHostname";
import {SCREEN_SIZE} from "../../Configs/Constants";
import HTMLEllipsis from "react-lines-ellipsis/lib/html.modern";

const NewsCard = (props) => {

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../assets/images/football-shots-you-need-to-know.jpg");
    }

    const renderNews = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor) => {
        return (
            <>
                <Card className="col border-0 bg-transparent">
                    <Card.Img
                        variant="top"
                        className={''}
                        src={news && news.news_image}
                        alt={news && news.news_title}
                        onError={addDefaultSrc}
                        style={mainImageStyle}
                    />
                    <Card.ImgOverlay
                        className={`d-flex align-items-start flex-column justify-content-end ${news.type === "videos" ? "video-overlay" : "news-overlay"}`}>
                        <Card.Text className={`text-white sfProDisplayFont ${cardTextSize}`}>
                            {
                                isNewsPage ?
                                    (
                                        <span className={`bebasFont text-news-carousel`} dangerouslySetInnerHTML={{__html: news && news.news_title}}/>
                                    )
                                    : (
                                        <>
                                            <img src={news && news.news_publisher.publisher_icon}
                                                 alt={news && news.news_publisher.publisher_name}
                                                 height={publisherIconSize}/>
                                            {/*<span className={'sfProDisplayFont ms-2 me-2 text-8'}>BBC Online</span>*/}
                                            <span
                                                className={'sfProDisplayFont ms-2 me-2 text-8'} dangerouslySetInnerHTML={{__html: news && news.news_publisher.publisher_name}}/>
                                        </>
                                    )
                            }
                        </Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <div className="row p-2">
                    <div className={'justify-content-between'}>
                        {
                            isNewsPage ?
                                isMobile ? (
                                    <>
                                        <div className={`row`}>
                                            <div className={`col-1 d-flex align-items-center`}>
                                                <img src={news && news.news_publisher.publisher_icon}
                                                     alt={news && news.news_publisher.publisher_name}
                                                     height={publisherIconSize}
                                                     className={`d-flex align-items-center`}/>
                                            </div>
                                            <div className={`col-8 d-flex align-items-center justify-content-start`}>
                                                <span
                                                    className={'ms-3 sfProDisplayFont'}>{news && news.news_publisher.publisher_name}</span>
                                                {/*<span className={textColor}>Published </span>*/}
                                                {/*<span>{news && news.news_date}</span>*/}
                                            </div>
                                            <div className={`col-3 d-flex align-items-center justify-content-end`}>
                                                <span className={`float-end mt-2 font-16 sfProDisplayFont font-weight-800 ${textColor}`}>
                                                    {news && news.news_time.replace("minutes", "mins").replace("ago", "").replace("an", "1").replace("a day", "1 day")}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={news && news.news_publisher.publisher_icon}
                                             alt={news && news.news_publisher.publisher_name}
                                             height={publisherIconSize}/>
                                        <span
                                            className={'sfProDisplayFont ms-2 me-2'}>{news && news.news_publisher.publisher_name}</span>
                                        {/*<span className={textColor}>Published </span>*/}
                                        {/*<span>{news && news.news_date}</span>*/}
                                        <span className={` float-end mt-2 font-16 font-weight-800 sfProDisplayFont ${textColor}`}>
                                            {news && news.news_time.replace("minutes", "mins").replace("ago", "").replace("an", "1").replace("a day", "1 day")}
                                        </span>
                                    </>
                                )

                                : (
                                    <>
                                        <span
                                            className={`sfProDisplayFontBold small ${props.isMobile ? 'd-flex' : ''}`}>
                                            <HTMLEllipsis
                                                unsafeHTML={news && news.news_title}
                                                maxLine='3'
                                                ellipsis='...'
                                                trimright={'true'}
                                                basedOn='letters'
                                                style={{height: isMobile ? '4.5em' : '2em'}}
                                            />
                                            {/*Salah sets target for third Premier League Golden Boot*/}
                                        </span>
                                        <span className={`${isMobile ? 'float-start' : 'float-end mt-2'} text-muted font-16 font-weight-800 sfProDisplayFont ${textColor}`}>
                                            {news && news.news_time.replace("minutes", "mins").replace("ago", "").replace("an", "1").replace("a day", "1 day")}
                                        </span>
                                    </>
                                )
                        }
                    </div>
                </div>
            </>
        );
    }

    const renderSnack = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, target) => {
        let link = '';
        if (target === 'fanzine') {
            if (typeof news.main_team !== "undefined" && news.main_team) {
                link = `/football/${news.main_team}/${news.slug}`;
            } else {
                link = `/football/${news.slug}`;
            }
        } else {
            link = `/news/${news.slug}`;
        }
        return (
            // <NavLink to={link} className={'text-decoration-none text-dark'}>
                renderNews(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor)
            // </NavLink>
        );
    }

    const renderNewsNow = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor) => {
        return (
            // <a href={news.news_url} className={'text-decoration-none text-dark'} target='_blank' rel="noreferrer">
                renderNews(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor)
            // </a>
        );
    }

    const render = () => {

        let isNewsPage = typeof props.isNewsPage !== 'undefined' && props.isNewsPage;
        let isSingleNewsPage = typeof props.isSingleNewsPage !== 'undefined' && props.isSingleNewsPage;
        let cardTextSize = isNewsPage ? 'h1' : 'small';
        let publisherIconSize = isNewsPage ? 30 : 10;
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const textColor = TEXT_COLOR[target];
        let extraClass = typeof props.classProps !== 'undefined' ? props.classProps : '';
        let cardWidth = props.isMobile ? 'slider-mobile-card' : 'col';
        let moreStories = props.moreStories;
        let news = props.newsItem;
        let mainImageStyle = {};
        let isMobile = props.isMobile;
        let isLaptopLg = false;
        if (screenWidth <= SCREEN_SIZE.MOBILE) {
            isMobile = true;
        }
        if (screenWidth >= SCREEN_SIZE.LAPTOP_LG) {
            isLaptopLg = true;
        }
        if (!isNewsPage) {
            if (isMobile) {
                mainImageStyle = {height: '164px', width: 'auto', objectFit: 'cover'};
                if (moreStories) {
                    mainImageStyle = {height: '224px', objectFit: 'cover'};
                }
            } else {
                if (isLaptopLg) {
                    mainImageStyle = {height: '300px', objectFit: 'cover'};
                } else {
                    mainImageStyle = {height: '210px', objectFit: 'cover'};
                }
            }
        } else {
            if (isMobile) {
                mainImageStyle = {height: '267.1px', objectFit: 'cover'};
            } else {
                mainImageStyle = {height: '600px', objectFit: 'cover'};
            }
        }
        if (isSingleNewsPage) {
            mainImageStyle = {height: '200px', width: 'auto', objectFit: 'cover'};
        }

        if (moreStories) {
            cardWidth = 'col-12';
        }
        return (
            <div className={`${cardWidth} ${extraClass}`}>
                <a href={`/news/${news.slug}`} className={'text-decoration-none text-dark'} target='_blank' rel="noreferrer">
                    {
                        news && news.is_snack ?
                            renderSnack(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, target) :
                            renderNewsNow(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor)
                    }
                </a>
            </div>
        );
    }

    return render();
}

NewsCard.propTypes = {
    isNewsPage: PropTypes.bool,
    moreStories: PropTypes.bool,
    isMobile: PropTypes.bool,
    classProps: PropTypes.string,
    newsItem: PropTypes.object.isRequired,
}

export default NewsCard;
