import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const ColorIcon = (props) => {

    let style = {};
    let color = props.color;
    let colorHex = props.colorHex;
    color = color.split("/");
    if (color.length === 1){
        let colorCode = colorHex[color[0]];
        if (props.filters) {
            style = {
                background: colorCode,
                height: "30px",
                width: "30px",
            }
        } else {
            style = {
                background: colorCode,
                height: "20px",
                width: "20px",
            }
        }

    } else {
        let colorCode1 = colorHex[color[0]];
        let colorCode2 = colorHex[color[1]];
        if (props.filters) {
            style = {
                background: `linear-gradient(to right, ${colorCode1} 50%, ${colorCode2} 50%)`,
                height: "30px",
                width: "30px",
            }
        } else {
            style = {
                background: `linear-gradient(to right, ${colorCode1} 50%, ${colorCode2} 50%)`,
                height: "20px",
                width: "20px",
            }
        }
    }

    const renderTooltip = (color) => (
        <Tooltip id="button-tooltip">
            {color}
        </Tooltip>
    );

    const render = () => {
        return (
            <>
                <OverlayTrigger placement={`top`} overlay={renderTooltip(props.color)}>
                    <span className="color-circle" style={style}></span>
                </OverlayTrigger>
            </>
        );
    }
    return render();
}