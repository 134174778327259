import React from 'react';
import '../assets/styles/footer.css';
import '../assets/styles/styles.css';
import {LOGO_MEDIUM, TEAM_NAME} from "../Configs/Targets";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {GrAndroid} from "react-icons/gr";
import {getHostname} from "../CustomFunctions/getHostname";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const Footer = () => {
    const store = useSelector(state => state);

    const render = () => {

        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navLogo = LOGO_MEDIUM[target];
        const navLogoMedium = LOGO_MEDIUM[target];
        const navName = TEAM_NAME[target];
        let urls = store.misc.appInit;

        return (
            <div className={'bg-footer pt-4 pb-4'}>
                <div className={'container-xxl container-xl container-lg container-md container-fluid'}>
                    <div className={'row'}>
                        <div className={'col text-footer d-none d-lg-block d-xl-block d-xxl-block'}>
                            <img src={navLogo} alt={navName} height={119}/>
                            <div className={'sfProDisplayFont font-18'}>2022 {navName}</div>
                            <div className={'sfProDisplayFont font-18'}>All rights reserved</div>
                        </div>
                        <div className={'col text-footer'}>
                            <div className={'sfProDisplayFont font-20'}>Follow Us</div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={urls && urls.facebook_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    <FontAwesomeIcon icon={faFacebook} color={'#686868'} size={'1x'}/>
                                    &nbsp;&nbsp;
                                    Facebook
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={urls && urls.twitter_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    <FontAwesomeIcon icon={faTwitter} color={'#686868'} size={'1x'}/>
                                    &nbsp;&nbsp;
                                    Twitter
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={urls && urls.instagram_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    <FontAwesomeIcon icon={faInstagram} color={'#686868'} size={'1x'}/>
                                    &nbsp;&nbsp;
                                    Instagram
                                </a>
                            </div>
                        </div>
                        <div className={'col text-footer'}>
                            <div className={'sfProDisplayFont font-20'}>Legal</div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={'/terms'} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    Terms of Use
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={'/privacy'} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    Privacy Policy
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={'/cookies'} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    Cookie Policy
                                </a>
                            </div>
                        </div>
                        <div className={'col text-footer'}>
                            <div className={'sfProDisplayFont font-20'}>Download the App</div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={urls && urls.ios_default_app_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    <FontAwesomeIcon icon={faApple} color={'#686868'} size={'1x'}/>
                                    &nbsp;&nbsp;
                                    App Store
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-18'}>
                                <a href={urls && urls.android_default_app_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                                    <GrAndroid color={'#686868'}/>
                                    &nbsp;&nbsp;
                                    Google Play
                                </a>
                            </div>
                        </div>
                        {
                            target === 'fanzine' && (
                                <div className={'col text-footer'}>
                                    <div className={'sfProDisplayFont font-18'}>
                                        <Link to={'football_content'} className={'text-decoration-none'} style={{color: '#686868'}}>Football</Link>
                                    </div>
                                    <div className={'sfProDisplayFont font-18'}>
                                        <Link to={'cricket_content'} className={'text-decoration-none'} style={{color: '#686868'}}>Cricket</Link>
                                    </div>
                                    <div className={'sfProDisplayFont font-18'}>
                                        <Link to={'nba_content'} className={'text-decoration-none'} style={{color: '#686868'}}>NBA</Link>
                                    </div>
                                    <div className={'sfProDisplayFont font-18'}>
                                        <Link to={'nfl_content'} className={'text-decoration-none'} style={{color: '#686868'}}>NFL</Link>
                                    </div>
                                    <div className={'sfProDisplayFont font-18'}>
                                        <Link to={'mlb_content'} className={'text-decoration-none'} style={{color: '#686868'}}>MLB</Link>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className={'col text-footer mt-3 d-block d-lg-none d-xl-none d-xxl-none'}>
                        <img src={navLogoMedium} alt={navName} height={'75'}/>
                        <span className={'d-inline-flex flex-column align-middle ms-3'}>
                            <div className={'sfProDisplayFont'}>2022 {navName}</div>
                            <div className={'sfProDisplayFont'}>All rights reserved</div>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return render();
}

export default Footer;