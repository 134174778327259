import React, {useEffect} from "react";
import './styles/NavbarCard.css';
import moment from "moment";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {COLORS_RGBA} from "../../Configs/Targets";
import {getHostname} from "../../CustomFunctions/getHostname";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const RecentResultCard = (props) => {
    let nextMatch = props.nextMatch;

    useEffect(() => {
    }, [nextMatch]);

    const render = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const colorRgba = COLORS_RGBA[target];

        let cardBackgroundStyle = {};

        if (nextMatch && Object.keys(nextMatch).length) {
            cardBackgroundStyle = {
                background: `linear-gradient(to bottom, ${colorRgba}0.7) 0%, ${colorRgba}0.7)), url(${nextMatch.venue.stadium_image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionY: 'center',
            };
        }
        return (
            <>
                {
                    nextMatch && Object.keys(nextMatch).length && (
                        // <Card className="next-match-card" style={cardBackgroundStyle}>
                        //     {/*<div className="color-layer"/>*/}
                        //     <Card.Body>
                        //         <div className="mb-auto">
                        //             <Card.Title className={'bebasFont text-header'}>Next Match</Card.Title>
                        //             <Card.Subtitle className="mb-3 mt-1 text-center match-date small sfProDisplayFont">
                        //                 {moment(nextMatch.date).format('ddd D MMMM')}
                        //             </Card.Subtitle>
                        //             <Card.Text className="text-center score sfProDisplayFont">
                        //                 {nextMatch.home_team.name} <img src={nextMatch.home_team.icon} width="50" alt={'home team icon'}/>
                        //                 17:30
                        //                 <img src={nextMatch.guest_team.icon} width="50" alt={'away team icon'}/> {nextMatch.guest_team.name}
                        //             </Card.Text>
                        //             <Card.Subtitle className="mb-2 text-center match-date small sfProDisplayFont">{nextMatch.league_name}</Card.Subtitle>
                        //             {/*<div className="text-end">
                        //         <div className={'col text-white text-end acuminFont text-nowrap'}>
                        //             Preview
                        //             &nbsp;&nbsp;&nbsp;&nbsp;
                        //             <FontAwesomeIcon icon={faAngleRight} size={'1x'}/>
                        //         </div>
                        //     </div>*/}
                        //         </div>
                        //     </Card.Body>
                        // </Card>
                        <div className={`next-match-card`} style={cardBackgroundStyle}>
                            {/*<div className={`color-layer`}/>*/}
                            <div className={`mt-n1`}>
                                <div className={`text-header bebasFont`}>Next Match</div>
                                <p className={`text-center match-date small sfProDisplayFontBold mb-0 mt-n2`}>{moment(nextMatch.date).format('ddd D MMMM')}</p>
                                <div className={`text-center sfProDisplayFontBold  mt-n1`}>
                                    {nextMatch.home_team.name} <img src={nextMatch.home_team.icon} width="50" alt={'home team icon'}/>
                                    17:30
                                    <img src={nextMatch.guest_team.icon} width="50" alt={'away team icon'}/> {nextMatch.guest_team.name}
                                    <p className={`text-center match-date small sfProDisplayFontBold mb-0 mt-n1`}>{nextMatch.league_name}</p>
                                </div>
                                {/*<div className={'match-date small sfProDisplayFontBold text-white text-end acuminFont text-nowrap'}>
                                    Preview
                                    &nbsp;
                                    <FontAwesomeIcon icon={faAngleRight} size={'1x'}/>
                                </div>*/}
                            </div>
                        </div>
                    )
                }
            </>
        );
    }

    return render();
}

export default RecentResultCard;