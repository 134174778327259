import Loader from '../Components/Loader/Loader';
import React, {useEffect, useState} from 'react';
import GoogleAd from '../Components/SnackAds/GoogleAd';
import {get} from '../Axios/get';
import {FOOTBALL, MLB} from '../Axios/EndPoints';
import moment from 'moment-timezone';
import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import {SCREEN_SIZE} from '../Configs/Constants';
import {SubNavbar} from './Components/SportSpecific/Football/SubNavbar';
import NewsCard from './Components/NewsCard';
import Social from '../Components/Social/Social';
import NewsOnSlider from './Components/SportSpecific/Football/NewsOnSlider';

const FanzineAllFootballTable = () => {
    let [isMobile, setIsMobile] = useState(false);
    let [loading, setLoading] = useState(true);

    let [allLeagues, setAllLeagues] = useState(null);
    let [allLeaguesLoading, setAllLeaguesLoading] = useState(true);
    let [transferNews, setTransferNews] = useState([]);
    let [transferNewsLoading, setTransferNewsLoading] = useState(true);

    let [latestNews, setLatestNews] = useState([]);
    let [latestNewsLoading, setLatestNewsLoading] = useState(true);

    let [allLeagueNewsDataLoading, setAllLeagueNewsDataLoading] = useState(true);
    let [allLeagueVideoDataLoading, setAllLeagueVideoDataLoading] = useState(
        true);
    let [allLeagueNewsData, setAllLeagueNewsData] = useState([]);
    let [allLeagueVideoData, setAllLeagueVideoData] = useState([]);

    const fetchTransferNews = () => {
        let page = '&page=1';
        let limit = '?limit=6';
        let url = MLB.NewsEndPoint + limit + page;
        get(FOOTBALL.TransferNewsEndPoint).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setTransferNews(tempNewsData);
            // setLoading(false);
            setTransferNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLeagues = () => {
        // let parameters = '?league_id=1204&limit=15';

        get(FOOTBALL.AllLeaguesEndPoint).then((response) => {
            console.log('response in getting leagues =', response.data.leagues);
            setAllLeagues(response.data?.leagues);
            // setLoading(false);
            setAllLeaguesLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };
    const fetchLatestNews = () => {
        let page = '&page=1';
        let limit = '?limit=81';

        get(FOOTBALL.LatestNewsEndPoint + limit).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setLatestNews(tempNewsData);
            // setLoading(false);
            setLatestNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchSpecificLeagueNews = async (league_Data) => {
        let leagueData = league_Data;
        let page = '&page=1';
        let limit = '?limit=7';
        let leagueId = '&league_id=' + leagueData?.league_id;
        let leagueName = leagueData?.name.replace(' ', '');
        await get(FOOTBALL.LatestNewsEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return

            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data, index) => {
                let news = {
                    leagueId: leagueData?.league_id,
                    leagueName: leagueName,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            (allLeagueNewsData.length <= allLeagues.length) &&
            setAllLeagueNewsData(
                allLeagueNewsData => [...allLeagueNewsData, tempNewsData]);
        }).catch((err) => {
            console.error(err);
        });
    };
    const fetchSpecificLeagueVideos = async (league_Data) => {
        let leagueData = league_Data;
        let limit = '?limit=15';
        let leagueId = '&league_id=' + leagueData?.league_id;

        await get(FOOTBALL.VideoEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return
            setAllLeagueVideoData(
                allLeagueVideoData => [...allLeagueVideoData, response.data]);
            // setLatestVideos(response.data);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    useEffect(() => {
        fetchLeagues();
        fetchLatestNews();
        fetchTransferNews();
    }, []);

    useEffect(() => {
        if (allLeagues && allLeagueNewsData.length === 0 &&
            allLeagueVideoData.length === 0) {
            for (let i = 0; i < allLeagues?.length; i++) {
                (allLeagueNewsData.length <= allLeagues.length) &&
                fetchSpecificLeagueNews(allLeagues[i]);
                (allLeagueVideoData.length <= allLeagues.length) &&
                fetchSpecificLeagueVideos(allLeagues[i]);
            }
        }
        setAllLeagueNewsDataLoading(false);
        setAllLeagueVideoDataLoading(false);
        setLoading(false);
    }, [allLeagues]);

    const renderNewsSkeleton = (sport, isMobile) => {
        return (
            <Container
                className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
                <div className={'customContainer'}>
                    <Link
                        className={`col-12 sfProDisplayFontBold font-22 text-decoration-none text-black mt-3`}
                        to={`/${sport.toLowerCase()}`}>
                        {sport}
                        <Icon.ArrowRight
                            className="bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2"
                            size="40"/>
                    </Link>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <Skeleton count={1} height={isMobile ? '300px' : '600px'}/>
                        </div>
                        <div className={'col-4'}>
                            <Skeleton count={2} height={isMobile ? '150px' : '300px'}/>
                        </div>
                    </div>
                    <Skeleton count={3} height={isMobile ? '150px' : '300px'}
                              width={'32%'} inline={true} className={'ms-3'}/>
                </div>
            </Container>
        );
    };

    const renderTransferNews = (News, Sport) => {
        const SportNews = News;
        const SportName = Sport;

        const renderLeftTransferNews = () => {
            return (
                <div className={`col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12`}>
                    <div className={'sfProDisplayFontBold font-28 container'}>
                        {'Transfer News'}
                    </div>
                    <div className={'row'}>
                        {SportNews.map((news, index) => {
                            return index < 8 &&
                                (
                                    <div
                                        className={`col-xl-6 m-0 p-1 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                    >
                                        <NewsCard
                                            isNewDesign={true}
                                            isMobile={isMobile}
                                            classProps={''}
                                            newsItem={news}
                                            isFanzine={false}
                                            // isNewsPage={true}
                                            isLatestNews={true}
                                            sport={SportName}
                                        />
                                    </div>
                                );
                        })}
                    </div>
                </div>
            );
        };

        const renderRightAds = () => {

            let numberOfAds = [1, 2, 3, 4];

            return (
                <div className={'col-4'}>
                    <div className={`row container`}>
                        {numberOfAds.map((news, index) => {

                            return (index < 4) && (
                                <div
                                    className={`${index === 0 &&
                                    'mt-4 pt-3'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                >
                                    <div
                                        style={{
                                            border: '1px black solid',
                                        }}
                                        className="d-flex justify-content-center align-items-center h1">
                                        <GoogleAd id={'4009154580'}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>);
        };

        const renderMain = () => {
            return (
                <div className={'row container ms-auto me-auto'}>
                    {renderLeftTransferNews()}
                    {!isMobile && renderRightAds()}
                </div>
            );
        };

        return renderMain();
    };

    const renderSpecificLeagues = (allSport, allVideos) => {
        const allSportsNews = allSport;
        const allSportsVideos = allVideos;

        return (
            <div>
                {
                    allSportsNews?.map((value, indexi) => (
                        <div key={indexi}>
                            <div
                                className="d-flex justify-content-center align-items-center h1">
                                <GoogleAd id={'4009154580'}/>
                            </div>

                            {allLeagueVideoData.length > 0 &&
                                <NewsOnSlider id={indexi} allLeagues={allLeagues}
                                              leagueName={value[0]?.leagueName}
                                              matchData={value} isMobile={false}
                                              loading={loading}/>
                            }
                            {renderLatestNews(latestNews, (indexi + 1) * 9, ((indexi + 1) * 9) + 9)}
                            {/*<div className={'row container ms-auto me-auto'}>
                                <div className={'col-8'}>
                                    <div className={`row `}>
                                        {value?.map((news, index) => (
                                            index === 0 ? (<div
                                                    className={`mt-4 m-0 p-1 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                                >
                                                    <NewsCard
                                                        BigImage={true}
                                                        isNewDesign={true}
                                                        isFanzine={true}
                                                        classProps={''}
                                                        newsItem={news}
                                                        isMain={true}
                                                        // sport={SportName}
                                                    />
                                                </div>)
                                                :
                                                (<div
                                                    className={`mt-4 m-0 p-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                                >
                                                    <NewsCard
                                                        isNewDesign={true}
                                                        isMobile={isMobile}
                                                        classProps={''}
                                                        newsItem={news}
                                                        isFanzine={false}
                                                        // isNewsPage={true}
                                                        // sport={SportName}
                                                    />
                                                </div>)
                                        ))}
                                    </div>
                                </div>
                                <div className={'col-4'}
                                >
                                    {renderRightAds()}
                                </div>
                            </div>*/}
                            <div
                                style={{
                                    border: '1px black solid',
                                }}
                                className="d-flex container justify-content-center align-items-center h1 mt-5">
                                <GoogleAd id={'4009154580'}/>
                            </div>
                        </div>
                    ))}
            </div>
        );
    };

    const renderLatestNews = (allSport, sliceStart, sliceEnd, last = false) => {
        return (
            <div className={'container row ms-auto me-auto'}>
                <div className={'col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12'}>
                    <div className={'row'}>

                        {allSport?.slice(sliceStart, sliceEnd).map((value, index) =>

                            index === 0 ? (
                                    <div
                                        className={`mt-4 m-0 p-1 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                    >
                                        <NewsCard
                                            BigImage={true}
                                            isNewDesign={true}
                                            isFanzine={true}
                                            classProps={''}
                                            newsItem={value}
                                            isMain={true}
                                            isMobile={isMobile}
                                            // sport={SportName}
                                        />
                                    </div>
                                )
                                :
                                (
                                    <div
                                        className={`mt-4 m-0 p-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                    >
                                        <NewsCard
                                            isNewDesign={true}
                                            isMobile={isMobile}
                                            classProps={''}
                                            newsItem={value}
                                            isFanzine={false}
                                            // isNewsPage={true}
                                            // sport={SportName}
                                        />
                                        {
                                            index % 10 === 0 && (
                                                <div
                                                    style={{
                                                        border: '1px black solid',
                                                        height: '400px'
                                                    }}
                                                    className={`d-flex justify-content-center align-items-center h1 mt-5`}>
                                                    <GoogleAd id={'4009154580'}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                        )}
                    </div>
                </div>
                {
                    !isMobile && (
                        <div className={'col-4'}>
                            {
                                sliceStart === 0 ?
                                    (
                                        <Social
                                            isCurrent={true}
                                            isSocial={false}
                                            isFanzine={true}
                                            isNewsPage={false}
                                            limit={50}
                                            setLoading={setLoading}
                                        />
                                    ) :
                                    renderRightAds(last)
                            }
                        </div>
                    )
                }

            </div>
        );
    };

    const renderRightAds = (last = false) => {

        let numberOfAds = [1, 2, 3, 4];

        return (
            <div className={'col-12'}>
                {numberOfAds.map((news, index) => {

                    return (
                        <div
                            className={`${index === 0 &&
                            'mt-3'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                        >
                            <div
                                style={{
                                    border: '1px black solid',
                                    height: '400px'
                                }}
                                className={`d-flex justify-content-center align-items-center h1 ${(last && index === 3) ? 'sticky-bottom' : ''}`}>
                                <GoogleAd id={'4009154580'}/>
                            </div>
                        </div>
                    );
                })}
            </div>);
    };

    const renderMain = () => {
        return (
            <div className={'bg-white'}>
                <SubNavbar isMobile={isMobile}/>
                {
                    loading && <Loader loading={loading}/>
                }
                {!loading &&
                    renderLatestNews(latestNews, 0, 9)
                }
                <div
                    style={{
                        border: '1px black solid',
                    }}
                    className="d-flex container justify-content-center align-items-center h1">
                    <GoogleAd id={'4009154580'}/>
                </div>
                {!transferNewsLoading ?
                    renderTransferNews(transferNews, 'Transfer News', isMobile,
                        'Transfer News') :
                    renderNewsSkeleton('Football')}

                <div
                    style={{
                        border: '1px black solid',
                    }}
                    className="d-flex container justify-content-center align-items-center h1">
                    <GoogleAd id={'4009154580'}/>
                </div>

                {!loading && !allLeagueNewsDataLoading && !allLeagueVideoDataLoading ?
                    renderSpecificLeagues(allLeagueNewsData, allLeagueVideoData) :
                    renderNewsSkeleton('Football')}

                {renderLatestNews(latestNews, (allLeagueNewsData.length) * 9, latestNews.length - 1, true)}
            </div>
        );

    };

    return renderMain();

};

export default FanzineAllFootballTable;
