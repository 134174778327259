import {Link, useLocation} from "react-router-dom";
import MobileNewsMenuItem from "../../../../Components/Navbar/MobileNewsMenuItem";
import {useState} from "react";

export const SubNavbar = (props) => {

    const location = useLocation();
    const {isMobile} = props;

    const [currentActive, setCurrentActive] = useState('')

    const renderMobileMenu = () => {
        return (
            <div className={'slider-mobile-news-container'}>
                <MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'Home'} uri={`/football`} selected={location.pathname === '/football'}/>
                <MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'News'} uri={`/football/news`} selected={location.pathname === '/football/news'}/>
                <MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'Matches'} uri={`/football/matches`} selected={location.pathname === '/football/matches'}/>
                <MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'Tables'} uri={`/football/table`} selected={location.pathname === '/football/table'}/>
            </div>
        );
    }

    return (
        <div className={`bg-fanzine-dark d-flex align-items-center p-2 fanzine-football-nav`}
        style={{height:'60px'}}
        >
            <div className={`container`}>
                <div className={`row ${isMobile ? 'pt-2' : 'ms-3'}`}>
                    <div className={`${isMobile ? 'col-5' : 'd-flex align-items-center col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-5'} text-black`}>
                        <img src={require('../../../../assets/images/default-league.png')} alt={`Fanzine Football Logo`} height={30}/>
                        &nbsp;&nbsp;&nbsp;
                        Football
                        &nbsp;&nbsp;&nbsp;
                        |
                    </div>
                    <div className={`${isMobile ? 'col-7' : 'd-flex align-items-center col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-7'}`}>
                        {
                            isMobile ? renderMobileMenu() :
                                (
                                    <div className={`d-flex flex-row justify-content-start`}>
                                        <Link to={'/football'} className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname === '/football' ? 'active font-weight-800' : ''}`}>
                                            Home
                                        </Link>
                                        <Link to={'/football/news'} className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname === '/football/news' ? 'active font-weight-800' : ''}`}>
                                            News
                                        </Link>
                                        <Link to={'/football/matches'} className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname === '/football/matches' ? 'active font-weight-800' : ''}`}>
                                            Matches
                                        </Link>
                                        <Link to={'/football/table'} className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname === '/football/table' ? 'active font-weight-800'  : ''}`}>
                                            Tables
                                        </Link>
                                        {/*<Link to={'/football/tables'} className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname === '/football/tables' ? 'active font-weight-800' : ''}`}>
                                Tables
                            </Link>*/}
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
