import React, {useEffect, useState} from "react";
import {withRouter} from "../CustomFunctions/withRouter";
import {get} from "../Axios/get";
import {NewsEndPoint} from "../Axios/EndPoints";
import {Container} from "react-bootstrap";
import NewsCard from "../Components/Cards/NewsCard";
import moment from "moment";
import {LOADING_GIF, TEXT_COLOR} from "../Configs/Targets";
import News from "../Components/Homepage/News";
import {chunk} from "../Components/Helper/chunk";
import {getHostname} from "../CustomFunctions/getHostname";
import {BsLink45Deg} from "react-icons/bs";
import {SCREEN_SIZE} from "../Configs/Constants";
import Billboard from "../Components/SnackAds/Billboard";
import DMPU from "../Components/SnackAds/DMPU";
import Loader from "../Components/Loader/Loader";
import RecommendationUnit from "../Components/SnackAds/RecommendationUnit";
import VideoAd from "../Components/SnackAds/VideoAd";
import Leaderboard from "../Components/SnackAds/Leaderboard";

const SingleNewsPage = (props) => {
    let newsId = props.match.params.id;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let textColor = TEXT_COLOR[target];
    let loadingGif = LOADING_GIF[target];

    let [iframeHeight, setIframeHeight] = useState(0);
    let [iframeData, setIframeData] = useState('null');
    let [latestNews, setLatestNews] = useState([]);
    let [relatedStories, setRelatedStories] = useState([]);
    let [news, setNews] = useState(null);
    let [newsContent, setNewsContent] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../assets/images/football-shots-you-need-to-know.jpg");
    }

    const fetchSingleNews = () => {
        get(NewsEndPoint + `/slug/${newsId}`)
            .then((response) => {
                setNews(response.data);
                if (!response.data.is_snack) {
                    // setNewsContent()
                    window.open(response.data.url, '_self');
                } else {
                    if (target !== 'fanzine'){
                        window.open(`https://fanzine.com/football/${response.data.main_team}/${response.data.slug}`, '_self');
                    }
                    let val = response.data.text;
                    let parser = new DOMParser();
                    let valAsHtml = parser.parseFromString(val, "text/html");
                    let h2Tags = valAsHtml.getElementsByTagName('h2');
                    for (let i = 0; i < h2Tags.length; i++) {
                        valAsHtml.getElementsByTagName('h2')[i].classList.add('bebasFont');
                    }
                    let pTags = valAsHtml.getElementsByTagName('p');
                    for (let i = 0; i < pTags.length; i++) {
                        valAsHtml.getElementsByTagName('p')[i].classList.add('sfProDisplayFont');
                    }
                    let imageTag = valAsHtml.getElementsByTagName('img');
                    for (let i = 0; i < imageTag.length; i++) {
                        valAsHtml.getElementsByTagName('img')[i].classList.add('img-fluid');
                    }
                    let iframeTag = valAsHtml.getElementsByTagName('iframe');
                    for (let i = 0; i < iframeTag.length; i++) {
                        valAsHtml.getElementsByTagName('iframe')[i].classList.add('w-fill');
                    }
                    let gmsVideoContainers = valAsHtml.getElementsByClassName('gms-video-container');
                    for (let i = 0; i < gmsVideoContainers.length; i++) {
                        valAsHtml.getElementsByClassName('gms-video-container')[i].remove();
                    }
                    let cInblogAd = valAsHtml.getElementsByClassName('c-inblog_ad');
                    for (let i = 0; i < cInblogAd.length; i++) {
                        valAsHtml.getElementsByClassName('c-inblog_ad')[i].remove();
                    }
                    let inblogAdWide = valAsHtml.getElementsByClassName('inblog_ad_wide');
                    for (let i = 0; i < inblogAdWide.length; i++) {
                        valAsHtml.getElementsByClassName('inblog_ad_wide')[i].remove();
                    }
                    val = valAsHtml.getElementsByTagName('body')[0].innerHTML;
                    window.newsContent = valAsHtml;
                    val = val.replaceAll('</p>', '</p>###');
                    let split = val.split('###');
                    let newArr = split.filter((a) => a);

                    setNewsContent(newArr);

                    if (target === 'fanzine') {
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const fetchLatestNews = () => {
        get(NewsEndPoint + `?main_site=1&limit=31`)
            .then((response) => {
                let prepareLatestNews = response.data.slice(0, 3);
                let prepareRelatedStories = response.data.slice(3, 31);
                let tempLatestNews = [], tempRelatedStories = [];
                prepareLatestNews.map((data) => {
                    let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                    };
                    return tempLatestNews.push(news);
                });
                prepareRelatedStories.map((data) => {
                    let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                    };
                    return tempRelatedStories.push(news);
                });
                setLatestNews(tempLatestNews);
                setRelatedStories(tempRelatedStories);
            });
    }

    useEffect(() => {
        setLoading(true);
        fetchSingleNews();
        if (target === 'fanzine') {
            fetchLatestNews();
        }
    }, [newsId]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const renderNewsHead = () => {
        return (
            <>
                <div className={'h1 bebasFont mt-3'} dangerouslySetInnerHTML={{__html: news.title}}/>
                <div>
                    {/*<img onError={addDefaultSrc} src={news.publisher.icon} alt="News"
                         height={30}/>
                    <span className={'sfProDisplayFont ms-2 me-2'}>{news.publisher.name}</span>*/}
                    <span className={`${textColor} font-weight-thin`}>Published </span>
                    <span>{moment(news.datetime).format('D MMM YYYY, H:mm')}</span>

                    <span>
                        <a
                            href={news.is_snack ? `https://fanzine.com/football/${news.main_team}/${news.slug}` : news.url}
                            className={`ms-3`}
                            target={'_self'}
                            rel="noreferrer">
                            <BsLink45Deg size='20'/>
                        </a>
                    </span>
                </div>
                <div className={'mb-5'}>
                    <img src={news.image} alt="Fanzine" className={`img-fluid rounded-custom mt-4 border`}/>
                </div>
            </>
        );
    }

    const renderBillboardBanner = () => {
        return (
            <div className="d-flex justify-content-center">
                {isMobile ? <Leaderboard id={'snack_mob_top'}/> : <Billboard id={'snack_dex3'}/>}
            </div>
        );
    }

    function renderRecommendationUnits() {
        return (<RecommendationUnit/>);
    }

    function renderRelatedStories() {
        return (
            <div className="row">
                <span className={'bebasFont font-weight-bold h3'}>Related Stories</span>
                {
                    relatedStories.length ?
                        (
                            chunk(relatedStories, 2).map((value) => (
                                <News moreStories={true} news={value} isSingleNewsPage={true}/>
                            ))
                        )
                        : null
                }
            </div>
        );
    }

    function renderRightContent() {
        return (
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                {!isMobile && <div
                    className={'double-mpu d-flex justify-content-center flex-column align-items-center h1 text-center'}>
                    <DMPU id={'snack_dex10'}/>
                </div>}
                <div className={`bebasFont mt-2 mb-2 h1`}>
                    More Stories
                </div>
                {
                    latestNews.length ?
                        (
                            <>
                                <NewsCard newsId={1} isMobile={true} newsItem={latestNews[0]} isSingleNewsPage={true}/>
                                <NewsCard newsId={1} isMobile={true} newsItem={latestNews[1]} isSingleNewsPage={true}/>
                                <NewsCard newsId={1} isMobile={true} newsItem={latestNews[2]} isSingleNewsPage={true}/>
                            </>
                        )
                        : null
                }
                {!isMobile && <div
                    className={'sticky-mpu d-flex justify-content-center flex-column align-items-center h1 text-center sticky-top'}>
                    <DMPU id={'snack_dmpu'}/>
                </div>}
            </div>
        );
    }

    const render = () => {
        return (
            <>
                <Container className={'mt-5 w-100 a-header-b'}>
                    {renderBillboardBanner()}
                    <div className={'row'}>
                        <div className={'col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12'}>
                            {
                                news ?
                                    renderNewsHead()
                                    : null
                            }
                            {
                                iframeHeight > 0 ?
                                    <iframe
                                        style={{height: iframeHeight}}
                                        height={iframeHeight}
                                        // src={'https://www.derbytelegraph.co.uk/sport/football/football-news/derby-county-wayne-rooney-sent-6589094'}
                                        srcDoc={iframeData}
                                        width={'100%'}
                                        scrolling={'no'}
                                    /> : <></>
                            }

                            {renderRecommendationUnits()}

                            {renderRelatedStories()}
                        </div>
                        {renderRightContent()}
                    </div>
                </Container>
            </>
        );
    }

    const renderSnack = () => {

        return (
            <>
                <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 ${!isMobile ? 'd-flex flex-row justify-content-center' : ''}`}>
                    <div className={'customContainer'}>
                        {renderBillboardBanner()}

                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <div className={`col-12`}>
                                    {renderNewsHead()}
                                    <Container className={'mt-3'}>
                                        {
                                            newsContent.map((value, index) => {
                                                return (
                                                    <>
                                                        <div dangerouslySetInnerHTML={{__html: value}}/>
                                                        {index === 1 ?
                                                            (<div className={`single-mpu`}>
                                                                <VideoAd/>
                                                            </div>) : null}
                                                        {index === 3 ?
                                                            (<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                                                ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                                                {isMobile ? <Billboard id={'snack_mex4'}/> : <Billboard id={'snack_dex4'}/>}
                                                            </div>) : null}
                                                        {index === 6 ?
                                                            (<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                                                ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                                                {isMobile ? <Billboard id={'snack_mex5'}/> : <Billboard id={'snack_dex5'}/>}
                                                            </div>) : null}
                                                        {index === 9 ?
                                                            (<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                                                ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                                                {isMobile ? <Billboard id={'snack_mex6'}/> : <Billboard id={'snack_dex6'}/>}
                                                            </div>) : null}
                                                        {index === 12 ?
                                                            (<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                                                ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                                                {isMobile ? <Billboard id={'snack_mex7'}/> : <Billboard id={'snack_dex7'}/>}
                                                            </div>) : null}
                                                    </>
                                                );
                                            })
                                        }

                                        {/*<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-6 col-xl-6 col-lg-6 col-md-6' +
                                            ' col-sm-6 offset-xxl-3 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-3'}>
                                            <MPU id={'snack_dex9'}/>
                                        </div>*/}

                                    </Container>

                                    {renderRecommendationUnits()}

                                    {renderRelatedStories()}
                                </div>
                            </div>
                            {renderRightContent()}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    return (
        <>
            <Loader loading={loading}/>
            {
                target === 'fanzine'
                    ? (news && news.is_snack ? renderSnack() : null/*render()*/)
                    : <div
                        className={'iframe-content'}>
                        {/*<iframe
                            height={'100%'}
                            width={'100%'}
                            onLoad={() => {
                                setLoading(false);
                            }
                            }
                            src={news && `https://fanzine.com/football/${news.main_team}/${news.slug}`}/>*/}
                    </div>
            }
        </>
    );
}

export default withRouter(SingleNewsPage);
