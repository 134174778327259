import Card from "react-bootstrap/Card";
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {get} from "../Axios/get";
import {FANZINE} from '../Axios/EndPoints'
import {SCREEN_SIZE} from "../Configs/Constants";
import Slider from 'react-slick';

const FanzineFanatics = () => {
    const location = useLocation();
    const [wcTeams, setWCTeams] = useState(null);
    const [bannerData, setBannerData] = useState(null)
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [isMobile, setIsMobile] = useState(false);

    const addDefaultSrc = (e) => {
        e.target.value = require('../assets/images/Default.jpeg');
    }

    const fetchBannerData = () => {
        get(FANZINE.FanaticsShopNowEndPoint)
            .then((res) => {
                let data = [];

                res.data.data.map((banner) => {
                    if (window.innerWidth <= SCREEN_SIZE.MOBILE && banner.show_on_mobile) {
                        data.push(banner);
                    }
                    if (!(window.innerWidth <= SCREEN_SIZE.MOBILE) && banner.show_on_web) {
                        data.push(banner);
                    }
                });

                if (!data.length) {
                    data.push(res.data.data[0]);
                }

                setBannerData(data);
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const fetchTeams = () => {
        get(FANZINE.TeamsEndPoint)
            .then((res) => {
                setWCTeams(res?.data?.data?.international)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const renderSliderBannerMobile = (imageStyle) => {
        let settings = {
            infinite: true,
            dots: true,
            slidesToShow: 1,
            cssEase: 'linear',
            centerPadding: '100',
            arrows: false,
            slidesToScroll: 1,
            lazyLoad: false,
            autoplay: false,
        };
        return (
            <div className="image-carousel mb-5" style={{marginTop: '-50px !important'}}>
                <Slider {...settings}>
                    {bannerData.map((banner) => (
                        <div key={`banner_item_${banner.id}`} className={isMobile ? 'slide' : 'slide ms-5 me-5 ps-5 pe-5'}>
                            <div className={`row`}>
                                {renderBannerCard(imageStyle, banner)}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }

    const renderBannerCard = (imageStyle, banner) => {
        console.log('banner', banner);
        return (
            <Card className="border-0 bg-transparent">
                <Card.Img
                    variant="top"
                    className={isMobile ? '' : 'img-fluid'}
                    src={isMobile && banner ? banner.image : require('../assets/images/fanaticMain.png')}
                    alt="Card image"
                    onError={addDefaultSrc}
                    style={imageStyle}
                />
                <Card.ImgOverlay
                    className={`d-flex align-items-center flex-column justify-content-center `}>
                    <Card.Text className={`text-white bebasFont mt-auto ${isMobile ? 'h1' : 'h2'}`}>
                        <p className={'bebasFont text-center'} style={{
                            webkitTextStroke: '2px #fff',
                            webkitTextFillColor: 'transparent',
                            fontSize: isMobile ? '70px' : '500%',
                            opacity: isMobile ? '0.95' : '0.85'
                        }}>
                            Licensed <br/> Products
                        </p>
                    </Card.Text>
                    <img className={'mt-auto border-0'}
                         src={require('../assets/images/Fanatics_Primary_Color_RGB.png')}
                         alt={'fanatics'} height={'45px'}/>
                    <a href={banner != null && banner.url} target={'_blank'} className={'btn sfProDisplayFontBold mt-auto rounded-0 '} color={'none'}
                       style={{color: 'white', background: '#e53d2e'}} rel="noreferrer">
                        SHOP NOW
                    </a>
                </Card.ImgOverlay>
            </Card>
        );
    }

    const renderLicensedProducts = () => {

        let imageStyle = {
            opacity: isMobile ? '85%' : '0.75',
            height: isMobile ? '' : '70%',
        };

        if (isMobile && bannerData?.length) {
            return renderSliderBannerMobile(imageStyle);
        }

        return (
            <div
                className={isMobile ? '' : 'p-2'} style={{marginTop: isMobile ? '-80px' : ''}}>
                <div className={isMobile ? '' : 'row'}>
                    <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 "}>
                        <div className={'text-decoration-none text-dark'}>
                            {renderBannerCard(imageStyle, bannerData)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderInternationalsTeams = () => {
        let imageStyle = {
            height: isMobile ? '135px' : '230px',
            minHeight: isMobile ? '135px' : '230px',
            objectFit: 'cover',
        }

        return (
            <div
                className={'pb-5'}>
                <div className={'row'}>
                    {wcTeams != null && wcTeams?.map((value, index) => (
                        <Link
                            key={index}
                            to={`/shop/product/${value.id}`}
                            className={isMobile ? 'col-6 mt-3' : "col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-4"}>
                            <div className={'text-decoration-none text-dark'}>
                                <Card className="border-0 bg-transparent rounded-custom">
                                    <Card.Img
                                        variant="top"
                                        className={'img rounded-custom'}
                                        src={value?.icon?.replace('testcdn', 'cdn')}
                                        alt="Card image"
                                        onError={addDefaultSrc}
                                        style={imageStyle}
                                    />
                                    <Card.ImgOverlay
                                        className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end fanatics-teams-overlay rounded-custom`}>
                                        <Card.Text className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end text-white bebasFont ${isMobile ? 'h1' : 'h2'}`}>
                                            <p className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end bebasFont ${isMobile ? 'font-26' : 'font-32'}`}>
                                                {value?.name}
                                            </p>
                                        </Card.Text>
                                    </Card.ImgOverlay>
                                </Card>
                            </div>
                        </Link>
                    ))
                    }                </div>
            </div>
        )
    }

    useEffect(() => {
        fetchBannerData();
        fetchTeams();
    }, [])

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    return (
        <div className={isMobile ? '' : 'container'}>
            {renderLicensedProducts()}
            {renderInternationalsTeams()}
        </div>
    )

}

export default FanzineFanatics;