import React, { useEffect, useState } from "react";
import "./styles/custom.css";
import { FONT_COLOR, NAV_CLASS } from "../../Configs/Targets";
import "../../assets/styles/footballTargets.css";
import { MATCH_STATES, SCREEN_SIZE } from "../../Configs/Constants";
import { get } from "../../Axios/get";
import { HomepageFixtures } from "../../Axios/EndPoints";
import moment from "moment";
import { getHostname } from "../../CustomFunctions/getHostname";
import { useDispatch } from "react-redux";
import {
  setCurrentTeamId,
  setCurrentTeamName,
} from "../../redux/actions/FooballLeagueActions";
import ItemLoader from "../Loader/ItemLoader";

const ResultsFixtures = (props) => {
  const dispatch = useDispatch();

  let isFanzineAllFootball = props.isFanzineAllFootball;
  let setNextMatch = props.setNextMatch;
  let setHomePageStandings = props.setHomePageStandings;

  let [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let [fixtures, setFixtures] = useState([]);
  let [loading, setLoading] = useState(true);

  const fetchFixtures = () => {
    get(HomepageFixtures + "?web=1")
      .then((response) => {

        if(isFanzineAllFootball) {
          setNextMatch(response.data.data);
          setLoading(false);
        }
        else
        {
          let prevMatch = response.data.data[0].prev_match;
          let matches = response.data.data[0].matches;
          let teamId = response.data.data[0].current_team_id;
          let teamName = response.data.data[0].current_team_name;
          dispatch(setCurrentTeamId(teamId));
          dispatch(setCurrentTeamName(teamName));
          let tempFixtures = [];
          console.log("tempFixtures: ", response.data.data[0].prev_match);
          tempFixtures.push({
            id: prevMatch.id,
            league: {
              id: prevMatch.league_id,
              name: prevMatch.league_name,
            },
            opponent: {
              name:
                prevMatch.home_team.id === teamId
                  ? prevMatch.guest_team.name
                  : prevMatch.home_team.name,
              icon:
                prevMatch.home_team.id === teamId
                  ? prevMatch.guest_team.club_logo
                    ? prevMatch.guest_team.club_logo
                    : prevMatch.guest_team.icon
                  : prevMatch.home_team.club_logo
                  ? prevMatch.home_team.club_logo
                  : prevMatch.home_team.icon,
            },
            home: {
              name: prevMatch.home_team.name,
              icon: prevMatch.home_team.club_logo
                ? prevMatch.home_team.club_logo
                : prevMatch.home_team.icon,
            },
            venue: prevMatch.home_team.id === teamId ? "(H)" : "(A)",
            color: MATCH_STATES.IN_PLAY.includes(prevMatch.state)
              ? ""
              : prevMatch.win_team_id === teamId
              ? "#0e7d45"
              : prevMatch.win_team_id === 0
              ? ""
              : "#e02020",
            total: prevMatch.total,
            date: prevMatch.date,
            dateTime: prevMatch.datetime,
            formattedDate: moment(prevMatch.datetime).format("ddd MMM D"),
            formattedTime: moment(prevMatch.datetime).format("H:mm"),
            isPrevious: true,
          });
          matches.map((data) =>
            tempFixtures.push({
              id: data.id,
              league: {
                id: data.league_id,
                name: data.league_name,
              },
              opponent: {
                name:
                  data.home_team.id === teamId
                    ? data.guest_team.name
                    : data.home_team.name,
                icon:
                  data.home_team.id === teamId
                    ? data.guest_team.club_logo
                      ? data.guest_team.club_logo
                      : data.guest_team.icon
                    : data.home_team.club_logo
                    ? data.home_team.club_logo
                    : data.home_team.icon,
              },
              home: {
                name: data.home_team.name,
                icon: data.home_team.club_logo
                  ? data.home_team.club_logo
                  : data.home_team.icon,
              },
              venue: data.home_team.id === teamId ? "(H)" : "(A)",
              total: data.total,
              date: data.date,
              dateTime: data.datetime,
              formattedDate: moment(data.datetime).format("ddd MMM D"),
              formattedTime: moment(data.datetime).format("H:mm"),
            })
          );
          setFixtures(tempFixtures);
          setNextMatch(response.data.data[0].next_match);
          setHomePageStandings(response.data.data[0].standings.standings);  
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [screenWidth]);

  useEffect(() => {
    fetchFixtures();
  }, []);

  const withData = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const fontColor = FONT_COLOR[target];
    let scoreFontSize = screenWidth < SCREEN_SIZE.LAPTOP ? "small" : "font-16";

    return (
      <div className="col-12 border-1 border-secondary rounded">
        <div className={navColor + " rounded-custom-1 text-center p-1"}>
          <span className={`${fontColor} bebasFont text-header`}>
            RESULTS & FIXTURES
          </span>
        </div>
        <ul className={"bg-fixtures text-center list-unstyled p-0"}>
          <ItemLoader loading={loading} />
          {fixtures.map((value, index, fixtures) => {
            return (
              <>
                <li className={`p-3`}>
                  <div className={"row"}>
                    <div
                      className={`col-3 text-center mt-2 mb-2 text-light-black sfProDisplayFont font-16 font-weight-500`}
                    >
                      {/*{value.formattedDate} &nbsp;| &nbsp;{value.league.name}*/}
                      <img src={value.opponent.icon} width="45" alt="" />
                    </div>
                    <div
                      className={`col text-start sfProDisplayFont ${scoreFontSize}`}
                      style={{ color: value.color }}
                    >
                      <div className={`sfProDisplayFontBold font-14`}>
                        {value.formattedDate}
                      </div>
                      <div
                        className={`sfProDisplayFont font-14 `}
                        style={value.isPrevious ? {} : { color: "#969696" }}
                      >
                        {value.formattedTime}
                        &nbsp;&nbsp;|&nbsp;&nbsp;{value.league.name}
                        <br />
                        {value.isPrevious &&
                          value.venue === "(A)" &&
                          value.opponent.name + " "}
                        {value.isPrevious && `${value.total}\t`}
                        {(!value.isPrevious || value.venue === "(H)") &&
                          value.opponent.name + " "}
                        {value.venue}
                      </div>
                      {/*<img src={value.home.icon} height={10} alt=""/>*/}

                      {/*<img src={value.opponent.icon} height={10} alt=""/>*/}

                      {/*<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value.isPrevious && ('(' + value.total + ')')}*/}
                    </div>
                  </div>
                  {/*<div className={`divider mt-3`}/>*/}
                </li>
              </>
            );
          })}
        </ul>
      </div>
    );
  };

  const withoutData = () => {
    return <div className={`bebasFont text-black`}></div>;
  };

  const render = () => {
    if (fixtures) {
      return withData();
    }

    return withoutData();
  };

  return render();
};

export default ResultsFixtures;
