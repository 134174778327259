import React, {useEffect, useState} from "react";
import '../assets/styles/NFT.css'
import mobileBackground from '../assets/images/nft/Component 596 – 7@3x.jpg';
import star from '../assets/images/nft/sta– 1 (1).png';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import Loader from "../Components/Loader/Loader";
import Skeleton from "react-loading-skeleton";
import {ContactUsEndpoint, FANZINE} from "../Axios/EndPoints";
import Card from "react-bootstrap/Card";
import {Button, Col, FormControl, Modal, Row} from "react-bootstrap";
import {post} from "../Axios/post";
import SingleAccordian from "../Components/Accordian/Accordian";
import {SCREEN_SIZE} from "../Configs/Constants";
import {NFTNavBar} from "../Components/Navbar/NFTNavBar";
import {getHostname} from "../CustomFunctions/getHostname";
import {COLORS, FONT_COLOR, LOGO, NAV_CLASS} from "../Configs/Targets";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import ItemLoader from "../Components/Loader/ItemLoader";
import validator from "validator/es";
import clubs from '../assets/data/teams/clubs.json';
import internationals from '../assets/data/teams/international.json';
import tm1 from '../assets/images/nft/team/dog (1).png';
import tm2 from '../assets/images/nft/team/dog (2).png';
import tm3 from '../assets/images/nft/team/dog (3).png';
import tm4 from '../assets/images/nft/team/dog (4).png';

const NFT = () => {
    const [loading, setLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState(false);
    const [matchData, setMatchData] = useState([]);
    const [isNFTModalOpen, setIsNFTModalOpen] = useState(false)

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorSubject, setErrorSubject] = useState(false);

    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [subject, setSubject] = useState('');
    let [message, setMessage] = useState('');

    let [selectedInternational, setSelectedInternational] = useState('');
    let [selectedClub, setSelectedClub] = useState('');
    let [isMobile, setIsMobile] = useState('');
    let [isTablet, setIsTablet] = useState(false);
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [open, setOpen] = useState(false);
    let [successfulNft, setSuccessfulNFT] = useState(false);
    let [successfulContact, setSuccessfulContact] = useState(false);

    const handleNFTModal = () => {
        if (isNFTModalOpen) {
            setIsNFTModalOpen(false)
        } else {
            setIsNFTModalOpen(true)
        }
    }

    const handleModalSubmit = () => {
        setItemLoading(true);

        let formD = new FormData();

        if (email === '' || selectedInternational === '' || selectedClub === '') {
            alert('Please Complete Empty Fields.')
            setItemLoading(false);
        } else {

            formD.append('email_address', email)
            formD.append('international_team', selectedInternational)
            formD.append('club', selectedClub)

            post(FANZINE.NFTINTRESTEndPoint, formD).then((response) => {
                setSuccessfulNFT(true);
                setItemLoading(false);
            }).catch(err => console.log('error =', err));
        }


    }

    useEffect(() => {
        return (() => {
        })
    }, [isNFTModalOpen])

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        if (screenWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
        if (screenWidth <= SCREEN_SIZE.TABLET) {
            setIsTablet(true);
        }
    }, [screenWidth]);


    // Images Imports
    const utility1 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/Component 46 – 1@3x.png");
    }
    const utility2 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/Component 47 – 1@3x.png");
    }
    const utility3 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/Component 48 – 1@3x.png");
    }
    const utility4 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/Component 49 – 1@3x.png");
    }
    const utilityOverlayImg1 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/overimage/6699362@3x.png");
    }
    const utilityOverlayImg2 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/overimage/email-solid@3x.png");
    }
    const utilityOverlayImg3 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/overimage/bag-shopping@3x.png");
    }
    const utilityOverlayImg4 = (ev) => {
        ev.target.src = require("../assets/images/nft/utility/overimage/shirt (1)@3x.png");
    }
    const team1 = (ev) => {
        ev.target.src = require("../assets/images/nft/team/dog (1).png");
    }
    const team2 = (ev) => {
        ev.target.src = require("../assets/images/nft/team/dog (2).png");
    }
    const team3 = (ev) => {
        ev.target.src = require("../assets/images/nft/team/dog (3).png");
    }
    const team4 = (ev) => {
        ev.target.src = require("../assets/images/nft/team/dog (4).png");
    }

// UI Components
    const renderNFTModal = () => {

        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const fontColor = FONT_COLOR[target];
        const logo = LOGO[target];
        const color = COLORS[target];
        return (
            <Modal
                show={isNFTModalOpen}
                size="lg"
                className={'modal-radius-30'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={`text-center azo-sans-bold w-100 font-34 fanzine-color border-0 d-flex align-items-center justify-content-start`}>
                        <img src={logo} alt={''} style={{height: '70px', width: 'auto'}}/>
                        {'NFT Club'}
                    </Modal.Title>
                    <Button className={`btn rounded-circle bg-white border-0`} onClick={handleNFTModal}>
                        <FontAwesomeIcon icon={faTimesCircle} size={'2x'} color={color}/>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{marginLeft: '10%', width: '80%'}}>
                    <p className={successfulNft ? 'text-center w-100 font-48 fanzine-color sfProDisplayFont' : 'text-start w-100 font-24 sfProDisplayFont'}>
                        {successfulNft ? `YOU'RE IN` : `Register now for your chance to be
                        amongst the first owners of a Fanzine NFT`}
                    </p>
                    {successfulNft && <p className={'text-center w-100 font-30 sfProDisplayFont'}>
                        Fanzine will be in touch soon
                    </p>}
                    {successfulNft && <div className={'row'}>
                        <div className={'d-flex justify-content-center'} style={{cursor: 'pointer'}}>
                            <div className={'m-3 sfProDisplayFont font-14'}>
                                <a href={'https://twitter.com/Fanzine_com'} target={"_blank"}
                                   className={'text-decoration-none'}
                                   style={{}} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faTwitter} size={'2x'}/>
                                </a>
                            </div>
                            <div className={'m-3 sfProDisplayFont font-14'}>
                                <a href={'https://www.instagram.com/fanzine_com/'} target={"_blank"}
                                   className={'text-decoration-none'}
                                   style={{}} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faInstagram} size={'2x'}/>
                                </a>
                            </div>
                            <div className={'m-3 sfProDisplayFont font-14'}>
                                <a href={'https://www.facebook.com/FanzineLtd'} target={"_blank"}
                                   className={'text-decoration-none'}
                                   style={{}} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFacebook} size={'2x'}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    }
                    {!successfulNft && <div className={'row'}>
                        <form className={`p-3 pb-3`}>
                            <Row className={`mt-3`}>
                                <Col>
                                    <input type={'email'} className={`form-control`} value={email}
                                           placeholder={'Email Address'}
                                           style={{fontSize: isMobile ? '14px' : '20px'}}
                                           onChange={(e) => {
                                               setEmail(e.target.value)
                                           }}/>
                                </Col>
                            </Row>
                            <Row className={`mt-3`}>
                                <Col>
                                    <select className={`form-control`}
                                            style={{fontSize: isMobile ? '14px' : '20px'}}
                                            onChange={(e) => {
                                                setSelectedInternational(e.target.value)
                                            }}
                                            value={selectedInternational}
                                    >
                                        <option placeholder={'International team your support'}>International team you
                                            support
                                        </option>
                                        {internationals != null &&
                                            internationals?.map((team, index) => (
                                                <option key={index} value={team}>{team}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className={`mt-3`}>
                                <Col>
                                    <select className={`form-control`}
                                            style={{fontSize: isMobile ? '14px' : '20px'}}
                                            onChange={(e) => {
                                                setSelectedClub(e.target.value)
                                            }}
                                            value={selectedClub}
                                    >
                                        <option>Club team your support</option>
                                        {clubs != null &&
                                            clubs?.map((club, index) => (
                                                <option key={index} value={club}>{club}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </Row>
                        </form>
                    </div>
                    }
                    <Modal.Footer className={`w-100 border-0 justify-content-start`}>
                        {!successfulNft &&
                            <div className={'d-flex flex-row'}>
                                <Button
                                    className={`btn btn-lg bg-fanzine-dark float-start sfProDisplayFont font-26 border-0 ps-5 pe-5 text-nowrap`}
                                    onClick={handleModalSubmit}
                                    disabled={itemLoading}
                                >
                                    Sign Up
                                </Button>
                                <ItemLoader loading={itemLoading} small={true}/>
                            </div>
                        }
                    </Modal.Footer>
                    <Modal.Footer className={`w-100 border-0 justify-content-end`}>

                    </Modal.Footer>
                </Modal.Body>
            </Modal>);
    }

    const renderContactUsSuccessModal = () => {

        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const logo = LOGO[target];
        const color = COLORS[target];
        return (
            <Modal
                show={successfulContact}
                size="lg"
                className={'modal-radius-30'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={`text-center azo-sans-bold w-100 font-34 fanzine-color border-0 d-flex align-items-center justify-content-start`}>
                        <img src={logo} alt={''} style={{height: '70px', width: 'auto'}}/>
                        {'Success'}
                    </Modal.Title>
                    <Button className={`btn rounded-circle bg-white border-0`} onClick={() => {
                        setSuccessfulContact(false)
                    }}>
                        <FontAwesomeIcon icon={faTimesCircle} size={'2x'} color={color}/>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{marginLeft: '10%', width: '80%'}}>
                    {successfulContact && <p className={'text-center w-100 font-30 sfProDisplayFont'}>
                        Thank you for your message
                    </p>}
                </Modal.Body>
            </Modal>);
    }

    const renderComingSoon = () => {

        let countdownStyleMobile = {width: 30, height: 40, fontSize: 30}

        return (
            <div className={'container p-4'}>
                <div className={'row'}>
                    <div className={'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'}>
                        <div className={'row'}>
                            <h1 className={`text-white azo-sans ${(isMobile || isTablet) ? 'font-40' : 'font-nft-title'}`}>
                                {(isMobile || isTablet) ? (<>FANZINE
                                    <div className={"fanzine-color azo-sans"}>
                                        WORLD CUP
                                        <img src={star} alt={'star'} className={'star'}/>
                                    </div>
                                    NFTs</>) : (<>
                                    FANZINE WORLD <br/> CUP NFTs
                                </>)}
                            </h1>
                        </div>
                        {(!isMobile && !isTablet) && <div className={'row pt-2'}>
                            <h3 className={'sfProDisplayFont text-white font-nft'}>
                                Fanzine will soon begin releasing limited <br/>
                                edition, World Cup NFTs!
                            </h3>
                        </div>}
                        {(!isMobile && !isTablet) && <div className={'row pt-3'}>
                            <h3 className={'sfProDisplayFont text-white font-nft'}>
                                Featuring dogs in retro kits <br/> and fixture based cards.
                            </h3>
                        </div>}
                        {(!isMobile && !isTablet) && <div className={'row pt-3'}>
                            <FlipClockCountdown
                                digitBlockStyle={(isMobile || isTablet) ? countdownStyleMobile : ''}
                                to={new Date('2022-11-08T00:00:00').getTime() + 24 * 3600 * 1000 + 5000}
                                className='flip-clock'
                                labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                                duration={0.5}
                            />
                        </div>}

                        <div className={`row ${(isMobile || isTablet) && 'ms-1'} pt-3`}
                             style={{width: (isMobile || isTablet) ? '100%' : '75%'}}>
                            <button className={`sfProDisplayFont ${(isMobile || isTablet) ? 'font-26' : 'font-nft'}`}
                                    onClick={handleNFTModal}
                                    style={{
                                        textTransform: 'uppercase',
                                        borderRadius: (isMobile || isTablet) ? '8px' : '4px',
                                        background: '#009245',
                                        color: 'white',
                                        border: 'none',
                                        height: '4rem'
                                    }}>Register Email
                            </button>
                        </div>

                        {(isMobile || isTablet) && <div className={'row pt-2 pb-3'}>
                            <img src={require("../assets/images/nft/cards-3x.png")} alt={'nft-card'}/>
                            <h3 className={'sfProDisplayFont text-center text-white font-16'}>
                                Fanzine’s mission is to become the biggest sports fan community in the world, with the
                                FANS at the very centre of everything that we do.
                                <br/>
                                Our inspiration for the Dog NFTs was to personify the feeling of the fans, the high and
                                the lows, the joy and the agony, with his expressions telling you how the fans
                                feel on the terraces.
                            </h3>

                        </div>}

                        {(!isMobile && !isTablet) && <div className={'row pt-3'}>
                            <h1
                                style={{
                                    textTransform: 'uppercase'
                                }}
                                className={"text-white azo-sans font-nft-title"}>
                                Coming Soon
                            </h1>
                        </div>}
                    </div>
                    <div className={'col-xxl-6 col-xl-6 col-lg-6 col-md-0 col-sm-0'}>
                        <img src={require("../assets/images/nft/3 dogs.png")} alt={'nft-card'}
                             className={'img-fluid  d-none d-lg-block d-xl-block d-xxl-block'}/>
                    </div>
                </div>
            </div>
        )
    }

    const renderBar = () => {
        return (<div
            className={`d-flex justify-content-center p-2`}
            style={{background: '#009245', height: '120px'}}
        >
            <div className={`w-100`}>
                <h1
                    style={{
                        fontSize: isMobile ? '30px' : '40px',
                        textTransform: 'uppercase',
                        lineHeight: isMobile ? '130%' : '120px',
                    }}
                    className={`text-white azo-sans text-center`}>
                    classic international Retro kits
                </h1>
            </div>
        </div>)
    }

    const renderNFTs = () => {
        return (<div className={'container-fluid'} style={{paddingRight: '0px', paddingLeft: '0px'}}>
            <Loader loading={loading}/>
            <div className={`slider-mobile-container overflow-scroll-x scrollbar-custom`} id={'box'}>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (1).jpg")}
                         alt={'NFT Dog 1'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (2).jpg")}
                         alt={'NFT Dog 2'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (3).jpg")}
                         alt={'NFT Dog 3'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (4).jpg")}
                         alt={'NFT Dog 4'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (5).jpg")}
                         alt={'NFT Dog 5'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (6).jpg")}
                         alt={'NFT Dog 6'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (7).jpg")}
                         alt={'NFT Dog 7'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (8).jpg")}
                         alt={'NFT Dog 8'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (9).jpg")}
                         alt={'NFT Dog 9'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (10).jpg")}
                         alt={'NFT Dog 10'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (11).jpg")}
                         alt={'NFT Dog 11'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (12).jpg")}
                         alt={'NFT Dog 12'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (13).jpg")}
                         alt={'NFT Dog 14'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (14).jpg")}
                         alt={'NFT Dog 15'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (15).jpg")}
                         alt={'NFT Dog 16'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (16).jpg")}
                         alt={'NFT Dog 17'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (17).jpg")}
                         alt={'NFT Dog 18'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (18).jpg")}
                         alt={'NFT Dog 19'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (19).jpg")}
                         alt={'NFT Dog 20'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (20).jpg")}
                         alt={'NFT Dog 20'} style={{height: '312px', width: '312px'}}/>
                </div>
                <div>
                    <img src={require("../assets/images/nft/Square NFTs1/dog (21).jpg")}
                         alt={'NFT Dog 20'} style={{height: '312px', width: '312px'}}/>
                </div>
            </div>
        </div>);
    }

    const renderNFTsSkeleton = () => {
        return (<Skeleton count={9} height={'200px'} width={'250px'} inline={true} className={'ms-2'}/>);
    }

    const renderTextBarBelowSlider = () => {
        return (<div
            className={'d-flex justify-content-center p-1'}
            style={{background: '#009245', height: isMobile? '320px' : '260px'}}
        >
            <div className={`row text-center sfProDisplayFont ${ isMobile ? 'font-18 m-2' :'font-26'}  text-white`}>
                Fanzine’s mission is to become the biggest sports fan community in <br/> the world, with the FANS at the very centre of everything that we do. <br/>
                <br/>
                Our inspiration for the Dog NFTs was to personify the feeling of the fans, the <br/> high and the lows,
                the joy and the agony, with his expressions telling you how <br/> the fans feel
                on the terraces.
            </div>
        </div>)
    }

    const renderUtility = () => {

        let imageStyle = {
            // width: '942px',
            // height:'592px'
        };
        let mainCategory = false;
        if (mainCategory) {
            imageStyle = {
                height: "174.2px", objectFit: "cover",
            }
        }

        return (
            <div
            className={'p-4'}>
            <div className={'row pt-5'}
                // id={'nft-utility'}
            >
                <h3 className={'azo-sans-bold text-white text-center'} style={{fontSize: '40px'}}>
                    Utility
                </h3>
            </div>
            <div className={'row pt-2'}>
                <h3 className={'azo-sans text-white text-center'} style={{fontSize: '20px'}}>
                    Stay tuned for updates on NFT members exclusive benefits. <br/>
                    Featuring Fixture based, both Qatar and WC Classics dropping soon! </h3>
            </div>
            <div className={'row'}>
                <div className={" col-lg-6 col-md-6 col-sm-12 col-xs-12 col-padding-25"}>
                    <div className={'text-decoration-none text-dark'}>
                        <Card className="border-0 bg-transparent ms-1 mt-5">
                            <Card.Img
                                variant="top"
                                className={'img-fluid'}
                                src={'#'}
                                alt="Card image"
                                onError={utility1}
                                style={imageStyle}
                            />
                            <Card.ImgOverlay
                                className={`d-flex align-items-center flex-column justify-content-center shop-category-overlay `}>
                                <Card.Img src={'#'} onError={utilityOverlayImg4}
                                          style={{
                                              height: isMobile ? '60px' : '80px', width: isMobile ? '80px' : '100px'
                                          }}/>
                                <Card.Text className={`text-white sfProDisplayFont ${isMobile ? 'h1' : 'h2'} `}>
                                    <span className={'bebasFont'}>
                                        {'Giveaways'}
                                    </span>
                                </Card.Text>
                                <Card.Text className={`text-white sfProDisplayFont h5 text-center`}>
                                    <span className={'bebasFont'}>
                                        {'Official and retro shirts'}
                                    </span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                </div>
                <div className={" col-lg-6 col-md-6 col-sm-12 col-xs-12 col-padding-25"}>
                    <div className={'text-decoration-none text-dark'}>
                        <Card className={`border-0 bg-transparent ms-1 ${isMobile ? 'mt-3' : 'mt-5'}`}>
                            <Card.Img
                                variant="top"
                                className={'img-fluid'}
                                src={'#'}
                                alt="Card image"
                                onError={utility4}
                                style={imageStyle}
                            />
                            <Card.ImgOverlay
                                className={`d-flex align-items-center flex-column justify-content-center shop-category-overlay `}>
                                <Card.Img src={'#'} onError={utilityOverlayImg1}
                                          style={{
                                              height: isMobile ? '80px' : '100px', width: isMobile ? '80px' : '100px'
                                          }}/>
                                <Card.Text className={`text-white sfProDisplayFont ${isMobile ? 'h1' : 'h2'} `}>
                                    <span className={'bebasFont'}>
                                        {'Whitelisted'}
                                    </span>
                                </Card.Text>
                                <Card.Text className={`text-white sfProDisplayFont h5 text-center`}>
                                    <span className={'bebasFont'}>
                                        For Limited Edition <br/>
                                        World Cup 2022 NFTs
                                    </span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                </div>
                <div className={" col-lg-6 col-md-6 col-sm-12 col-xs-12 col-padding-25"}>
                    <div className={'text-decoration-none text-dark'}>
                        <Card className="border-0 bg-transparent ms-1 mt-3">
                            <Card.Img
                                variant="top"
                                className={'img-fluid'}
                                src={'#'}
                                alt="Card image"
                                onError={utility2}
                                style={imageStyle}
                            />
                            <Card.ImgOverlay
                                className={`d-flex align-items-center flex-column justify-content-center shop-category-overlay `}>
                                <Card.Img src={'#'} onError={utilityOverlayImg3}
                                          style={{
                                              height: isMobile ? '80px' : '110px', width: isMobile ? '80px' : '100px'
                                          }}/>
                                <Card.Text className={`text-white sfProDisplayFont ${isMobile ? 'h1' : 'h2'}`}>
                                    <span className={'bebasFont'}>
                                        {'Store Benefited'}
                                    </span>
                                </Card.Text>
                                <Card.Text className={`text-white sfProDisplayFont h5 text-center`}>
                                    <span className={'bebasFont'}>
                                        Discounts and exclusive <br/>
                                        merchandise
                                    </span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                </div>
                <div className={" col-lg-6 col-md-6 col-sm-12 col-xs-12 col-padding-25"}>
                    <div className={'text-decoration-none text-dark'}>
                        <Card className="border-0 bg-transparent ms-1 mt-3">
                            <Card.Img
                                variant="top"
                                className={'img-fluid'}
                                src={'#'}
                                alt="Card image"
                                onError={utility3}
                                style={imageStyle}
                            />
                            <Card.ImgOverlay
                                className={`d-flex align-items-center flex-column justify-content-center shop-category-overlay `}>
                                <Card.Img src={'#'} onError={utilityOverlayImg2}
                                          style={{
                                              height: isMobile ? '60px' : '80px', width: isMobile ? '80px' : '100px'
                                          }}/>
                                <Card.Text className={`text-white sfProDisplayFont ${isMobile ? 'h1' : 'h2'} `}>
                                    <span className={'bebasFont'}>
                                        {'Club email address'}
                                    </span>
                                </Card.Text>
                                <Card.Text className={`text-white sfProDisplayFont h5 text-center`}>
                                    <span className={'bebasFont'}>
                                        @gunners.com, @watp.com, @coys.com <br/> @cfc-blues.com, @thekop.com...
                                    </span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                </div>
            </div>
            {!isMobile && <div
                id={'nft-roadmap'}
                className={"container-fluid"}>
                <div className={'col text-decoration-none text-dark'}>
                    <Card className={`border-0 bg-transparent ${isMobile ? 'mt-3' : 'mt-5'}`}>
                        <Card.Img
                            // variant="top"
                            className={'img-fluid w-auto ms-5'}
                            src={require("../assets/images/nft/roadmap/Component 68 – 1@2x.png")}
                            alt="Card image"
                            // onError={roadMap}
                            // style={imageStyle}
                        />
                        <Card.ImgOverlay
                            className={`d-flex align-items-center flex-column justify-content-start shop-category-overlay`}>
                            {/*<Card.Img src={'#'}  onError={utilityOverlayImg2}  style={{height:'80px', width:'100px'}}  />*/}
                            <Card.Text className={`text-white sfProDisplayFont h1`}>
                                <span className={'bebasFont'}>
                                    {'RoadMap'}
                                </span>
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            </div>}
        </div>)
    }

    const renderTeam = () => {

        let imageStyle = {
            // width: '942px',
            // height:'592px'
        };
        let mainCategory = false;
        if (mainCategory) {
            imageStyle = {
                height: "174.2px", objectFit: "cover",
            }
        }

        return (<div
            className={'container p-4 pb-5'}>
            <div className={'row pt-5'}>
                <h3 className={`azo-sans-bold ${isMobile ? 'text-white' : 'text-black'} text-center`}
                    style={{fontSize: '40px'}}>
                    Team
                </h3>
            </div>
            <div className={'row pt-2'}>
                <h3 className={`sfProDisplayFont ${isMobile ? 'text-white' : 'text-black'} text-center font-20`}>
                    The Fanzine team are a sports crazy diverse group, whose single mission is to <br/>unite the
                    community of like-minded obsessive fans from around the world. </h3>
            </div>
            <div className={'row'}>
                {[0, 1, 2, 3].map((value, index) => (<div className={isMobile ? "col-6" : "col-3"}>
                    <div className={'text-decoration-none text-dark'}>
                        <Card className="border-0 bg-transparent ms-1 mt-5">
                            <Card.Img
                                variant="top"
                                className={'img-fluid'}
                                src={index === 0 ? tm3 : index === 1 ? tm1 : index === 2 ? tm2 : tm4}
                                alt="Card image"
                                onError={index === 0 ? team3 : index === 1 ? team1 : index === 2 ? team2 : team4}
                                style={imageStyle}
                            />
                            <Card.Text
                                className={`azo-sans ${isMobile ? 'text-white' : 'text-black'} text-center`}
                                style={{fontSize: isMobile ? '20px' : '30px', fontWeight: '500'}}
                            >
                                {index === 0 ? 'Hammer Sam' : index === 1 ? 'Gooner Kev' : index === 2 ? 'CFC Jake' : 'Hammer Bray'}
                                <Card.Text
                                    className={'sfProDisplayFont text-center'}
                                    style={{fontSize: isMobile ? '14px' : '20px'}}
                                >
                                    {index === 0 ? 'Graphic Designer' : index === 1 ? 'Project Manager' : index === 2 ? 'UI Designer' : 'Marketing Specialist'}
                                </Card.Text>
                            </Card.Text>
                        </Card>
                    </div>
                </div>))}
            </div>
        </div>)
    }

    const renderContact = () => {
        return (<div
                // id={'nft-contact'}
                className={`mt-n3 ${isMobile ? 'pt-2' : 'pt-5'}`}>
                <div className={`container-fluid`}>
                    <div className={`${!isMobile ? 'row' : ''} mt-n3 pt-5`}>
                        <div
                            className={`col-md-8 col-sm-12 offset-md-2 offset-sm-0 font-24 sfProDisplayFontBold text-white`}>
                            <div className={`container`}>
                                <Loader loading={loading}/>
                                <div
                                    className={`sfProDisplayFont text-center ${isMobile ? 'text-white' : 'text-black'} `}
                                    style={{fontSize: isMobile ? '25px' : '40px'}}>
                                    Contact
                                </div>
                                <form className={`p-3 pb-5`}>
                                    <Row className={`mt-3`}>
                                        <Col>
                                            <FormControl
                                                isInvalid={errorName}
                                                type="text"
                                                id="name"
                                                aria-describedby="name"
                                                placeholder="Name"
                                                style={{background: '#e4e4e4', fontSize: isMobile ? '14px' : '25px'}}
                                                value={name}
                                                onChange={(e) => {
                                                    setErrorName(false);
                                                    setName(e.target.value);
                                                }}
                                            />
                                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input
                                                name</FormControl.Feedback>
                                        </Col>
                                        <Col>
                                            <FormControl
                                                isInvalid={errorEmail}
                                                type="email"
                                                id="email"
                                                aria-describedby="email"
                                                placeholder="Email"
                                                style={{background: '#e4e4e4', fontSize: isMobile ? '14px' : '25px'}}
                                                value={email}
                                                onChange={(e) => {
                                                    setErrorEmail(false);
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a
                                                valid email</FormControl.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className={`mt-3`}>
                                        <Col>
                                            <FormControl
                                                isInvalid={errorPhone}
                                                type="number"
                                                id="phone"
                                                aria-describedby="phone"
                                                placeholder="Telephone"
                                                style={{background: '#e4e4e4', fontSize: isMobile ? '14px' : '25px'}}
                                                value={phone}
                                                onChange={(e) => {
                                                    setErrorPhone(false);
                                                    setPhone(e.target.value);
                                                }}
                                            />
                                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a
                                                valid phone number</FormControl.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className={`mt-3`}>
                                        <Col>
                                            <FormControl
                                                isInvalid={errorSubject}
                                                id="subject"
                                                aria-describedby="subject"
                                                placeholder="Subject"
                                                style={{background: '#e4e4e4', fontSize: isMobile ? '14px' : '25px'}}
                                                value={subject}
                                                onChange={(e) => {
                                                    setErrorSubject(false);
                                                    setSubject(e.target.value);
                                                }}
                                            />
                                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input
                                                subject</FormControl.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className={`mt-3`}>
                                        <Col>
                                            <textarea className={`form-control sfProDisplayFont`} rows={10}
                                                      value={message}
                                                      placeholder={'Message'}
                                                      style={{
                                                          background: '#e4e4e4', fontSize: isMobile ? '14px' : '25px'
                                                      }}
                                                      onChange={(e) => {
                                                          setMessage(e.target.value)
                                                      }}>{message}</textarea>
                                        </Col>
                                    </Row>
                                    <Row className={`mt-3`}>
                                        <Col>
                                            <button type={"button"}
                                                    style={{
                                                        background: isMobile ? '#009245' : '#1e1e1e',
                                                        width: '200px',
                                                        borderRadius: '0px',
                                                        fontSize: isMobile ? '18px' : '20px'
                                                    }}
                                                    className={`btn float-start text-white btn-lg`}
                                                    onClick={onSubmit}>Submit
                                            </button>
                                        </Col>
                                        <Col>
                                            <div
                                                className="g-recaptcha float-end"
                                                data-sitekey="6LfWvMAhAAAAAPr6XLLaX05ZmCrmMtAJhkZXpC2z"
                                                data-size="invisible"
                                            >
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const renderFAQ = () => {

        const faq = [{
            id: 1,
            question: 'What is an NFT?',
            answer: 'A non-fungible token (NFT) is a record on a blockchain which is associated with a particular digital or physical asset. The ownership of an NFT is recorded in the blockchain and can be transferred by the owner, allowing NFTs to be transparently sold and traded.',
        }, {
            id: 2,
            question: 'What will I get when I have one?',
            answer: 'We will reveal more soon. Sign up to our newsletter to find out what we will be giving away!',
        }, {
            id: 3,
            question: 'How can I get an NFT?',
            answer: 'You’ll be able to purchase them as soon as they are released! Product and price details will be announced soon.',
        }, {
            id: 4,
            question: 'Where do I store my NFT?',
            answer: 'Your NFTs are sent directly to your digital wallet that is supported on the Solana network.',
        }, {
            id: 5,
            question: 'What is a digital wallet?',
            answer: 'Crypto wallets keep your private keys, the passwords that give you access to your cryptocurrencies safe and accessible, allowing you to send and receive cryptocurrencies like BTC, SOL etc.',
        }, {
            id: 6,
            question: 'What is a digital wallet?',
            answer: 'Crypto wallets keep your private keys, the passwords that give you access to your cryptocurrencies safe and accessible, allowing you to send and receive cryptocurrencies like Solana.',
        }, {
            id: 7,
            question: 'When are they going live?',
            answer: 'Soon!',
        }, {
            id: 8,
            question: 'Who do I contact for help?',
            answer: 'You can join our community by signing up via email. You can contact nft@fanzine.com and we will try to answer any questions when we can.',
        }];

        return (<div
            // id={'nft-faq'}
            className={'container pb-5'}>
            <div
                className={'border-bottom border-white'}
            >
                <h1
                    style={{
                        fontSize: isMobile ? '30px' : '40px',
                        textTransform: 'uppercase',
                        lineHeight: isMobile ? '200%' : '120px',
                    }}
                    className={"text-white text-center azo-sans"}>
                    FAQ
                </h1>
            </div>
            {faq.map((value, index) => (
                <SingleAccordian index={index} isMobile={isMobile} open={open} value={value}/>))}
        </div>)
    }

    const onSubmit = () => {
        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }
        if (subject === '') {
            canBeSubmitted = false;
            setErrorSubject(true);
        }

        if (canBeSubmitted) {
            setLoading(true);
            let formData = {
                to: ['junaid@codestudio.com.pk','nft@fanzine.com',],
                name: name,
                phone: phone,
                email: email,
                subject: subject,
                body: message,
            };

            post(ContactUsEndpoint, formData).then((response) => {
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
                setLoading(false);
                setSuccessfulContact(true);
            });
        }
    }

    const render = () => {
        return (<>
            {!isMobile && <NFTNavBar nftHome={'nft-home'} nftUtility={'nft-utility'} nftRoadmap={'nft-roadmap'}
                                     nftContact={'nft-contact'} nftFAQ={'nft-faq'}/>}
            {isNFTModalOpen ? renderNFTModal() : ''}
            {successfulContact ? renderContactUsSuccessModal() : ''}
            <div id={'nft-home'} className={'d-flex align-items-center nft-imgComponent w-100'}
                 style={{backgroundImage: `url("${mobileBackground}")`,}}>
                {renderComingSoon()}
            </div>
            {isMobile && <div className={'nft-border-transformv2 nft-fanzine-bg'} style={{marginBottom: '-10px'}}>
            </div>}
            {renderBar()}
            {!loading ? renderNFTs(matchData, 'football') : renderNFTsSkeleton()}
            {!isMobile && renderTextBarBelowSlider()}
            <div id={'nft-utility'} className={'nft-utility'}>
                {renderUtility()}
            </div>
            <div className={isMobile ? 'nft-fanzine-bg' : 'nft-team'}>
                {renderTeam()}
            </div>
            {isMobile && <div className={'nft-border-transform nft-utility'}>
            </div>}
            <div id={'nft-contact'} className={isMobile ? 'nft-utility' : 'nft-team'}>
                {renderContact()}
            </div>
            <div id={'nft-faq'} className={'nft-fanzine-bg'}>
                {renderFAQ()}
            </div>
        </>);
    }

    return render();

};

export default NFT;