import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSearch} from "@fortawesome/free-solid-svg-icons";
import {COLORS_RGBA} from "../Configs/Targets";
import '../assets/styles/styles.css';
import '../assets/styles/shop.css';
import "../assets/styles/MultiRangeSlider.css";
import {Breadcrumb} from "react-bootstrap";
import {NavLink, useParams} from "react-router-dom";
import ShopItemCarousel from "../Components/Carousel/ShopItemCarousel";
import {withRouter} from "../CustomFunctions/withRouter";
import ProductComponent from "../Components/Shop/ProductComponent";
import {useDispatch, useSelector} from "react-redux";
import {PRODUCT_GENDERS, SCREEN_SIZE, SHOPIFY} from "../Configs/Constants";
import {graphql} from "../Axios/graphql";
import {ShopifyGraphQlQueries} from "../Configs/ShopifyGraphQlQueries";
import {Banner} from "../Components/Shop/Banner";
import {getHostname} from "../CustomFunctions/getHostname";
import MobileFilters from "../Components/MobileFilters/MobileFilters"
import {setAllProducts} from "../redux/actions/StoreActions";
import Loader from "../Components/Loader/Loader";
import MultiRangeSlider from "multi-range-slider-react";

const ProductsListPage = (props) => {

    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const {filter, filter1} = useParams();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const colorRgba = COLORS_RGBA[target];

    let link = "/shop";

    const [horizontalScroll, setHorizontalScroll] = useState(false);
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [filterType, setFilterType] = useState(null);
    const [paramFilter, setParamFilter] = useState('');
    const [paramFilter1, setParamFilter1] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [loading, setLoading] = useState(true);
    const [colorsForFilters, setColorsForFilters] = useState([]);
    const [sizesForFilters, setSizesForFilters] = useState([]);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedFiltersChanged, setSelectedFiltersChanged] = useState(false);
    const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(500);
    const [checkSale, setCheckSale] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [showWebPriceRange, setShowWebPriceRange] = useState(false);

    const fetchProductsByTags = (tag, sortKey = ', sortKey: RELEVANCE') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null, {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;
                if (products.length) {
                    let tempCategories = [];
                    let tempData = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    products.map((value) => {
                        let product = value.node;
                        if (!tempCategories.includes(product.productType) && apiCategories.includes(product.productType) && product.tags.includes(tag)) {
                            tempCategories.push(product.productType);
                        }
                        return tempCategories;
                    });
                    tempCategories.map(cat => {
                        let tempDataByCat = [];
                        products.map((value) => {
                            let productColorImages = [];
                            let product = value.node;
                            if (product.productType === cat && product.tags.includes(tag)) {
                                let productSizes = [];
                                let productColors = [];
                                product.options.map((option) => {
                                    if (option.name === 'Size') {
                                        productSizes = option.values;
                                    }
                                    if (option.name === 'Color') {
                                        productColors = option.values;
                                    }
                                });
                                productColors.map((color) => {
                                    productColorImages[color] = (product.variants.edges.filter(variant => variant.node.title.includes(color))[0].node.image.src);
                                })
                                product.variants.edges.map((value1, index) => {
                                    // tempColorsForFilters.push(value1.node.selectedOptions);
                                    // debugger
                                    let tempColorArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Color')) : null;
                                    let tempColor = '';
                                    if (tempColorArr !== null && tempColorArr.length) {
                                        if (tempColorArr.constructor === Object) {
                                            tempColor = tempColorArr.value;
                                        }
                                        if (tempColorArr.constructor === Array) {
                                            tempColor = tempColorArr[0].value;
                                        }
                                    }
                                    if (tempColor.includes('/')) {
                                        tempColor = (tempColor.split('/'))[0];
                                    }
                                    if (tempColor !== '' && !tempColorsForFilters.includes(tempColor)) {
                                        tempColorsForFilters.push(tempColor);
                                    }
                                    let tempSizeArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Size')) : null;
                                    let tempSize = '';
                                    if (tempSizeArr !== null && tempSizeArr.length) {
                                        if (tempSizeArr.constructor === Object) {
                                            tempSize = tempSizeArr.value;
                                        }
                                        if (tempSizeArr.constructor === Array) {
                                            tempSize = tempSizeArr[0].value;
                                        }
                                    }
                                    if (tempSize !== '' && !tempSizesForFilters.includes(tempSize)) {
                                        tempSizesForFilters.push(tempSize);
                                    }
                                });
                                tempDataByCat.push({
                                    id: product.id,
                                    name: product.title,
                                    handle: product.handle,
                                    src: product.images && product.images.edges[0].node.src,
                                    alt: product.images && product.images.edges[0].node.alt,
                                    price: product['variants'].edges[0].node.price,
                                    oldPrice: product['variants'].edges[0].node.compareAtPrice,
                                    onSale: product['variants'].edges[0].node.compareAtPrice !== null,
                                    sizes: productSizes,
                                    tags: product.tags,
                                    colors: productColors,
                                    variants: product.variants,
                                    colorImages: productColorImages,
                                });
                            }
                            return tempDataByCat;
                        });
                        return tempData.push({
                            name: cat, products: tempDataByCat
                        });
                    })
                    setCategories(tempCategories);
                    // tempColorsForFilters = Array.from(new Set(tempColorsForFilters));
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    let filteredProducts = showFilteredProducts(tempData);
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    }
    // console.log('ColorForFilters = ',selectedColors);
    const fetchProductsByTypes = (type, sortKey = '') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null, {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;
                if (products.length) {
                    let tempCategories = [];
                    let tempData = [];
                    let tempDataByTag = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    products.map((value) => {
                        let productColorImages = [];
                        let product = value.node;
                        if (!tempCategories.includes(product.productType) && apiCategories.includes(product.productType)) {
                            tempCategories.push(product.productType);
                        }

                        let productSizes = [];
                        let productColors = [];
                        product.options.map((option) => {
                            if (option.name === 'Size') {
                                productSizes = option.values;
                            }
                            if (option.name === 'Color') {
                                productColors = option.values;
                            }
                        });
                        productColors.map((color) => {
                            productColorImages[color] = (product.variants.edges.filter(variant => variant.node.title.includes(color))[0].node.image.src);
                        })
                        product.variants.edges.map((value1, index) => {
                            // tempColorsForFilters.push(value1.node.selectedOptions);
                            // debugger
                            let tempColorArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Color')) : null;
                            let tempColor = '';
                            if (tempColorArr !== null && tempColorArr.length) {
                                if (tempColorArr.constructor === Object) {
                                    tempColor = tempColorArr.value;
                                }
                                if (tempColorArr.constructor === Array) {
                                    tempColor = tempColorArr[0].value;
                                }
                            }
                            if (tempColor !== '' && !tempColorsForFilters.includes(tempColor)) {
                                tempColorsForFilters.push(tempColor);
                            }
                            let tempSizeArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Size')) : null;
                            let tempSize = '';
                            if (tempSizeArr !== null && tempSizeArr.length) {
                                if (tempSizeArr.constructor === Object) {
                                    tempSize = tempSizeArr.value;
                                }
                                if (tempSizeArr.constructor === Array) {
                                    tempSize = tempSizeArr[0].value;
                                }
                            }
                            if (tempSize !== '' && !tempSizesForFilters.includes(tempSize)) {
                                tempSizesForFilters.push(tempSize);
                            }
                        });
                        if (product.productType === decodeURI(type)) {
                            tempDataByTag.push({
                                id: product.id,
                                name: product.title,
                                handle: product.handle,
                                src: product.images && product.images.edges[0].node.src,
                                alt: product.images && product.images.edges[0].node.alt,
                                price: product['variants'].edges[0].node.price,
                                oldPrice: product['variants'].edges[0].node.compareAtPrice,
                                onSale: product['variants'].edges[0].node.compareAtPrice !== null,
                                sizes: productSizes,
                                tags: product.tags,
                                colors: productColors,
                                variants: product.variants,
                                colorImages: productColorImages,
                            });
                        }

                        return tempCategories;
                    });
                    tempData.push({
                        name: type, products: tempDataByTag
                    })
                    setCategories(tempCategories);
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    let filteredProducts = showFilteredProducts(tempData);
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    }

    const fetchProductsByTypeAndTag = (type, tag, sortKey = '') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null, {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;
                if (products.length) {
                    let tempCategories = [tag + ' ' + type];
                    let tempData = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    let tempDataByTag = [];
                    products.map((value) => {
                        let productColorImages = [];
                        let product = value.node;
                        if (product.tags.includes(tag) && product.productType === decodeURI(type)) {
                            let productSizes = [];
                            let productColors = [];
                            let productColorImages = [];
                            product.options.map((option) => {
                                if (option.name === 'Size') {
                                    productSizes = option.values;
                                }
                                if (option.name === 'Color') {
                                    productColors = option.values;
                                }
                            });
                            productColors.map((color) => {
                                productColorImages[color] = (product.variants.edges.filter(variant => variant.node.title.includes(color))[0].node.image.src);
                            })
                            product.variants.edges.map((value1, index) => {
                                // tempColorsForFilters.push(value1.node.selectedOptions);
                                // debugger
                                let tempColorArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Color')) : null;
                                let tempColor = '';
                                if (tempColorArr !== null && tempColorArr.length) {
                                    if (tempColorArr.constructor === Object) {
                                        tempColor = tempColorArr.value;
                                    }
                                    if (tempColorArr.constructor === Array) {
                                        tempColor = tempColorArr[0].value;
                                    }
                                }
                                if (tempColor !== '' && !tempColorsForFilters.includes(tempColor)) {
                                    tempColorsForFilters.push(tempColor);
                                }
                                let tempSizeArr = value1.node.selectedOptions && value1.node.selectedOptions.length ? (value1.node.selectedOptions.filter(option => option.name === 'Size')) : null;
                                let tempSize = '';
                                if (tempSizeArr !== null && tempSizeArr.length) {
                                    if (tempSizeArr.constructor === Object) {
                                        tempSize = tempSizeArr.value;
                                    }
                                    if (tempSizeArr.constructor === Array) {
                                        tempSize = tempSizeArr[0].value;
                                    }
                                }
                                if (tempSize !== '' && !tempSizesForFilters.includes(tempSize)) {
                                    tempSizesForFilters.push(tempSize);
                                }
                            });
                            tempDataByTag.push({
                                id: product.id,
                                name: product.title,
                                handle: product.handle,
                                src: product.images && product.images.edges[0].node.src,
                                alt: product.images && product.images.edges[0].node.alt,
                                price: product['variants'].edges[0].node.price,
                                oldPrice: product['variants'].edges[0].node.compareAtPrice,
                                onSale: product['variants'].edges[0].node.compareAtPrice !== null,
                                sizes: productSizes,
                                tags: product.tags,
                                colors: productColors,
                                variants: product.variants,
                                colorImages: productColorImages,
                            });
                        }
                        return tempDataByTag;
                    });
                    setCategories(tempCategories);
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    tempData.push({
                        name: tag + ' ' + type, products: tempDataByTag
                    });
                    let filteredProducts = showFilteredProducts(tempData);
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    }

    const showFilteredProducts = (dataToFilter) => {
        let tempDataArray = dataToFilter;
        let tempFilteredData = [];
        if (selectedCategories.length) {
            tempFilteredData = tempDataArray.filter((value) => selectedCategories.some((item) => value.name === item) === true);
        }

        if (selectedColors.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item => Object.values(item.colors).filter(color => selectedColors.includes(color)).length > 0);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item => Object.values(item.colors).filter(color => selectedColors.includes(color)).length > 0);
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (selectedSizes.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item => Object.values(item.sizes).filter(size => selectedSizes.includes(size)).length > 0);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item => Object.values(item.sizes).filter(size => selectedSizes.includes(size)).length > 0);
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (selectedGenders.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item =>
                        Object.values(item.tags).filter(tag =>
                            selectedGenders.includes(tag)
                        ).length > 0
                    );
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item =>
                        Object.values(item.tags).filter(tag =>
                            selectedGenders.includes(tag)
                        ).length > 0
                    );
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (checkSale) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item => item.onSale === true);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item => item.onSale === true);
                });
                tempFilteredData = tempDataArray;
            }
        }
        if (tempFilteredData.length) {
            Object.values(tempFilteredData).map((value) => {
                value.products = value.products.filter(item => parseFloat(item.price) > minPrice && parseFloat(item.price) < maxPrice);
            });
        } else {
            Object.values(tempDataArray).map((value) => {
                value.products = value.products.filter(item => parseFloat(item.price) > minPrice && parseFloat(item.price) < maxPrice);
            });
            tempFilteredData = tempDataArray;
        }

        // debugger
        tempFilteredData = tempFilteredData.length ? tempFilteredData : tempDataArray;
        /*setData(tempFilteredData);*/
        setShowMobileFilters(false);
        setHasFiltersChanged(false);
        setLoading(false);
        // debugger
        return tempFilteredData.filter(value => value.products.length);
    }

    const initialize = (routeParams, sortKey = ', sortKey: RELEVANCE') => {
        setCategories([]);
        setData([]);
        let tempBreadcrumbs = Object.values(routeParams);
        setBreadcrumbs(tempBreadcrumbs);
        setCategories([routeParams.filter1]);
        setParamFilter(routeParams.filter);
        setParamFilter1(routeParams.filter1);
        if (tempBreadcrumbs.length === 1) {
            if (store.shopify.productTags.includes(routeParams.filter)) {
                fetchProductsByTags(routeParams.filter, sortKey);
                setFilterType(SHOPIFY.PRODUCT_TAGS);
            }
            setHorizontalScroll(true);
            if (store.shopify.productTypes.includes(routeParams.filter)) {
                fetchProductsByTypes(routeParams.filter, sortKey);
                setFilterType(SHOPIFY.PRODUCT_TYPES);
                setParamFilter1(routeParams.filter);
                setHorizontalScroll(false);
            }
        }
        if (tempBreadcrumbs.length === 2) {
            let type = routeParams.filter;
            let tag = routeParams.filter1;
            fetchProductsByTypeAndTag(tag, type, sortKey);
            setParamFilter1(type);
            setHorizontalScroll(false);
        }
    }

    const handleProductSort = (event) => {
        let sortKey = "";
        setLoading(true);
        switch (event.currentTarget.value) {
            case"BEST_SELLING":
                sortKey = ", sortKey: BEST_SELLING";
                break;
            case"NEWEST":
                sortKey = ", sortKey: CREATED, reverse: true";
                break;
            case"PRICE_ASC":
                sortKey = ", sortKey: PRICE, reverse: false";
                break;
            case"PRICE_DESC":
                sortKey = ", sortKey: PRICE, reverse: true";
                break;
            default:
                sortKey = "";
                break;
        }
        initialize(props.match.params, sortKey)
    }

    const clearFilters = () => {
        setLoading(true);
        initialize(props.match.params);
        setShowMobileFilters(false);
        setClearFilter(!setParamFilter);
    }

    const handlePriceRangeChange = (e) => {
        setMinPrice(e.minValue);
        setMaxPrice(e.maxValue);
    };

    const setColorForProducts = (value, index) => {
        let tempSrc = '';
        if (value.colorImages[selectedColors[index % selectedColors.length]]) {
            tempSrc = value.colorImages[selectedColors[index % selectedColors.length]];


        }
        return tempSrc;

    }


    useEffect(() => {
        initialize(props.match.params);
    }, [store.shopify.productTypes, store.shopify.productTags]);

    useEffect(() => {
        // setHasFiltersChanged(true);
        initialize(props.match.params);
    }, [filter, filter1]);

    useEffect(() => {
        if (hasFiltersChanged) {
            setLoading(true);
            initialize(props.match.params);
        }
    }, [hasFiltersChanged]);

    useEffect(() => {
    }, [data]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        if (screenWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [screenWidth]);

    const renderScrollDirection = (horizontalScroll, products) => {
        if (horizontalScroll) {
            return renderHorizontalScroll(products);
        }
        return renderVerticalScroll(products);
    }

    const renderHorizontalScroll = (products) => {
        return (<ShopItemCarousel images={products} showDetail={true} showColors={true}/>);
    }

    const renderVerticalScroll = (products) => {
        return (<div className={'row ms-0 me-0'}>
            {products.length ? products.map((value, index) => {

                if (selectedColors.length > 0) {
                    let imageSrc = setColorForProducts(value, index);
                    value.src = imageSrc === '' ? value.src : imageSrc;
                }

                return (<div
                    className={`ps-0 pe-0 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-3 ${isMobile ? 'col-6' : ''}`}>
                    <ProductComponent item={value} showColors={true} showDetail={true}/>
                </div>);
            }) : null}
        </div>);
    }

    const renderViewAllButton = (buttonText, filter1) => {
        let viewLink = `/shop/${paramFilter}/${filter1.name}`;
        if (filterType === SHOPIFY.PRODUCT_TYPES) {
            viewLink = `/shop/${filter1.name}/${paramFilter}`;
        }
        return (<div className={'col'}>
            <div className={'w-100 h-100 text-end h5 bebasFont d-flex flex-column justify-content-center'}>
                <div className={'h-100 w-100 text-end h5 bebasFont d-flex flex-row justify-content-end'}>
                    <div className={'text-end h5 bebasFont d-flex flex-column justify-content-center'}>
                        <NavLink to={viewLink}
                                 className={'text-decoration-none bg-yellow text-black sfProDisplayFont font-weight-800 small text-uppercase pt-1 ps-3 pe-3 pb-1 ' + 'rounded-pill float-end text-end' +
                                     ' w-fit-content'}>
                            {buttonText}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>);
    }

    const renderMobileFilters = () => {
        return (<div className={'col mt-1 mb-1'}>
            <div className={`form-control form-select rounded-pill text-light-gray ${isMobile ? "bg-grey-input" : ""}`}
                 onClick={() => {
                     setShowMobileFilters(true);
                     // document.getElementById('root').style={};
                 }}>
                Filters
            </div>
        </div>);
    }

    const renderFilters = () => {
        return (<>
            <>
                {breadcrumbs.includes("Mens") || breadcrumbs.includes("Womens") || breadcrumbs.includes("Childrens") ? <></> :
                    <div className={'col mt-1 mb-1'}>
                        <select name="gender" id="gender"
                                onChange={event => {
                                    if (event.currentTarget.value !== "-1") {
                                        setSelectedGenders([event.currentTarget.value]);
                                    } else {
                                        setSelectedGenders([]);
                                    }
                                    setHasFiltersChanged(true);
                                }}
                                className={'form-control form-select rounded-pill text-light-gray'}>
                            <option value="-1" disabled selected>Gender</option>
                            {PRODUCT_GENDERS.map((gender) => (
                                <option value={gender.filter_name}>{gender.shopify_name}</option>))}
                        </select>
                    </div>}
                {(breadcrumbs.includes("Mens") || breadcrumbs.includes("Womens") || breadcrumbs.includes("Childrens")) && breadcrumbs.length < 2 ?
                    <div className={'col mt-1 mb-1'}>
                        <select name="category" id="category"
                                onChange={event => {
                                    if (event.currentTarget.value !== "-1") {
                                        setSelectedCategories([event.currentTarget.value]);
                                    } else {
                                        setSelectedCategories([]);
                                    }
                                    setHasFiltersChanged(true);
                                }}
                                className={'form-control form-select rounded-pill text-light-gray'}>
                            <option value="-1" disabled selected>Categories</option>
                            {store.shopify.categories.map((category) => (
                                <option value={category.name}>{category.name}</option>))}
                        </select>
                    </div> : <></>}
                <div className={'col mt-1 mb-1'}>
                    <select name="color" id="color" className={'form-control form-select rounded-pill text-light-gray'}
                            onChange={event => {
                                if (event.currentTarget.value !== "-1") {
                                    setSelectedColors([event.currentTarget.value]);
                                } else {
                                    setSelectedColors([]);
                                }
                                setHasFiltersChanged(true);
                            }}
                            style={{
                                overflowY: "auto"
                            }}>
                        <option value="-1" disabled selected>Color</option>
                        {colorsForFilters.map((color) => (<option value={color}>{color}</option>))}
                    </select>
                </div>
                <div className={'col mt-1 mb-1'}>
                    <select name="size" id="size" className={'form-control form-select rounded-pill text-light-gray'}
                            onChange={event => {
                                if (event.currentTarget.value !== "-1") {
                                    setSelectedSizes([event.currentTarget.value]);
                                } else {
                                    setSelectedSizes([]);
                                }
                                setHasFiltersChanged(true);
                            }}>
                        <option value="-1">Size</option>
                        {sizesForFilters.map((size) => (<option value={size}>{size}</option>))}
                    </select>
                </div>
                <div className={'col mt-1 mb-1'}>
                    <div
                        className={`bg-white form-control sfProDisplayFont rounded-pill font-16 text-light-gray btn text-start`}
                        onClick={() => {
                            setCheckSale(!checkSale);
                            setHasFiltersChanged(true);
                        }}>
                        Sale
                        {checkSale &&
                            <FontAwesomeIcon className={`ms-2 m-1 float-end`} icon={faCheck} size={`1x`}/>
                        }
                    </div>

                    {/*<select name="sale" id="sale" className={'form-control form-select rounded-pill text-light-gray'}
                            onChange={event => {
                                setCheckSale(true);
                                setHasFiltersChanged(true);
                            }}>
                        <option value="1">Sale</option>
                    </select>*/}
                </div>
                <div className={'col mt-1 mb-1'}>
                    <button className={'form-control form-select rounded-pill text-light-gray text-start'}
                            onClick={() => {
                                setShowWebPriceRange(!showWebPriceRange)
                            }}>Price Range
                    </button>
                    {showWebPriceRange &&
                        <div className={`bg-grey p-2`} style={{
                            position: "absolute",
                            width: "300px",
                            right: window.innerWidth <= SCREEN_SIZE.LAPTOP_LG ? "0" : "auto",
                            left: "auto",
                            zIndex: "1",
                        }}>
                            <div className={`bg-white mt-2`}>
                                <div className={'bebasFont ps-4 pt-4 font-16'}>
                                    Selected Price Range
                                </div>
                                <div className={'sfProDisplayFont ps-4 pb-2 font-16'}>
                                    &pound;{minPrice} - &pound;{maxPrice}
                                </div>

                                <div className={`sfProDisplayFont font-16 p-4`}
                                     style={{height: "15%"}}>
                                    <MultiRangeSlider
                                        min={0}
                                        max={500}
                                        step={20}
                                        ruler={false}
                                        label={true}
                                        preventWheel={false}
                                        minValue={minPrice}
                                        maxValue={maxPrice}
                                        onChange={(e) => {
                                            handlePriceRangeChange(e);
                                        }}
                                    />
                                </div>
                                <button className={`btn bg-${target} text-white sfProDisplayFontBold font-18`} style={{
                                    borderRadius: "0",
                                    width: "284px",
                                }} onClick={() => {
                                    setShowWebPriceRange(false);
                                    setHasFiltersChanged(true);
                                }}>View Items
                                </button>
                            </div>
                        </div>}
                </div>
            </>
        </>);
    }

    const render = () => {
        return (<>
            <Loader loading={loading}/>
            <Banner breadcrums={false}/>
            {showMobileFilters && <MobileFilters setShowMobileFilters={setShowMobileFilters}
                                                 selectedCategories={selectedCategories}
                                                 setSelectedCategories={setSelectedCategories}
                                                 selectedColors={selectedColors}
                                                 setSelectedColors={setSelectedColors}
                                                 selectedSizes={selectedSizes}
                                                 setSelectedSizes={setSelectedSizes}
                                                 selectedGenders={selectedGenders}
                                                 colorsForFilters={colorsForFilters}
                                                 sizesForFilters={sizesForFilters}
                                                 setSelectedGenders={setSelectedGenders}
                                                 setSelectedChanged={setSelectedFiltersChanged}
                                                 setHasFiltersChanged={setHasFiltersChanged}
                                                 minPrice={minPrice}
                                                 setMinPrice={setMinPrice}
                                                 maxPrice={maxPrice}
                                                 setMaxPrice={setMaxPrice}
                                                 checkSale={checkSale}
                                                 setCheckSale={setCheckSale}
                                                 showFilteredItems={() => showFilteredProducts}
                                                 clearFilters={() => clearFilters()}
                                                 breadcrumbs={breadcrumbs}
            />}

            {!isMobile && <div className={'container mt-3'}>
                <div className={'row'}>
                    <div className={'col-8 offset-2'}>
                        <div className="input-group mb-3 rounded-custom-1">
                                <span className="input-group-text bg-grey border-0 rounded-custom-1"
                                      id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} className={'text-light-gray'}/>
                                </span>
                            <input type="text"
                                   className="form-control bg-grey-input border-0 rounded-custom-1 text-light-gray placeholder-gray p-3 sfProDisplayFont"
                                   placeholder="Search for items"
                                   aria-label="Search" aria-describedby="basic-addon1"/>
                        </div>
                    </div>
                </div>
            </div>}

            <div className={`w-100 ${isMobile ? "" : "bg-grey"} ${isMobile ? 'ps-3 pt-3 pe-3' : 'p-3'}`}>
                <div className={'container'}>
                    <div className={'row flex-wrap'}>
                        <div className={'col mt-1 mb-1'}>
                            <select name="sort_by" id="sort_by"
                                    className={`form-control form-select rounded-pill filter-options text-light-gray ${isMobile ? "bg-grey-input" : ""}`}
                                    onChange={handleProductSort}>
                                <option value="1" disabled>Sort By</option>
                                {/*<option value="1">Our favourites</option>*/}
                                <option value="BEST_SELLING">Best Selling</option>
                                <option value="NEWEST">Newest</option>
                                <option value="PRICE_DESC">Price High to Low</option>
                                <option value="PRICE_ASC">Price Low to High</option>
                            </select>
                        </div>
                        {isMobile ? renderMobileFilters() : renderFilters()}
                    </div>
                </div>
            </div>

            <div className={'container mt-3'}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to={'/shop'}
                                 className={'text-black text-decoration-none bebasFont font-weight-800 text-breadcrumb'}>
                            Home
                        </NavLink>
                    </Breadcrumb.Item>
                    {breadcrumbs.length && breadcrumbs.map((value, index, breadcrumbs) => {
                        link += "/" + value;
                        return (<Breadcrumb.Item active={index + 1 === breadcrumbs.length}>
                            <NavLink to={link}
                                     className={`text-breadcrumb text-decoration-none bebasFont ${(index + 1 === breadcrumbs.length) ? 'text-light-gray' : 'text-black'}`}>
                                {value}
                            </NavLink>
                        </Breadcrumb.Item>);
                    })}
                </Breadcrumb>
            </div>

            <div className={`mt-3 ${isMobile ? "" : "container"}`}>
                {data.length ? data.map((value) => {
                    return (<>
                        <div className={'row ms-0 me-0'}>
                            <div
                                className={`mt-2 col text-left h2 bebasFont ${isMobile ? "ps-4" : ''}`}>{value.name}</div>
                            {horizontalScroll && renderViewAllButton(`View All ${value.name}`, value)}
                        </div>
                        {renderScrollDirection(horizontalScroll, value.products)}
                    </>);
                }) : null}
            </div>
        </>);
    }

    return render();
}

export default withRouter(ProductsListPage);
