import NewsCard from '../../NewsCard';
import GoogleAd from '../../../../Components/SnackAds/GoogleAd';
import React, {useEffect, useState} from 'react';
import {get} from '../../../../Axios/get';
import {FOOTBALL} from '../../../../Axios/EndPoints';
import moment from 'moment-timezone';

const LeagueNews = (props) => {
    const {league, isMobile, indexi,leagueName} = props

    let [allLeagueNewsData, setAllLeagueNewsData] = useState([]);
    let [allLeagueNewsDataLoading, setAllLeagueNewsDataLoading] = useState(true);

    const fetchSpecificLeagueNews = async (league_Data) => {
        let leagueData = league_Data;
        let page = '&page=1';
        let limit = '?limit=8';
        let leagueId = '&league_id=' + leagueData?.league_id;
        let leagueName = leagueData?.name.replace(' ', '');
        await get(FOOTBALL.LatestNewsEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return

            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data, index) => {
                let news = {
                    leagueId: leagueData?.league_id,
                    leagueName: leagueName,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setAllLeagueNewsData(tempNewsData);
            setAllLeagueNewsDataLoading(false)
        }).catch((err) => {
            console.error(err);
        });
    };


    useEffect(() => {
        fetchSpecificLeagueNews(league)
    }, [props.league])

    const SportNews = allLeagueNewsData;
    const SportName = 'football';


    console.log('value -',league)

    const renderLeftLatestNews = () => {
        return (
            <>
                <div
                    className={'position-absolute mt-n5 robotoCondensedBold font-28 container'}
                    style={{
                        zIndex: 1,
                        width: '250px',
                        transform: 'skew(-10deg)',
                        background:league?.bg_color || '#000000',
                        color:league?.font_color || '#000000',
                    }}
                >
                    {leagueName}
                </div>
                <hr style={{height:'3px'}}/>
                <div className={`col-xxl-8 mt-2 col-xl-8 col-lg-8 col-md-8 col-sm-12`}>
                    <div className={`w-100`}>
                        <div className={'row '}>
                            {SportNews.map((news, index) => {
                                return index < 8 &&
                                    (
                                        <div
                                            className={`col-xl-6 m-0 p-1 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                        >
                                            <NewsCard
                                                isNewDesign={true}
                                                isMobile={isMobile}
                                                classProps={''}
                                                newsItem={news}
                                                isFanzine={false}
                                                // isNewsPage={true}
                                                isLatestNews={true}
                                                sport={SportName}
                                            />
                                        </div>
                                    );
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderRightAds = () => {

        let numberOfAds = [1, 2, 3, 4];

        return (
            <div className={'col-4'}>
                {numberOfAds.map((news, index) => {
                    return (index < 4) && (
                        <div
                            className={`${index === 0 && 'mt-4 pt-3'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                        >
                            <div
                                style={{
                                    border: '1px black solid',
                                }}
                                className="d-flex justify-content-center align-items-center h1">
                                <GoogleAd id={'4009154580'}/>
                            </div>
                        </div>
                    );
                })}
            </div>);
    };

    const renderMain = () => {
        return (
            <section className={'w-100 mt-5'}>
                <div className={'container'}>
                    <div className={'container'}>
                        <div className={'row ps-5 pe-5'}>
                            {renderLeftLatestNews()}
                            {!isMobile && renderRightAds()}
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    return renderMain();


}

export default LeagueNews
