import React, {useEffect, useState} from "react";
import './styles/custom.css';
import {COLORS, COLORS_RGBA, FONT_COLOR, NAV_CLASS, TEXT_COLOR} from "../../Configs/Targets";
import '../../assets/styles/footballTargets.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {get} from "../../Axios/get";
import {HomepageStandings} from "../../Axios/EndPoints";
import {chunk} from "../Helper/chunk";
import {useSelector} from "react-redux";
import {getHostname} from "../../CustomFunctions/getHostname";
import {SCREEN_SIZE} from "../../Configs/Constants";
import ItemLoader from "../Loader/ItemLoader";
import {useNavigate} from "react-router-dom";

const Standings = (props) => {
    let store = useSelector(state => state);
    let navigate = useNavigate();

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [fullTable, setFullTable] = useState(false);
    let [standings, setStandings] = useState(props.standings ? props.standings : []);
    let [moreStandings, setMoreStandings] = useState([]);
    let [hasData, setHasData] = useState(false);
    let [isMobile, setIsMobile] = useState(false);
    let [loading, setLoading] = useState(true);
    const [shouldNavigate, setShouldNavigate] = useState('false');

    const fetchStandings = (is_mobile) => {
        get(HomepageStandings)
            .then((response) => {
                const hostName = window.location.hostname;
                const domainObject = getHostname(hostName);
                const target = domainObject.host;
                let standingsData = response.data.standings;
                let bandsData = response.data.bands;
                let tempMoreStandings = [];
                let tempStandings = [];
                let standingsChunked = chunk(standingsData, 100);
                let promotionQuantity = parseInt(bandsData.promotion_quantity);
                let promotionColor = bandsData.promotion_color;
                let belowPromotionQuantity = parseInt(bandsData.below_promotion_quality);
                let belowPromotionColor = bandsData.below_promotion_color;
                let aboveRelegationQuantity = parseInt(bandsData.above_relegation_quantity);
                let aboveRelegationColor = bandsData.above_relegation_color;
                let relegationQuantity = parseInt(bandsData.relegation_quantity);
                let relegationColor = bandsData.relegation_color;
                let chunkMoreStandingsArray = is_mobile ? standingsData : standingsChunked[0];
                standingsChunked[0].map((data, index) => {
                    let backgroundColor = {};
                    if (index < promotionQuantity) {
                        backgroundColor = {backgroundColor: promotionColor};
                    }
                    if (index < promotionQuantity + belowPromotionQuantity && index >= promotionQuantity) {
                        backgroundColor = {backgroundColor: belowPromotionColor};
                    }
                    if (relegationQuantity !== 0 && index >= standingsData.length - relegationQuantity) {
                        backgroundColor = {backgroundColor: relegationColor};
                    }
                    if (aboveRelegationQuantity !== 0 && index < standingsData.length - relegationQuantity && index >= standingsData.length - aboveRelegationQuantity - relegationQuantity) {
                        backgroundColor = {backgroundColor: aboveRelegationColor};
                    }
                    if (parseInt(data.team.id) === parseInt(store.football.currentTeamId)) {
                        backgroundColor = {backgroundColor: COLORS_RGBA[target] + '0.5)'};
                    }
                    tempStandings.push({
                        team: {
                            name: data.team.short_name,
                            icon: data.team.club_color ? data.team.club_color : data.team.icon,
                        },
                        arrow: data.arrow,
                        position: data.position,
                        mp: data.pl,
                        pl: data.pl,
                        gd: data.gd,
                        pts: data.pts,
                        win: data.win,
                        draw: data.draw,
                        lose: data.lose,
                        gd2: data.gd2,
                        background: backgroundColor,
                    })
                });
                chunkMoreStandingsArray.map((data, index) => {
                    let backgroundColor = {};
                    if (index < promotionQuantity) {
                        backgroundColor = {backgroundColor: promotionColor};
                    }
                    if (index < promotionQuantity + belowPromotionQuantity && index >= promotionQuantity) {
                        backgroundColor = {backgroundColor: belowPromotionColor};
                    }
                    if (relegationQuantity !== 0 && index >= standingsData.length - relegationQuantity) {
                        backgroundColor = {backgroundColor: relegationColor};
                    }
                    if (aboveRelegationQuantity !== 0 && index < standingsData.length - relegationQuantity && index >= standingsData.length - aboveRelegationQuantity - relegationQuantity) {
                        backgroundColor = {backgroundColor: aboveRelegationColor};
                    }
                    if (parseInt(data.team.id) === parseInt(store.football.currentTeamId)) {
                        backgroundColor = {backgroundColor: COLORS_RGBA[target] + '0.5)'};
                    }

                    tempMoreStandings.push({
                        team: {
                            name: data.team.short_name,
                            short_name: data.team.short_name,
                            icon: data.team.club_color ? data.team.club_color : data.team.icon,
                        },
                        arrow: data.arrow,
                        position: data.position,
                        mp: data.pl,
                        pl: data.pl,
                        gd: data.gd,
                        pts: data.pts,
                        win: data.win,
                        draw: data.draw,
                        lose: data.lose,
                        gd2: data.gd2,
                        background: backgroundColor,
                    })
                });

                if (!is_mobile) {
                    setStandings(tempStandings);
                }
                setMoreStandings(tempMoreStandings);
                setHasData(true);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        fetchStandings(window.innerWidth <= SCREEN_SIZE.MOBILE);
        if (props.standings) {
            setStandings(props.standings);
        }
    }, [store.football.currentTeamId]);

    useEffect(() => {
        shouldNavigate !== 'false' && navigate(shouldNavigate + '');
    }, [navigate, shouldNavigate]);

    useEffect(() => {
        setStandings(props.standings);
    }, [props.standings]);

    const renderMobilePartialTable = (standingBgColor, textColor, color) => {
        return (
            <>
                <div className={`bebasFont font-weight-800 text-header`} style={{fontSize: "30px"}}>
                    {store.football.defaultLeague.name}
                </div>
                <div className={`mt-3`}>

                </div>
                <table>
                    <thead>
                    <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
                        <td></td>
                        <td style={{width: "130px"}}>Teams</td>
                        <td>P</td>
                        <td>W</td>
                        <td>D</td>
                        <td>L</td>
                        <td>F/A</td>
                        <td>GD</td>
                        <td>Pts</td>
                    </tr>
                    </thead>
                    <tbody>
                    {standings.map((value) => (
                        <>
                            <tr className={`text-light-black sfProDisplayFont small`}
                                style={store.football.currentTeamId === value.team.id ? {backgroundColor: standingBgColor} : {}}>
                                <td className={`text-center`}><span className={'sfProDisplayFont'}>{value.position}. &nbsp;</span></td>
                                <td className={`p-2`}>
                                    <img src={value.team.icon} height={30} alt=""/>
                                    <span className={'sfProDisplayFont ms-2'}>&nbsp; {value.team.short_name}</span>
                                </td>
                                <td className={`text-center`}>{value.pl}</td>
                                <td className={`text-center`}>{value.win}</td>
                                <td className={`text-center`}>{value.draw}</td>
                                <td className={`text-center`}>{value.lose}</td>
                                <td className={`text-center`}>{value.gd2}</td>
                                <td className={`text-center`}>{value.gd}</td>
                                <td className={`text-center`}>{value.pts}</td>
                            </tr>
                        </>
                    ))}
                    </tbody>
                </table>
                {moreStandings.length &&
                    <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                        onClick={() => {
                            setFullTable(!fullTable)
                        }}
                    >
                        {fullTable ? 'Collapse' : 'Full Table'} &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color} size={'1x'}/>
                    </li>}
            </>
        );
    }

    const renderMobileFullTable = (standingBgColor, textColor, color) => {
        return (
            <>
                <div className={`bebasFont font-weight-800 text-header`} style={{fontSize: "30px"}}>
                    {store.football.defaultLeague.name}
                </div>
                <div className={`mt-3`}>

                </div>
                <table>
                    <thead>
                    <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
                        <td></td>
                        <td style={{width: "130px"}}>Teams</td>
                        <td>P</td>
                        <td>W</td>
                        <td>D</td>
                        <td>L</td>
                        <td>F/A</td>
                        <td>GD</td>
                        <td>Pts</td>
                    </tr>
                    </thead>
                    <tbody>
                    {moreStandings.length && moreStandings.map(((value, index, array) => (
                        <tr className={`text-light-black sfProDisplayFont small`} style={value.background}>
                            <td className={`ps-1 text-center`}><span className={'sfProDisplayFont'}>{value.position}. &nbsp;</span></td>
                            <td className={`p-2`}>
                                <img src={value.team.icon} height={20} alt=""/>
                                <span className={'sfProDisplayFont ms-2'}>
                                                        &nbsp; {value.team.short_name}
                                                    </span>
                            </td>
                            <td className={`text-center`}>{value.pl}</td>
                            <td className={`text-center`}>{value.win}</td>
                            <td className={`text-center`}>{value.draw}</td>
                            <td className={`text-center`}>{value.lose}</td>
                            <td className={`text-center`}>{value.gd2}</td>
                            <td className={`text-center`}>{value.gd}</td>
                            <td className={`text-center`}>{value.pts}</td>
                        </tr>
                    )))}

                    </tbody>
                </table>
                {moreStandings.length &&
                    <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                        onClick={() => {
                            setFullTable(!fullTable)
                            window.scroll({
                                top: 1850,
                                behavior: "smooth"
                            })
                        }}
                    >
                        {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color}
                                         size={'1x'}/>
                    </li>}
                {/**/}
            </>
        );
    }

    const renderLaptop = (standingBgColor, textColor, color, navColor, fontColor) => {
        return (
            <>
                <div className="col-12 border-1 border-secondary rounded mt-4">
                    <div className={`${navColor} rounded-custom-1 text-center p-1`}>
                        <span className={`${fontColor} text-header`}>
                            <span className={'bebasFont'}>{store.football.defaultLeague.name}</span>
                            {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
                    </div>
                    <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0'}>
                        <ItemLoader loading={loading}/>
                        <li className={'p-3 bg-white'}>
                            <div className={'row'}>
                                <div
                                    className={'col-3 text-center text-light-black sfProDisplayFont text-nowrap font-14'}>
                                    Teams
                                </div>
                                <div className={'col text-end text-light-black sfProDisplayFont font-14'}>

                                </div>
                                <div className={'col text-end text-light-black sfProDisplayFont font-14 m-0 p-0'}>P</div>
                                <div className={'col text-end text-light-black sfProDisplayFont font-14 m-0 p-0'}>GD</div>
                                <div className={'col text-end text-light-black sfProDisplayFont font-14 m-0 ps-0'}>Pts</div>
                            </div>
                        </li>
                        {
                            standings.map(((value, index, array) => {
                                return (
                                    <li className={'p-3'} style={value.background}>
                                        <div className={'row'}>
                                            <div className={'col-4 text-start text-dark text-nowrap font-14 ps-1'}>
                                                <span className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                                                <img src={value.team.icon} height={20} alt=""/>
                                                <span className={'sfProDisplayFont'}>
                                                        &nbsp; {value.team.name}
                                                    </span>
                                            </div>
                                            <div className={'col text-end text-light-black'}>
                                                {() => {
                                                    if (value.arrow === 'up') {
                                                        return (<FontAwesomeIcon icon={faAngleUp} color={'#0e7d45'}
                                                                                 size={'1x'}/>);
                                                    }
                                                    if (value.arrow === 'down') {
                                                        return (
                                                            <FontAwesomeIcon icon={faAngleDown} color={'#e02020'}
                                                                             size={'1x'}/>);
                                                    }
                                                }}
                                            </div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14 m-0 p-0'}>{value.mp}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14 m-0 p-0'}>{value.gd}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14 m-0 ps-0'}>{value.pts}</div>
                                        </div>
                                    </li>
                                );
                            }))
                        }
                        {
                            moreStandings.length && moreStandings.map(((value, index, array) => {
                                return (
                                    <li className={`p-3 ${fullTable ? '' : 'd-none'}`} style={value.background}>
                                        <div className={'row'}>
                                            <div className={'col-4 text-start text-dark text-nowrap font-14'}>
                                                <span className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                                                <img src={value.team.icon} height={20} alt=""/>
                                                <span className={'sfProDisplayFont'}>
                                                &nbsp; {value.team.name}
                                            </span>
                                            </div>
                                            <div className={'col text-end text-light-black'}>
                                                {() => {
                                                    if (value.arrow === 'up') {
                                                        return (<FontAwesomeIcon icon={faAngleUp} color={'#0e7d45'}
                                                                                 size={'1x'}/>);
                                                    }
                                                    if (value.arrow === 'down') {
                                                        return (
                                                            <FontAwesomeIcon icon={faAngleDown} color={'#e02020'}
                                                                             size={'1x'}/>);
                                                    }
                                                }}
                                            </div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                                        </div>
                                    </li>
                                );
                            }))
                        }
                        {/*{moreStandings.length &&
                            <li className={`p-3 bg-white text-center sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                                onClick={() => {
                                    setFullTable(!fullTable)
                                }}
                            >
                                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color}
                                                 size={'1x'}/>
                            </li>}*/}
                    </ul>
                </div>
            </>
        );
    }

    const withData = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const textColor = TEXT_COLOR[target];
        const fontColor = FONT_COLOR[target];
        const color = COLORS[target];
        const standingBgColor = COLORS_RGBA[target] + "0.3)";
        return (
            isMobile ?
                !fullTable && standings ?
                    renderMobilePartialTable(standingBgColor, textColor, color) :
                    renderMobileFullTable(standingBgColor, textColor, color) :
                renderLaptop(standingBgColor, textColor, color, navColor, fontColor)
        );
    }

    const withoutData = () => {
        return (
            <div className={'bebasFont'}>
            </div>
        );
    }

    const render = () => {
        if (standings) {
            return withData();
        }
        return withoutData();
    }

    return render();
}

export default Standings;
