import PreviewUnavailablePlayers from "./Components/PreviewUnavaiablePlayers";
import PreviewRecentForm from "./Components/PreviewRecentForm";
import {get} from "../../../../../Axios/get";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../../../../../Configs/Constants";
import PreviewSocial from "./Components/PreviewNews";
import {useSelector} from "react-redux";

const SingleFootballMatchContentPreview = (props) => {
    let store = useSelector((state) => state);
    const {addDefaultSrc} = props
    let h2h, match_prediction, recent_form, sidelined, standings
    let [isMobile, setIsMobile] = useState(false);
    const isCurrent = props.currentActive === 'preview';

    let matchData = [];
    if (store?.football?.singleFootballMatch !== []) {
        matchData = store?.football?.singleFootballMatch;
    }

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);


    if (matchData) {

        h2h = matchData.h2h;
        match_prediction = matchData.match_prediction
        recent_form = matchData.recent_form
        sidelined = matchData.sidelined
        standings = matchData.standings
    }


    const render = () => {
        return (
            <div>
                <div className={'mb-5'}>
                    <PreviewUnavailablePlayers isMobile={isMobile} sidelined={sidelined} addDefaultSrc={addDefaultSrc}/>
                    <PreviewRecentForm
                        isCurrent={isCurrent}
                        isMobile={isMobile} recent_form={recent_form} addDefaultSrc={addDefaultSrc}/>
                </div>
                <PreviewSocial isCurrent={isCurrent} />
            </div>
        )
    }


    return render();

}
export default SingleFootballMatchContentPreview;
