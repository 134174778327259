import MatchItem from "./MatchItem";
import MatchItemLeague from "./MatchItemLeague";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import moment from "moment";

const Matches = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let {
        isMobile,
        matchData,
        liveMatchData,
        selectedDate,
        setIsSingleMatchSelected,
        setSingleMatchData,
        leagueMatchesGroupedByDate,
        selectedLeagueDate,
        setSelectedLeagueData,
        setLeagueMatchesGroupedByDate,
        isLeagueSelected, setIsLeagueSelected,
        store
    } = props;
    let [leagueID, setLeagueID] = useState(searchParams.get('league_id') || '')

    useEffect(() => {
    }, [searchParams.get('league_id'), leagueMatchesGroupedByDate]);
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [leagueMatchesGroupedByDate]);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../../../assets/images/default-league.png");
    };

    return (
        <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 `}>
            <div className={`row`}>
                <div
                    className={`mt-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                >
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        {(leagueMatchesGroupedByDate == null) &&
                            matchData?.map((value, index) => (
                                (value?.league_id !== 0) &&
                                (<div key={index} className="container p-1">
                                    {(searchParams.get('league_id') != null && searchParams.get('league_id') == value.league_id) ?
                                        <MatchItemLeague leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                         isLeagueSelected={isLeagueSelected}
                                                         isMobile={isMobile}
                                                         setIsLeagueSelected={setIsLeagueSelected}
                                                         setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate} setSelectedLeagueData={setSelectedLeagueData} addDefaultSrc={addDefaultSrc}
                                                         value={value}/>
                                        :
                                        ''
                                    }
                                    {searchParams.get('league_id') == null &&
                                        <MatchItemLeague addDefaultSrc={addDefaultSrc} value={value}
                                                         isLeagueSelected={isLeagueSelected}
                                                         isMobile={isMobile}
                                                         setIsLeagueSelected={setIsLeagueSelected}
                                                         leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                         setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}/>
                                    }
                                    {value.matches.map((matches, matchIndex) => (
                                        (
                                            (searchParams.get('league_id') != null && searchParams.get('league_id') == value.league_id) ?
                                                <MatchItem setSingleMatchData={setSingleMatchData}
                                                           liveMatchData={liveMatchData}
                                                           isMobile={isMobile}
                                                           setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                           selectedDate={selectedDate}
                                                           matches={matches}
                                                           matchIndex={matchIndex}/> : searchParams.get('league_id') == null ?
                                                    <MatchItem setSingleMatchData={setSingleMatchData}
                                                               liveMatchData={liveMatchData}
                                                               isMobile={isMobile}
                                                               setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                               selectedDate={selectedDate}
                                                               matches={matches} matchIndex={matchIndex}/> : '': 'w'
                                        )
                                    ))}
                                </div>)
                            ))}

                        {(leagueMatchesGroupedByDate != null) &&
                            leagueMatchesGroupedByDate?.map((value, index) => (
                                <>
                                    {index == 0 && <div className={'container p-1 '}>
                                        <MatchItemLeague addDefaultSrc={addDefaultSrc}
                                                         value={store.football.fanzineLeagues.find(obj => {
                                                             return obj.league_id == leagueID
                                                         })}
                                                         isLeagueSelected={isLeagueSelected}
                                                         setIsLeagueSelected={setIsLeagueSelected}
                                                         selectedLeagueDate={selectedLeagueDate}
                                                         isMobile={isMobile}
                                                         leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                         setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                                        />
                                    </div>
                                    }
                                    <h6 className={'m-2'}>
                                        <b> {moment(value.date).format('dddd')} {'\t'} {moment(value.date).date()} {'\t'}
                                            {moment(value.date).format('MMMM')} {moment(value.date).year()}
                                        </b>
                                    </h6>
                                    {value.data.map((matches, matchIndex) => (
                                        (
                                            // console.log('matches =', matches)
                                            <MatchItem setSingleMatchData={setSingleMatchData}
                                                       liveMatchData={liveMatchData}
                                                       setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                       selectedDate={selectedDate}
                                                       isMobile={isMobile}
                                                       matches={matches}
                                                       matchIndex={matchIndex}/>
                                        )
                                    ))}
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Matches;
