import React, {useEffect, useState} from 'react';
import {get} from '../../../../../Axios/get';
import {FANZINE} from '../../../../../Axios/EndPoints';
import {useParams} from 'react-router-dom';
import PreviewNews from './Components/PreviewNews';
import LineupsPlayerModal from './Components/LineupsPlayerModal';
import {Button, Modal} from 'react-bootstrap';
import ItemLoader from '../../../../../Components/Loader/ItemLoader';

const SingleFootballMatchContentLineups = (props) => {
  const params = useParams();
  const {isMobile, addDefaultSrc} = props;
  const [selectedTeam, setSelectedTeam] = useState(true);
  const [lineupsData, setLineupsData] = useState(null);
  const [sidelined, setSidelined] = useState(null);
  const isCurrent = props.currentActive === 'lineups';
  const [show, setShow] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    isCurrent && fetchLineups();
  }, [isCurrent]);

  const fetchLineups = () => {
    get(FANZINE.SingleMatchEndPoint + params.id +
        FANZINE.MatchesLineupsSubEndPoint).then((res) => {
      setLineupsData(res.data);
      setSidelined(res.data);
    }).catch((error) => {
      console.error(error);
    });
  };

  const renderTeamSelect = () => {

    const handleTeamSelect = () => {
      setSelectedTeam(!selectedTeam);
    };

    return (
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-6'}>
              <div
                  onClick={handleTeamSelect}
                  className={`container d-flex align-items-center sfProDisplayFontBold`}
                  style={{
                    cursor: 'pointer',
                    background: selectedTeam ? '#1e1e1e' : '#f0f1f2',
                    borderRadius: '41px',
                    height: '54px',
                    color: selectedTeam ? 'white' : '',
                  }}
              >
                <img
                    className={` ${isMobile ? '' : 'me-auto'} `}
                    height={isMobile ? 25 : 40}
                    width={'auto'}
                    alt={'Host team badge'}
                    src={sidelined?.localteam?.localteam_icon}
                    onError={addDefaultSrc}
                ></img>
                <p className={`text-center ${isMobile ?
                    'm-2 font-14' :
                    'm-5'}`}>
                  {sidelined?.localteam?.localteam_name}
                </p>

              </div>
            </div>
            <div className={'col-6'}>
              <div
                  onClick={handleTeamSelect}
                  className={`container d-flex align-items-center sfProDisplayFontBold`}
                  style={{
                    cursor: 'pointer',
                    background: !selectedTeam ? '#1e1e1e' : '#f0f1f2',
                    borderRadius: '41px',
                    height: '54px',
                    color: !selectedTeam ? 'white' : '',
                  }}
              >
                <img
                    className={`me-auto`}
                    height={isMobile ? 25 : 40}
                    width={'auto'}
                    alt={'Host team badge'}
                    src={sidelined?.visitorteam?.visitorteam_icon}
                    onError={addDefaultSrc}
                ></img>
                <p className={`text-center ${isMobile ?
                    'm-2 font-14' :
                    'm-5'}`}>
                  {sidelined?.visitorteam?.visitorteam_name}
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderHomeGoalKeeper = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              [sidelined?.localteam?.squad?.goalkeeper]?.map(
                  (players, index) => (
                      <div className={'col'}
                           onClick={() => getPlayerData(players)}

                           key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                        </span>
                        <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                            'font-10' :
                            'font-10'}`}
                           style={{color: '#000000'}}>
                          {players?.player_number + '. '}{players?.name}
                        </p>
                      </div>
                  ))}
        </div>
    );
  };

  const renderHomeDefenders = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line1?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeMidfielders = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line2?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeWingers = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line3?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}
                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeStrikers = () => {
    return (
        <div className={`row ${sidelined?.localteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-3'}`}>
          {sidelined !== undefined &&
              sidelined?.localteam?.squad?.line4?.map((players, index) => (
                  <div
                      onClick={() => getPlayerData(players)}
                      className={'col'}
                       key={index}>
                    <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                      {players?.yellowcards != 0 &&
                          renderIconsOnBench('yellowcard')}
                      {players?.redcards != 0 &&
                          renderIconsOnBench('redcard')}
                      {players?.goals != 0 && renderIconsOnBench('goal')}
                      <img src={players?.icon}
                           className={'player-unavailable ms-auto me-auto d-flex'}
                           alt={'player'}
                           height={isMobile ? '34px' : '52px'}
                           width={'auto'}
                           onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderHomeTeamFormation = () => {
    return (
        <div className={` ${sidelined?.localteam?.squad?.line4 ?
            'mt-5' :
            'mt-3'}`}>
          {renderHomeStrikers()}
          {renderHomeWingers()}
          {renderHomeMidfielders()}
          {renderHomeDefenders()}
          {renderHomeGoalKeeper()}
        </div>
    );
  };

  const renderAwayGoalKeeper = () => {
    console.log('sidelined?.localteam?.squad?.line4.length =',
        sidelined?.visitorteam?.squad);
    return (
        <div className={`row ${sidelined?.visitorteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              [sidelined?.visitorteam?.squad?.goalkeeper]?.map(
                  (players, index) => (
                      <div className={'col'}
                           onClick={() => getPlayerData(players)}

                           key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '35px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                        </span>
                        <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                            'font-10' :
                            'font-10'}`}
                           style={{color: '#000000'}}>
                          {players?.player_number + '. '}{players?.name}
                        </p>
                      </div>
                  ))}
        </div>
    );
  };

  const renderAwayDefenders = () => {
    return (
        <div className={`row ${sidelined?.visitorteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.visitorteam?.squad?.line1?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderAwayMidfielders = () => {
    return (
        <div className={`row ${sidelined?.visitorteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.visitorteam?.squad?.line2?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderAwayWingers = () => {
    return (
        <div className={`row ${sidelined?.visitorteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.visitorteam?.squad?.line3?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}

                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderAwayStrikers = () => {
    return (
        <div className={`row ${sidelined?.visitorteam?.squad?.line4 ?
            isMobile ? 'm-3' : 'm-2' :
            'm-4'}`}>
          {sidelined !== undefined &&
              sidelined?.visitorteam?.squad?.line4?.map((players, index) => (
                  <div className={'col'}
                       onClick={() => getPlayerData(players)}
                       key={index}>
                        <span className={'d-flex justify-content-center'}>
                            {players?.substituted &&
                                renderIconsOnBench('subs')}
                          {players?.yellowcards != 0 &&
                              renderIconsOnBench('yellowcard')}
                          {players?.redcards != 0 &&
                              renderIconsOnBench('redcard')}
                          {players?.goals != 0 && renderIconsOnBench('goal')}
                          <img src={players?.icon}
                               className={'player-unavailable ms-auto me-auto d-flex'}
                               alt={'player'}
                               height={isMobile ? '34px' : '52px'}
                               width={'auto'}
                               onError={addDefaultSrc}/>
                    </span>
                    <p className={`sfProDisplayFontBold mt-1 text-white text-center ${isMobile ?
                        'font-10' :
                        'font-10'}`}
                       style={{color: '#000000'}}>
                      {players?.player_number + '. '}{players?.name}
                    </p>
                  </div>
              ))}
        </div>
    );
  };

  const renderAwayTeamFormation = () => {
    return (
        <div className={` ${sidelined?.localteam?.squad?.line4 ?
            'mt-5' :
            'mt-3'}`}>
          {renderAwayStrikers()}
          {renderAwayWingers()}
          {renderAwayMidfielders()}
          {renderAwayDefenders()}
          {renderAwayGoalKeeper()}
        </div>
    );
  };

  const renderFieldView = () => {
    return (
        <div className={'container pt-3'}>
          <div className={'row position-relative'}>
            <img src={require('../../../../../assets/images/field.png')}
                 alt={'field'} height={'520px'}
                 style={{objectFit: 'cover'}}
                 className={'bg-dark-green rounded-custom'}
            />
            <div className={'position-absolute'}>
              {selectedTeam ?
                  renderHomeTeamFormation() :
                  renderAwayTeamFormation()}
            </div>
          </div>
        </div>
    );
  };

  const renderHomeTeamManager = () => {
    return (
        <div className={` ${isMobile ? 'col-6' : 'row'}  mt-3 mb-1`}>
          {
            sidelined?.localteam?.manager.map((manager, index) => (
                <div
                    key={index}
                    className={'d-flex'}>
                  <img src={manager?.icon}
                       className={'player-unavailable'}
                       alt={'player'}
                       height={isMobile ? '45px' : '56px'}
                       width={'auto'}
                       onError={addDefaultSrc}/>
                  <p className={`sfProDisplayFont ms-2 mt-1 text-left ${isMobile ?
                      'font-10' :
                      'font-12'}`}
                     style={{color: '#000000'}}>
                    {manager?.name}
                    <p className={'sfProDisplayFont font-10 text-left'}
                       style={{color: '#878787'}}>
                      Since July 2016 <br/>72% Win Percentage

                    </p>
                  </p>
                </div>
            ))
          }
        </div>
    );
  };

  const renderAwayTeamManager = () => {
    return (
        <div className={` ${isMobile ? 'col-6' : 'row'}  mt-3 mb-1`}>
          {
            sidelined?.visitorteam?.manager.map((manager, index) => (
                <div
                    key={index}
                    className={'d-flex'}>
                  <img src={manager?.icon}
                       className={'player-unavailable'}
                       alt={'player'}
                       height={isMobile ? '45px' : '56px'}
                       width={'auto'}
                       onError={addDefaultSrc}/>
                  <p className={`sfProDisplayFont ms-2 mt-1 text-left ${isMobile ?
                      'font-10' :
                      'font-12'}`}
                     style={{color: '#000000'}}>
                    {manager?.name}
                    <p className={'sfProDisplayFont font-10 text-left'}
                       style={{color: '#878787'}}>
                      Since July 2016 <br/>72% Win Percentage

                    </p>
                  </p>
                </div>

            ))
          }
        </div>
    );
  };

  const renderManagerMobileView = () => {
    return (
        <div className={`row rounded-custom ${isMobile && 'bg-white'}`}>
          <p className={'bebasFont font-24'}> Manager
          </p>
          {renderHomeTeamManager()}
          {renderAwayTeamManager()}
        </div>
    );
  };

  const renderIconsOnBench = (value, isBench) => {

    const renderSubIcon = () => {

      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginRight: '40px',
            display: 'none',
          };

      return (
          <img
              src={require(
                  '../../../../../assets/images/subEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderYellowCardIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '40px',
            display: 'none',
          };

      return (
          <img
              src={require(
                  '../../../../../assets/images/yellowEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderRedCardIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '40px',
            display: 'none',

          };
      return (
          <img
              src={require(
                  '../../../../../assets/images/yellowEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderGoalIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginLeft: '50px',
            display: 'none',

          };

      return (
          <img
              src={require(
                  '../../../../../assets/images/goalEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    const renderAssistIcon = () => {
      let style = isBench ? {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
          } :
          {
            position: 'absolute',
            marginTop: '-10px',
            marginRight: '40px',
          };
      return (
          <img
              src={require(
                  '../../../../../assets/images/assisstEmblem.png')}
              alt={'subIcon'}
              height={'35'}
              style={style}
          />
      );
    };

    switch (value) {
      case 'subs' :
        return renderSubIcon();
      case 'yellowcard':
        return renderYellowCardIcon();
      case 'redcard':
        return renderRedCardIcon();
      case 'goal':
        return renderGoalIcon();
      case 'assist':
        return renderAssistIcon();
      default:
        console.log('error no arg send to func renderIconsOnBench');
        break;
    }
  };

  const [isModal, setIsModal] = useState(false);
  const updateModal = () => {
    setIsModal(!isModal);
  };

  const renderHomeTeamBench = () => {



    return (
        <div
            className={'col-6 mt-3 mb-1'}
            style={{borderRight: '2px solid #f1f1f1'}}
        >
          <p className={'sfProDisplayFontBold font-14 text-start'}>
            {sidelined?.localteam.localteam_name} Bench
          </p>
          <div className={'row'}>
            {sidelined !== undefined &&
                sidelined?.localteam?.substitutions?.map((value, index) =>
                    (
                        <div
                            onClick={() => getPlayerData(value)}
                            className={'col-4'}
                            style={{height: '140px'}}
                            key={index}>


                          <span>
                            {value?.off_name !== null &&
                                renderIconsOnBench('subs', true)}
                            {value?.on_yellowcards !== 0 &&
                                renderIconsOnBench('yellowcard', true)}
                            {value?.on_redcards !== 0 &&
                                renderIconsOnBench('redcard', true)}
                            {value?.on_goals !== 0 &&
                                renderIconsOnBench('goal', true)}
                            <img src={value.on_icon}
                                 className={'player-unavailable ms-auto me-auto d-flex'}
                                 alt={'player'}
                                 height={isMobile ? '45px' : '56px'}
                                 width={'auto'}
                                 onError={addDefaultSrc}>
                                </img>
                                </span>
                          <p className={`sfProDisplayFont mt-1 text-center ${isMobile ?
                              'font-10' :
                              'font-12'}`}
                             style={{color: '#000000'}}>
                            {value?.on_player_number + '. '} {value?.on_name}
                          </p>
                        </div>
                    ))
            }
          </div>
        </div>
    );
  };

  const renderAwayTeamBench = () => {
    return (
        <div className={'col-6 mt-3 mb-1'}>
          <p className={'sfProDisplayFontBold font-14 text-start'}>
            {sidelined?.visitorteam.visitorteam_name} Bench
          </p>
          <div className={'row'}>
            {sidelined !== undefined &&
                sidelined?.visitorteam?.substitutions?.map((value, index) =>
                    (
                        <div className={'col-4'}
                             style={{height: '140px'}}
                             key={index}
                             onClick={() => getPlayerData(value)}
                        >

                          <span>
                            {value?.off_name !== null &&
                                renderIconsOnBench('subs', true)}
                            {value?.on_yellowcards !== 0 &&
                                renderIconsOnBench('yellowcard', true)}
                            {value?.on_redcards !== 0 &&
                                renderIconsOnBench('redcard', true)}
                            {value?.on_goals !== 0 &&
                                renderIconsOnBench('goal', true)}
                            <img src={value.on_icon}
                                 className={'player-unavailable ms-auto me-auto d-flex'}
                                 alt={'player'}
                                 height={isMobile ? '45px' : '56px'}
                                 width={'auto'}
                                 onError={addDefaultSrc}/>
                          </span>
                          <p className={`sfProDisplayFont mt-1 text-center ${isMobile ?
                              'font-10' :
                              'font-12'}`}
                             style={{color: '#000000'}}>
                            {value?.on_player_number + '. '} {value?.on_name}
                          </p>
                        </div>
                    ))
            }
          </div>
        </div>
    );
  };

  const renderSubs = () => {
    return (
        <div>
          <div className={'row'}
          >
            {renderHomeTeamBench()}
            {renderAwayTeamBench()}
          </div>
        </div>
    );
  };

  const renderUnavaiablePlayers = () => {

    const handleTeamSelect = () => {
      setSelectedTeam(!selectedTeam);
    };

    return (sidelined !== null &&
        (<div className={'row rounded-custom mt-2 mb-2'}
              style={{background: '#ffffff'}}
        >
          <p className={'bebasFont font-24 text-start'}>
            Unavailable Player
          </p>
          <div className={'container mb-4'}>
            <div className={'row'}>
              <div className={'col-6'}>
                <div
                    onClick={handleTeamSelect}
                    className={`container d-flex align-items-center sfProDisplayFontBold`}
                    style={{
                      cursor: 'pointer',
                      background: selectedTeam ? '#1e1e1e' : '#f0f1f2',
                      borderRadius: '41px',
                      height: '54px',
                      color: selectedTeam ? 'white' : '',
                    }}
                >
                  <img
                      className={` ${isMobile ? '' : 'me-auto'} `}
                      height={isMobile ? 30 : 40}
                      width={'auto'}
                      alt={'Host team badge'}
                      src={sidelined?.localteam?.localteam_icon}
                      onError={addDefaultSrc}
                  ></img>
                  <p className={`text-center ${isMobile ?
                      'm-2 font-14' :
                      'm-5'}`}>
                    {sidelined?.localteam?.localteam_name}
                  </p>

                </div>
              </div>
              <div className={'col-6'}>
                <div
                    onClick={handleTeamSelect}
                    className={`container d-flex align-items-center sfProDisplayFontBold`}
                    style={{
                      cursor: 'pointer',
                      background: !selectedTeam ? '#1e1e1e' : '#f0f1f2',
                      borderRadius: '41px',
                      height: '54px',
                      color: !selectedTeam ? 'white' : '',
                    }}
                >
                  <img
                      className={`me-auto`}
                      height={40}
                      width={'auto'}
                      alt={'Host team badge'}
                      src={sidelined?.visitorteam?.visitorteam_icon}
                      onError={addDefaultSrc}
                  ></img>
                  <p className={`text-center ${isMobile ?
                      'm-2 font-14' :
                      'm-5'}`}>
                    {sidelined?.visitorteam?.visitorteam_name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {selectedTeam && <div className={'col-12'}>
            <div className={'row'}
                 style={{borderRight: '1px solid #f1f1f1'}}
            >
              {sidelined !== undefined &&
                  sidelined.localteam.sidelined?.map((value, index) =>
                      (
                          <div className={'col-4'}
                               key={index}>
                            <img src={value.icon}
                                 className={'player-unavailable ms-auto me-auto d-flex'}
                                 alt={'player'}
                                 height={isMobile ? '45px' : '56px'}
                                 width={'auto'}
                                 onError={addDefaultSrc}/>
                            <p className={`sfProDisplayFont mt-1 text-center ${isMobile ?
                                'font-10' :
                                'font-12'}`}
                               style={{color: '#000000'}}>
                              {value.name}
                              <p className={'sfProDisplayFont font-10 text-center'}
                                 style={{color: '#878787'}}>
                                {value.description} <br/>
                                {value.startdate}
                              </p>
                            </p>
                          </div>
                      ))
              }
            </div>
          </div>}
          {!selectedTeam && <div className={'col-12'}>
            <div className={'row'}>
              {sidelined !== undefined &&
                  sidelined.visitorteam.sidelined?.map((value, index) =>
                      (
                          <div className={'col-4'} key={index}>
                            <img src={value.icon}
                                 className={'player-unavailable ms-auto me-auto d-flex'}
                                 alt={'player'}
                                 height={isMobile ? '45px' : '56px'}
                                 width={'auto'}
                                 onError={addDefaultSrc}/>
                            <p className={`sfProDisplayFont mt-1 text-center ${isMobile ?
                                'font-10' :
                                'font-12'}`}
                               style={{color: '#000000'}}>
                              {value.name}
                              <p className={'sfProDisplayFont font-10 text-center'}
                                 style={{color: '#878787'}}>
                                {value.description} <br/>
                                {value.startdate}
                              </p>
                            </p>
                          </div>
                      ))
              }
            </div>
          </div>
          }            </div>)
    );

  };

  const renderEmbels = () => {

    let dataRight = [
      {
        title: 'Assist',
        height: 15,
        img: require('../../../../../assets/images/emblem/assist.png'),
      },
      {
        title: 'Yellow Card',
        height: 15,
        img: require('../../../../../assets/images/emblem/yellow.png'),
      },
      {
        title: 'Red Card',
        height: 15,
        img: require('../../../../../assets/images/emblem/red.png'),
      },
      {
        title: 'Second Yellow',
        height: 15,
        img: require('../../../../../assets/images/emblem/redyellow.png'),
      },
    ];

    let data = [
      {
        title: 'Goal',
        height: 15,
        img: require('../../../../../assets/images/emblem/goal.png'),
      },
      {
        title: 'Missed Penalty',
        height: 15,
        img: require('../../../../../assets/images/emblem/mpen.png'),
      },
      {
        title: 'Substitution',
        height: 15,
        img: require('../../../../../assets/images/emblem/sub.png'),
      },
      {
        title: 'Own Goal',
        height: 15,
        img: require('../../../../../assets/images/emblem/iconsOwnGoal@3x.png'),
      },
    ];

    return (
        <div className={'container-fluid'}>
          <div className={'row'}>
            <div className={'col-6 p-2'}>
              {data.map((value, index) =>
                  (
                      <div className={'row p-2'}>
                        <div
                            key={index}
                            className={'col-2 sfProDisplayFontBold '}
                        >
                          <img
                              className={''}
                              src={value?.img}
                              alt={value?.title}
                              height={20}
                          />
                        </div>
                        <div
                            className={'col-10 sfProDisplayFontBold align-items-center text-center font-11 d-flex'}
                        >

                          {value?.title}
                        </div>
                      </div>
                  ))
              }
            </div>
            <div className={'col-6 p-2'}>
              {dataRight.map((value, index) =>
                  (
                      <div className={'row p-2'}>
                        <div
                            key={index}
                            className={'col-2 sfProDisplayFontBold '}
                        >
                          <img
                              className={''}
                              src={value?.img}
                              alt={value?.title}
                              height={20}
                          />
                        </div>
                        <div
                            className={'col-10 sfProDisplayFontBold align-items-center text-center font-11 d-flex'}
                        >
                          {value?.title}
                        </div>
                      </div>
                  ))
              }

            </div>
          </div>
        </div>
    );
  };

  const renderMain = () => {
    return (
        <div className={'pt-2'}
        >
          <div className={`${isMobile &&
          'bg-white rounded-custom pt-2 pb-2 row'}`}>
            {renderTeamSelect()}
            {renderFieldView()}
          </div>
          {!isMobile && renderSubs()}
          {isMobile && renderUnavaiablePlayers()}
          {isMobile && renderManagerMobileView()}
          {!isMobile && <div className={'row'}>
            <div className={'col'}>
              {renderHomeTeamManager()}
            </div>
            <div className={'col'}>
              {renderAwayTeamManager()}
            </div>
          </div>
          } {isMobile && renderEmbels()}
          {!isMobile && <PreviewNews isCurrent={isCurrent}/>}
        </div>
    );
  };

  const getPlayerData = (value) => {
    console.log('value in player data=', value);
    let player_id = value?.id || value?.on_id;
    get(FANZINE.SingleMatchEndPoint + params?.id +
        FANZINE.MatchPlayerStatsMainEndPoint + player_id +
        FANZINE.MatchesPlayerStatsSubEndPoint).then((res) => {
      console.log('res =', res.data.data);
      setPlayerData(res.data.data);
      setShow(!show);
    }).catch((error) => console.error(error));
  };

  const playerStatsModal = () => {

    let player = playerData?.player;
    let stats = playerData?.stats;

    console.log('player', playerData?.player)

    const attacker = require('../../../../../assets/images/fw.png')
    const midfielder = require('../../../../../assets/images/mf.png')
    const defender = require('../../../../../assets/images/df.png')
    const goalkeeper = require('../../../../../assets/images/gk.png')

    const renderTopLine = () => {
      return (
          <div
              className={'row ps-2 pe-2 d-flex justify-content-between align-items-center'}>
            <div className={'col font-14 bebasFont'}>
                <div className={'d-flex justify-content-center'}>
                  <img
                      src={player?.position === "Attacker" ? attacker : player?.position === "Midfielder" ? midfielder :
                          player?.position === "Defender" ? defender :player?.position === "Goalkeeper" ? goalkeeper : ''}

                      alt={'img'} height={'50px'} className={'img-flex'}
                      onError={addDefaultSrc}/>
                </div>
              <div className={'d-flex justify-content-center'}>
                {player?.position}
              </div>
            </div>
            <div className={'col font-18 sfProDisplayFontBold'}>
                <div className={'d-flex justify-content-center'}>
                  <img src={player?.icon} alt={'img'} height={'66.2px'}
                       className={'rounded-custom img-flex'}
                       onError={addDefaultSrc}/>
                </div>
              <div className={'d-flex justify-content-center'}>
                {player?.name}
              </div>
            </div>
            <div className={'col '}
            >
              <div className={'d-flex bebasFont justify-content-center'}
                   style={{fontSize: '82px'}}
              >
                {player?.number}
              </div>
            </div>
          </div>
      );
    };

    const renderBottomLine = () => {

      let player_height = {
        ft: Math.trunc(player?.height / 30.48),
        inches: Math.trunc(Number(
            (player?.height / 30.48) - Math.trunc(player?.height / 30.48)).
            toFixed(1) * 12),
      };

      return (
          <div
              className={'row ps-2 pe-2 d-flex justify-content-between align-items-center'}>
            <div className={'col sfProDisplayFont'}>
              <div className={'d-flex sfProDisplayFontBold justify-content-center'}>
                  {player_height?.ft}' {player_height?.inches}"
                </div>
              <div className={'d-flex font-12 justify-content-center'}>
              HEIGHT
              </div>
            </div>
            <div className={'col font-12 sfProDisplayFont'}>
              <div
                  className={'d-flex font-14 sfProDisplayFontBold justify-content-center align-items-center'}>
                <img src={player?.flag_icon} alt={'img'} width={'26.5px'}
                     height={'17.6px'} className={'img-flex'}
                     onError={addDefaultSrc}/>
                &nbsp;
                {player?.nationality}
              </div>
              <div className={'d-flex font-12 justify-content-center'}>
              COUNTRY
              </div>
            </div>
            <div className={'font-12 col sfProDisplayFont '}
            >
              <div
                  className={'sfProDisplayFontBold font-14 d-flex justify-content-center'}>
                {player?.age}
              </div>
              <div className={'d-flex font-12 justify-content-center'}>
                AGE
              </div>
            </div>
          </div>
      );
    };

    const renderOtherStats = (value) => {

      return (value?.value !== '0' && value?.value !== '0.00' && value?.value !== '') ?
          (
              <div className={'col-3 font-14 pt-n150 text-center'}>
                <div className={'font-40 d-flex justify-content-center  sfProDisplayFontBold'}
                style={{marginBottom:'-10px'}}
                >
                  {value?.value}
                </div>
                {value?.name}
              </div>
          ) : '';
    };

    return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body
          className={'p-2'}
          >
            {playerData?.player && renderTopLine()}
            {playerData?.player && renderBottomLine()}
            {playerData?.stats && <div className={'row p-0 m-0'}
            style={{overflowY:'scroll'}}
            >
              {
                Object?.values(stats).map((value, index) => (
                    renderOtherStats(value)
                ))
              }
            </div>}
          </Modal.Body>
          {/*<Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>*/}
        </Modal>
    );
  };

  return (
      <>
        {playerStatsModal()}
        {renderMain()}
      </>
  );

};

export default SingleFootballMatchContentLineups;
