import React, {useEffect, useState} from 'react';
import './styles/custom.css';
import {FONT_COLOR, NAV_CLASS} from '../../Configs/Targets';
import '../../assets/styles/footballTargets.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import 'simplebar/src/simplebar.css';
import {FANZINE, SocialEndPoint} from '../../Axios/EndPoints';
import {get} from '../../Axios/get';
import moment from 'moment';
import {getHostname} from '../../CustomFunctions/getHostname';
import ItemLoader from '../Loader/ItemLoader';
import {useParams} from 'react-router-dom';
import {FaRetweet} from 'react-icons/fa';
import {faHeart, faRetweet} from '@fortawesome/free-solid-svg-icons';
import {FiShare} from 'react-icons/fi';
import SocialShareButton from './SocialShareButton';

const Social = (props) => {
  let {isNewsPage, queryParams, isFanzine, isSocial, isCurrent,isFanzineNews, limit} = props;
  let currentActive = isCurrent;
  limit = typeof limit !== 'undefined' ? limit : (isNewsPage ? 50 : 5);
  let params = useParams();
  let [socialNewsLimit] = useState(limit);
  let [apiPage, setApiPage] = useState(1);
  let [socialNews, setSocialNews] = useState([]);
  let [restrictApiCall, setRestrictApiCall] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleScroll = (e) => {
    const socialBottom = e.target.scrollHeight - e.target.scrollTop ===
        e.target.clientHeight;
    if (socialBottom) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  };

  const fetchSocialNews = (prevNews) => {
    let otherParam = '?team_type=all';
    if (isFanzine) {
      otherParam = otherParam + '&main_site=1';
    }
    if (queryParams) {
      otherParam = queryParams;
    }
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    if (target === 'fanzinesports') {
      console.log('props.league_id', props.league_id);
      if (props.league_id != 0) {
        otherParam = `?league_id=${props.league_id}`;
      } else {
        // otherParam = "?league_id=1000";
      }
    }
    // let limit = '&limit=' + (isNewsPage ? 50 : socialNewsLimit);
    let limit = '&limit=' + (isFanzineNews ? 4 : isNewsPage ? 30 : socialNewsLimit);
    let page = '&page=' + apiPage;

    let baseURL = isSocial ?
        FANZINE.MatchSocial + params.id :
        SocialEndPoint + otherParam + limit + page;

    get(baseURL).then((response) => {
      setSocialNews([...prevNews, ...response.data]);
      setRestrictApiCall(false);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  /*useEffect(() => {
        setRestrictApiCall(true);
        fetchSocialNews();
    }, []);*/

  useEffect(() => {
    !restrictApiCall && fetchSocialNews(socialNews);
  }, [apiPage]);

  useEffect(() => {
    setRestrictApiCall(true);
    setApiPage(1);
    fetchSocialNews([]);
  }, [queryParams]);

  const renderButtons = (socialnews) => {

    let tmpSocialNews = socialnews;




    return (<div className={'container p-2 border-top'}>
      <div className={'row d-flex justify-content-center'}>
        <div className={'col d-flex justify-content-center align-items-center'}>
          <FontAwesomeIcon
              icon={faRetweet}
              color={'#65b4ee'}
              size={'1x'}
          />
          &nbsp;
          {tmpSocialNews?.sharesCount}
        </div>
        <div className={'col d-flex justify-content-center align-items-center'}>
          <FontAwesomeIcon
              icon={faHeart}
              color={'#65b4ee'}
              size={'1x'}
          />
          &nbsp;
          {tmpSocialNews?.likesCount}
        </div>
        <SocialShareButton tmpSocialNews={tmpSocialNews}  />
      </div>
    </div>);
  };

  const render = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const fontColor = FONT_COLOR[target];

    return (
        <div className="col-12 border-1 border-secondary rounded mt-2">
      {typeof isFanzine === 'undefined' &&
          (<div className={navColor + ' rounded-custom-1 text-center p-1'}>
            <span className={`${fontColor} text-header`}>
              <span className={'bebasFont'}>Social Media</span>
            </span>
          </div>)}{' '}
      <ItemLoader loading={loading}/>
      <ul
          className={`bg-fixtures rounded-bottom-custom text-center ${isNewsPage ?
              'social-list-long' :
              'social-list'} list-unstyled p-2`}
          onScroll={handleScroll}
      >
        {socialNews.map((socialNews, index) => (<li key={index}
                                                    className={'p-2 mt-2 border border-1 rounded-custom'}>
          <div className={'row'}>
            <div className={'col-3 pe-1 ps-0'}>
              {/*<img src={require('../../assets/images/team_icons/gunners/gunners_1x.png')} alt="" className={'img img-fluid img-circle'}/>*/}
              <img
                  src={socialNews?.author_photo}
                  alt={'author'}
                  className="img img-fluid rounded-circle"
              />
            </div>
            <div
                className={'col-9 text-start sfProDisplayFont small ps-0 pe-1'}
            >
              {socialNews.author} &nbsp;&nbsp;
              <FontAwesomeIcon
                  icon={faTwitter}
                  color={'#65b4ee'}
                  size={'1x'}
              />
              <br/>
              <div>
                <span className={'text-muted small sfProDisplayFont'}>
                    {socialNews?.author_name} &nbsp;&nbsp;
                {moment.utc(socialNews?.datetime).
                    local().
                    startOf('seconds').
                    fromNow()}
                  </span>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div
                className={'col-12 pt-3 text-start sfProDisplayFont text-muted small'}
            >
              {socialNews.title}
            </div>
            <div
                className={'col-12 pt-3 text-start sfProDisplayFont text-muted small'}
            >
              {socialNews?.video != '' &&
                  <video width="100%" height={'100%'} controls>
                    <source src={socialNews.video} type="video/mp4"/>
                  </video>}

              {socialNews?.video == '' && <img
                  src={socialNews.image}
                  alt={''}
                  className={'img-fluid'}
              />}
            </div>
          </div>
          {(isSocial || isFanzine ) && renderButtons(socialNews)}
        </li>))}
      </ul>
    </div>);
  };

  return render();
};

export default Social;
