import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/styles.css";
import "../assets/styles/customContainer.css";
import Container from "react-bootstrap/Container";
import ResultsFixtures from "../Components/ResultsFixtures/ResultsFixtures";
import Standings from "../Components/Standings/Standings";
import News from "../Components/Homepage/News";
import Social from "../Components/Social/Social";
import NextMatchCard from "../Components/Cards/NextMatchCard";
import {
  CampaignEndPoint,
  NewsEndPoint,
  PodcastEndPoint,
} from "../Axios/EndPoints";
import { get } from "../Axios/get";
import { chunk } from "../Components/Helper/chunk";
import { useSelector } from "react-redux";
import moment from "moment";
import TopNewsSection from "../Components/Homepage/Sections/TopNewsSection";
import VideoSection from "../Components/Homepage/Sections/VideoSection";
import { SCREEN_SIZE } from "../Configs/Constants";
import { useNavigate } from "react-router-dom";
import Billboard from "../Components/SnackAds/Billboard";
import DMPU from "../Components/SnackAds/DMPU";
import MPU from "../Components/SnackAds/MPU";
import Shop from "../Components/Homepage/Shop";
import Loader from "../Components/Loader/Loader";
import ItemLoader from "../Components/Loader/ItemLoader";
import Gossip from "../Components/Gossip/Gossip";
import CampaignPopup from "../Components/Campaigns/CampaignPopup";
import Leaderboard from "../Components/SnackAds/Leaderboard";
import RecommendationUnit from "../Components/SnackAds/RecommendationUnit";
import LeagueCodePopup from "../Components/Campaigns/LeagueCodePopup";
import Cookies from "universal-cookie";
import { getHostname } from "../CustomFunctions/getHostname";

const Homepage = () => {
  const store = useSelector((state) => state);
  let navigate = useNavigate();
  let mainPodcastRef = useRef(null);
  const cookies = new Cookies();

  const [moreNews, setMoreNews] = useState([]);
  const [podCastLimit] = useState(4);
  const [podCast, setPodCast] = useState([]);
  const [showGossip, setShowGossip] = useState(true);
  const [campaign, setCampaign] = useState([]);
  const [podCasts, setPodCasts] = useState([]);
  const [morePodCastPage, setMorePodCastPage] = useState(2);
  const [backupMorePodCast, setBackupMorePodCast] = useState([]);
  const [nextMatch, setNextMatch] = useState({});
  const [shouldNavigate, setShouldNavigate] = useState("false");
  const [homePageStandings, setHomePageStandings] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [moreNewsLoading, setMoreNewsLoading] = useState(true);
  const [campaignModalShow, setCampaignModalShow] = useState(false);
  const [campaignLeagueCodeModalShow, setCampaignLeagueCodeModalShow] = useState(false);

  const fetchMoreNews = () => {
    let league_id = "?league_id=" + store.football.defaultLeague.league_id;
    let page = "&page=1";
    let limit = "&limit=28";
    get(NewsEndPoint + league_id + limit + page)
      .then((response) => {
        let tempMoreNews = [];
        response.data.map((data) => {
          let news = {
            news_id: data.news_id,
            slug: data.slug,
            news_image: data.image,
            news_title: data.title,
            news_time: moment
              .utc(data.datetime)
              .local()
              .startOf("seconds")
              .fromNow(),
            news_publisher: {
              publisher_name: data.publisher.name,
              publisher_icon: data.publisher.icon,
            },
            is_snack: data.is_snack,
            news_url: data.is_snack ? data.snack_link : data.url,
          };
          tempMoreNews.push(news);
        });
        setMoreNews(chunk(tempMoreNews, 2));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setMoreNewsLoading(false);
      });
  };

  const fetchPodCast = () => {
    let limit = "?limit=" + podCastLimit;
    get(PodcastEndPoint + limit)
      .then((response) => {
        let tempPodCast = [];
        response.data.map((data) => {
          let podCast = {
            id: data.id,
            image: data.image_url,
            title: data.title,
            url: data.url,
            podcast_time: moment
              .utc(data.published_at.date)
              .local()
              .startOf("seconds")
              .fromNow(true),
            autoPlay: false,
          };
          tempPodCast.push(podCast);
        });
        setPodCast(tempPodCast[0]);
        setPodCasts(tempPodCast.slice(1));
      })
      .catch((err) => {});
  };

  const fetchMorePodCast = () => {
    let page = "?page=" + morePodCastPage;
    let limit = "&limit=4";
    get(PodcastEndPoint + page + limit)
      .then((response) => {
        let podCastData = response.data;
        let tempMorePodCast = [];
        podCastData.map((data) => {
          let podCast = {
            id: data.id,
            image: data.image_url,
            title: data.title,
            url: data.url,
            podcast_time: moment
              .utc(data.published_at.date)
              .local()
              .startOf("seconds")
              .fromNow(true),
          };

          if (tempMorePodCast.length === 3) {
            setBackupMorePodCast([...backupMorePodCast, podCast]);
          } else {
            tempMorePodCast.push(podCast);
          }
        });

        setPodCasts([...podCasts, ...tempMorePodCast]);

        setMorePodCastPage(morePodCastPage + 1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showMorePodCast = () => {
    if (backupMorePodCast.length === 3) {
      setPodCasts([...podCasts, ...backupMorePodCast]);
      setBackupMorePodCast([]);
    } else {
      fetchMorePodCast();
    }
  };

  const MobilePodcastScroll = (condition) => {
    if (condition) {
      showMorePodCast();
    }
  };

  const updateScreenWidth = () => {
    setWidth(window.innerWidth);
  };

  const fetchCampaigns = () => {
    get(CampaignEndPoint)
      .then((response) => {
        let campaignData = response.data.data;
        console.log("campaign", campaignData.length);
        let tempCampaign = [];
        let campaign = null;
        if (campaignData.length) {
          campaignData.map((data) => {
            campaign = {
              id: data.id,
              campaign_id: data.campaign_id,
              type: data.type,
              leagueCode: data.league_code,
              image: data.image,
              title: data.title,
              description: data.description,
              questions: data.questions,
            };
            if (data.display_page === "home") {
              tempCampaign.push(campaign);
            }
          });
          const hostName = window.location.hostname;
          const domainObject = getHostname(hostName);
          const target = domainObject.host;
          if (
            cookies.get(
              `fanzine_campaign_${campaign.campaign_id}_${target}`
            ) !== "1"
          ) {
            setCampaignModalShow(true);
          }
        }
        setCampaign(tempCampaign);
      })
      .catch((err) => {
        console.log("campaign_err", err);
      });
  };

  useEffect(() => {
    !store.misc.campaignAnswered && fetchCampaigns();
    fetchMoreNews();
    // fetchPodCast();
    updateScreenWidth();
    if (width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    shouldNavigate !== "false" && navigate(shouldNavigate + "");
  }, [navigate, shouldNavigate]);

  return (
    <>
      <Loader loading={loading} />
      {
        <CampaignPopup
          show={campaignModalShow}
          onHide={() => setCampaignModalShow(false)}
          showLeageCode={campaignLeagueCodeModalShow}
          setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
          onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
          campaign={campaign}
        />
      }
      <LeagueCodePopup
        show={campaignLeagueCodeModalShow}
        onHide={() => setCampaignModalShow(false)}
        showLeageCode={campaignLeagueCodeModalShow}
        setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
        onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
        campaign={campaign}
      />
      <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 `}>
        <div className={"customContainer"}>
          {/*Billboard Banner Ad*/}
          <div className="d-flex justify-content-center align-items-center h1">
            {isMobile ? (
              <Leaderboard id={"snack_mob_top"} />
            ) : (
              <Billboard id={"snack_dex3"} />
            )}
          </div>

          {/*Section 1 - News Stories, Fixtures, Table*/}
          <div className="row mt-2">
            <TopNewsSection
              setShouldNavigate={setShouldNavigate}
              setLoading={setLoading}
            />
            <div className="col-xxl-3 col-xl-3 col-lg-3 d-none d-lg-block d-xl-block d-xxl-block">
              <ResultsFixtures
                setNextMatch={setNextMatch}
                setHomePageStandings={setHomePageStandings}
              />
              {window.innerWidth > SCREEN_SIZE.TABLET ? <Standings /> : null}
              {window.innerWidth > SCREEN_SIZE.MOBILE ? (
                <Social setLoading={setLoading} />
              ) : null}
            </div>
          </div>

          {/*Billboard Banner Ad*/}
          <div className="d-flex flex-column justify-content-center align-items-center h1">
            {isMobile ? (
              <MPU id={"snack_mex4"} />
            ) : (
              <Billboard id={"snack_dex4"} />
            )}
          </div>

          {/*Section 2 - Videos, Single MPU Ad*/}
          {!isMobile && <VideoSection />}

          <div className={"row mt-4 d-block d-lg-none no-gutters"}>
            <NextMatchCard nextMatch={nextMatch} />
          </div>

          {/*Section 2 - Shop, Social*/}
          <div className="row mt-2">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
              <Shop />
            </div>
          </div>

          <div className={"row mt-4 d-block d-lg-none"}>
            {window.innerWidth <= SCREEN_SIZE.TABLET ? (
              <Standings standings={homePageStandings} />
            ) : null}
          </div>

          {isMobile && (
            <div
              className={
                "col-sm-10 d-block d-lg-none single-mpu d-flex justify-content-center flex-column align-items-center mt-2"
              }
            >
              <MPU id={"snack_mex7"} />
            </div>
          )}

          {/*Section 2 - Videos, Single MPU Ad*/}
          {isMobile && <VideoSection />}

          {/*Section 3 - Podcasts, Gossip*/}
          {/*<div className="row mt-2" ref={mainPodcastRef}>
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-7 col-sm-12 mt-4">
                        {podCast && <PodcastCard main={true} podCast={podCast} id={podCast.id}/>}
                    </div>
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <span className={'bebasFont font-weight-bold h3'}>More News Stories</span>
                        <ItemLoader loading={moreNewsLoading}/>
                        {moreNews && moreNews.map((item) => (
                            <News news={item} moreStories={true}/>
                        ))}
                    </div>
                    <div
                        className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                        {
                            window.innerWidth > SCREEN_SIZE.MOBILE ?
                                <Gossip/> : null
                        }
                    </div>
                </div>*/}

          {/*Section 3 - Podcasts, Gossip*/}
          {/* <div className="row mt-2">
                    <div className={`col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 ${!isMobile ? ' ' : ''}`}>
                        {<Podcasts data={podCasts} mobileMorePodcast={MobilePodcastScroll} mainPodcast={podCast} setMainPodCast={setPodCast} mainPodcastRef={mainPodcastRef}/>
                        {window.innerWidth > SCREEN_SIZE.MOBILE ? (
                            <div onClick={() => showMorePodCast()}><Divider text={'See more podcasts'}/></div>
                        ) : (<></>)}}

                    </div>
                    {!isMobile && <div className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center flex-column align-items-center h1 text-center' +
                        ' d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex'}>
                        <DMPU id={'snack_dex10'}/>
                    </div>}
                </div>*/}

          {isMobile && (
            <div
              className={
                "col-sm-10 d-block d-md-none single-mpu d-flex justify-content-center flex-column align-items-center mt-2"
              }
            >
              <MPU id={"snack_mex8"} />
            </div>
          )}

          {/*Section 4 - More Team News*/}
          <div className="row mt-2">
            {
              <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12">
                <RecommendationUnit />
                <span className={"bebasFont font-weight-bold h3 mt-5"}>
                  More News Stories
                </span>
                <ItemLoader loading={moreNewsLoading} />
                {moreNews &&
                  moreNews.map((item) => (
                    <News news={item} moreStories={true} />
                  ))}
              </div>
            }
            {!isMobile && (
              <div
                className={
                  "col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 d-flex justify-content-start flex-column align-items-center text-center" +
                  " d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex"
                }
              >
                <MPU id={"snack_dex8"} />
                {showGossip && (
                  <Gossip
                    showGossip={showGossip}
                    setShowGossip={setShowGossip}
                  />
                )}
                <div
                  className={
                    "col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center flex-column align-items-center text-center" +
                    " sticky-top sticky-mpu d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex"
                  }
                >
                  <DMPU id={"snack_dmpu"} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Homepage;
