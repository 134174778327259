import FanzineHome from "./FanzineCom/FanzineHome";

import Homepage from "./Pages/Homepage";
import NewsPage from "./Pages/NewsPage";
import ShopPage from "./Pages/ShopPage";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import SingleNewsPage from "./Pages/SingleNewsPage";
import ProductsListPage from "./Pages/ProductsListPage";
import ProductDetailPage from "./Pages/ProductDetailPage";
import NavbarHeader from "./Components/Navbar/NavbarHeader";
import ScrollToTop from "./Components/ScrollToTop";
import Footer from "./Components/Footer";
import { getHostname } from "./CustomFunctions/getHostname";
import FanzineNav from "./FanzineCom/Components/FanzineNav";
import FanzineAllFootballMatches from "./FanzineCom/FanzineAllFootballMatches";
import FanzineCricket from "./FanzineCom/FanzineCricket";
import FanzineMLB from "./FanzineCom/FanzineMLB";
import FanzineNBA from "./FanzineCom/FanzineNBA";
import FanzineNFL from "./FanzineCom/FanzineNFL";
import ContactUs from "./FanzineCom/ContactUs";
import SingleVideoPage from "./Pages/SingleVideoPage";
import Loader from "./Components/Loader/Loader";
import { OneLink } from "./Pages/OneLink";
import Checkout from "./Pages/Checkout";
import Sitemap from "./Pages/Sitemap";
import NFT from "./Pages/NFT";
import { Football } from "./FanzineCom/SEO/Football";
import { Cricket } from "./FanzineCom/SEO/Cricket";
import { MLB } from "./FanzineCom/SEO/MLB";
import { NBA } from "./FanzineCom/SEO/NBA";
import { NFL } from "./FanzineCom/SEO/NFL";
import FanzineAllFootballSingleMatch from "./FanzineCom/FanzineAllFootballSingleMatch";
import FanzineAllFootball from "./FanzineCom/FanzineAllFootball";
import FanzineFanatics from "./FanzineCom/FanzineFanatics";
import FanzineFanaticDetails from "./FanzineCom/FanzineFanaticDetails";
import NFTs from "./Pages/NFTs";
import NFTDetail from "./Pages/NFTDetail";
import FanzineAllFootballTable from './FanzineCom/FanzineAllFootballTable';
import FanzineAllFootballNews from './FanzineCom/FanzineAllFootballNews';

const AppRoutes = (props) => {
  const renderFanzineSports = () => {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <NavbarHeader />
          <Routes>
            <Route exact path="/" element={<NewsPage />} />
            <Route path="/news/:id" element={<SingleNewsPage />} />
            <Route path="/sitemap" component={Sitemap} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    );
  };

  const renderFanzine = () => {
    return (
      <BrowserRouter>
        {/*<Loader loading={loading}/>*/}
        <ScrollToTop>
          <FanzineNav />
          <Routes>
            <Route exact path="/" element={<FanzineHome />} />
            <Route exact path="/football" element={<FanzineAllFootball />} />
            <Route
              exact
              path="/football/matches"
              element={<FanzineAllFootballMatches />}
            />
            <Route
              exact
              path="/football/news"
              element={<FanzineAllFootballNews />}
            />
            <Route
              exact
              path="/football/table"
              element={<FanzineAllFootballTable />}
            />
            <Route
              exact
              path="/football/matches/:id"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/preview"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/h2h"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/social"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/overview"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/stats"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/stats"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/lineups"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/matches/:id/commentary"
              element={<FanzineAllFootballSingleMatch />}
            />
            <Route
              exact
              path="/football/tables"
              element={<FanzineAllFootball />}
            />
            <Route exact path="/football_content" element={<Football />} />
            <Route exact path="/cricket" element={<FanzineCricket />} />
            <Route exact path="/cricket_content" element={<Cricket />} />
            <Route exact path="/mlb" element={<FanzineMLB />} />
            <Route exact path="/mlb_content" element={<MLB />} />
            <Route exact path="/nba" element={<FanzineNBA />} />
            <Route exact path="/nba_content" element={<NBA />} />
            <Route exact path="/nfl" element={<FanzineNFL />} />
            <Route exact path="/nfl_content" element={<NFL />} />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route path="/:sport/:team/:id" element={<SingleNewsPage />} />
            <Route path="/:sport/:id" element={<SingleNewsPage />} />
            <Route path="/:sport/video/:id" element={<SingleVideoPage />} />
            <Route path="/nft" element={<NFT />} />
            <Route path="/nfts" element={<NFTs />} />
            <Route path="/nft/:id" element={<NFTDetail />} />
            <Route path="/shop" element={<FanzineFanatics />} />
            <Route path="/shop/product/:id" element={<FanzineFanaticDetails />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    );
  };

  const renderMain = () => {
    return (
      <>
        <Loader loading={props.loading} />
        {props.reduxStore && (
          <>
            <BrowserRouter>
              <ScrollToTop>
                <NavbarHeader />
                <Routes>
                  <Route exact path="/" element={<Homepage />} />
                  <Route exact path="/shop">
                    <Route path=":filter" element={<ProductsListPage />}>
                      <Route path=":filter1" element={<ProductsListPage />}>
                        <Route path=":filter2" element={<ProductsListPage />} />
                      </Route>
                    </Route>
                    <Route
                      exact
                      path="product/:id"
                      element={<ProductDetailPage />}
                    />
                    <Route exact path="" element={<ShopPage />} />
                  </Route>
                  <Route exact path="/news" element={<NewsPage />} />
                  <Route
                    exact
                    path="/news/snack"
                    element={<NewsPage snack={true} />}
                  />
                  <Route path="/news/:id" element={<SingleNewsPage />} />
                  <Route exact path="/checkout" element={<Checkout />} />
                  <Route exact path="/onelink" element={<OneLink />} />
                  <Route path="/sitemap" component={Sitemap} />
                </Routes>
                <Footer />
              </ScrollToTop>
            </BrowserRouter>
          </>
        )}
      </>
    );
  };

  const render = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    if (target === "fanzinesports") {
      return renderFanzineSports();
    }

    if (target === "fanzine") {
      return renderFanzine();
    }

    return renderMain();
  };

  return render();
};

export default AppRoutes;
