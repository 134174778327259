import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import MultiRangeSlider from "multi-range-slider-react";
import React, {useState} from "react";
import {getHostname} from "../../CustomFunctions/getHostname";
import {COLORS, COLORS_RGBA} from "../../Configs/Targets";
import {useSelector} from "react-redux";
import FilterItems from "./FilterItems";
import FilterItemOptionScreen from "./FilterItemOptionScreen";
import {PRODUCT_GENDERS} from "../../Configs/Constants";
import PropTypes from "prop-types";

const MobileFilters = (props) => {

    const store = useSelector(state => state);

    let selectedCategories = props.selectedCategories;
    let setSelectedCategories = props.setSelectedCategories;
    let selectedColors = props.selectedColors;
    let setSelectedColors = props.setSelectedColors;
    let selectedSizes = props.selectedSizes;
    let setSelectedSizes = props.setSelectedSizes;
    let selectedGenders = props.selectedGenders;
    let setSelectedGenders = props.setSelectedGenders;
    let minPrice = props.minPrice;
    let setMinPrice = props.setMinPrice;
    let maxPrice = props.maxPrice;
    let setMaxPrice = props.setMaxPrice;
    let checkSale = props.checkSale;
    let setCheckSale = props.setCheckSale;
    let setSelectedChanged = props.setSelectedChanged;
    let setHasFiltersChanged = props.setHasFiltersChanged;
    let sizesForFilters = props.sizesForFilters;
    let colorsForFilters = props.colorsForFilters;

    let [mainFiltersScreen, setMainFiltersScreen] = useState(true);
    let [colorFiltersScreen, setColorFiltersScreen] = useState(false);
    let [sizeFiltersScreen, setSizeFiltersScreen] = useState(false);
    let [genderFiltersScreen, setGenderFiltersScreen] = useState(false);
    let [priceRangeFiltersScreen, setPriceRangeFiltersScreen] = useState(false);
    let [categoryFiltersScreen, setCategoryFiltersScreen] = useState(false);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const color = COLORS[target];
    const colorRgba = COLORS_RGBA[target];
    let setShowMobileFilters = props.setShowMobileFilters;


    const handlePriceRangeChange = (e) => {
        setMinPrice(e.minValue);
        setMaxPrice(e.maxValue);
    };

    const clearAllFilters = () => {
        setSelectedCategories([]);
        setSelectedColors([]);
        setSelectedSizes([]);
        setSelectedGenders([]);
        setMinPrice(0);
        setMaxPrice(500);
        setCheckSale(false);
        props.clearFilters();
    }

    const renderSaleFilter = () => {
        return (
            <>
                <div className={`row w-100 p-4`} style={{height: "9%"}}>
                    <div
                        className={`col-6 sfProDisplayFont font-16 d-flex align-items-center justify-content-start`} onClick={() => {
                        setCheckSale(!checkSale)
                    }}>
                        Sale
                    </div>
                    {checkSale && <div
                        className={`col-6 sfProDisplayFont d-flex align-items-center justify-content-end text-gray`}>
                        <FontAwesomeIcon className={`ms-2`} icon={faCheck} size={`1x`}/>
                    </div>}
                </div>
                <div className={`d-flex justify-content-end`}>
                    <div style={{
                        border: "solid 0.8px #dfdfdf",
                        width: "97%",
                    }}/>
                </div>
            </>
        );
    }

    const renderPriceFilter = () => {
        return (
            <>
                <div className={`sfProDisplayFont font-16 d-flex justify-content-start align-items-center ps-4 pe-4 pt-4`}
                     style={{height: "9%"}}
                     onClick={() => {
                         setMainFiltersScreen(false);
                         setPriceRangeFiltersScreen(true);
                     }}>
                    Price Range
                </div>
                <div className={'sfProDisplayFont font-16 d-flex justify-content-start align-items-center ps-4 pe-4 pb-4 small text-muted'}>
                    &pound; {minPrice} - &pound; {maxPrice}
                </div>
                <div className={`d-flex justify-content-end`}>
                    <div style={{
                        border: "solid 0.8px #dfdfdf",
                        width: "97%",
                    }}/>
                </div>
            </>
        );
    }
    const render = () => {
        return (
            <>
                <div className={`row`} style={{
                    position: "absolute",
                    left: "11px",
                    width: '-webkit-fill-available',
                    marginRight: '1em',
                }}>
                    <div
                        className={`col-1 bg-${target} d-flex justify-content-center align-items-center font-32 text-white shadow-nav-dark`}
                        style={{
                            width: "50px",
                            height: "50px",
                        }} onClick={() => setShowMobileFilters(false)}><FontAwesomeIcon icon={faTimes}/>
                    </div>
                    {mainFiltersScreen && <div className={`col bg-white ps-0 pe-0 pt-0 pb-4 shadow-nav-dark`} style={{
                        width: "-webkit-fill-available",
                        whiteSpace: 'nowrap',
                        zIndex: "999",
                        height: "max-content",
                    }}>
                        <div className={`row w-100`} style={{height: "50px"}}>
                            <div
                                className={`col-6 ps-4 sfProDisplayFontBold font-16 d-flex align-items-center justify-content-start`}>
                                Filter
                            </div>
                            <div
                                className={`col-6 sfProDisplayFont d-flex align-items-center justify-content-end text-gray`}
                                onClick={clearAllFilters}
                            >
                                Clear All
                            </div>
                        </div>
                        <div className={`bg-grey`} style={{height: "2%"}}/>
                        {props.breadcrumbs.includes('Mens') || props.breadcrumbs.includes('Womens') || props.breadcrumbs.includes('Childrens') ?
                            <FilterItems setMainFiltersScreen={setMainFiltersScreen} title={'Category'} setItemFiltersScreen={setCategoryFiltersScreen} selectedFilters={selectedCategories}/>
                            :
                            <FilterItems setMainFiltersScreen={setMainFiltersScreen} title={'Gender'}
                                         setItemFiltersScreen={setGenderFiltersScreen} selectedFilters={selectedGenders}/>
                        }
                        <FilterItems setMainFiltersScreen={setMainFiltersScreen} title={'Color'} setItemFiltersScreen={setColorFiltersScreen} selectedFilters={selectedColors}/>
                        <FilterItems setMainFiltersScreen={setMainFiltersScreen} title={'Size'} setItemFiltersScreen={setSizeFiltersScreen} selectedFilters={selectedSizes}/>
                        {renderSaleFilter()}
                        {renderPriceFilter()}
                        <div className={`text-center`} style={{marginTop: "5rem"}}>
                            <button className={`btn bg-${target} text-white sfProDisplayFontBold font-18`} style={{
                                borderRadius: "0",
                                width: "315px",
                            }} onClick={() => {
                                setHasFiltersChanged(true);
                            }}>View Items
                            </button>
                        </div>
                    </div>}
                    {categoryFiltersScreen &&
                        <FilterItemOptionScreen
                            setMainFiltersScreen={setMainFiltersScreen}
                            setItemFiltersScreen={setCategoryFiltersScreen}
                            setSelectedFilters={setSelectedCategories}
                            selectedFilters={selectedCategories}
                            title={'Categories'} target={target} items={store.shopify.categories}
                            showFilteredItems={props.showFilteredItems()}
                            setHasFiltersChanged={setHasFiltersChanged}/>
                    }
                    {colorFiltersScreen &&
                        <FilterItemOptionScreen
                            setMainFiltersScreen={setMainFiltersScreen}
                            setItemFiltersScreen={setColorFiltersScreen}
                            setSelectedFilters={setSelectedColors}
                            selectedFilters={selectedColors}
                            title={'Colors'} target={target} items={colorsForFilters} isColorScreen={true}
                            showFilteredItems={props.showFilteredItems()}
                            setSelectedChanged={setSelectedChanged}
                            setHasFiltersChanged={setHasFiltersChanged}/>
                    }
                    {sizeFiltersScreen &&
                        <FilterItemOptionScreen
                            setMainFiltersScreen={setMainFiltersScreen}
                            setItemFiltersScreen={setSizeFiltersScreen}
                            setSelectedFilters={setSelectedSizes}
                            selectedFilters={selectedSizes}
                            title={'Sizes'} target={target} items={sizesForFilters} isColorScreen={false}
                            showFilteredItems={props.showFilteredItems()}
                            setHasFiltersChanged={setHasFiltersChanged}/>
                    }
                    {genderFiltersScreen &&
                        <FilterItemOptionScreen
                            setMainFiltersScreen={setMainFiltersScreen}
                            setItemFiltersScreen={setGenderFiltersScreen}
                            setSelectedFilters={setSelectedGenders}
                            selectedFilters={selectedGenders}
                            title={'Sizes'} target={target} items={PRODUCT_GENDERS} isColorScreen={false}
                            showFilteredItems={props.showFilteredItems()}
                            setHasFiltersChanged={setHasFiltersChanged}/>
                    }
                    {priceRangeFiltersScreen &&
                        <div className={`col bg-white p-0 shadow-nav-dark`} style={{
                            width: "376px",
                            zIndex: "999",
                        }}>
                            <div className={`row w-100`} style={{height: "50px"}}>
                                <div
                                    className={`col-6 ps-4 sfProDisplayFontBold font-16 d-flex align-items-center justify-content-start`}>
                                    <FontAwesomeIcon className={`me-2`} icon={faArrowLeft} size="1.5x" onClick={() => {
                                        setPriceRangeFiltersScreen(false);
                                        setMainFiltersScreen(true);
                                    }}/> Price Range
                                </div>
                            </div>
                            <div className={`bg-grey`} style={{height: "2%"}}/>

                            <div className={'bebasFont ps-4 pt-4 font-16'}>
                                Selected Price Range
                            </div>
                            <div className={'sfProDisplayFont ps-4 pb-2 font-16'}>
                                &pound;{minPrice} - &pound;{maxPrice}
                            </div>

                            <div className={`sfProDisplayFont font-16 p-4`}
                                 style={{height: "15%"}}>
                                <MultiRangeSlider
                                    min={0}
                                    max={500}
                                    step={20}
                                    ruler={false}
                                    label={true}
                                    preventWheel={false}
                                    minValue={minPrice}
                                    maxValue={maxPrice}
                                    onChange={(e) => {
                                        handlePriceRangeChange(e);
                                    }}
                                />
                            </div>

                            <div className={`text-center`} style={{marginTop: "5rem"}}>
                                <button className={`btn bg-${target} text-white sfProDisplayFontBold font-18`} style={{
                                    borderRadius: "0",
                                    width: "315px",
                                }} onClick={() => {
                                    setHasFiltersChanged(true);
                                }}>View Items
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
    return render();
}

MobileFilters.propTypes = {
    selectedCategories: PropTypes.array.isRequired,
    selectedColors: PropTypes.array.isRequired,
    selectedSizes: PropTypes.array.isRequired,
    selectedGenders: PropTypes.array.isRequired,
    minPrice: PropTypes.number.isRequired,
    maxPrice: PropTypes.number.isRequired,
    checkSale: PropTypes.bool.isRequired,
    setSelectedCategories: PropTypes.func.isRequired,
    setSelectedColors: PropTypes.func.isRequired,
    setSelectedSizes: PropTypes.func.isRequired,
    setSelectedGenders: PropTypes.func.isRequired,
    setMinPrice: PropTypes.func.isRequired,
    setMaxPrice: PropTypes.func.isRequired,
    setCheckSale: PropTypes.func.isRequired,
    showFilteredItems: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    breadcrumbs: PropTypes.string.isRequired,
    colorsForFilters: PropTypes.array.isRequired,
    sizesForFilters: PropTypes.array.isRequired,
}

export default MobileFilters;