import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import React from "react";

export const NFTNavBar = (props) => {

    const {nftHome, nftUtility, nftRoadmap, nftContact, nftFAQ} = props;
    const location = useLocation();

    return (
        <div className={`bg-fanzine-dark p-2`}>
            <div className={`container`}>
                <div className={`row ms-4`}>
                    <div className={`col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-2 text-white`}>
                        <img src={require('../../assets/images/nft/nft-logo-3x.png')} alt={`Fanzine Football Logo`}
                             height={30}/>
                        &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        |
                    </div>
                    <div className={`col-xxl-5 col-xl-5 col-lg-5 col-md-7 col-sm-7`}>
                        <div className={`d-flex flex-row justify-content-between align-content-start text-start`}>
                            <a href={'#' + nftHome}
                               className={`text-white text-decoration-underline-fanzine ${location.pathname === '/football' ? 'active' : ''}`}>
                                Home
                            </a>
                            <a href={'#' + nftUtility}
                               className={`text-white text-decoration-underline-fanzine ${location.pathname === '/football/matches' ? 'active' : ''}`}>
                                Utility
                            </a>
                            <a href={'#' + nftRoadmap}
                               className={`text-white text-decoration-underline-fanzine ${location.pathname === '/football/tables' ? 'active' : ''}`}>
                                Roadmap
                            </a>
                            <a href={'#' + nftContact}
                               className={`text-white text-decoration-underline-fanzine ${location.pathname === '/football/tables' ? 'active' : ''}`}>
                                Contact
                            </a>
                            <a href={'#' + nftFAQ}
                               className={`text-white text-decoration-underline-fanzine ${location.pathname === '/football/tables' ? 'active' : ''}`}>
                                FAQ
                            </a>
                        </div>
                    </div>
                    <div className={'offset-xxl-4 offset-xl-4 offset-lg-2 offset-md-0 offset-sm-0 col float-end'}>
                        <div className={'d-flex justify-content-between'} style={{cursor: 'pointer'}}>
                            <div className={'sfProDisplayFont font-14'}>
                                <a href={'https://twitter.com/Fanzine_com'} target={"_blank"} className={'text-decoration-none'}
                                   style={{
                                       color: 'white',
                                   }} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faTwitter} size={'2x'}/>
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-14'}>
                                <a href={'https://www.instagram.com/fanzine_com/'} target={"_blank"}
                                   className={'text-decoration-none'}
                                   style={{
                                       color: 'white',
                                   }} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faInstagram} size={'2x'}/>
                                </a>
                            </div>
                            <div className={'sfProDisplayFont font-14'}>
                                <a href={'https://www.facebook.com/FanzineLtd'} target={"_blank"} className={'text-decoration-none'}
                                   style={{
                                       color: 'white',
                                   }} rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFacebook} size={'2x'}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}