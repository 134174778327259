import React, {useEffect, useState} from 'react';
import {get} from '../../../../../Axios/get';
import {FANZINE} from '../../../../../Axios/EndPoints';
import {useLocation, useParams} from 'react-router-dom';
import PreviewRecentForm from './Components/PreviewRecentForm';
import {useSelector} from 'react-redux';
import PreviewNews from './Components/PreviewNews';
import {PieChart} from 'react-minimal-pie-chart';
import PossessionCircle from './Components/PosessionCircle';
import OnOffTargetAttemps from './Components/OnOffTargetBox';

const SingleFootballMatchContentStats = (props) => {
    let store = useSelector((state) => state);
    let recent_form;
    const isCurrent = props.currentActive === 'stats';
    if (store?.football?.singleFootballMatch !== []) {
        recent_form = store?.football?.singleFootballMatch?.recent_form;
        // recent_form = matchData.recent_form
    }

    const {isMobile, singleMatchData} = props;
    const params = useParams();
    const queryParams = useLocation();
    const [matchStats, setMatchStats] = useState(null);
    const [homeColor, setHomeColor] = useState(
        singleMatchData?.home_team?.hex_color === '#ffffff' ?
            singleMatchData?.home_team?.font_color
            :
            singleMatchData?.home_team?.hex_color);
    const [guestColor, setGuestColor] = useState(
        singleMatchData?.guest_team?.hex_color === '#ffffff' ?
            singleMatchData?.guest_team?.font_color
            :
            singleMatchData?.guest_team?.hex_color);
    const [homeFontColor, setHomeFontColor] = useState(
        singleMatchData?.home_team?.hex_color === '#ffffff' ?
            singleMatchData?.home_team?.hex_color
            :
            singleMatchData?.home_team?.font_color
            || '#ffffff');
    const [guestFontColor, setGuestFontColor] = useState(
        singleMatchData?.guest_team?.hex_color === '#ffffff' ?
            singleMatchData?.guest_team?.hex_color
            :
            singleMatchData?.guest_team?.font_color
            || '#ffffff');


    const addDefaultSrc = (e) => {
        e.target.src = require('../../../../../assets/images/Default.jpeg');
    };

    useEffect(() => {
        isCurrent && fetchMatchStats();
        // isCurrent && fetchMatchAnalysis()
    }, [isCurrent]);

    const fetchMatchStats = () => {
        let parameter = queryParams.search;

        if (!parameter) {
            parameter = '?match_id=' + params.id;
        }
        get(FANZINE.MatchStats + parameter).then((res) => {
            console.log('match stats =', res.data);
            setMatchStats(res.data);
        }).catch((error) => console.error(error));
    };

    const fetchMatchAnalysis = () => {
        get(FANZINE.SingleMatchEndPoint + params.id +
            FANZINE.MatchesAnalysisSubEndPoint).then((res) => {
            console.log('response =', res.data);
            recent_form = res.data.recent_games;
        }).catch((error) => console.error(error));
    };

    const renderStats = () => {
        let data = [];
        for (let j = 0; j < matchStats?.length; j++) {
            for (let i = 0; i < matchStats[j]?.stats?.length; i++) {
                let total = parseInt(matchStats[j]?.stats[i]?.home_value) +
                    parseInt(matchStats[j]?.stats[i]?.guest_value);
                let tmp =
                    {
                        title: matchStats[j]?.stats[i]?.title_stats,
                        hvalue: matchStats[j]?.stats[i]?.home_value,
                        gvalue: matchStats[j]?.stats[i]?.guest_value,
                        home: total === 0 ?
                            0 :
                            Math.floor(
                                (matchStats[j]?.stats[i]?.home_value / total) * 12),
                        away: total === 0 ?
                            0 :
                            Math.floor(
                                (matchStats[j]?.stats[i]?.guest_value / total) * 12),
                    };
                data.push(tmp);
            }
        }


        const renderSpecificStats = (value,index) =>
        {
          let total = parseFloat(value?.hvalue) + parseFloat(value?.gvalue);
          let homePerc = total === 0 ? 0 :  ((value?.hvalue / total) * 100)
          let awayPerc = total === 0 ? 0 : ((value?.gvalue / total) * 100)

            return(
                <div className={`w-100 p-2 ${index === 0 ?  'mt-4 mb-2' : 'mt-2 mb-2'}`}>
                  <div className={'row'}>
                    <div className={'col-12 sfProDisplayFontLight font-16 text-center'}>
                       {value?.title}
                    </div>
                    <div className={'d-flex flex-row'}>
                      {
                          <div
                          className={`m-0 text-start font-16 ${homePerc > awayPerc ? 'sfProDisplayFontBold' : 'sfProDisplayFont'}`}
                          style={{
                            borderBottom: `10px solid #c6c6c6`,
                            width: `${(100-homePerc)}%`,
                          }}>
                            {value?.hvalue}
                          </div>
                      }
                      {
                          <div
                              className={`m-0 text-center`}
                              style={{
                                borderBottom: `10px solid ${homeColor}`,
                                width: `${homePerc}%`

                              }}></div>}
                      <div className={'m-1'} />
                      {
                          <div
                          className={`m-0 text-center`}
                          style={{
                            borderBottom: `10px solid ${guestColor}`,
                            width: `${awayPerc}%`

                          }}></div>
                      }
                      {
                          <div
                              className={`m-0 text-end font-16 ${homePerc > awayPerc ? 'sfProDisplayFont' : 'sfProDisplayFontBold'}`}
                              style={{
                                width: `${(100-awayPerc)}%`,
                                borderBottom: '10px solid #c6c6c6',
                              }}>
                            {value?.gvalue}
                          </div>}
                    </div>
                  </div>
                </div>
            )

            // return(
            //     <div key={index}
            //          className={`${index === 0 ?
            //              isMobile ? 'mt-4' : 'mt-2' :
            //              'mt-4'} row d-flex align-items-center justify-content-center`}>
            //         <div className={'col-12'}>
            //             <p className={'text-center'}>
            //                 {value?.title}
            //             </p>
            //         </div>
            //         <div className={`col-5 me-1`}>
            //             <div className={'row'}>
            //                 {data[index]?.home !== 12 &&
            //                     <div
            //                         className={`m-0 text-start sfProDisplayFontBold col`}
            //                         style={{borderBottom: `10px solid #c6c6c6`}}>{value?.hvalue}</div>
            //                 } {
            //                 data[index]?.home !== 0 &&
            //                 <div
            //                     className={`m-0 text-start sfProDisplayFontBold col-${data[index]?.home}`}
            //                     style={{borderBottom: `10px solid ${homeColor}`}}>
            //                     {data[index]?.home === 12 && value?.hvalue}
            //                 </div>}
            //             </div>
            //         </div>
            //         <div className={`col-5 ms-1`}>
            //             <div className={'row'}>
            //                 {
            //                     data[index]?.away !== 0 &&
            //                     <div
            //                         className={`m-0 text-end sfProDisplayFontBold col-${data[index]?.away}`}
            //                         style={{borderBottom: `10px solid ${guestColor}`}}>
            //                         {data[index]?.away === 12 && value?.gvalue}
            //                     </div>
            //                 }
            //                 {
            //                     data[index].away !== 12 &&
            //                     <div
            //                         className={`m-0 text-end sfProDisplayFontBold col`}
            //                         style={{borderBottom: '10px solid #c6c6c6'}}>{value?.gvalue}</div>}
            //             </div>
            //         </div>
            //     </div>
            // )
        }



        return (
            <div>
                {matchStats != null && data?.map((value, index) => (
                    <div>
                    {renderSpecificStats(value,index)}
                    </div>
                ))
                }
            </div>
        );
    };

    const renderKeyStats = () => {
        let data = [];
        for (let i = 0; i < matchStats[0]?.stats?.length; i++) {
            let total = parseInt(matchStats[0]?.stats[i]?.home_value) +
                parseInt(matchStats[0]?.stats[i]?.guest_value);
            let tmp =
                {
                    title: matchStats[0]?.stats[i]?.title_stats,
                    home: total === 0 ?
                        0 :
                        Math.floor((matchStats[0]?.stats[i]?.home_value / total) * 12),
                    away: total === 0 ?
                        0 :
                        Math.floor((matchStats[0]?.stats[i]?.guest_value / total) * 12),
                };
            data.push(tmp);
        }


        const totalAttempts = (value) => {
            return (
                <div className={'container'}>
                    <div className={'row'}>
                        <div
                            className={'col-2 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFontBold font-18'}>{value?.home_value}</p>
                        </div>
                        <div
                            className={'col-8 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFont font-12'}>Total attempts</p>
                        </div>
                        <div
                            className={'col-2 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFontBold font-18'}>{value?.guest_value}</p>
                        </div>
                    </div>
                </div>
            );
        };


        const otherKeyStat = (value, index) => {

            let imgOverStat = '';
            if (value?.title_stats === 'Yellow cards') {
                imgOverStat = require('../../../../../assets/images/cyellowcard.png');
            } else if (value?.title_stats === 'Red cards') {
                imgOverStat = require('../../../../../assets/images/credcard.png');
            }

            return (

                <div className={'container mb-5 bg-white rounded-custom'}>
                    <div className={'row'}>
                        {(value?.title_stats === 'Yellow cards' || value?.title_stats ===
                                'Red cards') &&
                            <div className={'d-flex m-0 col-12 justify-content-center'}>
                                <img src={imgOverStat}
                                     alt={'yellow'}
                                     height={'21'} width={'15'}/>
                            </div>
                        }
                        <p
                            className={'text-center m-0 font-12  sfProDisplayFont'}>{value?.title_stats}</p>
                        {data[index]?.home !== 12 &&
                            <div
                                className={`m-0 text-start sfProDisplayFontBold ${value?.home_value ===
                                0 ? 'col-6' : `col-${(data[index]?.home)}`}`}
                                style={{borderBottom: `5px solid ${homeColor}`}}>{value?.home_value}</div>
                        } {
                        (data[index]?.away !== 0 || value?.guest_value >= 0) &&
                        <div
                            className={`m-0 text-end sfProDisplayFontBold ${(data[index]?.away ===
                                0 && value?.guest_value > 0) ? 'col-1' : 'col'}`}
                            style={{borderBottom: `5px solid ${guestColor}`}}>
                            {value?.guest_value}
                        </div>}
                    </div>
                </div>
            );
        };

        return (
            <div className={'bg-white rounded-custom pb-1'}>
                <p className={'text-start ps-2 font-26 bebasFont mt-3'}>
                    Key Stats
                </p>
                {matchStats != null && matchStats[0]?.stats?.map((value, index) => (
                    <div
                        key={index}
                        className={'container-fluid'}>
                        {value?.title_stats === 'Possession time' &&
                            <PossessionCircle  value={value} homeColor={homeColor} guestColor={guestColor} />
                        }
                        {value.title_stats === 'Shots total' &&
                            totalAttempts(value)
                        }
                        {value.title_stats === 'Shots on goal' &&
                            <OnOffTargetAttemps value={value} index={index}
                                                matchStats={matchStats}
                                                homeColor={homeColor} homeFontColor={homeFontColor}
                                                guestColor={guestColor} guestFontColor={guestFontColor} />
                        }

                        {
                            (value.title_stats !== 'Shots total' &&
                                value.title_stats !== 'Possession time' &&
                                value.title_stats !== 'Shots on goal') &&
                            otherKeyStat(value, index)}
                    </div>
                ))
                }
            </div>
        );

    };

    const renderAttacking = () => {
        let data = [];
        for (let i = 0; i < matchStats[1]?.stats?.length; i++) {
            let total = parseInt(matchStats[1]?.stats[i]?.home_value) +
                parseInt(matchStats[1]?.stats[i]?.guest_value);
            let tmp =
                {
                    title: matchStats[1]?.stats[i]?.title_stats,
                    home: total === 0 ?
                        0 :
                        Math.floor((matchStats[1]?.stats[i]?.home_value / total) * 12),
                    away: total === 0 ?
                        0 :
                        Math.floor((matchStats[1]?.stats[i]?.guest_value / total) * 12),
                };
            data.push(tmp);
        }

        const totalAttempts = (value) => {
            return (
                <div className={'container'}>
                    <div className={'row'}>
                        <div
                            className={'col-2 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFontBold font-18'}>{value?.home_value}</p>
                        </div>
                        <div
                            className={'col-8 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFont font-12'}>Total attempts</p>
                        </div>
                        <div
                            className={'col-2 d-flex align-items-center justify-content-center'}>
                            <p className={'sfProDisplayFontBold font-18'}>{value?.guest_value}</p>
                        </div>
                    </div>
                </div>
            );
        };


        const otherKeyStat = (value, index) => {

            let imgOverStat = '';
            if (value?.title_stats === 'Yellow cards') {
                imgOverStat = require('../../../../../assets/images/cyellowcard.png');
            } else if (value?.title_stats === 'Red cards') {
                imgOverStat = require('../../../../../assets/images/credcard.png');
            }

            return (

                <div className={'container mb-5 bg-white rounded-custom'}>
                    <div className={'row'}>
                        {(value?.title_stats === 'Yellow cards' || value?.title_stats ===
                                'Red cards') &&
                            <div className={'d-flex m-0 col-12 justify-content-center'}>
                                <img src={imgOverStat}
                                     alt={'yellow'}
                                     height={'21'} width={'15'}/>
                            </div>
                        }
                        <p
                            className={'text-center m-0 font-12  sfProDisplayFont'}>{value?.title_stats}</p>
                        {data[index]?.home !== 12 &&
                            <div
                                className={`m-0 text-start sfProDisplayFontBold ${value?.home_value ===
                                0 ? 'col-6' : `col-${(data[index]?.home)}`}`}
                                style={{borderBottom: `5px solid ${homeColor}`}}>{value?.home_value}</div>
                        } {
                        (data[index]?.away !== 0 || value?.guest_value >= 0) &&
                        <div
                            className={`m-0 text-end sfProDisplayFontBold ${(data[index]?.away ===
                                0 && value?.guest_value > 0) ? 'col-1' : 'col'}`}
                            style={{borderBottom: `5px solid ${guestColor}`}}>
                            {value?.guest_value}
                        </div>}
                    </div>
                </div>
            );
        };

        return (
            <div className={'bg-white rounded-custom pb-1'}>
                <p className={'text-start ps-2 font-26 bebasFont mt-3'}>
                    Attacking
                </p>
                {matchStats != null && matchStats[1]?.stats?.map((value, index) => (
                    <div
                        key={index}
                        className={'container-fluid'}>
                        {value.title_stats === 'Shots total' &&
                            totalAttempts(value)
                        }
                        {value.title_stats === 'Shots on goal' &&
                            <OnOffTargetAttemps value={value} index={index}
                                                matchStats={matchStats}
                                                homeColor={homeColor} homeFontColor={homeFontColor}
                                                guestColor={guestColor} guestFontColor={guestFontColor} />
                        }

                        {
                            (value.title_stats !== 'Shots total' &&
                                value.title_stats !== 'Possession time' &&
                                value.title_stats !== 'Shots on goal') &&
                            otherKeyStat(value, index)}
                    </div>
                ))
                }

            </div>
        );

    };

    const renderDefending = () => {
        let data = [];
        for (let i = 0; i < matchStats[2]?.stats?.length; i++) {
            let total = parseInt(matchStats[2]?.stats[i]?.home_value) +
                parseInt(matchStats[2]?.stats[i]?.guest_value);
            let tmp =
                {
                    title: matchStats[1]?.stats[2]?.title_stats,
                    home: total === 0 ?
                        0 :
                        Math.floor((matchStats[2]?.stats[i]?.home_value / total) * 12),
                    away: total === 0 ?
                        0 :
                        Math.floor((matchStats[2]?.stats[i]?.guest_value / total) * 12),
                };
            data.push(tmp);
        }

        const otherKeyStat = (value, index) => {

            let imgOverStat = '';
            if (value?.title_stats === 'Yellow cards') {
                imgOverStat = require('../../../../../assets/images/cyellowcard.png');
            } else if (value?.title_stats === 'Red cards') {
                imgOverStat = require('../../../../../assets/images/credcard.png');
            }

            return (
                <div className={'container mb-5 bg-white rounded-custom pb-1'}>
                    <div className={'row'}>
                        {(value?.title_stats === 'Yellow cards' || value?.title_stats ===
                                'Red cards') &&
                            <div className={'d-flex m-0 col-12 justify-content-center'}>
                                <img src={imgOverStat}
                                     alt={'yellow'}
                                     height={'21'} width={'15'}/>
                            </div>
                        }
                        <p
                            className={'text-center m-0 font-12  sfProDisplayFont'}>{value?.title_stats}</p>
                        {data[index]?.home !== 12 &&
                            <div
                                className={`m-0 text-start sfProDisplayFontBold ${value?.home_value ===
                                0 ? 'col-6' : `col-${(data[index]?.home)}`}`}
                                style={{borderBottom: `5px solid ${homeColor}`}}>{value?.home_value}</div>
                        } {
                        (data[index]?.away !== 0 || value?.guest_value >= 0) &&
                        <div
                            className={`m-0 text-end sfProDisplayFontBold ${(data[index]?.away ===
                                0 && value?.guest_value > 0) ? 'col-1' : 'col'}`}
                            style={{borderBottom: `5px solid ${guestColor}`}}>
                            {value?.guest_value}
                        </div>}
                    </div>
                </div>
            );
        };

        return (
            <div className={'bg-white rounded-custom pb-1'}>
                <p className={'text-start ps-2 font-26 bebasFont mt-3'}>
                    Defending
                </p>
                {matchStats != null && matchStats[2]?.stats?.map((value, index) => (
                    <div
                        key={index}
                        className={'container-fluid'}>
                        {
                            (value.title_stats !== 'Shots total' &&
                                value.title_stats !== 'Possession time' &&
                                value.title_stats !== 'Shots on goal') &&
                            otherKeyStat(value, index)}
                    </div>
                ))
                }

            </div>
        );

    };

    const renderPossession = () => {
        let data = [];
        for (let i = 0; i < matchStats[3]?.stats?.length; i++) {
            let total = parseInt(matchStats[3]?.stats[i]?.home_value) +
                parseInt(matchStats[3]?.stats[i]?.guest_value);
            let tmp =
                {
                    title: matchStats[3]?.stats[i]?.title_stats,
                    home: total === 0 ?
                        0 :
                        Math.floor((matchStats[3]?.stats[i]?.home_value / total) * 12),
                    away: total === 0 ?
                        0 :
                        Math.floor((matchStats[3]?.stats[i]?.guest_value / total) * 12),
                };
            data.push(tmp);
        }


        const passAccuracy = (value, index) => {
            let bgColorCircle = homeColor;
            let bgColorCircle1 = guestColor;
            let totalHomePasses = matchStats[3]?.stats[index - 1]?.home_value;
            let totalGuestPasses = matchStats[3]?.stats[index - 1]?.guest_value;

            let homeAccuracy = Math.round(
                (value?.home_value / totalHomePasses) * 100);
            let guestAccuracy = Math.round(
                (value?.guest_value / totalGuestPasses) * 100);

            return (
                <div className={'container pb-2'}>
                    <div className={'row'}>
                        <div
                            className={'col-4 d-flex align-items-center justify-content-center'}>
                            <div
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    background: bgColorCircle,
                                    borderRadius: '50%',
                                }}
                                className={'sfProDisplayFontBold text-white d-flex align-items-center justify-content-center'}
                            >
                                {homeAccuracy} %
                            </div>
                        </div>
                        <div className={'col-4 d-flex justify-content-center'}>
                            <p className={'sfProDisplayFont d-flex  align-items-center text-center font-12'}>Passing
                                Accuracy (%)</p>
                        </div>
                        <div
                            className={'col-4 d-flex align-items-center justify-content-center'}>
                            <div
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    background: bgColorCircle1,
                                    borderRadius: '50%',
                                }}
                                className={'sfProDisplayFontBold text-white d-flex align-items-center justify-content-center'}
                            >
                                {guestAccuracy} %
                            </div>

                        </div>
                    </div>
                </div>
            );
        };

        const otherKeyStat = (value, index) => {

            let imgOverStat = '';
            if (value?.title_stats === 'Yellow cards') {
                imgOverStat = require('../../../../../assets/images/cyellowcard.png');
            } else if (value?.title_stats === 'Red cards') {
                imgOverStat = require('../../../../../assets/images/credcard.png');
            }

            return (

                <div className={'container mb-5 bg-white rounded-custom'}>
                    <div className={'row'}>
                        {(value?.title_stats === 'Yellow cards' || value?.title_stats ===
                                'Red cards') &&
                            <div className={'d-flex m-0 col-12 justify-content-center'}>
                                <img src={imgOverStat}
                                     alt={'yellow'}
                                     height={'21'} width={'15'}/>
                            </div>
                        }
                        <p
                            className={'text-center m-0 font-12  sfProDisplayFont'}>{value?.title_stats}</p>
                        {data[index]?.home !== 12 &&
                            <div
                                className={`m-0 text-start sfProDisplayFontBold ${value?.home_value ===
                                0 ? 'col-6' : `col-${(data[index]?.home)}`}`}
                                style={{borderBottom: `5px solid ${homeColor}`}}>{value?.home_value}</div>
                        } {
                        (data[index]?.away !== 0 || value?.guest_value >= 0) &&
                        <div
                            className={`m-0 text-end sfProDisplayFontBold ${(data[index]?.away ===
                                0 && value?.guest_value > 0) ? 'col-1' : 'col'}`}
                            style={{borderBottom: `5px solid ${guestColor}`}}>
                            {value?.guest_value}
                        </div>}
                    </div>
                </div>
            );
        };

        return (
            <div className={'bg-white rounded-custom pb-1'}>
                <p className={'text-start ps-2 font-26 bebasFont mt-3'}>
                    Possession
                </p>
                {matchStats != null && matchStats[3]?.stats?.map((value, index) => (
                    <div
                        key={index}
                        className={'container-fluid'}>
                        {value?.title_stats === 'Possession time' &&
                            <PossessionCircle  value={value} homeColor={homeColor} guestColor={guestColor} />
                        }
                        {
                            (value.title_stats !== 'Shots total' &&
                                value.title_stats !== 'Possession time' &&
                                value.title_stats !== 'Shots on goal') &&
                            otherKeyStat(value, index)}
                        {value?.title_stats === 'Accurate passes' &&
                            passAccuracy(value, index)
                        }

                    </div>
                ))
                }
            </div>
        );

    };

    const renderDisciplinary = () => {

        let data = [];
        for (let i = 0; i < matchStats[4]?.stats?.length; i++) {
            let total = parseInt(matchStats[4]?.stats[i]?.home_value) +
                parseInt(matchStats[4]?.stats[i]?.guest_value);
            let tmp =
                {
                    title: matchStats[4]?.stats[i]?.title_stats,
                    home: total === 0 ?
                        0 :
                        Math.floor((matchStats[4]?.stats[i]?.home_value / total) * 12),
                    away: total === 0 ?
                        0 :
                        Math.floor((matchStats[4]?.stats[i]?.guest_value / total) * 12),
                };
            data.push(tmp);
        }

        const otherKeyStat = (value, index) => {

            let imgOverStat = '';
            if (value?.title_stats === 'Yellow cards') {
                imgOverStat = require('../../../../../assets/images/cyellowcard.png');
            } else if (value?.title_stats === 'Red cards') {
                imgOverStat = require('../../../../../assets/images/credcard.png');
            }

            return (

                <div className={'container mb-5 bg-white rounded-custom'}>
                    <div className={'row'}>
                        {(value?.title_stats === 'Yellow cards' || value?.title_stats ===
                                'Red cards') &&
                            <div className={'d-flex m-0 col-12 justify-content-center'}>
                                <img src={imgOverStat}
                                     alt={'yellow'}
                                     height={'21'} width={'15'}/>
                            </div>
                        }
                        <p
                            className={'text-center m-0 font-12  sfProDisplayFont'}>{value?.title_stats}</p>
                        {data[index]?.home !== 12 &&
                            <div
                                className={`m-0 text-start sfProDisplayFontBold ${value?.home_value ===
                                0 ? 'col-6' : `col-${(data[index]?.home)}`}`}
                                style={{borderBottom: `5px solid ${homeColor}`}}>{value?.home_value}</div>
                        } {
                        (data[index]?.away !== 0 || value?.guest_value >= 0) &&
                        <div
                            className={`m-0 text-end sfProDisplayFontBold ${(data[index]?.away ===
                                0 && value?.guest_value > 0) ? 'col-1' : 'col'}`}
                            style={{borderBottom: `5px solid ${guestColor}`}}>
                            {value?.guest_value}
                        </div>}
                    </div>
                </div>
            );
        };

        return (
            <div className={'bg-white rounded-custom pb-1'}>
                <p className={'text-start ps-2 font-26 bebasFont mt-3'}>
                    Disciplinary
                </p>
                {matchStats != null && matchStats[4]?.stats?.map((value, index) => (
                    <div
                        key={index}
                        className={'container-fluid'}>
                        {otherKeyStat(value, index)}
                    </div>
                ))
                }
            </div>
        );
    };

    const renderMobileView = () => {
        return (
            <div>
                {matchStats !== null && isMobile && renderKeyStats()}
                {matchStats !== null && isMobile && renderAttacking()}
                {matchStats !== null && isMobile && renderDefending()}
                {matchStats !== null && isMobile && renderPossession()}
                {matchStats !== null && isMobile && renderDisciplinary()}
            </div>

        );
    };

    return (
        <div>
            {isMobile && renderMobileView()}
            {!isMobile && renderStats()}
            <div className={'mt-4'}>
                <PreviewRecentForm isMobile={isMobile} recent_form={recent_form}
                                   isCurrent={isCurrent}
                                   addDefaultSrc={addDefaultSrc}/>
            </div>
            <PreviewNews isCurrent={isCurrent}/>

        </div>
    );

};

export default SingleFootballMatchContentStats;
