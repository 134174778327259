import React, {useEffect, useState} from 'react';
import {Button, Container} from 'react-bootstrap';
import NewsCard from './Components/NewsCard';
import {get} from '../Axios/get';
import {CRICKET, FOOTBALL, MLB, NewsEndPoint} from '../Axios/EndPoints';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {SCREEN_SIZE} from '../Configs/Constants';
import Loader from '../Components/Loader/Loader';
import '../assets/styles/customContainer.css';
import {Link, useLocation} from 'react-router-dom';
import {SiteMetaHelmet} from '../Components/SiteMetaHelmet';
import {SubNavbar} from './Components/SportSpecific/Football/SubNavbar';
import {useSelector} from 'react-redux';
import {
  LeftbarLeagues,
} from './Components/SportSpecific/Football/LeftbarLeagues';
import RightbarVideos from './Components/SportSpecific/Football/RightbarVideos';
import {GiSpeaker} from 'react-icons/gi';
import Social from '../Components/Social/Social';
import GoogleAd from '../Components/SnackAds/GoogleAd';
import HomeMatchTabContainer from './Components/HomeMatchTabContainer';
import Skeleton from 'react-loading-skeleton';
import * as Icon from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import VideosOnSlider from './Components/SportSpecific/Football/VideosOnSlider';
import LeagueNews from './Components/SportSpecific/Football/LeagueNews';
import VideoAd from '../Components/SnackAds/VideoAd';

const FanzineAllFootball = (props) => {
  let store = useSelector((state) => state);
  // console.log('store in all football =', store);
  let queryParams = useLocation();

  // switch between old fanzine design and new
  let [isNewDesign, setIsNewDesign] = useState(true);

  let [apiPage, setApiPage] = useState(1);
  let [apiLimit, setApiLimit] = useState(48);
  let [topStories, setTopStories] = useState([]);
  let [restrictApiCall, setRestrictApiCall] = useState(true);
  let [loading, setLoading] = useState(true);
  let [isMobile, setIsMobile] = useState(false);
  let [latestVideos, setLatestVideos] = useState([]);
  let [footballLeagues, setFootballLeagues] = useState(
      store?.football?.fanzineLeagues);
  // let [latestVideos, setLatestVideos] = useState([]);
  let [latestNews, setLatestNews] = useState([]);
  let [allLeagues, setAllLeagues] = useState(null);
  let [transferNews, setTransferNews] = useState([]);
  let [staticPage, setStaticPage] = useState([]);
  let [allLeagueNewsData, setAllLeagueNewsData] = useState([]);
  let [allLeagueVideoData, setAllLeagueVideoData] = useState([]);
  let [matchDataNBA, setMatchDataNBA] = useState([]);
  let [newsDataNFL, setNewsDataNFL] = useState([]);
  let [matchDataNFL, setMatchDataNFL] = useState([]);
  let [topStoriesLoading, setTopStoriesLoading] = useState(true);
  let [matchDataLoading, setLatestVideosLoading] = useState(true);
  let [latestNewsLoading, setLatestNewsLoading] = useState(true);
  let [allLeaguesLoading, setAllLeaguesLoading] = useState(true);
  let [transferNewsLoading, setTransferNewsLoading] = useState(true);
  let [staticPageLoading, setStaticPageLoading] = useState(true);
  let [allLeagueNewsDataLoading, setAllLeagueNewsDataLoading] = useState(true);
  let [allLeagueVideoDataLoading, setAllLeagueVideoDataLoading] = useState(
      true);
  let [matchDataNBALoading, setMatchDataNBALoading] = useState(true);
  let [newsDataNFLLoading, setNewsDataNFLLoading] = useState(true);
  let [matchDataNFLLoading, setMatchDataNFLLoading] = useState(true);
  let [homePageMatches, setHomePageMatches] = useState(null);

  const handleScroll = (e) => {
    const pageBottom = document.body.scrollHeight -
        document.documentElement.clientHeight - 10 <=
        document.documentElement.scrollTop;
    if (pageBottom && document.body.scrollHeight > 1000) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  };

  const fetchTopStories = (isNew) => {
    let searchParams = queryParams.search;

    if (!searchParams) {
      searchParams = '?main_site=1&snack=1';
    }

    let page = `&page=${apiPage}`;
    let limit = `&limit=${apiLimit}`;

    let url = NewsEndPoint + searchParams + limit + page;

    get(FOOTBALL.LatestNewsEndPoint).then((responseNews) => {
      let tempNewsData = [];
      console.log('responseNEws =', responseNews);
      responseNews.data.map((data) => {
        let news = {
          news_id: data.news_id,
          slug: data.slug,
          news_image: data.image,
          news_title: data.title,
          news_url: data.url,
          news_time: moment.tz(data.datetime, 'Europe/London').
              local().
              startOf('seconds').
              fromNow(),
          news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
          news_publisher: {
            publisher_name: data.publisher.name,
            publisher_icon: data.publisher.icon,
          },
          is_snack: data.is_snack,
          main_team: data.main_team,
          type: 'news',
        };
        tempNewsData.push(news);
      });

      if (isNew) {
        setTopStories(tempNewsData);
      } else {
        setTopStories([...topStories, ...tempNewsData]);
      }
      // setLoading(false);
      setTopStoriesLoading(false);

    }).catch((error) => console.log(error));
  };

  const fetchLatestVideos = () => {
    get(FOOTBALL.LatestVideosEndPoint).then((response) => {
      // eslint-disable-next-line array-callback-return
      setLatestVideos(response.data);
      setLatestVideosLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchLatestNews = () => {
    let page = '&page=1';
    let limit = '?limit=6';

    let url = CRICKET.NewsEndPoint + limit + page;
    get(FOOTBALL.LatestNewsEndPoint).then((response) => {
      let tempNewsData = [];
      // eslint-disable-next-line array-callback-return
      response.data.map((data) => {
        let news = {
          news_id: data.news_id,
          slug: data.slug,
          news_image: data.image,
          news_title: data.title,
          news_url: data.url,
          news_time: moment.tz(data.datetime, 'Europe/London').
              local().
              startOf('seconds').
              fromNow(),
          news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
          news_publisher: {
            publisher_name: data.publisher.name,
            publisher_icon: data.publisher.icon,
          },
        };
        tempNewsData.push(news);
      });
      setLatestNews(tempNewsData);
      // setLoading(false);
      setLatestNewsLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchStaticPages = () => {
    get(FOOTBALL.StaticPageEndPoint).then((response) => {
      setStaticPage(response.data);
      setStaticPageLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchTransferNews = () => {
    let page = '&page=1';
    let limit = '?limit=6';
    let url = MLB.NewsEndPoint + limit + page;
    get(FOOTBALL.TransferNewsEndPoint).then((response) => {
      let tempNewsData = [];
      // eslint-disable-next-line array-callback-return
      response.data.map((data) => {
        let news = {
          news_id: data.news_id,
          slug: data.slug,
          news_image: data.image,
          news_title: data.title,
          news_url: data.url,
          news_time: moment.tz(data.datetime, 'Europe/London').
              local().
              startOf('seconds').
              fromNow(),
          news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
          news_publisher: {
            publisher_name: data.publisher.name,
            publisher_icon: data.publisher.icon,
          },
        };
        tempNewsData.push(news);
      });
      setTransferNews(tempNewsData);
      // setLoading(false);
      setTransferNewsLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchLeagues = () => {
    // let parameters = '?league_id=1204&limit=15';

    get(FOOTBALL.AllLeaguesEndPoint).then((response) => {
      console.log('response in getting leagues =', response.data.leagues);
      setAllLeagues(response.data?.leagues);
      // setLoading(false);
      setAllLeaguesLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchSpecificLeagueNews = async (league_Data) => {
    let leagueData = league_Data;
    let page = '&page=1';
    let limit = '?limit=7';
    let leagueId = '&league_id=' + leagueData?.league_id;
    let leagueName = leagueData?.name.replace(' ', '');
    await get(FOOTBALL.LatestNewsEndPoint + limit + leagueId).
        then((response) => {
          // eslint-disable-next-line array-callback-return

          let tempNewsData = [];
          // eslint-disable-next-line array-callback-return
          response.data.map((data, index) => {
            let news = {
              leagueId: leagueData?.league_id,
              leagueName: leagueName,
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.datetime, 'Europe/London').
                  local().
                  startOf('seconds').
                  fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
            };
            tempNewsData.push(news);
          });
          (allLeagueNewsData.length <= allLeagues.length) &&
          setAllLeagueNewsData(
              allLeagueNewsData => [...allLeagueNewsData, tempNewsData]);
        }).
        catch((err) => {
          console.error(err);
        });
  };

  const fetchSpecificLeagueVideos = async (league_Data) => {
    let leagueData = league_Data;
    let limit = '?limit=15';
    let leagueId = '&league_id=' + leagueData?.league_id;

    await get(FOOTBALL.VideoEndPoint + limit + leagueId).then((response) => {
      // eslint-disable-next-line array-callback-return
      setAllLeagueVideoData(
          allLeagueVideoData => [...allLeagueVideoData, response.data]);
      // setLatestVideos(response.data);
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    if (allLeagues && allLeagueNewsData.length === 0 &&
        allLeagueVideoData.length === 0) {
      for (let i = 0; i < allLeagues?.length; i++) {
        (allLeagueNewsData.length <= allLeagues.length) &&
        fetchSpecificLeagueNews(allLeagues[i]);
        (allLeagueVideoData.length <= allLeagues.length) &&
        fetchSpecificLeagueVideos(allLeagues[i]);
      }
    }
    setAllLeagueNewsDataLoading(false);
    setAllLeagueVideoDataLoading(false);
  }, [allLeagues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchTopStories();
    fetchLatestVideos();
    fetchLatestNews();
    fetchStaticPages();
    fetchTransferNews();
    fetchLeagues();
  }, []);

  useEffect(() => {
    setFootballLeagues(store?.football?.fanzineLeagues);
  }, [store?.football?.fanzineLeagues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await setTopStories([]);
    await setLatestVideos([]);
    setLoading(true);
    setRestrictApiCall(true);
    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    // fetchTopStories(true);
    setLoading(false);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [queryParams.search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   setLoading(true);
  //   !restrictApiCall && fetchTopStories();
  // }, [apiPage, restrictApiCall]);

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  const renderTabMatches = (news, sport, isMobile) => {
    return (
        <HomeMatchTabContainer news={news} sport={sport} isMobile={isMobile}/>);
  };

  const renderLeftContent = () => {
    return (<div
        className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
    >
      <LeftbarLeagues
          footballLeagues={footballLeagues}/>

      <Container
          className={`bg-white mb-5 rounded-custom`}
      >
        <div className={'customContainer'}>
          <div className="row">
                        <span className={'mt-lg-2'}>
                            <h5 className={'robotoCondensedBold font-16 d-flex align-items-center'}>
                                <GiSpeaker size={'30'}
                                           color={'green'}/> &nbsp;&nbsp;
                              Social Media
                            </h5>
                        </span>
            {window.innerWidth > SCREEN_SIZE.MOBILE ? (<Social
                isFanzine={true}
                isNewsPage={true}
                setLoading={setLoading}
            />) : null}
          </div>
        </div>
      </Container>
    </div>);
  };

  const renderStaticPages = (StaticPage, isMobile) => {

    const addDefaultSrc = (e) => {
      e.target.value = require('../assets/images/default-league.png');
    };

    const renderCard = (sPage) => {
      return (
          <Card
              className="col border-0 m-2 bg-transparent"
              style={{
                height: 339,
              }}
          >
            <Card.Img
                variant="top"
                // className={'img-fluid'}
                height={339}
                src={sPage?.image}
                alt={sPage?.title}
                onError={addDefaultSrc}
                style={{
                  objectFit: 'cover',
                  // borderRadius: !props.isNewDesign && '15px',
                }}
                // style={mainImageStyle}
            />
            <Card.ImgOverlay
                style={{
                  background: 'linear-gradient(147deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
                // className={` ${sPage?.type ===
                // 'videos' ? 'video-overlay' : 'news-overlay'}`}
            >
              <Card.Text
                  className={`text-white robotoCondensedBold ${'font-30'} w-100`}
              >
                {sPage?.title}
              </Card.Text>
              <div className={'d-flex align-items-end h-75'}>
                <Button variant={'outline-light'} size={'lg'}
                        className={'w-25 robotoCondensedBold rounded-pill'}>{sPage?.title ===
                'SHOP' ? 'Shop' : 'Pick'}</Button>
              </div>
            </Card.ImgOverlay>
          </Card>
      );
    };

    // only consist pick your team, venue, podcasts and shop data
    let filteredData = [];

    for (let i = 0; i < staticPage?.length; i++) {
      if (staticPage[i].title === 'VENUES') {
        filteredData.push(staticPage[i]);
      } else if (staticPage[i].title === 'SHOP') {
        filteredData.push(staticPage[i]);
      } else if (staticPage[i].title === 'PICK YOUR TEAM') {
        filteredData.push(staticPage[i]);
      } else if (staticPage[i].title === 'PODCAST') {
        filteredData.push(staticPage[i]);
      }
    }

    filteredData.sort((a, b) => {
      return a.sort_order - b.sort_order;
    });

    return (
        <div className={'container-fluid'}>
          <div className={`row mt-5 pt-5 ms-auto me-auto container `}>
            {/*<div className={'robotoCondensedBold font-28 container'}>*/}
            {/*  {'Static'}*/}
            {/*</div>*/}
            {filteredData?.map((sPage, index) => (
                <div
                    className={'m-0 p-0 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'}
                    key={index}>
                  {renderCard(sPage)}
                </div>
            ))}
          </div>
        </div>
    );
  };

  const renderVideosSkeleton = () => {
    return (
        <Skeleton count={9} height={'200px'} width={'250px'} inline={true}
                  className={'ms-2'}/>
    );
  };

  const renderNewsSkeleton = (sport, isMobile) => {
    return (
        <Container
            className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
          <div className={'customContainer'}>
            <Link
                className={`col-12 robotoCondensedBold font-22 text-decoration-none text-black mt-3`}
                to={`/${sport.toLowerCase()}`}>
              {sport}
              <Icon.ArrowRight
                  className="bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2"
                  size="40"/>
            </Link>
            <div className={'row'}>
              <div className={'col-8'}>
                <Skeleton count={1} height={isMobile ? '300px' : '600px'}/>
              </div>
              <div className={'col-4'}>
                <Skeleton count={2} height={isMobile ? '150px' : '300px'}/>
              </div>
            </div>
            <Skeleton count={3} height={isMobile ? '150px' : '300px'}
                      width={'32%'} inline={true} className={'ms-3'}/>
          </div>
        </Container>
    );
  };

  const renderLatestNews = (News, Sport,isMobile,title) => {
    const SportNews = News;
    const SportName = Sport;

    const renderLeftLatestNews = () => {
      return (
          <>
            <div
                className={'position-absolute mt-n5 bg-fanzine-dark robotoCondensedBold font-28 container'}
                style={{
                  zIndex: 1,
                  width: '250px',
                  transform: 'skew(-10deg)',
                }}
            >
              {title}
            </div>
            <hr style={{height:'3px'}}/>
            <div className={`col-xxl-8 col-xl-8 mt-2 col-lg-8 col-md-8 col-sm-12`}>
              <div className={`w-100`}>

                <div className={'row '}>
                  {SportNews.map((news, index) => {
                    return index < 8 &&
                        (
                            <div
                                className={`col-xl-6 m-0 p-1 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                            >
                              <NewsCard
                                  isNewDesign={isNewDesign}
                                  isMobile={isMobile}
                                  classProps={''}
                                  newsItem={news}
                                  isFanzine={false}
                                  // isNewsPage={true}
                                  isLatestNews={true}
                                  sport={SportName}
                              />
                            </div>
                        );
                  })}
                </div>
              </div>
            </div>
          </>
      );
    };

    const renderRightAds = () => {

      let numberOfAds = [1, 2, 3, 4];

      return (
          <div className={'col-4'}>
              {numberOfAds.map((news, index) => {

                return (index < 4) && (
                    <div
                        className={`${index === 0 && 'mt-4 pt-3'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                    >
                      <div
                          style={{
                            border: '1px black solid',
                          }}
                          className="d-flex justify-content-center align-items-center h1">
                        <GoogleAd id={'4009154580'}/>
                      </div>
                    </div>
                );
              })}
          </div>);
    };

    const renderMain = () => {
      return (
          <section className={'w-100'}>
          <div className={'container'}>
            <div className={'container'}>

            <div className={'row ps-5 pe-5'}>
              {renderLeftLatestNews()}
              {!isMobile && renderRightAds()}
            </div>
            </div>
          </div>
          </section>
      );
    };

    return renderMain();
  };


  const renderSpecificLeagues = (allSport, allVideos) => {
    const allSportsNews = allSport;
    const allSportsVideos = allVideos;
    const AllLeagues = allLeagues;
    return (
        <div className={`w-100`}>
          {
            allLeagues?.map((value, indexi) => (
                <div key={indexi}>
                  <div
                      className="d-flex justify-content-center align-items-center h1">
                    <GoogleAd id={'4009154580'}/>
                  </div>
                  {<VideosOnSlider league={value} allLeagues={allLeagues}
                                   isMobile={isMobile} loading={loading}
                                   leagueName={value?.name}/>}
                  {/*<div*/}
                  {/*    className={'robotoCondensedBold mt-2 font-28 container'}>*/}
                  {/*  {value?.name}*/}
                  {/*</div>*/}
                  <LeagueNews leagueName={value.name} league={value}
                              indexi={indexi} isMobile={isMobile}/>

                  {
                      !isMobile && (
                          <div
                              style={{
                                border: '1px black solid',
                              }}
                              className="d-flex container justify-content-center align-items-center h1">
                            <GoogleAd id={'4009154580'}/>
                          </div>
                      )
                  }
                </div>
            ))}
        </div>
    );
  };

  const renderNews = (News, Sport, isMobile, title) => {
    const SportNews = News;
    const SportName = Sport;
    const Title = title;

    const renderLeftNews = () => {
      return (
          <div
              className={'container pb-3'}>
            <div className={'robotoCondensedBold font-28 container'}>
              {Title}
            </div>
            <div className={'row'}>
              <div
                  className={`mt-4 m-0 p-1 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}
              >
                <NewsCard
                    BigImage={true}
                    isNewDesign={isNewDesign}
                    isFanzine={true}
                    classProps={''}
                    newsItem={SportNews[0]}
                    isMain={true}
                    sport={SportName}
                    isMobile={isMobile}
                />
              </div>
              <div
                  className={'col-lg-4 mt-4 m-0 p-1 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                <NewsCard
                    isNewDesign={isNewDesign}
                    isMobile={isMobile}
                    classProps={'mb-2'}
                    newsItem={SportNews[1]}
                    isFanzine={false}
                    // isNewsPage={true}
                    sport={SportName}
                />
                <NewsCard
                    isNewDesign={isNewDesign}
                    isMobile={isMobile}
                    classProps={''}
                    newsItem={SportNews[2]}
                    isFanzine={false}
                    // isNewsPage={true}
                    sport={SportName}
                />
              </div>
            </div>
            <div className={'container'}>
              <div className={'row ps-5 pe-5'}>
                <div
                    style={{
                      border: '1px solid black',
                    }}
                    className={`mt-4 p-1 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}
                >
                  <VideoAd/>
                </div>
                <div
                    className={'col-lg-4 mt-4  p-1 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                  <NewsCard
                      isNewDesign={isNewDesign}
                      isMobile={isMobile}
                      classProps={'mb-2'}
                      newsItem={SportNews[3]}
                      isFanzine={false}
                      // isNewsPage={true}
                      sport={SportName}
                  />
                  <NewsCard
                      isNewDesign={isNewDesign}
                      isMobile={isMobile}
                      classProps={''}
                      newsItem={SportNews[4]}
                      isFanzine={false}
                      // isNewsPage={true}
                      sport={SportName}
                  />
                </div>
              </div>
            </div>
          </div>
      );

    };

    const renderRightAds = () => {

      let numberOfAds = [1, 2, 3];

      return (
          <div className={'m-0 p-0 col-4'}>
            <div className={`row container`}>
              {numberOfAds.map((news, index) => {

                return (index < 4) && (
                    <div
                        className={`${index === 0 &&
                        'mt-5 pt-4'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                    >
                      <div
                          style={{
                            border: '1px black solid',
                          }}
                          className="d-flex justify-content-center align-items-center h1">
                        <GoogleAd id={'4009154580'}/>
                      </div>
                    </div>
                );
              })}
            </div>
          </div>);
    };

    const renderMain = () => {

      return (
          <section className={`w-100`}>
            {renderLeftNews()}
            {/*{!isMobile && renderRightAds()}*/}
          </section>
      );

    };

    return renderMain();
  };

  const renderCenterContent = () => {

    return (
        <div className={'w-100'}>

          {!topStoriesLoading ?
              renderNews(topStories, 'Football', isMobile, '') :
              renderNewsSkeleton('Football')}

          {/*<GoogleAd id={'4009154580'}/>*/}
          {!matchDataLoading ?
              <VideosOnSlider matchData={latestVideos} isMobile={isMobile}
                              loading={loading}
                              leagueName={'Latest Videos'}/> :
              renderVideosSkeleton()}

          <GoogleAd id={'4009154580'}/>

          {!latestNewsLoading ?
              renderLatestNews(latestNews, 'football',isMobile,'Latest News') :
              renderNewsSkeleton('Football')}

          <GoogleAd id={'4009154580'}/>

          {/*{renderStaticPages(staticPage, isMobile)}*/}

          {/*<GoogleAd id={'4009154580'}/>*/}

          {/*{!transferNewsLoading ?*/}
          {/*    renderLatestNews(transferNews, 'Transfer News', isMobile,'Transfer News') :*/}
          {/*    renderNewsSkeleton('Football')}*/}

          {/*<GoogleAd id={'4009154580'}/>*/}

          {!allLeagueNewsDataLoading && !allLeagueVideoDataLoading ?
              renderSpecificLeagues(allLeagueNewsData, allLeagueVideoData) :
              renderNewsSkeleton('Football')}

          <GoogleAd id={'4009154580'}/>

        </div>
    );
  };

  const renderRightContent = () => {
    return (<div
        className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
        style={{
          marginLeft: 'auto',
        }}
    >
      <RightbarVideos isMobile={isMobile} latestVideos={latestVideos}/>
    </div>);
  };

  const renderContent = (news, sport, isMobile) => {
    return (
        <div className="mt-0 w-100">
          {/* left content */}
          {(!isNewDesign) && !isMobile && renderLeftContent()}
          {/* Center Content */}
          {/* Right content */}
          {(!isNewDesign) && !isMobile && renderRightContent()}
        </div>);
  };

  const render = () => {
    return (
        <div className={'bg-white w-100'}>
          <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
          <Loader loading={loading}/>

          <SubNavbar isMobile={isMobile}/>
          {/*{topStories.length && renderContent(topStories, 'Football', isMobile)}*/}
          {renderTabMatches(homePageMatches, 'football', isMobile)}
          {renderCenterContent()}

        </div>
    );
  };

  return render();
};

FanzineAllFootball.propTypes = {
  snack: PropTypes.bool,
};

export default FanzineAllFootball;
