import React, {useEffect, useState} from "react";
import {get} from "../../../Axios/get";
import {TeamNewsEndPoint} from "../../../Axios/EndPoints";
import {chunk} from "../../Helper/chunk";
import moment from "moment";
import ImageCarousel from "../../Carousel/ImageCarousel";
import News from "../News";
import Divider from "../Divider";
import {SCREEN_SIZE} from "../../../Configs/Constants";
import PropTypes from "prop-types";

const TopNewsSection = (props) => {

    let [news, setNews] = useState([]);
    let [topNewsPage] = useState(1);
    let [topNewsLimit] = useState(16);
    let [newsImages, setNewsImages] = useState([]);
    let [moreNewsPage, setMoreNewsPage] = useState(5);
    let [width, setWidth] = useState(window.innerWidth);
    let [isMobile, setIsMobile] = useState(false);

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    const fetchNews = () => {
        let page = "&page=" + topNewsPage;
        let limit = "&limit=" + topNewsLimit;
        get(TeamNewsEndPoint + limit + page)
            .then((response) => {
                let tempTopNews = [];
                let tempNewsImages = [];
                let responseData = chunk(response.data, 6);
                responseData[0].map(data => (
                    tempNewsImages.push({
                        id: data.news_id,
                        slug: data.slug,
                        src: data.image,
                        alt: data.title,
                        news: data.title,
                        news_time: moment.utc(data.datetime).local().startOf('seconds').fromNow(true),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        news_url: data.is_snack ? data.snack_link : data.url,
                    })
                ));
                for (let i = 1; i < responseData.length; i++) {
                    responseData[i].map(data => (
                        tempTopNews.push({
                            news_id: data.news_id,
                            slug: data.slug,
                            news_image: data.image,
                            news_title: data.title,
                            news_url: data.is_snack ? data.snack_link : data.url,
                            news_time: moment.utc(data.datetime).local().startOf('seconds').fromNow(true),
                            news_publisher: {
                                publisher_name: data.publisher.name,
                                publisher_icon: data.publisher.icon,
                            },
                            is_snack: data.is_snack,
                        })
                    ));
                }
                setNews(tempTopNews);
                setNewsImages(tempNewsImages);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                props.setLoading(false);
            });
    }

    const fetchMoreNews = () => {
        let limit = "&limit=4";
        let page = "&page=" + moreNewsPage;
        get(TeamNewsEndPoint + page + limit)
            .then((response) => {
                let tempMoreTopNews = [];
                response.data.map((data) => (
                    tempMoreTopNews.push({
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.is_snack ? data.snack_link : data.url,
                        news_time: moment.utc(data.datetime).local().startOf('seconds').fromNow(true),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                    })
                ));
                setNews([...news, ...tempMoreTopNews]);
                setMoreNewsPage(moreNewsPage + 1);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        fetchNews();
    }, []);
    useEffect(() => {
        updateScreenWidth();
        if (width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [width]);

    const MobileNewsScroll = (condition) => {
        if (condition) {
            fetchMoreNews();
        }
    }

    const withData = () => {
        return (
            <div className="col-xxl-89 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                {newsImages ? <ImageCarousel images={newsImages} showNews={true} setShouldNavigate={props.setShouldNavigate}/> : null}
                <div className={'mb-3 text-white'}>.</div>
                {news ? <News news={news} mobileMoreNews={MobileNewsScroll}/> : null}

                {!isMobile && <div onClick={() => fetchMoreNews()}>
                    <Divider text={'See more news stories'} textShort={'More News'}/>
                </div>}
            </div>
        );
    }

    const withoutData = () => {
        return (
            <div className={'col-12'}>
                No News Found
            </div>
        );
    }

    const render = () => {
        if (news) {
            return withData();
        }

        return withoutData();
    }

    return render();

};

TopNewsSection.propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
}

export default TopNewsSection;
