import React, {useEffect, useState} from "react";
import './styles/custom.css';
import {FONT_COLOR, NAV_CLASS, TEXT_COLOR} from "../../Configs/Targets";
import '../../assets/styles/footballTargets.css';
import "simplebar/src/simplebar.css";
import {GossipEndPoint} from "../../Axios/EndPoints";
import {get} from '../../Axios/get';
import {getHostname} from "../../CustomFunctions/getHostname";

const Gossip = (props) => {

    const [gossip, setGossip] = useState([]);
    const {isNewsPage, showGossip, setShowGossip} = props;

    const fetchGossip = () => {
        get(GossipEndPoint)
            .then((response) => {
                if (response.data.length <=0){
                    setShowGossip(false);
                }
                setGossip(response.data);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        fetchGossip();
    }, []);

    const render = () => {

        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const textColor = TEXT_COLOR[target];
        const fontColor = FONT_COLOR[target];

        return (
            <div className="col-12 border-1 border-secondary rounded mt-4">
                <div className={navColor + ' rounded-custom-1 text-center p-1'}>
                    <span className={`${fontColor} text-header`}>
                        <span className={'bebasFont'}>Gossip</span>
                    </span>
                </div>
                <ul className={`bg-fixtures rounded-bottom-custom text-center ${isNewsPage ? 'social-list-long' : 'social-list'} list-unstyled p-0`}>
                    {gossip.map((gossip) => (
                        <li className={'p-3 border-bottom border-1 border-gray'}>

                            <div className={'text-start sfProDisplayFont text-muted small'}>
                                {gossip.text}
                            </div>
                            <div className={`text-start sfProDisplayFontBold ${textColor}`}>
                                <a target={'_blank'} href={gossip.url} className={`text-decoration-none ${textColor}`}>{gossip.url_name}</a>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return render();
}

export default Gossip;
