import {Button} from 'react-bootstrap';
import VideoCard from '../../../../Components/Cards/VideoCard';
import Loader from '../../../../Components/Loader/Loader';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {get} from '../../../../Axios/get';
import {FOOTBALL} from '../../../../Axios/EndPoints';

const VideosOnSlider = (props) => {
    const {league, matchData, leagueName, isMobile, loading, allLeagues} = props;

    let [allLeagueVideoData, setAllLeagueVideoData] = useState([]);
    let [allLeagueVideoDataLoading, setAllLeagueVideoDataLoading] = useState(
        true);

    const fetchSpecificLeagueVideos = async (league_Data) => {
        let leagueData = league_Data;
        let limit = '?limit=15';
        let leagueId = typeof leagueData?.league_id !== 'undefined' ?
            '&league_id=' + leagueData?.league_id :
            '&main_site=1';

        await get(FOOTBALL.VideoEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return
            setAllLeagueVideoData(response.data);
            setAllLeagueVideoDataLoading(false);
            // setLatestVideos(response.data);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        fetchSpecificLeagueVideos(league);
    }, []);

    const renderLeftButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleLeftClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft -= 400;

        };

        return (
            <div
                style={{
                    marginLeft: '-3em',
                    marginRight: '-2em',
                    zIndex: '1',
                    marginTop: '-4em',
                }}
                onClick={handleLeftClick}
            >
                <Button className={''} style={style}>
                    <AiOutlineLeft/>
                </Button>
            </div>
        );
    };

    const renderRightButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleRightClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft += 400;
        };

        return (
            <div
                onClick={handleRightClick}
                style={{
                    marginLeft: '-3em',
                    zIndex: '1',
                    marginTop: '-4em',

                }}
            >
                <Button
                    style={style}
                > <AiOutlineRight/>
                </Button>
            </div>
        );
    };

    const renderVideos = (matchData, league_name) => {
        let leagueName = league_name;
        console.log('match data league =', league)
        let style = {
            background: league_name === 'Latest Videos' ?
                'linear-gradient(to bottom, #74dbef, #0092af)' :
                league?.bg_color ? league?.bg_color :
                    '#000000',
            color: league?.font_color ? league?.font_color : '#000000',
        };

        for (let i = 0; i < allLeagues?.length; i++) {
            if (allLeagues && allLeagues[i]?.name.replace(' ', '') == leagueName) {
                style.background = allLeagues[i]?.bg_color;
                style.color = allLeagues[i]?.font_color;
            }

        }

        return (
            <div className={'w-100'}
                 style={style}
            >
                <div className={'container'}>
                    <div className={'container'}>
                        <div className={'row ps-5 pe-5'}>
                            <p className={'font-30 pt-4 pb-3 m-0 robotoCondensedBold font-italic'}
                                // style={{fontStyle:'italic'}}
                            >
                                {leagueName ? leagueName : 'Latest Videos'}
                            </p>
                            <div
                                className={'d-flex justify-content-center align-items-center'}>
                                {renderLeftButton(matchData[0]?.id)}
                                <div
                                    id={'videoSliderContainer' + matchData[0]?.id}
                                    className={`m-1 slider-mobile-container ${matchData?.length <=
                                    3 ?
                                        'justify-content-center' :
                                        'overflow-scroll-x'}`}>
                                    {
                                        matchData?.map((match) => (
                                                <VideoCard video={match} isFanzineAllFootball={true}
                                                           videoHeight={'229px'} isMain={false}
                                                           extraClass={'m-2'}
                                                           league={league}
                                                           sport={'football'}
                                                           isMobile={isMobile}/>
                                            ),
                                        )
                                    }
                                </div>
                                {renderRightButton(matchData[0]?.id)}
                            </div>
                        </div>
                    </div>
                </div>
                <Loader loading={loading}/>
            </div>
        );
    };

    const renderMain = () => {

        if (matchData?.length === 0 || allLeagueVideoData?.length === 0) {
            return ('');
        }
        return (leagueName === 'Latest Videos' ? matchData : allLeagueVideoData) &&
            renderVideos(
                (leagueName === 'Latest Videos' ? matchData : allLeagueVideoData),
                leagueName);

    };

    return renderMain();

};

VideosOnSlider.propTypes = {
    // matchData: PropTypes.array.isRequired,
    leagueName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

export default VideosOnSlider;
