const PreviewUnavailablePlayers = (props) => {
    const {addDefaultSrc, sidelined, isMobile} = props;


    return (sidelined !== undefined && sidelined.localteam.players.length > 0) && (
        <div className={'bg-white p-3 rounded-custom mb-3'}>
            <div className={'row'}>
                <p className={'sfProDisplayFont font-22 text-center'}>
                    Unavailable Player
                </p>
                <div className={'col-6'}>
                    <div className={'row'}
                         style={{borderRight: '1px solid #f1f1f1'}}
                    >
                        {sidelined?.localteam?.players?.map((value, index) =>
                            (
                                <div className={'col-6'}
                                     key={index}>
                                    <img src={value.icon}
                                         className={'player-unavailable ms-auto me-auto d-flex'}
                                         alt={'player'}
                                         height={isMobile ? '45px' : '56px'}
                                         width={'auto'}
                                         onError={addDefaultSrc}/>
                                    <p className={`sfProDisplayFont mt-1 text-center ${isMobile ? 'font-10' : 'font-12'}`}
                                       style={{color: '#000000'}}>
                                        {value.name}
                                        <p className={'sfProDisplayFont font-10 text-center'} style={{color: '#878787'}}>
                                            {value.description} <br/>
                                            {value.startdate}
                                        </p>
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={'col-6'}>
                    <div className={'row'}>
                        {sidelined?.visitorteam?.players?.map((value, index) =>
                            (
                                <div className={'col-6'} key={index}>
                                    <img src={value.icon}
                                         className={'player-unavailable ms-auto me-auto d-flex'}
                                         alt={'player'}
                                         height={isMobile ? '45px' : '56px'}
                                         width={'auto'}
                                         onError={addDefaultSrc}/>
                                    <p className={`sfProDisplayFont mt-1 text-center ${isMobile ? 'font-10' : 'font-12'}`}
                                       style={{color: '#000000'}}>
                                        {value.name}
                                        <p className={'sfProDisplayFont font-10 text-center'} style={{color: '#878787'}}>
                                            {value.description} <br/>
                                            {value.startdate}
                                        </p>
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PreviewUnavailablePlayers;
