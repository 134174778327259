import {FaMinus, FaPlus, FaTimes} from "react-icons/fa";
import React, {useState} from "react";

const SingleAccordian = (props) => {

    const [open, setOpen] = useState(false);

    const {index, value, isMobile} = props;

    const clickHandler = () => {
        setOpen(!open);
    }

    return (
        <div key={index} style={{cursor: 'pointer'}}>
            <div className={`row mt-3 mb-3 p-3`} onClick={clickHandler}>
                <div className={'col'}>
                    <div className={`azo-sans text-white text-center pt-3 pb-3 ${isMobile ? 'font-20' : 'font-36'} font-600`}
                         style={{backgroundColor: '#007e3b'}}>
                        {open ? <FaMinus
                            style={{cursor: 'pointer'}}
                            onClick={clickHandler}
                        /> : <FaPlus
                            style={{cursor: 'pointer'}}
                            onClick={clickHandler}
                        />}
                    </div>
                </div>
                <div className={`${isMobile ? 'col-10' : 'col-11'}`}>
                    <div
                        className={`ps-4 pt-3 pb-3 azo-sans font-400 text-start ${isMobile ? 'font-20' : 'font-36 ms-5'} text-white`}
                        style={{backgroundColor: '#007e3b'}}>
                        {value.question}
                    </div>
                    <div
                        className={`ps-2 pt-2 pb-2 azo-sans font-300 text-start ${isMobile ? 'font-14' : 'font-30 ms-5'} mt-2 text-white ${open ? 'd-block' : 'd-none'}`}
                    >
                        <span className={`azo-sans font-300 text-start ${isMobile ? 'font-14' : 'font-30'}`} dangerouslySetInnerHTML={{__html: value.answer}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleAccordian;
