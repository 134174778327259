import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import NewsCard from "./Components/NewsCard";
import {get} from "../Axios/get";
import {CampaignEndPoint, CRICKET, HomepageFixtures, MLB, NBA, NewsEndPoint, NFL} from "../Axios/EndPoints";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import {SCREEN_SIZE} from "../Configs/Constants";
import Loader from "../Components/Loader/Loader";
import '../assets/styles/customContainer.css';
import * as Icon from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import MatchCard from "./Components/MatchCard";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import FanzineBanners from "./Components/FanzineBanners";
import {SiteMetaHelmet} from "../Components/SiteMetaHelmet";
import GoogleAd from "../Components/SnackAds/GoogleAd";
import CampaignPopup from "../Components/Campaigns/CampaignPopup";
import Cookies from "universal-cookie";
import {getHostname} from "../CustomFunctions/getHostname";
import HomeMatchTabContainer from './Components/HomeMatchTabContainer';

const FanzineHome = props => {
    const cookies = new Cookies();

    let [newsData, setNewsData] = useState([]);
    let [matchData, setMatchData] = useState([]);
    let [newsDataCricket, setNewsDataCricket] = useState([]);
    let [matchDataCricket, setMatchDataCricket] = useState([]);
    let [newsDataMLB, setNewsDataMLB] = useState([]);
    let [matchDataMLB, setMatchDataMLB] = useState([]);
    let [newsDataNBA, setNewsDataNBA] = useState([]);
    let [matchDataNBA, setMatchDataNBA] = useState([]);
    let [newsDataNFL, setNewsDataNFL] = useState([]);
    let [matchDataNFL, setMatchDataNFL] = useState([]);
    let [newsDataLoading, setNewsDataLoading] = useState(true);
    let [matchDataLoading, setMatchDataLoading] = useState(true);
    let [newsDataCricketLoading, setNewsDataCricketLoading] = useState(true);
    let [matchDataCricketLoading, setMatchDataCricketLoading] = useState(true);
    let [newsDataMLBLoading, setNewsDataMLBLoading] = useState(true);
    let [matchDataMLBLoading, setMatchDataMLBLoading] = useState(true);
    let [newsDataNBALoading, setNewsDataNBALoading] = useState(true);
    let [matchDataNBALoading, setMatchDataNBALoading] = useState(true);
    let [newsDataNFLLoading, setNewsDataNFLLoading] = useState(true);
    let [matchDataNFLLoading, setMatchDataNFLLoading] = useState(true);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    const [campaignModalShow, setCampaignModalShow] = useState(false);
    const [campaignLeagueCodeModalShow, setCampaignLeagueCodeModalShow] = useState(false);
    const [campaign, setCampaign] = useState([]);

    const fetchCampaigns = () => {
        get(CampaignEndPoint)
            .then((response) => {
                let campaignData = response.data.data;
                console.log("campaign", campaignData.length);
                let tempCampaign = [];
                let campaign = null;
                if (campaignData.length) {
                    campaignData.map((data) => {
                        campaign = {
                            id: data.id,
                            campaign_id: data.campaign_id,
                            type: data.type,
                            leagueCode: data.league_code,
                            image: data.image,
                            title: data.title,
                            description: data.description,
                            questions: data.questions,
                        };
                        if (data.display_page === "home") {
                            tempCampaign.push(campaign);
                        }
                    });
                    const hostName = window.location.hostname;
                    const domainObject = getHostname(hostName);
                    const target = domainObject.host;
                    if (
                        cookies.get(
                            `fanzine_campaign_${campaign.campaign_id}_${target}`
                        ) !== "1"
                    ) {
                        setCampaignModalShow(true);
                    }
                }
                setCampaign(tempCampaign);
            })
            .catch((err) => {
                console.log("campaign_err", err);
            });
    };

    const fetchNews = () => {
        let searchParams = '?main_site=1&snack=1';
        let page = "&page=1";
        let limit = "&limit=6";
        get(NewsEndPoint + searchParams + limit + page)
            .then((response) => {
                let tempNewsData = [];
                // eslint-disable-next-line array-callback-return
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                    };
                    tempNewsData.push(news);
                });
                setNewsData(tempNewsData);
                setLoading(false);
                setNewsDataLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchFixtures = () => {
        get(HomepageFixtures)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.map((data) => {
                    let match = {
                        id: data.id,
                        league_id: data.league_id,
                        leagueName: data.league_name,
                        state: data.state,
                        timer: data.current_time_match,
                        time: moment.utc(data.datetime).local().format('HH:mm'),
                        total: data.total,
                        date: data.date,
                        teams: {
                            home: {
                                name: data.home_team.name,
                                icon: data.home_team.icon
                            },
                            away: {
                                name: data.guest_team.name,
                                icon: data.guest_team.icon
                            }
                        },
                        venue: {
                            stadium_image: data.venue.stadium_image
                        },
                    };
                    tempMatchData.push(match);
                });
                setMatchData(tempMatchData);
                setLoading(false);
                setMatchDataLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchCricketNews = () => {
        let page = "&page=1";
        let limit = "?limit=6";
        get(CRICKET.NewsEndPoint + limit + page)
            .then((response) => {
                let tempNewsData = [];
                // eslint-disable-next-line array-callback-return
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                    };
                    tempNewsData.push(news);
                });
                setNewsDataCricket(tempNewsData);
                setLoading(false);
                setNewsDataCricketLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchCricketFixtures = () => {
        get(CRICKET.HomepageMatchesEndPoint)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.map((leagues) => {
                    leagues.matches.map((data) => {
                        let match = {
                            id: data.id,
                            league_id: data.league_id,
                            leagueName: data.series.name,
                            state: data.state_id,
                            status: data.status,
                            date: data.date,
                            datetime: data.datetime,
                            time: data.time,
                            teams: {
                                home: {
                                    name: data.local_team.name,
                                    icon: (data.local_team.icon).includes('cricket.png') ? require('../assets/images/cricket_white.png') : data.local_team.icon,
                                    score: data.local_team.total_score,
                                },
                                away: {
                                    name: data.visitor_team.name,
                                    icon: (data.visitor_team.icon).includes('cricket.png') ? require('../assets/images/cricket_white.png') : data.visitor_team.icon,
                                    score: data.visitor_team.total_score,
                                }
                            },
                        };
                        tempMatchData.push(match);
                    });
                })
                setMatchDataCricket(tempMatchData);
                setLoading(false);
                setMatchDataCricketLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchMLBNews = () => {
        let page = "&page=1";
        let limit = "?limit=6";
        get(MLB.NewsEndPoint + limit + page)
            .then((response) => {
                let tempNewsData = [];
                // eslint-disable-next-line array-callback-return
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                    };
                    tempNewsData.push(news);
                });
                setNewsDataMLB(tempNewsData);
                setLoading(false);
                setNewsDataMLBLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchMLBFixtures = () => {
        get(MLB.HomepageMatchesEndPoint)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.map((league) => {
                    league.matches.map((data) => {
                        let match = {
                            id: data.id,
                            league_id: data.league_id,
                            leagueName: league.league_name,
                            state: data.state_id,
                            status: data.status,
                            date: data.date,
                            datetime: data.datetime,
                            time: data.time,
                            teams: {
                                home: {
                                    name: data.local_team.name,
                                    icon: data.local_team.icon,
                                    score: data.local_team.total_score,
                                },
                                away: {
                                    name: data.visitor_team.name,
                                    icon: data.visitor_team.icon,
                                    score: data.visitor_team.total_score,
                                }
                            },
                        };
                        tempMatchData.push(match);
                    });
                })
                setMatchDataMLB(tempMatchData);
                setLoading(false);
                setMatchDataMLBLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchNBANews = () => {
        let page = "&page=1";
        let limit = "?limit=6";
        get(NBA.NewsEndPoint + limit + page)
            .then((response) => {
                let tempNewsData = [];
                // eslint-disable-next-line array-callback-return
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                    };
                    tempNewsData.push(news);
                });
                setNewsDataNBA(tempNewsData);
                setLoading(false);
                setNewsDataNBALoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchNBAFixtures = () => {
        get(NBA.HomepageMatchesEndPoint)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.map((league) => {
                    league.matches.map((data) => {
                        let match = {
                            id: data.id,
                            league_id: data.league_id,
                            leagueName: league.league_name,
                            state: data.state_id,
                            status: data.status,
                            date: data.date,
                            datetime: data.datetime,
                            time: data.time,
                            teams: {
                                home: {
                                    name: data.local_team.name,
                                    icon: data.local_team.icon,
                                    score: data.local_team.total_score,
                                },
                                away: {
                                    name: data.visitor_team.name,
                                    icon: data.visitor_team.icon,
                                    score: data.visitor_team.total_score,
                                }
                            },
                        };
                        tempMatchData.push(match);
                    });
                })
                setMatchDataNBA(tempMatchData);
                setLoading(false);
                setMatchDataNBALoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchNFLNews = () => {
        let page = "&page=1";
        let limit = "?limit=6";
        get(NFL.NewsEndPoint + limit + page)
            .then((response) => {
                let tempNewsData = [];
                // eslint-disable-next-line array-callback-return
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                    };
                    tempNewsData.push(news);
                });
                setNewsDataNFL(tempNewsData);
                setLoading(false);
                setNewsDataNFLLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchNFLFixtures = () => {
        get(NFL.HomepageMatchesEndPoint)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.matches.map((data) => {
                    let match = {
                        id: data.id,
                        league_id: data.league_id,
                        leagueName: data.tournament,
                        state: data.state_id,
                        status: data.status,
                        date: data.date,
                        datetime: data.datetime,
                        time: data.time,
                        teams: {
                            home: {
                                name: data.local_team.name,
                                icon: data.local_team.icon,
                                score: data.local_team.total_score,
                            },
                            away: {
                                name: data.visitor_team.name,
                                icon: data.visitor_team.icon,
                                score: data.visitor_team.total_score,
                            }
                        },
                    };
                    tempMatchData.push(match);
                });
                setMatchDataNFL(tempMatchData);
                setLoading(false);
                setMatchDataNFLLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchCampaigns();
        fetchNews();
        fetchFixtures();
        fetchCricketNews();
        fetchCricketFixtures();
        fetchMLBNews();
        fetchMLBFixtures();
        fetchNBANews();
        fetchNBAFixtures();
        fetchNFLNews();
        fetchNFLFixtures();
    }, []);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);


    const renderNews = (news, sport, isMobile) => {
        return (
            <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
                <div className={'customContainer'}>

                    <div className="row">
                        <Link className={`col-12 sfProDisplayFontBold font-22 text-decoration-none text-black mt-3`} to={`/${sport.toLowerCase()}`}>
                            {sport}
                            <Icon.ArrowRight className='bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2' size='40'/>
                        </Link>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className={`row`}>
                                <div className={`mt-4 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}>
                                    <NewsCard classProps={''} newsItem={news[0]} isMain={true} sport={sport.toLowerCase()}/>
                                </div>
                                <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                    <div className={`col-12`}>
                                        <NewsCard isMobile={isMobile} classProps={''} newsItem={news[1]} sport={sport.toLowerCase()}/>
                                    </div>
                                    <div className={`mt-2 pt-1 col-12`}>
                                        <NewsCard isMobile={isMobile} classProps={''} newsItem={news[2]} sport={sport.toLowerCase()}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                    <NewsCard isMobile={isMobile} classProps={''} newsItem={news[3]} sport={sport.toLowerCase()}/>
                                </div>
                                <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                    <NewsCard isMobile={isMobile} classProps={''} newsItem={news[4]} sport={sport.toLowerCase()}/>
                                </div>
                                <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                    <NewsCard isMobile={isMobile} classProps={''} newsItem={news[5]} sport={sport.toLowerCase()}/>
                                </div>
                            </div>
                        </div>

                        <Link className={`col-12 sfProDisplayFontBold font-18 text-decoration-none text-black text-end float-end mt-3`} to={`/${sport}`}>
                            More News
                            <Icon.ArrowRight className='bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2' size='40' color='#24722f'/>
                        </Link>
                    </div>
                </div>
            </Container>
        );
    }

    const renderMatches = (matchData, sport) => {
        return (
            <div className={'container-fluid  mb-5'}>
                <Loader loading={loading}/>

                <div className={`slider-mobile-container ${matchData.length <= 3 ? 'justify-content-center' : 'overflow-scroll-x'}`}
                >
                    {
                        matchData.map((match) => (<MatchCard isMobile={isMobile} isNewsPage={false} classProps={''} singleMatch={match} sport={sport}/>))
                    }
                </div>
            </div>
        );
    }

    const renderMatchesSkeleton = () => {
        return (
            <Skeleton count={9} height={'200px'} width={'250px'} inline={true} className={'ms-2'}/>
        );
    }

    const renderNewsSkeleton = (sport, isMobile) => {
        return (
            <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
                <div className={'customContainer'}>
                    <Link className={`col-12 sfProDisplayFontBold font-22 text-decoration-none text-black mt-3`} to={`/${sport.toLowerCase()}`}>
                        {sport}
                        <Icon.ArrowRight className='bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2' size='40'/>
                    </Link>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <Skeleton count={1} height={isMobile ? '300px' : '600px'}/>
                        </div>
                        <div className={'col-4'}>
                            <Skeleton count={2} height={isMobile ? '150px' : '300px'}/>
                        </div>
                    </div>
                    <Skeleton count={3} height={isMobile ? '150px' : '300px'} width={'32%'} inline={true} className={'ms-3'}/>
                </div>
            </Container>
        );
    }

    const render = () => {
        return (<>
            <SiteMetaHelmet sport={'Football, Cricket, NBA, MLB, NFL'} urlEndpoint={''}/>
            <CampaignPopup
                show={campaignModalShow}
                onHide={() => setCampaignModalShow(false)}
                showLeageCode={campaignLeagueCodeModalShow}
                setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                campaign={campaign}
            />
            <FanzineBanners/>
            <Loader loading={loading}/>
            {!newsDataLoading ? renderNews(newsData, 'Football', isMobile) : renderNewsSkeleton('Football')}
            {!matchDataLoading ? renderMatches(matchData, 'football') : renderMatchesSkeleton()}

            <div className="d-flex justify-content-center align-items-center h1">
                <GoogleAd id={'4009154580'}/>
            </div>

            {!newsDataCricketLoading ? renderNews(newsDataCricket, 'Cricket', isMobile) : renderNewsSkeleton('Football')}
            {!matchDataCricketLoading ? renderMatches(matchDataCricket, 'cricket') : renderMatchesSkeleton()}

            <div className="d-flex justify-content-center align-items-center h1">
                <GoogleAd id={'4009154580'}/>
            </div>

            {!newsDataMLBLoading ? renderNews(newsDataMLB, 'MLB', isMobile) : renderNewsSkeleton('Football')}
            {!matchDataMLBLoading ? renderMatches(matchDataMLB, 'mlb') : renderMatchesSkeleton()}

            <div className="d-flex justify-content-center align-items-center h1">
                <GoogleAd id={'4009154580'}/>
            </div>

            {!newsDataNBALoading ? renderNews(newsDataNBA, 'NBA', isMobile) : renderNewsSkeleton('Football')}
            {!matchDataNBALoading ? renderMatches(matchDataNBA, 'nba') : renderMatchesSkeleton()}

            <div className="d-flex justify-content-center align-items-center h1">
                <GoogleAd id={'4009154580'}/>
            </div>

            {!newsDataNFLLoading ? renderNews(newsDataNFL, 'NFL', isMobile) : renderNewsSkeleton('Football')}
            {!matchDataNFLLoading ? renderMatches(matchDataNFL, 'mlb') : renderMatchesSkeleton()}

        </>);
    }

    return render();
}

FanzineHome.propTypes = {
    snack: PropTypes.bool,
};

export default FanzineHome;
