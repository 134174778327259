export const FootballLeagueActionTypes = {
    SET_FOOTBALL_LEAGUE: "SET_FOOTBALL_LEAGUE",
    SET_NAVBAR_LEAGUES: "SET_NAVBAR_FOOTBALL_LEAGUES",
    SET_NAVBAR_TEAM_TYPES: "SET_NAVBAR_FOOTBALL_TEAM_TYPES",
    SET_TABLE_LEAGUES: "SET_TABLE_LEAGUES",
    SET_DEFAULT_LEAGUES: "SET_DEFAULT_LEAGUES",
    SET_CURRENT_TEAM_ID: "SET_CURRENT_TEAM_ID",
    SET_CURRENT_TEAM_NAME: "SET_CURRENT_TEAM_NAME",
    SET_NAVBAR_LEAGUES_TEAMS_FANZINE: "SET_NAVBAR_LEAGUES_TEAMS_FANZINE",
    SET_FANZINE_LEAGUES: "SET_FANZINE_LEAGUES",
    SET_SINGLE_FOOTBALL_MATCH: "SET_SINGLE_FOOTBALL_MATCH",

}