import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/styles/sliderDots.css';
import PropTypes from "prop-types";
import {NAV_CLASS} from "../../Configs/Targets";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {getHostname} from "../../CustomFunctions/getHostname";
import ProductComponent from "../Shop/ProductComponent";

function TargetNextArrow(props) {
    const {className, style, onClick, arrowColor, showArrows} = props;
    return (
        <div
            className={`${className} ${arrowColor}`}
            style={{...style, display: showArrows}}
            onClick={onClick}
        />
    );
}

function TargetPrevArrow(props) {
    const {className, style, onClick, arrowColor, showArrows} = props;
    return (
        <div
            className={`${className} ${arrowColor}`}
            style={{...style, display: showArrows}}
            onClick={onClick}
        />
    );
}

const ShopItemCarousel = (props) => {
    const render = () => {

        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let arrowColor = NAV_CLASS[target] + '-before';
        let showArrows = 'block';
        let isMobile = false;
        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            showArrows = "none";
            isMobile = true;
        }

        let settings = {
            infinite: false,
            dots: false,
            slidesToShow: isMobile ? 2.5 : 4,
            cssEase: 'linear',
            centerPadding: '15',
            arrows: true,
            slidesToScroll: 1,
            lazyLoad: false,
            autoplay: false,
            prevArrow: <TargetPrevArrow arrowColor={arrowColor} showArrows={showArrows}/>,
            nextArrow: <TargetNextArrow arrowColor={arrowColor} showArrows={showArrows}/>,
        };

        return (
            <div className="image-carousel">
                <Slider {...settings}>
                    {props.images.map((item) => (
                        <>
                            <ProductComponent item={item} showColors={true} showDetail={true}/>
                        </>
                    ))}
                </Slider>
            </div>
        );
    }

    return render();

}

ShopItemCarousel.propTypes = {
    showDetail: PropTypes.bool,
    showColors: PropTypes.bool,
    showPriceFirst: PropTypes.bool,
    priceColor: PropTypes.string,
};

export default ShopItemCarousel
