import NewsCard from "../../../../NewsCard";
import GoogleAd from "../../../../../../Components/SnackAds/GoogleAd";
import React, {useEffect, useState} from "react";
import {get} from "../../../../../../Axios/get";
import {FANZINE, NewsEndPoint} from "../../../../../../Axios/EndPoints";
import moment from "moment-timezone";
import {useLocation, useParams} from "react-router-dom";
import {SCREEN_SIZE} from "../../../../../../Configs/Constants";
import ItemLoader from "../../../../../../Components/Loader/ItemLoader";

const PreviewNews = (props) => {
    const {isSocial} = props;
    let params = useParams();
    let queryParams = useLocation();
    let [apiPage, setApiPage] = useState(1);
    let [apiLimit, setApiLimit] = useState(48);
    let [newsData, setNewsData] = useState([]);
    let [restrictApiCall, setRestrictApiCall] = useState(true);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = "?main_site=1&snack=1";
        }

        let page = `&page=${apiPage}`;
        let limit = `&limit=${apiLimit}`;

        let baseURL = isSocial ? FANZINE.MatchSocial + params.id : NewsEndPoint + searchParams + limit + page

        get(baseURL)
            .then((responseNews) => {
                let tempNewsData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data?.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment
                            .tz(data.datetime, "Europe/London")
                            .local()
                            .startOf("seconds")
                            .fromNow(),
                        news_date: moment(data.datetime).format("D MMM YYYY, H:mm"),
                        news_publisher: {
                            publisher_name: data?.publisher?.name,
                            publisher_icon: data?.publisher?.icon,
                        },
                        is_snack: data?.is_snack,
                        main_team: data?.main_team,
                        type: "news",
                    };
                    tempNewsData.push(news);
                });
                console.log('loading =', loading)
                if (isNew) {
                    setNewsData(tempNewsData);
                } else {
                    setNewsData([...newsData, ...tempNewsData]);
                }
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };


    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const handleScroll = (e) => {
        const pageBottom =
            document.body.scrollHeight - document.documentElement.clientHeight - 10 <=
            document.documentElement.scrollTop;
        if (pageBottom && document.body.scrollHeight > 1000) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await setNewsData([]);
        newsData === [] && setLoading(true);
        setRestrictApiCall(true);
        window.addEventListener("scroll", handleScroll);
        setApiPage(1);
        props.isCurrent && fetchNews(true);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [queryParams.search, props.isCurrent]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setLoading(true);
        !restrictApiCall && fetchNews();
    }, [apiPage, restrictApiCall]);


    return (

        <div
            className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-white rounded-custom mt-1"
        >
            {loading ? <ItemLoader loading={loading}/> :
                <div
                    className={`mb-5 ${isMobile && 'p-2'} `}
                >
                    <div className="container-fluid">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className={`row`}>
                                {newsData.map((news, index) => {
                                    return (
                                        <>
                                            {index % 3 === 0 ? (
                                                <div
                                                    className={`mt-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                                >
                                                    <NewsCard
                                                        isFanzine={true}
                                                        classProps={""}
                                                        newsItem={news}
                                                        isMain={true}
                                                        sport={"football"}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={`mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                                >
                                                    <NewsCard
                                                        isMobile={isMobile}
                                                        classProps={""}
                                                        newsItem={news}
                                                        isFanzine={false}
                                                        isNewsPage={true}
                                                        sport={"football"}
                                                    />
                                                </div>
                                            )}{" "}
                                            {(index + 1) % 6 === 0 ? (
                                                <div
                                                    className={"billboard d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-12"}>
                                                    {/*<GoogleAd id={'4009154580'}/>*/}
                                                </div>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
}

export default PreviewNews;
