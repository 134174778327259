import {useSelector} from "react-redux";
import ImageCarousel from "../Carousel/ImageCarousel";
import React from "react";
import {SCREEN_SIZE} from "../../Configs/Constants";

const Shop = () => {

    const store = useSelector(state => state);

    const render = () => {

        let shopContainerImages = [];

        store.shopify.containerImages.map((value, index) => (
            shopContainerImages.push({
                id: value.product_id,
                slug: value.product_slug,
                title: value.overlay_text,
                buttonText: value.button_text,
                src: value.image,
            })
        ));

        return (
            <ImageCarousel images={shopContainerImages} showLabel={true} showTitle={true} showProduct={true} shopMobile={window.innerWidth <= SCREEN_SIZE.MOBILE}/>
        );
    }

    return render();

}

export default Shop;