import PropTypes from "prop-types";
import {CRICKET_MATCH_STATES} from "../../../Configs/Constants";
import moment from "moment";

const CricketMatchCard = (props) => {

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../../assets/images/cricket_white.png");
    }

    const showScore = (match, fontSize='') => {
        return (
            <div className={`row text-center pt-4`}>
                <div className={`col-9 text-start mt-2 ${fontSize}`}>
                    <img src={match.teams.home.icon} alt={match.teams.home.name} height={30} className={`me-2`} onError={addDefaultSrc}/>
                    {match.teams.home.name}
                </div>
                <div className={`col-3 text-end font-20 d-flex flex-column justify-content-center`}>
                    {match.teams.home.score}
                </div>
                <div className={`col-9 text-start mt-2 ${fontSize}`}>
                    <img src={match.teams.away.icon} alt={match.teams.away.name} height={30} className={`me-2`} onError={addDefaultSrc}/>
                    {match.teams.away.name}
                </div>
                <div className={`col-3 text-end font-20 d-flex flex-column justify-content-center`}>
                    {match.teams.away.score}
                </div>
            </div>
        );
    }

    const showTime = (match, fontSize='') => {
        return (
            <div className={`row text-center pt-4`}>
                <div className={`col-8 text-start mt-2`}>
                    <div className={`col-12`}>
                        <img src={match.teams.home.icon} alt={match.teams.home.name} height={30} className={`me-2`}/>
                        {match.teams.home.name}
                    </div>
                    <div className={`col-12 mt-2`}>
                        <img src={match.teams.away.icon} alt={match.teams.away.name} height={30} className={`me-2`}/>
                        {match.teams.away.name}
                    </div>
                </div>
                <div className={`col-4 text-center d-flex flex-column justify-content-center font-30 border p-1`}>
                    {moment(match.datetime).format('hh:mm')}
                </div>
            </div>
        );
    }

    const render = () => {
        let match = props.match;
        return (
            <div className={`match-card bg-dark`}>
                <div className={`row pb-3`}>
                    <div className={`col-6`}>{moment(match.date.date).format('ddd D MMMM Y')}</div>
                    {CRICKET_MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-6 float-end`}>
                        <div className={`text-small sfProDisplayFont float-end bg-dark-green ps-3 pe-3 pt-1`}>Result</div>
                    </div> : CRICKET_MATCH_STATES.IN_PLAY.includes(match.state) ? <div className={`col-6 float-end`}>
                            <div className={`text-small sfProDisplayFont float-end bg-yellow ps-3 pe-3 pt-1 text-black`}>Live</div>
                        </div> :
                        <div className={`col-6 float-end ps-3 pe-3 pt-1 mt-4`}></div>}
                </div>
                {CRICKET_MATCH_STATES.UPCOMING.includes(match.state) ? showTime(match) : showScore(match)}
                <div className={`col-12 float-end pt-5`}>
                    <p className={`text-start match-date sfProDisplayFont font-20`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    const renderMobile = () => {
        let match = props.match;
        return (
            <div className={`match-card bg-dark`}>
                <div className={`row`}>
                    <div className={`col-6 font-12`}>{moment(match.date.date).format('ddd D MMMM Y')}</div>
                    {CRICKET_MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-6 float-end`}>
                        <div className={`font-12 sfProDisplayFont float-end bg-dark-green ps-3 pe-3`}>Result</div>
                    </div> : CRICKET_MATCH_STATES.IN_PLAY.includes(match.state) ? <div className={`col-6 float-end`}>
                            <div className={`font-12 sfProDisplayFont float-end bg-yellow ps-3 pe-3 text-black`}>Live</div>
                        </div> :
                        <div className={`col-6 float-end ps-3 pe-3 pt-1`}></div>}
                </div>
                {CRICKET_MATCH_STATES.UPCOMING.includes(match.state) ? showTime(match, 'font-10') : showScore(match, 'font-18')}
                <div className={`col-12 float-end mt-4`}>
                    <p className={`text-start match-date sfProDisplayFont font-12`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    return props.isMobile ? renderMobile() : render();
}

CricketMatchCard.propTypes = {
    match: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
}

export default CricketMatchCard;