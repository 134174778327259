import PropTypes from "prop-types";
import {MLB_MATCH_STATES} from "../../../Configs/Constants";
import moment from "moment";

const MLBMatchCard = (props) => {

    function empty(e) {
        switch (e) {
            case "":
            case 0:
            case "0":
            case null:
            case false:
            case undefined:
                return true;
            default:
                return false;
        }
    }

    const render = () => {
        let match = props.match;
        let totalBg = '';
        if (MLB_MATCH_STATES.FINISHED.includes(match.state)) {
            totalBg = 'bg-dark-green';
        } else if (MLB_MATCH_STATES.IN_PLAY.includes(match.state)) {
            totalBg = 'bg-yellow text-black';
        } else {
            totalBg = 'border';
        }
        let score = false;
        if (!empty(match.teams.home.score) || !empty(match.teams.away.score)) {
            score = `${match.teams.home.score} - ${match.teams.away.score}`;
        }
        return (
            <div className={`match-card bg-dark`}>
                {MLB_MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-12 float-end`}>
                    <div className={`text-small bebasFont float-end bg-dark-green ps-3 pe-3 pt-1`}>Result</div>
                </div> : <div className={`col-12 float-end ps-3 pe-3 pt-1 mt-4`}></div>}
                <div className={`col-12 float-end`}>
                    {
                        MLB_MATCH_STATES.IN_PLAY.includes(match.state) ?
                            <div className={`text-center match-date sfProDisplayFont mb-0`}>
                                <span className={`text-yellow`}>LIVE:</span> {match.timer}
                            </div>
                            :
                            <p className={`text-center match-date sfProDisplayFont mb-0`}>{moment(match.date).format('ddd D MMMM Y')}</p>
                    }
                </div>
                <div className={`row text-center pt-3`}>
                    <div className={`col-4 text-center`}>
                        <img src={match.teams.home.icon} alt={match.teams.home.name} height={50}/>
                        <br/>
                        {match.teams.home.name}
                    </div>
                    <div className={`col-4 text-center ${totalBg} font-28 d-flex flex-column justify-content-center p-4`}>
                        {score ? score : moment(match.datetime).format('hh:mm')}
                    </div>
                    <div className={`col-4 text-center`}>
                        <img src={match.teams.away.icon} alt={match.teams.away.name} height={50}/>
                        <br/>
                        {match.teams.away.name}
                    </div>
                </div>
                <div className={`col-12 float-end pt-3`}>
                    <p className={`text-center match-date bebasFont font-20`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    const renderMobile = () => {
        let match = props.match;
        let totalBg = '';
        if (MLB_MATCH_STATES.FINISHED.includes(match.state)) {
            totalBg = 'bg-dark-green';
        } else if (MLB_MATCH_STATES.IN_PLAY.includes(match.state)) {
            totalBg = 'bg-yellow text-black';
        } else {
            totalBg = 'border';
        }
        let score = false;
        if (!empty(match.teams.home.score) || !empty(match.teams.away.score)) {
            score = `${match.teams.home.score} - ${match.teams.away.score}`;
        }
        return (
            <div className={`match-card bg-dark`}>
                {MLB_MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-12 float-end`}>
                    <div className={`text-small bebasFont float-end bg-dark-green ps-3 pe-3 pt-1 font-12`}>Result</div>
                </div> : <div className={`col-12 float-end ps-3 pe-3 pt-1 mt-4`}></div>}
                <div className={`col-12 float-end`}>
                    {
                        MLB_MATCH_STATES.IN_PLAY.includes(match.state) ?
                            <div className={`text-center match-date sfProDisplayFont mb-0 font-12`}>
                                <span className={`text-yellow`}>LIVE:</span> {match.timer}
                            </div>
                            :
                            <p className={`text-center match-date sfProDisplayFont mb-0 font-12`}>{moment(match.date).format('ddd D MMMM Y')}</p>
                    }
                </div>
                <div className={`row text-center pt-3`}>
                    <div className={`col-4 text-center font-12`}>
                        <img src={match.teams.home.icon} alt={match.teams.home.name} height={50}/>
                        <br/>
                        {match.teams.home.name}
                    </div>
                    <div className={`col-4 text-center ${totalBg} h1 d-flex flex-column justify-content-center`}>
                        {score ? score : moment(match.datetime).format('hh:mm')}
                    </div>
                    <div className={`col-4 text-center font-12`}>
                        <img src={match.teams.away.icon} alt={match.teams.away.name} height={50}/>
                        <br/>
                        {match.teams.away.name}
                    </div>
                </div>
                <div className={`col-12 float-end font-12`}>
                    <p className={`text-center match-date sfProDisplayFont font-20`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    return props.isMobile ? renderMobile() : render();
}

MLBMatchCard.propTypes = {
    match: PropTypes.object.isRequired,
}

export default MLBMatchCard;