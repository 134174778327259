import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useCallback, useEffect} from "react";

const MatchItemLeague = (props) => {
    const {
        value, addDefaultSrc, selectedLeagueDate,
        setLeagueMatchesGroupedByDate, isLeagueSelected,
      setIsLeagueSelected
    } = props;

    const handleClearParams = useCallback(() => {
      setIsLeagueSelected(false)
        console.log('im in handle clear params')
        setLeagueMatchesGroupedByDate(null)
    }, [])

    useEffect(() => {
    }, []);

    return (
        <div className={isLeagueSelected ? "row rounded-custom" : "row"}>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={!isLeagueSelected ? "col-1" : "col-1  bg-background"}>
                <img
                    height={25}
                    alt={value?.league_name || value?.name || selectedLeagueDate?.name}
                    src={value?.league_new_icon?.replace('testcdn', 'cdn') || value?.new_icon?.replace('testcdn', 'cdn') || selectedLeagueDate?.icon}
                    onError={addDefaultSrc}
                ></img>
            </div>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={isLeagueSelected ? "col-5 p-2 sfProDisplayFontBold font-14 bg-background" : " sfProDisplayFontBold font-14 col-5 p-2"}>
                {value?.league_name || value?.name || selectedLeagueDate?.name}
                {isLeagueSelected &&
                    <Link to={'/football/matches'} onClick={handleClearParams}>
                        <FontAwesomeIcon icon={faWindowClose}
                                         size={'20'}
                                         className={'ms-3'}
                                         color={'green'}/>
                    </Link>
                }
            </div>
        </div>
    )
}

export default MatchItemLeague;
