import {FootballLeagueActionTypes} from "../constants/FootballLeaguesActionTypes";

export const setFootballLeagues = (leagues, type) => {
    return {
        type: FootballLeagueActionTypes.SET_FOOTBALL_LEAGUE,
        payload: leagues,
    }
}

export const setNavbarLeagues = (leagues) => {
    return {
        type: FootballLeagueActionTypes.SET_NAVBAR_LEAGUES,
        payload: leagues,
    }
}

export const setNavbarFanzineTeamsLeagues = (items) => {
    return {
        type: FootballLeagueActionTypes.SET_NAVBAR_LEAGUES_TEAMS_FANZINE,
        payload: items,
    }
}

export const setFanzineLeagues = (leagues) => {
    return {
        type: FootballLeagueActionTypes.SET_FANZINE_LEAGUES,
        payload: leagues,
    }
}

export const setSingleFootballMatch = (items) => {
    return {
        type: FootballLeagueActionTypes.SET_SINGLE_FOOTBALL_MATCH,
        payload: items,
    }
}

export const setNavbarTeamTypes = (teamTypes) => {
    return {
        type: FootballLeagueActionTypes.SET_NAVBAR_TEAM_TYPES,
        payload: teamTypes,
    }
}

export const setTableLeagues = (leagues) => {
    return {
        type: FootballLeagueActionTypes.SET_TABLE_LEAGUES,
        payload: leagues,
    }
}

export const setDefaultLeagues = (league) => {
    return {
        type: FootballLeagueActionTypes.SET_DEFAULT_LEAGUES,
        payload: league,
    }
}

export const setCurrentTeamId = (teamId) => {
    return {
        type: FootballLeagueActionTypes.SET_CURRENT_TEAM_ID,
        payload: teamId,
    }
}

export const setCurrentTeamName = (teamName) => {
    return {
        type: FootballLeagueActionTypes.SET_CURRENT_TEAM_NAME,
        payload: teamName,
    }
}

