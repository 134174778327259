import {Link} from 'react-router-dom';
import NewsCard from '../../NewsCard';
import Loader from '../../../../Components/Loader/Loader';
import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';

const NewsOnSlider = (props) =>
{
  const {id, matchData, leagueName, isMobile, loading, allLeagues} = props;

  const renderLeftButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'
        };

    const handleLeftClick = () =>
    {
      document.getElementById('NewsSliderContainer'+id).scrollLeft -= 200;

    }


    return (
        <div
            style={{
              marginLeft:'-3em',
              marginRight:'-2em',
              zIndex:'1',
              marginTop:'-4em'
            }}

            onClick={handleLeftClick}
        >
          <Button className={''} style={style}>
            <AiOutlineLeft/>
          </Button>
        </div>
    );
  };

  const renderRightButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px'

        };

    const handleRightClick = () =>
    {
      document.getElementById('NewsSliderContainer'+id).scrollLeft += 200;

    }

    return (
        <div
            style={{
              marginLeft:'-3em',
              zIndex:'1',
              marginTop:'-4em'

            }}

            onClick={handleRightClick}
        >
          <Button
              style={style}
          > <AiOutlineRight/>
          </Button>
        </div>
    );
  };



  const renderNews = (matchData, league_name,id) => {
    let leagueName = league_name;
    let style = {
      background: league_name === 'Latest Videos' ? 'linear-gradient(to bottom, #009245, #004923)' : '#000000',
      color:'#ffffff',
    };
    for(let i=0;i<allLeagues?.length ; i++)
    {
      if(allLeagues && allLeagues[i]?.name.replace(' ', '') == leagueName)
      {
        style.background =  allLeagues[i]?.bg_color
        style.color = allLeagues[i]?.font_color
      }
    }
    return (
        <div className={'w-100'}
             style={style}
        >
          <div className={'container p-5'}>
            <p className={'font-30 text-white sfProDisplayFontBold'}>
              {leagueName ? leagueName : 'Latest'} News
            </p>
            <div className={'d-flex justify-content-center align-items-center'}>
              {renderLeftButton(id)}
              <div
                  id={'NewsSliderContainer' +id}
                  className={`slider-mobile-container ${matchData?.length <= 3 ?
                      'justify-content-center' :
                      'overflow-scroll-x'}`}>
                {
                  matchData?.map((news) => (
                          <>
                            <div
                                // onClick={handleClick}
                                className={`slider-mobile-news-menu text-left d-flex flex-column justify-content-center ${isMobile ?
                                    'pt-3 mt-4' :
                                    ''} `}
                            >
                              <Link to={news.news_url}
                                    className={`dropdown-item ${'selected' ?
                                        'textColor' :
                                        ''}  text-decoration-none acuminFont h-100 d-flex flex-column justify-content-center`}>
                                <NewsCard
                                    isFanzineNews={true}
                                    isNewDesign={true}
                                    isMobile={isMobile}
                                    classProps={''}
                                    newsItem={news}
                                    isNewsPage={false}
                                />
                              </Link>
                            </div>
                          </>
                      ),
                  )
                }
              </div>
              {renderRightButton(id)}
            </div>
          </div>
          <Loader loading={loading}/>
        </div>
    );
  };


  const renderMain = () =>
  {
    return(
        <div>
          {matchData && renderNews(matchData, leagueName,id)}
        </div>
    )
  }

  return renderMain()

}


NewsOnSlider.propTypes = {

  id: PropTypes.number.isRequired,
  matchData: PropTypes.array.isRequired,
  leagueName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};


export default NewsOnSlider;
