import NewsCard from "../../NewsCard";
import React, {useEffect, useState} from "react";
import {get} from "../../../../Axios/get";
import {HomePageVideoEndPoint, NewsEndPoint} from "../../../../Axios/EndPoints";
import moment from "moment-timezone";
import {useLocation} from "react-router-dom";
import ItemLoader from "../../../../Components/Loader/ItemLoader";
import GoogleAd from "../../../../Components/SnackAds/GoogleAd";
import axios from "axios";

const AllFootballMatchesVideo = (props) => {
    const {isFanzine} = props;
    const queryParams = useLocation();
    const [latestNews, setLatestNews] = useState(null)
    const [isMobile, setIsMobile] = useState(props.isMobile || false)
    const [isNewsLoading, setIsNewsLoading] = useState(true)

    /*const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = "?main_site=1&snack=1";
        }
        let apiPage = 1;
        let page = `&page=${apiPage}`;
        let limit = `&limit=48`;

        get(NewsEndPoint + searchParams + limit + page)
            .then((responseNews) => {
                    let tempNewsData = [];
                    responseNews.data.map((data) => {
                        let news = {
                            news_id: data.news_id,
                            slug: data.slug,
                            news_image: data.image,
                            news_title: data.title,
                            news_url: data.url,
                            news_time: moment
                                .tz(data.datetime, "Europe/London")
                                .local()
                                .startOf("seconds")
                                .fromNow(),
                            news_date: moment(data.datetime).format("D MMM YYYY, H:mm"),
                            news_publisher: {
                                publisher_name: data.publisher.name,
                                publisher_icon: data.publisher.icon,
                            },
                            is_snack: data.is_snack,
                            main_team: data.main_team,
                            type: "news",
                        };
                        tempNewsData.push(news);
                    });

                    if (isNew) {
                        setLatestNews(tempNewsData);
                    } else {
                        setLatestNews([...latestNews, ...tempNewsData]);
                    }
                    setIsNewsLoading(false);
                }
            )
            .catch((error) => console.log(error));
    };*/

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = "?main_site=1&snack=1";
        }
        let apiPage = 1;
        let page = `&page=${apiPage}`;
        let limit = `&limit=24`;

        let newsRes = get(NewsEndPoint + searchParams + limit + page);
        let videosRes = get(HomePageVideoEndPoint + searchParams + limit + page);

        axios.all([newsRes, videosRes])
            .then(axios.spread((responseNews, responseVideos) => {
                let tempNewsData = [];
                let tempVideosData = [];
                let tempData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });
                responseVideos.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.id,
                        news_image: data.preview_image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.published_at.date, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.published_at.date).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'videos',
                    };
                    tempVideosData.push(news);
                });

                for (let i = 0; i < tempNewsData.length; i++) {
                    let ctr = i + 1;
                    if (ctr % 3 === 0) {
                        tempData.push(tempVideosData[i]);
                    } else {
                        tempData.push(tempNewsData[i]);
                    }
                }

                if (isNew) {
                    setLatestNews(tempData);
                } else {
                    setLatestNews([...latestNews, ...tempData]);
                }
                setIsNewsLoading(false);
            }))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        fetchNews(true)
    }, [])


    return (
        <>
            {isNewsLoading && <ItemLoader loading={isNewsLoading}/>}
            {(latestNews != null || latestNews?.length > 0) && latestNews?.map((news, index) => (
                <>
                    <div
                        key={index}
                        className={`${index !== 0 && 'mt-2'} col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                    >
                        <NewsCard
                            isMobile={isMobile}
                            classProps={""}
                            newsItem={news}
                            isNewDesign={true}
                            isNewsPage={false}
                            sport={"football"}
                        />
                    </div>
                    {(index + 1) % 3 === 0 && (
                        <div className={"billboard d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-12"}>
                            <GoogleAd id={'4009154580'}/>
                        </div>
                    )}
                </>
            ))}{" "}

        </>
    )
}

export default AllFootballMatchesVideo;
