import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SingleFootballMatchContentOverview from "./SingleFootballMatchContentOverview";
import SingleFootballMatchContentStats from "./SingleFootballMatchContentStats";
import SingleFootballMatchContentLineups from "./SingleFootballMatchContentLineups";
import SingleFootballMatchContentCommentary from "./SingleFootballMatchContentCommentary";
import SingleFootballMatchContentH2H from "./SingleFootballMatchContentH2H";
import SingleFootballMatchContentSocial from "./SingleFootballMatchContentSocial";
import {Link, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../../../../../Configs/Constants";

const SingleFootballMatchOverviewMain = (props) => {
    const {singleMatchData} = props;
    const params = useParams();
    const queryParams = useLocation();
    const [matchData, setMatchData] = useState(null);
    const [matchDataLoading, setMatchDataLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [currentActive, setCurrentActive] = useState(queryParams.pathname.split('/')[4])

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../../../../assets/images/default-league.png");
    };

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    useEffect(() => {
        // fetchMatchDetails();
        return (() => {
        })
    }, [])

    const updateActiveTab = () => {
        if (queryParams.pathname.split('/')[4]) {
            setCurrentActive(queryParams.pathname.split('/')[4])
        } else {
            setCurrentActive('overview')
        }
    }

    useEffect(() => {
        updateActiveTab()
    }, [queryParams.pathname,queryParams])

    const handleURLUpdate = (paramsToAdd) => {
        let newUrl = '';

        if (queryParams.pathname.split('/')[4] === '') {
            newUrl = queryParams.pathname + '/' + paramsToAdd
        } else {
            queryParams.pathname.split('/')[4] = ''
            newUrl = '/' + queryParams.pathname.split('/')[1] + '/' + queryParams.pathname.split('/')[2]
                + '/' + queryParams.pathname.split('/')[3] + '/' + paramsToAdd
        }
        setCurrentActive(paramsToAdd)
        window.history.pushState({}, null, newUrl);
    }

    const fetchURL = (paramsToAdd) => {
        let newUrl = '';

        if (queryParams.pathname.split('/')[4] === '') {
            newUrl = queryParams.pathname + '/' + paramsToAdd
        } else {
            queryParams.pathname.split('/')[4] = ''
            newUrl = '/' + queryParams.pathname.split('/')[1] + '/' + queryParams.pathname.split('/')[2]
                + '/' + queryParams.pathname.split('/')[3] + '/' + paramsToAdd
        }
        return newUrl
    }

    const renderOverviewTab = () => {
        return (
            <Tab eventKey="overview" title="Overview">
                <SingleFootballMatchContentOverview currentActive={currentActive === '' ? 'overview' : currentActive}   isMobile={isMobile} addDefaultSrc={addDefaultSrc}/>
            </Tab>
        )
    }
    const renderStatTab = () => {
        return (
            <Tab eventKey="stats" title="Stats">
                <SingleFootballMatchContentStats singleMatchData={singleMatchData} currentActive={currentActive} isMobile={isMobile} />
            </Tab>
        )
    }
    const renderLineupsTab = () => {
        return (
            <Tab eventKey="lineups" title="Lineups">
                <SingleFootballMatchContentLineups currentActive={currentActive} addDefaultSrc={addDefaultSrc} isMobile={isMobile} />
            </Tab>
        )
    }
    const renderCommentaryTab = () => {
        return (
            <Tab eventKey="commentary" title="Commentary">
                <SingleFootballMatchContentCommentary currentActive={currentActive}  isMobile={isMobile} />
            </Tab>
        )
    }
    const renderH2HTab = () => {
        return (
            <Tab eventKey="h2h" title="H2H">
                <SingleFootballMatchContentH2H isMobile={isMobile} matchData={matchData}
                                               currentActive={currentActive}
                                               addDefaultSrc={addDefaultSrc}/>
            </Tab>
        )
    }
    const renderSocialTab = () => {
        return (
            <Tab eventKey="social" title="Social">
                <SingleFootballMatchContentSocial isMobile={isMobile} currentActive={currentActive} />
            </Tab>
        )
    }
    const renderMatchLive = () => {
        return (
            <div>
                {/*{isMobile && renderMobileMenu()}*/}
                { <Tabs
                    defaultActiveKey={currentActive}
                    activeKey={currentActive}
                    onSelect={(e) => handleURLUpdate(e)}
                    id="fill-tab-example"
                    transition={false}
                    className={`mb-2 font-14 text-black text-decoration-none ${ isMobile && 'd-none'} `}
                >
                    {renderOverviewTab()}
                    {renderStatTab()}
                    {renderLineupsTab()}
                    {renderCommentaryTab()}
                    {renderH2HTab()}
                    {renderSocialTab()}
                </Tabs>}
            </div>
        )
    }

    return (
        <div>
            {renderMatchLive()}
        </div>
    )

}


export default SingleFootballMatchOverviewMain