import React, {useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../../assets/styles/styles.css';
import '../../assets/styles/responsive/stylesLaptop.css';
import '../../assets/styles/responsive/stylesTablet.css';
import '../../assets/styles/responsive/stylesMobile.css';
import '../../assets/styles/footballTargets.css';
import {COLORS, FONT_COLOR, LOGO, LOGO_BANNER, LOGO_SMALL, NAV_CLASS, TEAM_NAME, TEXT_COLOR} from "../../Configs/Targets";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {Col, NavDropdown, NavItem, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import {getHostname} from "../../CustomFunctions/getHostname";
import * as Icon from "react-bootstrap-icons";
import useComponentVisible from "../../CustomFunctions/useComponentVisible";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";

const NavbarHeader = () => {
    const store = useSelector((state) => state);
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(true);

    const [width, setWidth] = useState(window.innerWidth);
    const [showCart, setShowCart] = useState(false);
    const [navShadow, setNavShadow] = useState('navShadow');

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const route = window.location.pathname;
    const navColor = NAV_CLASS[target];
    const navColorDark = NAV_CLASS[target] + '-dark';
    const navColorHover = NAV_CLASS[target] + '-hover-color';
    const fontColor = FONT_COLOR[target];
    const navName = TEAM_NAME[target];
    const color = COLORS[target];

    // let showCart = false;

    // let navShadow = "shadow-nav";


    let [navLogo, setNavLogo] = useState(LOGO[target]);
    let [imageProps, setImageProps] = useState({});
    let [isMobile, setIsMobile] = useState(false);
    let [showDownloadBanner, setShowDownloadBanner] = useState(false);
    let [isCollapsed, setIsCollapsed] = useState(true);
    let topNavBarDropdown = {};
    if (isMobile) {
        topNavBarDropdown = {
            width: "100%"
        }
    }

    useEffect(() => {
        setNavShadow("navShadow");
        setShowCart(false);
        if (route.includes("shop")) {
            setNavShadow("shadow-nav-dark");
            setShowCart(true);
        }
    }, [route])

    useEffect(() => {
        const navLogoSmall = LOGO_SMALL[target];
        const navLogoBig = LOGO[target];

        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            setShowDownloadBanner(true);
        }
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setNavLogo(navLogoSmall);
            setImageProps({
                height: 50
            });
            setIsMobile(true);
        } else {
            setNavLogo(navLogoBig);
            if (['the-toffees', 'unitedreds', 'coys', 'playuppompey', 'foresttilidie', 'comeonderby', 'fanzinesports'].includes(target)) {
                setImageProps({
                    style: {marginTop: '-2em'}, height: 85,
                });
            } else {
                setImageProps({
                    height: 119,
                });
            }
            setIsMobile(false);
        }
        if (target !== 'fanzinesports' && !document.getElementById('navbarMobileToggle').classList.contains('collapsed')) {
            document.getElementById('navbarMobileToggle').click();
        }
    }, [window.innerWidth]);

    useEffect(() => {
        if (isMobile && target !== 'fanzinesports') {
            if (!isCollapsed) {
                document.getElementById('navbarMobileToggle').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 fa-2x"' + ' role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
                document.getElementById('responsive-navbar-nav').style = "display: block !important;";
            } else {
                document.getElementById('navbarMobileToggle').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-11 fa-2x' + '" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163' + ' 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>';
                document.getElementById('responsive-navbar-nav').style = 'display: none !important;';
                // document.getElementById('navbarMobileToggle').innerHTML = "<FontAwesomeIcon icon={'bars'} className={fontColor}/>";
            }
        }
    }, [isCollapsed]);

    /*useEffect(() => {
        console.log('navbarissue', target, imageProps);
    }, [target, imageProps]);*/

    const handleCollapse = () => {
        document.getElementsByClassName('nav-mega-menu')[0].closest('.dropdown-menu').previousSibling.click();
        document.getElementById('navbarMobileToggle').click();
    };

    const handleMainCollapse = () => {
        document.getElementById('navbarMobileToggle').click();
    };

    const renderMainNav = () => {
        return (<Navbar.Collapse id="responsive-navbar-nav" className={`${navColor} w-100`}
                                 style={{zIndex: '10000'}}>
            {/*<Nav className="me-auto"/>*/}
            <Nav
                className={'justify-content-evenly float-xxl-end float-xl-end float-lg-end float-md-end float-sm-end p-xxl-0 p-xl-0 p-lg-0 p-md-0 p-sm-0 nav-content float-sm-start align-content-sm-start mt-xxl-2 mt-xl-2 mt-lg-2'}>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : ''}`}>
                    <NavLink to={'/'}
                             className={`${fontColor} h5 text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Home
                    </NavLink>
                </NavItem>
                {isMobile ? <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : ''}`}>
                    <NavLink to={'/news?teamType=team'}
                             className={`${fontColor} h5 text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        News
                    </NavLink>
                </NavItem> : <NavDropdown className={`no-caret-dropdown ${isMobile && 'pt-2'}`}
                                          title={<span className={`${fontColor} h5 ${isMobile ? 'bebasFont ps-4 pt-2' : 'sfProDisplayFontBold font-16'}`}>News & Social</span>}
                                          id="top-menu-dropdown" renderMenuOnMount={true} style={topNavBarDropdown}>
                    <div className="nav-mega-menu pt-0 mt-0">
                        <Row className={`p-0 m-0`}>
                            <div className={`bg-dark-grey sfProDisplayFontBold font-14 d-flex align-items-center`}
                                 style={{color: '#777777', height: "31px"}}>
                                {store.misc.appInit && store.misc.appInit.team_name}
                            </div>
                            {store.football.navbarTeamTypes && store.football.navbarTeamTypes.map((item) => {
                                if (item.display_on.includes('news')) {
                                    return (<Col xs="4" md="4"

                                                 className={`text-left ${navColorHover} d-flex flex-column justify-content-center p-0`}>
                                        <NavLink to={`/news?teamType=${item.teamType}`}
                                                 className={`${navColorHover} dropdown-item text-gray-custom text-decoration-none font-14 h-100 d-flex flex-column justify-content-center`}
                                                 onClick={handleCollapse}>
                                            <div className={'flex-row justify-content-start align-middle'}>
                                                {/*<img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                                {' ' + item.name}
                                            </div>
                                        </NavLink>
                                    </Col>);
                                }
                            })}
                            <div className={`bg-dark-grey sfProDisplayFontBold font-14 d-flex align-items-center`}
                                 style={{color: '#777777', height: "31px"}}>
                                Leagues
                            </div>
                            {store.football.navbarLeagues && store.football.navbarLeagues.map((item) => (<Col xs="4" md="4"
                                                                                                              className={`text-left ${navColorHover} d-flex flex-column justify-content-center p-0`}>
                                <NavLink to={`/news?league_id=${item.league_id}`}
                                         className={`${navColorHover} dropdown-item text-gray-custom text-decoration-none font-14 d-flex flex-column justify-content-center`}
                                         onClick={handleCollapse}>
                                    <div className={'flex-row justify-content-start align-middle sfProDisplayFont '}>
                                        {/*<img src={item.icon} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                        {' ' + item.name}
                                    </div>
                                </NavLink>
                            </Col>))}
                        </Row>
                    </div>
                </NavDropdown>}
                <NavItem className={`d-flex flex-column justify-content-center mt-2 ${isMobile && 'ps-4 pt-2'}`}>
                    <NavLink to={'/shop'}
                             className={`${fontColor} text-decoration-none h5 ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Shop
                    </NavLink>
                </NavItem>
            </Nav>

            {isMobile && renderSocialNav()}
        </Navbar.Collapse>);
    }

    function renderSocialNav() {
        return (<div className={`p-4 mt-5 w-100 border-top`}>
            <div className={`${fontColor} sfProDisplayFont`}>
                Follow us for the best {navName} content
            </div>
            <div className={'mt-3'}>
                <a href={store.misc.appInit && store.misc.appInit.facebook_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faFacebook} className={fontColor} size={'3x'}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.twitter_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faTwitter} size={'3x'} className={`ms-4 ${fontColor}`}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.instagram_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faInstagram} size={'3x'} className={`ms-4 ${fontColor}`}/>
                </a>
            </div>
        </div>);
    }

    const renderDownloadBanner = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navLogoMedium = LOGO_BANNER[target];
        const appName = TEAM_NAME[target];
        const textColor = TEXT_COLOR[target];
        const fontColor = FONT_COLOR[target];
        const bgColor = NAV_CLASS[target];
        return (
            <div className={`row p-3 bg-white`}>
                {/*<div className={`col-1 d-flex flex-column justify-content-center`}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        size={'1x'}
                        className={`text-gray`}
                        onClick={() => {
                            setShowDownloadBanner(false);
                        }}
                    />
                </div>*/}
                <div className={`col-2`}>
                    <img src={navLogoMedium} alt={navName} height={'50'}/>
                </div>
                <div className={`col-6 sfProDisplayFont d-flex flex-column justify-content-center`}>
                    <div className={`flex-row justify-content-start`}>
                        <div className={`sfProDisplayFontBold ${textColor} font-16`}>{appName}</div>
                        <div className={`sfProDisplayFont font-12`}>Open in the {appName} app</div>
                    </div>
                </div>
                <div className={`col d-flex flex-column justify-content-center w-auto float-end me-0`}>
                    <Link to={'/onelink'} className={`btn rounded-pill ${bgColor} ${fontColor} btn-block sfProDisplayFont font-16`}>INSTALL</Link>
                </div>
            </div>
        );
    }

    const render = () => {
        return (<div className={`fixed-top`} ref={ref}>
            {
                showDownloadBanner ? renderDownloadBanner() : null
            }
            {isMobile ? (<></>) : (<div className={`topBar ${navColorDark}`}/>)}

            <Navbar collapseOnSelect expand="md" className={`nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}>
                <NavLink to={'/shop'} id={'navbarShop'}/>
                <div className={`${isMobile ? 'w-100' : 'container'}`}>
                    {isMobile ? (<div className={`${isMobile ? "row" : ""}`}>
                        <div className={` ${isMobile && 'col-3 ps-4 d-inline-flex'} ${fontColor}`}>
                            <button aria-controls="responsive-navbar-nav" id="navbarMobileToggle" type="button" aria-label="Toggle navigation"
                                    className={`${fontColor} float-start text-start navbar-toggler collapsed`}
                                    onClick={() => {
                                        setIsComponentVisible(true);
                                        setIsCollapsed(!isCollapsed);
                                    }}>
                                <FontAwesomeIcon icon={faBars} className={fontColor} size={'2x'}/>
                            </button>
                        </div>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile && 'col-5 text-center'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                                {!['coys'].includes(target) ? (<span
                                    className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
                            </NavLink>
                        </Navbar.Brand>
                        {isMobile && <div
                            className={`text-white flex-column float-end text-end font-weight-100 col-3 d-inline-flex justify-content-center`}>
                            <div className={`sfProDisplayFont d-inline-flex ${showCart ? 'visible' : 'invisible'}`}>
                                <Link to="/checkout" style={{textDecoration: "none", color: "#fff"}}>
                                    <Icon.Cart4 className='bg-white rounded-circle ps-2 pe-2 pb-1 pt-1 me-2' color={color} size='38'/>
                                </Link>
                                <div className={'d-flex flex-column justify-content-center'}>
                                    <div className={'d-flex flex-row justify-content-center small'}>
                                        <span className={'font-weight-100'}>Cart</span>
                                        <span className={'font-weight-100 ps-1'}>({store.cart.cartItems.length})</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {isComponentVisible && isMobile && renderMainNav()}
                        {!isMobile && renderMainNav()}

                    </div>) : (<>
                        <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                           className={`${fontColor} float-start text-start`}
                                           id={'navbarMobileToggle'}
                                           onClick={() => {
                                               setIsComponentVisible(true);
                                               setIsCollapsed(!isCollapsed);
                                           }}/>
                        </div>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile && 'col-5 text-end'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />

                                {!['coys'].includes(target) ? (<span
                                    className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
                            </NavLink>
                        </Navbar.Brand>
                        {isMobile && <div
                            className={`text-white flex-column float-end text-end font-weight-100 col-4`}>
                            <div className={`sfProDisplayFont d-inline-flex ${showCart ? 'visible' : 'invisible'}`}>
                                <div
                                    className={`bg-white p-2 rounded-circle me-2`}>
                                    <Link to="/checkout" style={{textDecoration: "none", color: "#fff"}}>
                                        <Icon.Cart4 color={color} size='30'/>
                                    </Link>
                                </div>
                                <div className={'d-flex flex-column justify-content-center'}>
                                    <div className={'d-flex flex-row justify-content-center small'}>
                                        <span className={'font-weight-100'}>Cart</span>
                                        <span className={'font-weight-100 ps-1'}>(0)</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {isComponentVisible && isMobile && renderMainNav()}
                        {!isMobile && renderMainNav()}
                    </>)}
                </div>
            </Navbar>
        </div>)
    }

    const renderFanzineSports = () => {
        return (<div className={`fixed-top`} ref={ref}>
            {isMobile ? (<></>) : (<div className={`topBar ${navColorDark}`}/>)}

            <Navbar collapseOnSelect expand="md" className={`nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}>
                <div className={`${isMobile ? 'w-100' : 'container justify-content-start'}`}>
                    {isMobile ? (<div className={`${isMobile ? "row" : ""}`}>
                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile && 'col-5 text-start'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                                {!['coys'].includes(target) ? (<span
                                    className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                    {navName}
                                </span>) : null}
                            </NavLink>
                            {target === 'fanzinesports' ? (<>
                                <NavLink to={'/?league_id=1000'} className={'text-decoration-none ps-4'}>
                                    <span className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'FPL News'}
                                    </span>
                                </NavLink>
                                <NavLink to={'/?league_id=1204'} className={'text-decoration-none ps-4'}>
                                    <span className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'EPL News'}
                                    </span>
                                </NavLink>
                            </>) : null}
                        </Navbar.Brand>
                        {isComponentVisible && isMobile && renderMainNav()}
                        {!isMobile && renderMainNav()}

                    </div>) : (<>
                        <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                           className={`${fontColor} float-start text-start`}
                                           id={'navbarMobileToggle'}
                                           onClick={() => {
                                               setIsComponentVisible(true);
                                               setIsCollapsed(!isCollapsed);
                                           }}/>
                        </div>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile && 'col-5 text-start'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />

                                {!['coys', 'fanzinesports'].includes(target) ? (
                                    <span className={`${fontColor} h2 align-middle d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex d-xxl-inline-flex bebasFont ps-2`}>
                                        {navName}
                                    </span>) : null}
                            </NavLink>
                            {target === 'fanzinesports' ? (<>
                                <NavLink to={'/?league_id=1000'} className={'text-decoration-none ps-4'}>
                                    <span className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'FPL News'}
                                    </span>
                                </NavLink>
                                <NavLink to={'/?league_id=1204'} className={'text-decoration-none ps-4'}>
                                    <span className={`${fontColor} h2 align-middle bebasFont ps-2`}>
                                        {'Premier League News'}
                                    </span>
                                </NavLink>
                            </>) : null}
                        < /Navbar.Brand>
                    </>)}
                </div>
            </Navbar>
        </div>)
    }

    return target !== 'fanzinesports' ? render() : renderFanzineSports();
}

export default NavbarHeader;
