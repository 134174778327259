import React, {useState} from "react";
import Social from "../../../../../Components/Social/Social";

const SingleFootballMatchContentSocial = (props) =>
{
    const [isSocial, setSocial] = useState(true);
    const [loading,setLoading] = useState(true)
    const isCurrent = props.currentActive === 'social';

    return(
        <div>
            <Social
                isCurrent={isCurrent}
                isSocial={isSocial}
                isFanzine={true}
                isNewsPage={true}
                setLoading={setLoading}
            />
        </div>
    )

}

export default SingleFootballMatchContentSocial;
