import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import NewsCard from "../Components/Cards/NewsCard";
import Social from "../Components/Social/Social";
import Gossip from "../Components/Gossip/Gossip";
import {get} from "../Axios/get";
import {CampaignEndPoint, NewsEndPoint} from "../Axios/EndPoints";
import moment from "moment-timezone";
import {useLocation} from "react-router-dom";
import {getHostname} from "../CustomFunctions/getHostname";
import {COLORS, LOADING_GIF, NAV_CLASS} from "../Configs/Targets";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {SCREEN_SIZE} from "../Configs/Constants";
import MobileNewsMenuItem from "../Components/Navbar/MobileNewsMenuItem";
import Billboard from "../Components/SnackAds/Billboard";
import MPU from "../Components/SnackAds/MPU";
import DMPU from "../Components/SnackAds/DMPU";
import Loader from "../Components/Loader/Loader";
import '../assets/styles/customContainer.css';
import Cookies from "universal-cookie";
import CampaignPopup from "../Components/Campaigns/CampaignPopup";
import LeagueCodePopup from "../Components/Campaigns/LeagueCodePopup";

const NewsPage = props => {

    const store = useSelector(state => state);
    let queryParams = useLocation();
    const cookies = new Cookies();

    let [newsData, setNewsData] = useState([]);
    let [socialUpdate, setSocialUpdate] = useState(false);
    let [apiPage, setApiPage] = useState(1);
    let [restrictApiCall, setRestrictApiCall] = useState(true);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [campaignModalShow, setCampaignModalShow] = useState(false);
    const [campaignLeagueCodeModalShow, setCampaignLeagueCodeModalShow] = useState(false);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColorHover = NAV_CLASS[target] + '-hover-color';
    // const navLogo = LOGO[target];
    const loadingGif = LOADING_GIF[target];
    const color = COLORS[target];

    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight - document.documentElement.clientHeight - 10 <= document.documentElement.scrollTop;
        if (pageBottom) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    }

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;
        /*if (!searchParams) {
            searchParams = '?team_type=team';
        }*/
        if (props.snack || !searchParams) {
            searchParams = '?main_site=1';
            if (target === 'fanzinesports') {
                searchParams = '?league_id=1000';
            }
        }
        let page = "&page=" + apiPage;
        let limit = "&limit=50";
        get(NewsEndPoint + searchParams + limit + page)
            .then((response) => {
                let tempNewsData = [];
                response.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                    };
                    tempNewsData.push(news);
                });
                if (isNew) {
                    setNewsData(tempNewsData);
                } else {
                    setNewsData([...newsData, ...tempNewsData]);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchCampaigns = () => {
        get(CampaignEndPoint)
            .then((response) => {
                let campaignData = response.data.data;
                let tempCampaign = [];
                let campaign = null;
                if (campaignData.length) {
                    campaignData.map((data) => {
                        campaign = {
                            id: data.id,
                            campaign_id: data.campaign_id,
                            type: data.type,
                            leagueCode: data.league_code,
                            image: data.image,
                            title: data.title,
                            description: data.description,
                            questions: data.questions,
                        };
                        tempCampaign.push(campaign);
                    });
                    const hostName = window.location.hostname;
                    const domainObject = getHostname(hostName);
                    const target = domainObject.host;
                    if (cookies.get(`fanzine_campaign_${campaign.campaign_id}_${target}`) !== '1' && target === 'fanzinesports') {
                        setCampaignModalShow(true);
                    }
                }
                setCampaign(tempCampaign);
            })
            .catch((err) => {
                console.log('campaign_err', err);
            })
    }

    useEffect(() => {
        setLoading(true);
        setRestrictApiCall(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        setNewsData([]);
        fetchNews(true);
        setSocialUpdate(true);
        console.log('queryParams.search', queryParams.search);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [queryParams.search]);

    useEffect(() => {
        !restrictApiCall && fetchNews();
        !store.misc.campaignAnswered && apiPage === 1 && fetchCampaigns();
        setSocialUpdate(true);
    }, [apiPage, restrictApiCall]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const renderMobileMenu = () => {
        return (
            <div className={'slider-mobile-news-container shadow-nav-dark'}>
                {
                    store.football.navbarTeamTypes && store.football.navbarTeamTypes.map((item) => {
                        if (item.display_on.includes('news')) {
                            return (
                                <MobileNewsMenuItem title={item.name} uri={`/news?teamType=${item.teamType}`} selected={queryParams.search === `?teamType=${item.teamType}`}/>
                            )
                        }
                    })
                }
                {store.football.navbarLeagues && store.football.navbarLeagues.map((item) => (
                    <MobileNewsMenuItem title={item.name} uri={`/news?league_id=${item.league_id}`} selected={queryParams.search === `?league_id=${item.league_id}`}/>
                ))}
            </div>
        );
    }

    const render = () => {
        return (
            <>
                <CampaignPopup
                    show={campaignModalShow}
                    onHide={() => setCampaignModalShow(false)}
                    showLeageCode={campaignLeagueCodeModalShow}
                    setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                    onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                    campaign={campaign}
                />
                <LeagueCodePopup
                    show={campaignLeagueCodeModalShow}
                    onHide={() => setCampaignModalShow(false)}
                    showLeageCode={campaignLeagueCodeModalShow}
                    setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                    onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                    campaign={campaign}
                />
                {isMobile ? renderMobileMenu() : null}
                <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2`}>
                    <div className={'customContainer'}>
                        <Loader loading={loading}/>
                        {/*Billboard Banner Ad*/}
                        <div className="d-flex justify-content-center">
                            {isMobile ? <MPU id={'snack_mob_top'}/> : <Billboard id={'snack_dex3'}/>}
                        </div>

                        {/*Section 1 - News Stories, Fixtures, Table*/}
                        <div className="row">
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 col-sm-12">
                                <div className={`row`}>
                                    {
                                        newsData &&
                                        newsData.map((news, index) => {
                                            if (index === 10) {
                                                return isMobile ? (<div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_mex4'}/></div>) : (
                                                    <div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_dex4'}/></div>);
                                            }
                                            if (index === 20) {
                                                return isMobile ? (<div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_mex5'}/></div>) : (
                                                    <div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_dex5'}/></div>);
                                            }
                                            if (index === 30) {
                                                return isMobile ? (<div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_mex6'}/></div>) : (
                                                    <div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_dex6'}/></div>);
                                            }
                                            if (index === 40) {
                                                return isMobile ? (<div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_mex7'}/></div>) : (
                                                    <div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_dex7'}/></div>);
                                            }
                                            if (index === 50) {
                                                return isMobile ? (<div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_mex8'}/></div>) : (
                                                    <div className={`col-12 mt-3 mb-3`}><Billboard id={'snack_dex8'}/></div>);
                                            }
                                            if (index % 5 === 0) {
                                                return (
                                                    <NewsCard isNewsPage={true} classProps={'mt-4 col-12'} newsItem={news}/>
                                                );
                                            } else {
                                                return (
                                                    <NewsCard isNewsPage={false} classProps={'mt-4 col-6'} newsItem={news}/>
                                                );
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            {!isMobile && <div
                                className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                                {socialUpdate && <Social isNewsPage={true} queryParams={queryParams.search} setLoading={setLoading}/>}
                                <div
                                    className={'double-mpu d-flex justify-content-center flex-column align-items-center h1 text-center'}>
                                    <DMPU id={'snack_dex10'}/>
                                </div>
                                {
                                    target !== 'fanzinesports' ? <Gossip isNewsPage={true}/> : null
                                }
                                <div
                                    className={'sticky-mpu d-flex justify-content-center flex-column align-items-center h1 text-center sticky-top'}>
                                    <DMPU id={'snack_dmpu'}/>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    return render();
}

NewsPage.propTypes = {
    snack: PropTypes.bool,
};

export default NewsPage;
