import React from "react";
import Card from "react-bootstrap/Card";
import './styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

class ShopCategoryCard extends React.Component {

    render() {

        let {name, src, link, mainCategory} = this.props;
        let imageStyle = {};
        if (mainCategory) {
            imageStyle = {
                height: "174.2px",
                objectFit: "cover",
            }
        }

        return (
            <div className={`${mainCategory && mainCategory ? "col" : "col-6"} rounded-custom mt-3`}>
                <NavLink to={`/shop/${link}`} className={'text-decoration-none text-dark'}>
                    <Card className="border-0 bg-transparent m-1">
                        <Card.Img
                            variant="top"
                            className={'rounded-custom img-fluid'}
                            src={src}
                            alt="Card image"
                            // style={imageStyle}
                        />
                        <Card.ImgOverlay className={`d-flex align-items-start flex-column justify-content-end ${mainCategory ? "shop-category-overlay" : "shop-category-overlay"} rounded-custom`}>
                            <Card.Text className={`text-white sfProDisplayFont h1`}>
                                <span className={'bebasFont'}>
                                    {name}
                                </span>
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </NavLink>
            </div>
        );
    }
}

ShopCategoryCard.propTypes = {
    name: PropTypes.string.isRequired, src: PropTypes.string.isRequired,
}

export default ShopCategoryCard;
