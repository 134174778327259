import {useEffect, useState} from "react";
import {MATCH_STATES} from "../../../../Configs/Constants";
import SingleFootballMatchPreviewMain from "./SingleFootballMatchContent/SingleFootballMatchPreviewMain";
import SingleFootballMatchOverviewMain from "./SingleFootballMatchContent/SingleFootballMatchOverviewMain";


const SingleFootballMatchContent = (props) => {

    const {matchData,isMobile, singleMatchData} = props;
    const [isMatchLive, setIsMatchLive] = useState(false);
    // const [currentActive, setCurrentActive] = useState(queryParams.pathname.split('/')[4] || 'preview')


    let match;
    if(matchData)
    {
        match = matchData?.match;
    }

    const SetIsMatchLiveStatus = ()=>
    {
        if(MATCH_STATES.FINISHED.includes(match?.state) || MATCH_STATES.IN_PLAY.includes(match?.state))
        {
            setIsMatchLive(true);
        }
        else
        {
            setIsMatchLive(false);
        }
    }

    useEffect(()=>{
        SetIsMatchLiveStatus()
    },[matchData])

    return (
        <div
        >
            {
                isMatchLive ? <SingleFootballMatchOverviewMain singleMatchData={singleMatchData} /> : <SingleFootballMatchPreviewMain singleMatchData={singleMatchData} />
            }
        </div>
    );
}

export default SingleFootballMatchContent;