import React, {useEffect, useState} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/styles/sliderDots.css';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {COLORS, NAV_CLASS, TEXT_COLOR} from "../../Configs/Targets";
import {getHostname} from "../../CustomFunctions/getHostname";
import {SCREEN_SIZE} from "../../Configs/Constants";
import defaultImage from "../../assets/images/Default.jpeg";

const ImageCarousel = (props) => {

    let [images, setImages] = useState([]);
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [currentSlideIndex, setCurrentSlideIndex] = useState([]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let textColor = TEXT_COLOR[target];
    let isMobile = false;
    if (screenWidth <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }
    let imageStyle = {
        width: '100%',
        height: "500px",
        objectFit: 'cover'
    };
    if (isMobile) {
        imageStyle = {
            width: '100%',
            height: "263.1px",
            objectFit: 'cover'
        }
    }
    if (screenWidth >= SCREEN_SIZE.LAPTOP_LG) {
        imageStyle = {
            width: '100%',
            height: "600px",
            objectFit: 'cover'
        }
    }

    const settings = {
        infinite: true,
        dots: true,
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: dots => {
            return <ul>{dots}</ul>;
        },
        beforeChange: (prev, next) => {   // here to detect slide change
            setCurrentSlideIndex(next);
        },
        customPaging: (page, i) => {
            const style = {
                width: '1rem',
                height: '1rem',
                borderRadius: '100%',
                display: "inline-block",
                backgroundColor: '#bdbdbd', // need to change as navigator-active.png this when active
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat"
            };
            const activeStyle = {
                width: '1rem',
                height: '1rem',
                borderRadius: '100%',
                display: "inline-block",
                backgroundColor: COLORS[target],
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat"
            };
            return (
                <a
                    className={`slick-dot ${page === currentSlideIndex ? 'active' : ''}`}
                    style={page === currentSlideIndex ? activeStyle : style}
                />
            );
        }
    };


    const handleClick = (id, showNews, showProduct, url, isSnack=false) => {
        console.clear();
        if (showNews) {
            if (isSnack) {
                props.setShouldNavigate('/news/' + id);
            }
            else {
                window.open(url, '_blank');
            }
        }
        if (showProduct) {
            props.setShouldNavigate('/shop/product/' + id);
        }
    }
    const render = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let {showTitle, showLabel, showNews, shopMobile, showProduct} = props;
        let btnBgColor = NAV_CLASS[target];
        return (
            <div className="image-carousel">
                <Slider {...settings}>
                    {props.images.map((item) => (
                        <>
                            <div key={item.id} className={'slide'}>
                                <img src={item.src} alt={item.alt}
                                     style={imageStyle}
                                     className={`rounded-custom-1`}
                                     onError={({ currentTarget }) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src=defaultImage;
                                     }}/>
                                <div className={`card-img-overlay news-overlay rounded-custom`}
                                     onClick={() => (handleClick(item.slug, showNews, showProduct, item.news_url, item.is_snack))}>
                                    {showTitle && !isMobile && <h2 className="slick-slide-title">
                                        <div className="d-grid gap-2 w-25">
                                            <NavLink to={`/shop/product/${item.slug}`}
                                                     className={`btn ${btnBgColor} btn-xl text-white rounded-pill h1 bebasFont`}>
                                                {item.title}
                                            </NavLink>
                                        </div>
                                    </h2>}
                                    {showLabel &&
                                        (<>
                                            {
                                                isMobile ?
                                                    (<div className={`slick-slide-news text-news-carousel text-white bebasFont ${shopMobile ? "mb-5" : ""}`}>
                                                        {item.title}
                                                    </div>) : null
                                            }
                                            <div className="slick-slide-label h1">
                                                <div className={`d-grid ${shopMobile ? "w-35 mt-5" : "w-25 float-end"}`}>
                                                    <NavLink to={`/shop/product/${item.slug}`}
                                                             className={`btn ${btnBgColor} text-white rounded-pill h1 ${shopMobile ? "sfProDisplayFontBold font-13 p-2" : "bebasFont btn-xl"}`}>
                                                        {item.buttonText}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </>)
                                    }
                                    {showNews &&
                                        <div className={`slick-slide-news text-news-carousel text-white bebasFont ${shopMobile ? "mb-5" : ""}`}
                                             dangerouslySetInnerHTML={{__html: item.news}}/>}
                                </div>
                            </div>
                            <div className={`row mt-3`}>
                                <div className={`col-6 d-flex justify-content-start`}>
                                    {item.news_publisher &&
                                        <>
                                            <img src={item.news_publisher && item.news_publisher.publisher_icon}
                                                 alt={item.news_publisher && item.news_publisher.publisher_name}
                                                 height="30"/>
                                            <span
                                                className={'align-items-center d-inline-flex me-2 ms-2 sfProDisplayFont'} dangerouslySetInnerHTML={{__html: item.news_publisher && item.news_publisher.publisher_name}} />
                                        </>
                                    }

                                </div>
                                <div
                                    className={`col-6 d-flex justify-content-end ${textColor} font-weight-800 font-16`}>{item.news_publisher && item.news_time.replace("minutes", "mins").replace("ago", "").replace("an", "1")}</div>
                            </div>
                        </>
                    ))}
                </Slider>

            </div>
        );
    }
    return render();

}

ImageCarousel.propTypes = {
    showTitle: PropTypes.bool,
    showLabel: PropTypes.bool,
    showNews: PropTypes.bool,
};

export default ImageCarousel
