import React, {useEffect, useState} from "react";
import NewsCard from "../Cards/NewsCard";
import {SCREEN_SIZE} from "../../Configs/Constants";
import PropTypes from "prop-types";
import {getHostname} from "../../CustomFunctions/getHostname";
import {LOADING_GIF} from "../../Configs/Targets";

const News = (props) => {
    let [width, setWidth] = useState(0);
    let [height, setHeight] = useState(0);
    let [moreLoading, setMoreLoading] = useState(false);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let loadingGif = LOADING_GIF[target];
    let isNewsPage = props.isNewsPage;

    useEffect(() => {
        setMoreLoading(false);
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        }
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const handleScrollEnd = (event) => {
        const end = event.target.scrollWidth - event.target.scrollLeft === event.target.clientWidth;
        if (end) {
            setMoreLoading(true);
            props.mobileMoreNews(true);
        }
    }

    const renderMobile = (moreStories) => {
        let container = moreStories ? 'row' : 'slider-mobile-container';
        return (
            <>
                <div className={` ${container}`} onScroll={handleScrollEnd}>
                    {props.news.map((item) => (
                        <NewsCard isMobile={true} newsItem={item} moreStories={moreStories} newsId={2} isNewsPage={isNewsPage}/>
                    ))}
                    {!moreStories && moreLoading && <div className={`slider-mobile-card d-flex flex-column justify-content-center align-middle text-center`} style={{height: '164px'}}>
                        <img src={loadingGif}
                             className={`align-self-center`}
                             alt="loader" width="50"/>
                    </div>}
                </div>
            </>
        );
    }

    const render = () => {
        let screenWidth = width;
        let moreStories = props.moreStories;
        if (screenWidth <= SCREEN_SIZE.MOBILE) {
            return renderMobile(moreStories);
        }

        return (
            <div className={'row'}>
                {props.news.map((item) => (
                    <NewsCard isNewDesign={true} newsItem={item} newsId={1} classProps={'col-6'} isNewsPage={isNewsPage}/>
                ))}
            </div>
        );
    }

    return render();

}

News.propTypes = {
    moreStories: PropTypes.bool,
    news: PropTypes.array.isRequired,
}

export default News;
