import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {get} from '../Axios/get';
import {FANZINE, NewsEndPoint} from '../Axios/EndPoints';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {SCREEN_SIZE} from '../Configs/Constants';
import Loader from '../Components/Loader/Loader';
import '../assets/styles/customContainer.css';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {SiteMetaHelmet} from '../Components/SiteMetaHelmet';
import {SubNavbar} from './Components/SportSpecific/Football/SubNavbar';
import {useSelector} from 'react-redux';
import {GiSpeaker} from 'react-icons/gi';
import Social from '../Components/Social/Social';
import Matches from './Components/SportSpecific/Football/Matches';
import CalendarContainer from '../Components/Calendar/CalendarContainer';
import {LeftbarLeagues,} from './Components/SportSpecific/Football/LeftbarLeagues';
import {getCountryForTimezone} from 'countries-and-timezones';
import axios from 'axios';
import RightbarNews from './Components/SportSpecific/Football/RightbarNews';
import GoogleAd from '../Components/SnackAds/GoogleAd';

const FanzineAllFootballMatches = (props) => {
    let params = useParams();
    let store = useSelector((state) => state);
    // console.log("store in all football =", store);
    let queryParams = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    let [apiPage, setApiPage] = useState(1);
    let [restrictApiCall, setRestrictApiCall] = useState(true);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    let [matchData, setMatchData] = useState(null);
    let [matchDataLoading, setMatchDataLoading] = useState(true);
    let [latestNews, setLatestNews] = useState([]);
    let [footballLeagues, setFootballLeagues] = useState(null);
    let [selectedLeague, setSelectedLeague] = useState(0);
    let [selectedDate, setSelectedDate] = useState(
        moment(Date.now()).format('YYYY-MM-DD'));
    let [selectedDatePicker, setSelectedDatePicker] = useState();
    let [liveMatchData, setLiveMatchData] = useState(null);
    let [isSingleMatchSelected, setIsSingleMatchSelected] = useState(false);
    let [singleMatchData, setSingleMatchData] = useState(null);
    let [filterByLeagueId, setFilterByLeagueId] = useState(null);
    let [isFanzineAllFootballMatches, setIsFanzineAllFootballMatches] = useState(
        true);
    let [leagueMatchesGroupedByDate, setLeagueMatchesGroupedByDate] = useState(
        null);
    let [selectedLeagueDate, setSelectedLeagueData] = useState(null);
    let [isLeagueSelected, setIsLeagueSelected] = useState(false);

    const handleScroll = (e) => {
        const pageBottom =
            document.body.scrollHeight - document.documentElement.clientHeight -
            10 <=
            document.documentElement.scrollTop;
        if (pageBottom && document.body.scrollHeight > 1000) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = '?main_site=1&snack=1';
        }

        let page = `&page=${apiPage}`;
        let limit = `&limit=48`;

        get(NewsEndPoint + searchParams + limit + page).then((responseNews) => {
                let tempNewsData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });
                if (isNew) {
                    setLatestNews(tempNewsData);
                } else {
                    setLatestNews([...latestNews, ...tempNewsData]);
                }
                setLoading(false);
            },
        ).catch((error) => console.log(error));
    };

    const fetchFixtures = () => {
        let params = queryParams.search;
        if (!params) {
            params = `?date=${selectedDate ||
            moment(Date.now()).format('YYYY-MM-DD')}`;
        }
        let timezone = getCountryForTimezone(
            Intl.DateTimeFormat().resolvedOptions().timeZone);
        params = params + '&timezone=utc';

        let url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches' +
            params;

        axios.get(url).then((response) => {
            // eslint-disable-next-line array-callback-return
            let tempData = response.data;
            console.log('tempdata=', tempData);
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i].new_web_order == 0) {
                    tempData[i].new_web_order = '100'
                }
                if (tempData[i].league_id == 0) {
                    tempData[i].new_web_order = '1000'
                }

            }
            tempData.sort(function (a, b) {
                return a.new_web_order - b.new_web_order;
            });
            tempData.pop()
            console.log('sorted tempdata=', tempData);
            setMatchData(response?.data);
            setMatchDataLoading(false);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const selectedDay = (e) => {
        let tmpDate = moment(e).format('YYYY-MM-DD');
        setSelectedDate(tmpDate);
        setSelectedDatePicker(e);
        console.log('tmpdate =', tmpDate);
    };

    const fetchLiveMatches = () => {
        let params = queryParams.search;
        if (!params) {
            params = `?date=${selectedDate ||
            moment(Date.now()).format('YYYY-MM-DD')}`;
        }

        let url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches/live' +
            params;
        axios.get(url).then((response) => {
            setLiveMatchData(response?.data);
            setMatchDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLeaguesMatchesByDate = () => {
        let params = searchParams.get('league_id');

        if (params) {
            let timezone = getCountryForTimezone(
                Intl.DateTimeFormat().resolvedOptions().timeZone);
            params = params + '/matchesByDate?limit=1000&timezone=utc';
            // + timezone.timezones[0]

            get(FANZINE.MatchesByDateEndPoint + params).then((response) => {
                setLeagueMatchesGroupedByDate(response.data.matches);
                setIsLeagueSelected(true);
                setLoading(false);

            }).catch((err) => {
                console.error(err);
            });
        }

    };

    useEffect(() => {
        setSelectedDate(
            searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD'));
        console.log('searchParams.get(\'date\') =',
            searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD') +
            '  today');
        setLoading(true);
        setRestrictApiCall(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        fetchNews(true);
        searchParams.get('league_id') == null && fetchFixtures();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [queryParams.search, selectedLeague, searchParams]);

    useEffect(() => {
        setFootballLeagues(store?.football?.fanzineLeagues);
    }, [store?.football?.fanzineLeagues]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    useEffect(() => {
        fetchLeaguesMatchesByDate();
        // setIsLeagueSelected(true);
    }, [searchParams.get('league_id')]);

    useEffect(() => {
    }, [isLeagueSelected]);

    // fetching Live Matches After 10s
    useEffect(() => {
        let isParamDate = !!searchParams.get('date');

        let checkDateWith = isParamDate ? searchParams.get('date') : selectedDate;

        if (checkDateWith == moment(Date.now()).format('YYYY-MM-DD')) {
            console.log('today');
            const interval = setInterval(() => {
                fetchLiveMatches();
            }, 30000);

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
    }, [isLeagueSelected]);

    const renderLeftContent = () => {
        return (
            <div
                className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
                style={{
                    marginLeft: 'auto',
                }}
            >
                <LeftbarLeagues setSelectedLeagueData={setSelectedLeagueData}
                                setFilterByLeagueId={setFilterByLeagueId}
                                isFanzineAllFootballMatches={isFanzineAllFootballMatches}
                                footballLeagues={footballLeagues}/>
                <div
                    className={`bg-white pt-2 mt-xxl-12 mt-xl-12 mt-lg-12 mt-md-12 mt-sm-1 mt-2 mb-5 rounded-custom`}
                >
                    <div className={'customContainer'}>
                        <div className="row">
                            <span className={'mt-lg-2'}>
                                <h5 className={'sfProDisplayFontBold font-16 d-flex align-items-center'}>
                                    <GiSpeaker size={30}
                                               color={'green'}/> &nbsp;
                                    Social Media
                                </h5>
                            </span>
                            {window.innerWidth > SCREEN_SIZE.MOBILE ? (
                                <Social
                                    isFanzine={true}
                                    isNewsPage={true}
                                    setLoading={setLoading}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNews = (news, sport, isMobile) => {

        let classNameCenter = `col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 ${isMobile ?
            'bg-fanzine-dark' :
            ' border-radius-15px'} h-100`;

        return (
            <div className="bg-background overflow-hidden">
                <div className={`${!isMobile ? 'container' : 'w-100'}`}>
                    <div
                        className={`${!isMobile ? 'row' : 'mb-5'}`}
                        style={{marginLeft: '0%', marginRight: '0%'}}
                    >
                        {/* left content */}
                        {/*new design*/}
                        {/*{!isMobile && renderLeftContent()}*/}
                        {/* Center Content */}
                        <Container
                            className={classNameCenter}
                            style={{
                                // marginLeft: "auto",
                                // marginRight: "auto",
                                // borderRadius: "0",
                            }}
                        >
                            <div className={`${isMobile ? '' : 'mb-5'}`}>
                                <div className={'customContainer'}>
                                    <div className="row">
                                        {/*<h5 className={'mt-lg-2 d-none d-md-block d-lg-block d-xl-block d-xxl-block'}>*/}
                                        {/*    <span className={'d-flex align-items-center sfProDisplayFontBold font-16'}>*/}
                                        {/*        <img src={Soccer_Field} alt={'soccer_field'} height={20}/>*/}
                                        {/*        &nbsp;*/}
                                        {/*        Fixtures*/}
                                        {/*    </span>*/}
                                        {/*</h5>*/}
                                        {leagueMatchesGroupedByDate == null &&
                                            <CalendarContainer selectedDay={selectedDay}
                                                               setSelectedDate={setSelectedDate}
                                                               selectedDate={selectedDate}
                                                               isMobile={isMobile}
                                            />
                                        }
                                        <Matches
                                            isMobile={isMobile}
                                            setSelectedLeagueData={setSelectedLeagueData}
                                            selectedLeagueDate={selectedLeagueDate}
                                            isLeagueSelected={isLeagueSelected}
                                            setIsLeagueSelected={setIsLeagueSelected}
                                            setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                                            setIsSingleMatchSelected={setIsSingleMatchSelected}
                                            leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                            setSingleMatchData={setSingleMatchData}
                                            selectedDate={selectedDate}
                                            liveMatchData={liveMatchData}
                                            matchData={matchData}
                                            store={store}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Container>
                        {/* Right content */}
                        {
                            !isMobile &&
                            <Container
                                className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                                style={{
                                    // marginLeft: "auto",
                                }}
                            >
                                <RightbarNews isFanzine={isFanzineAllFootballMatches}
                                              isMain={true} isMobile={isMobile}/>
                            </Container>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <>
                <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
                <Loader loading={loading}/>
                <SubNavbar isMobile={isMobile}/>
                <div className="d-flex justify-content-center align-items-center h1">
                    {!isMobile && <GoogleAd id={'3349434796'}/>}
                    {/*{isMobile ? <Leaderboard id={'snack_mob_top'}/> : <Billboard id={'snack_dex3'}/>}*/}
                </div>
                {renderNews(latestNews, 'Football', isMobile)}
                {/*{isSingleMatchSelected && latestVideos.length && renderSingleMatch(latestVideos, "Football", isMobile)}*/}

            </>
        );
    };

    return render();
};

FanzineAllFootballMatches.propTypes = {
    snack: PropTypes.bool,
};

export default FanzineAllFootballMatches;
