import PropTypes from "prop-types";
import {MATCH_STATES} from "../../../Configs/Constants";
import moment from "moment";
import {getHostname} from "../../../CustomFunctions/getHostname";
import {COLORS_RGBA} from "../../../Configs/Targets";

const FootballMatchCard = (props) => {

    const renderMobile = () => {
        let match = props.match;
        let totalBg = '';
        if (MATCH_STATES.FINISHED.includes(match.state)) {
            totalBg = 'bg-dark-green';
        } else if (MATCH_STATES.IN_PLAY.includes(match.state)) {
            totalBg = 'bg-yellow text-black';
        } else {
            totalBg = 'border';
        }
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const colorRgba = COLORS_RGBA[target];
        let cardBackgroundStyle = {
            background: `linear-gradient(to bottom, ${colorRgba}0.7) 0%, ${colorRgba}0.7)), url(${match.venue.stadium_image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionY: 'center',
        };
        return (
            <div className={`match-card bg-dark`} style={cardBackgroundStyle}>
                {MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-12 float-end`}>
                    <div className={`text-small bebasFont float-end bg-dark-green ps-3 pe-3 pt-1`}>FT</div>
                </div> : <div className={`col-12 float-end ps-3 pe-3 pt-1 mt-4`}></div>}
                <div className={`col-12 float-end`}>
                    {
                        MATCH_STATES.IN_PLAY.includes(match.state) ?
                            <div className={`text-center match-date sfProDisplayFont mb-0`}>
                                <span className={`text-yellow`}>LIVE:</span> {match.timer}
                            </div>
                            :
                            <p className={`text-center match-date sfProDisplayFont mb-0 font-12`}>{moment(match.date).format('ddd D MMMM Y')}</p>
                    }
                </div>
                <div className={`row text-center pt-3`}>
                    <div className={`col-4 text-center font-12`}>
                        <img src={match.teams.home.icon} alt={match.teams.home.name} height={50}/>
                        <br/>
                        {match.teams.home.name}
                    </div>
                    <div className={`col-4 text-center ${totalBg} font-20 d-flex flex-column justify-content-center`}>
                        {MATCH_STATES.UPCOMING.includes(match.state) ? match.time : match.total}
                    </div>
                    <div className={`col-4 text-center font-12`}>
                        <img src={match.teams.away.icon} alt={match.teams.away.name} height={50}/>
                        <br/>
                        {match.teams.away.name}
                    </div>
                </div>
                <div className={`col-12 float-end pt-3`}>
                    <p className={`text-center match-date sfProDisplayFont font-12`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    const render = () => {
        let match = props.match;
        let totalBg = '';
        if (MATCH_STATES.FINISHED.includes(match.state)) {
            totalBg = 'bg-dark-green';
        } else if (MATCH_STATES.IN_PLAY.includes(match.state)) {
            totalBg = 'bg-yellow text-black';
        } else {
            totalBg = 'border';
        }
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const colorRgba = COLORS_RGBA[target];
        let cardBackgroundStyle = {
            background: `linear-gradient(to bottom, ${colorRgba}0.7) 0%, ${colorRgba}0.7)), url(${match.venue.stadium_image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionY: 'center',
        };
        return (
            <div className={`match-card bg-dark`} style={cardBackgroundStyle}>
                {MATCH_STATES.FINISHED.includes(match.state) ? <div className={`col-12 float-end`}>
                    <div className={`text-small bebasFont float-end bg-dark-green ps-3 pe-3 pt-1`}>FT</div>
                </div> : <div className={`col-12 float-end ps-3 pe-3 pt-1 mt-4`}></div>}
                <div className={`col-12 float-end`}>
                    {
                        MATCH_STATES.IN_PLAY.includes(match.state) ?
                            <div className={`text-center match-date sfProDisplayFont mb-0`}>
                                <span className={`text-yellow`}>LIVE:</span> {match.timer}
                            </div>
                            :
                            <p className={`text-center match-date sfProDisplayFont mb-0`}>{moment(match.date).format('ddd D MMMM Y')}</p>
                    }
                </div>
                <div className={`row text-center pt-3`}>
                    <div className={`col-4 text-center`}>
                        <img src={match.teams.home.icon} alt={match.teams.home.name} height={50}/>
                        <br/>
                        {match.teams.home.name}
                    </div>
                    <div className={`col-4 text-center ${totalBg} h1 d-flex flex-column justify-content-center`}>
                        {MATCH_STATES.UPCOMING.includes(match.state) ? match.time : match.total}
                    </div>
                    <div className={`col-4 text-center`}>
                        <img src={match.teams.away.icon} alt={match.teams.away.name} height={50}/>
                        <br/>
                        {match.teams.away.name}
                    </div>
                </div>
                <div className={`col-12 float-end pt-3`}>
                    <p className={`text-center match-date bebasFont font-20`}>{match.leagueName}</p>
                </div>
            </div>
        );
    }

    return props.isMobile ? renderMobile() : render();
}

FootballMatchCard.propTypes = {
    match: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
}

export default FootballMatchCard;