import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getHostname} from '../../CustomFunctions/getHostname';
import {COLORS, NAV_CLASS, TEXT_COLOR} from '../../Configs/Targets';

const MobileNewsMenuItem = (props) => {

  let title = props.title;
  let uri = props.uri;
  let inactiveFontColor = typeof props.inactiveFontColor !== 'undefined' ? props.inactiveFontColor : '#707070';

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const navColorHover = NAV_CLASS[target] + '-hover-color';
  let color = COLORS[target] || 'white';
  if (target === 'fanzine') {
    color = '#ffffff';
  }
  const textColor = color;

  const [borderBottomSelected, setBorderBottomSelected] = useState('');
  const [boldSelected, setBoldSelected] = useState('');
  const selected = props.selected;

  const handleClick = () => {
    props?.setCurrentActive(title.toLowerCase());
  };

  useEffect(() => {
    if (selected) {
      setBorderBottomSelected(`5px solid ${color}`);
      setBoldSelected(`bold`);
    } else {
      setBorderBottomSelected('none');
      setBoldSelected(``);
    }
    console.log('queryParams.search:selected', selected);
  }, [selected]);

  const render = () => {
    return (
        <div
            onClick={handleClick}
            className={`slider-mobile-news-menu text-left d-flex flex-column justify-content-center ${props.isMobile ? 'pt-3 mt-4' : ''} ${selected ? textColor : ''}`}
            style={{height: `50px`, borderBottom: borderBottomSelected}}>
          <Link to={uri}
                className={`dropdown-item ${selected ?
                    textColor :
                    ''}  text-decoration-none acuminFont h-100 d-flex flex-column justify-content-center`}>
            <div
                className={`flex-row justify-content-start  align-middle ${selected ?
                    textColor :
                    ''}`}
                style={{
                  fontWeight: boldSelected,
                  color: selected ? textColor : inactiveFontColor,
                }}
            >
              {title}
            </div>
          </Link>
        </div>
    );
  };

  return render();

};

MobileNewsMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  inactiveFontColor: PropTypes.string,
  selected: PropTypes.bool.isRequired,
};

export default MobileNewsMenuItem;
