import React, {useEffect, useRef, useState} from "react";
import Card from "react-bootstrap/Card";
import "../../Components/Cards/styles/NavbarCard.css";
import "../../assets/styles/styles.css";
import "../styles/fanzine.css";
import PropTypes from "prop-types";
import {TEXT_COLOR} from "../../Configs/Targets";
import {NavLink} from "react-router-dom";
import {getHostname} from "../../CustomFunctions/getHostname";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {setSingleVideo} from "../../redux/actions/MiscActions";
import {useDispatch} from "react-redux";

const NewsCard = (props) => {
    let dispatch = useDispatch();
    const videoRef = useRef(null);

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../assets/images/football-shots-you-need-to-know.jpg");
    };

    const addDefaultPublisherIcon = (ev) => {
        ev.target.src = require("../../assets/images/default_publisher_icon.png");
    };

    const newsOverImage = (news, publisherIconSize) => {
        return (<>
            <span
                className={`robotoCondensedBold ${props?.BigImage ? 'font-36' : 'font-18'}`}
                dangerouslySetInnerHTML={{__html: news && news.news_title}}
            />
            <br/>
            {/*<img
                src={news && news.news_publisher.publisher_icon}
                alt={news && news.news_publisher.publisher_name}
                onError={addDefaultPublisherIcon}
                height={publisherIconSize}
            />*/}
            <span
                className={`robotoCondensedBold me-2 ${props?.BigImage ? 'font-18' : 'font-16'} font-fanzine`}
                dangerouslySetInnerHTML={{
                    __html: news && news.news_publisher.publisher_name,
                }}
            />
            <span
                className={`float-end font-12 robotoCondensedBold font-weight-800 text-white ${props?.BigImage ? 'font-18' : 'font-16'} font-fanzine`}
            >
                {news && news.news_time
                    .replace("minutes", "mins")
                    .replace("ago", "")
                    .replace("an", "1")
                    .replace("a day", "1 day")}
            </span>
        </>);
    };

    const newsBelowImage = (news, publisherIconSize) => {
        return (<>
            <div
                className={`robotoCondensedBold font-18 mt-2 news-text`}
                dangerouslySetInnerHTML={{__html: news && news.news_title}}
            />
            <img
                src={news && news.news_publisher.publisher_icon}
                alt={news && news.news_publisher.publisher_name}
                onError={addDefaultPublisherIcon}
                height={publisherIconSize}
            />
            <span
                className={"robotoCondensedBold ms-2 me-2 font-13 text-gray"}
                dangerouslySetInnerHTML={{
                    __html: news && news.news_publisher.publisher_name,
                }}
            />
            <span className={`float-end font-13 robotoCondensedBold text-gray pt-1`}>
                {news && news.news_time
                    .replace("minutes", "mins")
                    .replace("ago", "")
                    .replace("an", "1")
                    .replace("a day", "1 day")}
            </span>
        </>);
    };

    const newsSideImage = (news, publisherIconSize, cardTextSize) => {
        return (<div className={`col-7`}>
            <img
                src={news && news.news_publisher.publisher_icon}
                alt={news && news.news_publisher.publisher_name}
                onError={addDefaultPublisherIcon}
                height={publisherIconSize}
            />
            <span
                className={"robotoCondensedBold ms-2 me-2 font-12 text-gray"}
                dangerouslySetInnerHTML={{
                    __html: news && news.news_publisher.publisher_name,
                }}
            />
            <div
                className={`robotoCondensedBold font-14 mt-2 news-text`}
                dangerouslySetInnerHTML={{__html: news && news.news_title}}
            />
            <span className={`float-start font-12 robotoCondensedBold text-gray pt-1`}>
                {news && news.news_time
                    .replace("minutes", "mins")
                    .replace("ago", "")
                    .replace("an", "1")
                    .replace("a day", "1 day")}
            </span>
        </div>);
    };

    const renderNews = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain) => {
        // originally it was set to 250px
        let height = "245px";

        if (isMain && !isMobile) {
            height = "510px";
        }
        if (props.isFanzine !== undefined) {
            if (props.isFanzine) {
                height = "300px";
            } else {
                height = "245px";
            }
        }

        if(props.BigImage)
        {
            if(props?.isNewDesign)
            {
                height = "500px";
                // 399px for small
            }
        }

        if(props?.isLatestNews)
        {
            height = "277px"
        }

        if(props?.isFanzineNews)
        {
            height = '200px'
        }


        return (<>
            <Card
                className="col border-0 bg-transparent"
                style={{height: height}}
            >
                <Card.Img
                    variant="top"
                    className={'rounded-0'}
                    height={height}
                    src={news && news.news_image}
                    alt={news && news.news_title}
                    onError={addDefaultSrc}
                    style={{
                        objectFit: "cover", borderRadius: !props.isNewDesign && "15px",
                        width: props?.isFanzineNews && 'auto',
                    }}
                    // style={mainImageStyle}
                />
                <Card.ImgOverlay
                    className={`${!props?.isNewDesign ? 'rounded-custom' : 'rounded-0'} d-flex align-items-start flex-column justify-content-end ${news.type === "videos" ? "video-overlay" : "news-overlay"}`}
                >
                    <Card.Text
                        className={`text-white robotoCondensedBold text-wrap ${props?.isFanzineNews ? 'font-10' :  cardTextSize} w-100`}
                    >
                        {isNewsPage ? null : newsOverImage(news, publisherIconSize)}
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
            {isNewsPage ? newsBelowImage(news, publisherIconSize) : null}
        </>);
    };

    const renderNewsMobile = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize) => {
        return (<
            div className={`row`}>
            <div className={`col-5`}>
                <img
                    height={"100"}
                    width={"100%"}
                    src={news && news.news_image}
                    alt={news && news.news_title}
                    onError={addDefaultSrc}
                    className={`rounded-custom`}
                    style={{
                        objectFit: "cover",
                    }}
                />
            </div>
            <div className={`col-7`}>
                <div>
                    <img
                        src={news && news.news_publisher.publisher_icon}
                        alt={news && news.news_publisher.publisher_name}
                        height={publisherIconSize}
                    />
                    <span
                        className={"robotoCondensedBold ms-2 me-2 font-9 text-light-gray"}
                        dangerouslySetInnerHTML={{
                            __html: news && news.news_publisher.publisher_name,
                        }}
                    />
                </div>
                <div>
                    <span
                        className={`robotoCondensedBold font-16`}
                        dangerouslySetInnerHTML={{
                            __html: news && news.news_title.slice(0, 43) + "...",
                        }}
                    />
                </div>
                <span
                    className={`mt-2 font-9 robotoCondensedBold font-weight-800 text-light-gray`}
                >
                    {news && news.news_time
                        .replace("minutes", "mins")
                        .replace("ago", "")
                        .replace("an", "1")
                        .replace("a day", "1 day")}
                </span>
            </div>
        </div>);
    };

    const renderVideoMobile = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain) => {
        return (<div className={`row`}>
            <Card
                className={`col-5 border-0 p-0 ${!props?.isNewDesign && 'rounded-custom'}`}
            >
                <Card.Img
                    variant="top"
                    className={'img-fluid rounded-custom'}
                    src={news && news.news_image}
                    alt={news && news.news_title}
                    onError={addDefaultSrc}
                />
                <Card.ImgOverlay
                    className={`rounded-custom d-flex align-items-start flex-column justify-content-end ${news.type === "videos" ? "video-overlay" : "news-overlay"}`}
                >
                    <Card.Text
                        className={`text-white robotoCondensedBold ${cardTextSize} w-100`}
                    >
                        {isNewsPage ? null : newsOverImage(news, publisherIconSize)}
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
            {isNewsPage ? newsSideImage(news, publisherIconSize) : null}
        </div>);
    };

    const renderSnack = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain) => {
        let link = "";
        if (typeof news.main_team !== "undefined" && news.main_team) {
            link = `/${props.sport}/${news.main_team}/${news.slug}`;
        } else {
            link = `/${props.sport}/${news.slug}`;
        }
        return (<NavLink to={link} className={"text-decoration-none text-dark"}>
            {isMobile ? renderNewsMobile(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain) : renderNews(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain)}
        </NavLink>);
    };

    const renderNewsNow = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain) => {
        return (<a
            href={news.news_url}
            className={"text-decoration-none text-dark"}
            target="_blank"
            rel="noreferrer"
        >
            {isMobile ? renderNewsMobile(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain) : renderNews(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain)}
        </a>);
    };

    const renderVideo = (news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain) => {
        return (<div
            onClick={() => {
                dispatch(setSingleVideo(news));
                videoRef.current.click();
            }}
            className={"text-decoration-none text-dark"}
            style={{cursor: "pointer"}}
        >
            <NavLink
                to={`/${props.sport}/video/${news.slug}`}
                className={"d-none"}
                ref={videoRef}
            >
                &nbsp;
            </NavLink>
            {isMobile ? renderVideoMobile(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain) : renderNews(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMain)}
        </div>);
    };

    const render = () => {
        let isNewsPage = typeof props.isNewsPage !== "undefined" && props.isNewsPage;
        let isSingleNewsPage = typeof props.isSingleNewsPage !== "undefined" && props.isSingleNewsPage;
        let cardTextSize = isNewsPage ? "h1" : "small";
        let publisherIconSize = isNewsPage ? 25 : 10;
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const textColor = TEXT_COLOR[target];
        let extraClass = typeof props.classProps !== "undefined" ? props.classProps : "";
        let news = props.newsItem;
        let mainImageStyle = {};
        let isMobile = props.isMobile;
        let isMain = props.isMain;
        if (isSingleNewsPage) {
            mainImageStyle = {height: "200px", width:  "auto", objectFit: "cover"};
        }
        if (!news) {
            return null;
        }

        if (news.type === "videos") {
            return (<div className={`col ${extraClass}`}>
                {renderVideo(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain)}
            </div>);
        }

        return (<div className={`col ${extraClass}`}>
            {news && news.is_snack ? renderSnack(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain) : renderNewsNow(news, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize, isMobile, textColor, isMain)}
        </div>);
    };

    return render();
};

NewsCard.propTypes = {
    isNewDesign: PropTypes.bool,
    isNewsPage: PropTypes.bool,
    moreStories: PropTypes.bool,
    isMobile: PropTypes.bool,
    isMain: PropTypes.bool,
    classProps: PropTypes.string,
    sport: PropTypes.string,
    newsItem: PropTypes.object.isRequired,
};

export default NewsCard;
