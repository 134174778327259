import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";

export const NBA = () => {
    return (
        <div className={`container mt-5`}>
            <SiteMetaHelmet sport={'NBA'} urlEndpoint={'nba'}/>
            <h1 className={`bebasFont`}>
                Latest NBA News Headlines and US Basketball Headlines
            </h1>
            <div className={`sfProDisplayFont mt-3`}>
                Start your day by catching up on the latest NBA news headlines with Fanzine. We bring you the biggest stories, the latest rumours, and the freshest NBA articles about the teams and
                players that you love. From the Lakers and Cavaliers to the Knicks and the Warriors, we bring you the US basketball headlines that you want to read.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                From exclusive headlines and top-quality original articles to the biggest news from the teams and players you love, at Fanzine we help you keep up to date with the teams you love.
                Whether you’re a Kevin Durant fan, think Steph Curry is the greatest, or are a Ben Simmons supporter, we keep you up to date on the latest NBA news as it happens.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                While you might not be able to watch all the live games in the UK you can keep up to date on the gossip, news, and rumours from the NBA by reading the latest headlines right here at
                Fanzine.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Our writers love delivering their views on what’s happening in the game we love. In short, we strive
                to provide original nba journalism our readers love and give an up-to-date, comprehensive
                understanding of what is happening in the sport you love and issues that you care about.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                US basketball news
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Want to know what’s happening in the NBA? At Fanzine we’ve got our fingers on the pulse so we can bring you the latest news stories as soon as they break. From the biggest scoops in
                the Western Conference to the latest transfer updates in the Eastern Conference, we help you keep up with the biggest US basketball news and headlines.
            </div>
            <div className={`sfProDisplayFont mt-3 mb-5`}>
                Our NBA news feed is updated 24/7 bringing you the biggest NBA news headlines as and when they happen. We understand how hard it can be in the UK to find out what’s happening in US
                basketball so here at Fanzine we are on hand to help you stay in the loop. Check out the latest basketball headlines below.
            </div>
        </div>
    )
}
