import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";


const SingleMatchLeagueHeading = (props) =>
{
    const {addDefaultSrc,matchData,isMobile} = props
    let  match

    if(matchData)
    {
        match = matchData.match
    }


    return(
        <div className={"d-flex"}>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={`col-lg-1 col-md-1 col-xs-1 ${isMobile && 'col-2'}`}>
                <img
                    height={'auto'}
                    width={30}
                    alt={'League Icon'}
                    src={match?.league_icon}
                    onError={addDefaultSrc}
                ></img>
            </div>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={`col-lg-5 col-md-5 col-xs-5 ${ isMobile && 'col text-white'} p-2 sfProDisplayFontBold`}>
                {match?.league_name}
            </div>
        </div>
    )

}

export default SingleMatchLeagueHeading;