import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {get} from '../Axios/get';
import {FANZINE, HomepageStandings, HomePageVideoEndPoint, NewsEndPoint} from '../Axios/EndPoints';
import ItemLoader from '../Components/Loader/ItemLoader';
import {getHostname} from '../CustomFunctions/getHostname';
import {COLORS, COLORS_RGBA, FONT_COLOR, NAV_CLASS, TEXT_COLOR,} from '../Configs/Targets';
import {SubNavbar} from './Components/SportSpecific/Football/SubNavbar';
import {Dropdown} from 'react-bootstrap';
import GoogleAd from '../Components/SnackAds/GoogleAd';
import moment from 'moment/moment';
import {chunk} from '../Components/Helper/chunk';
import News from '../Components/Homepage/News';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {SCREEN_SIZE} from "../Configs/Constants";
import axios from "axios";
import NewsCard from "./Components/NewsCard";

const FanzineAllFootballTable = (props) => {
    const {singleMatchData} = props;

    const location = useLocation()
    const navigate = useNavigate()

    let [searchParams, setSearchParams] = useSearchParams();
    const [fullTable, setFullTable] = useState(false);
    const [standings, setStanding] = useState(null);
    const [moreStandings, setMoreStanding] = useState(null);
    const [isGroup, setIsGroup] = useState(false);
    const [selectLeagueData, setSelectLeagueData] = useState(null);
    const [selectedLeague, setSelectedLeague] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [youMightLike, setYouMightLike] = useState([]);
    let [isMobile, setIsMobile] = useState(false);

    useEffect(() => {

        let tempSelectedLeague = leagues.find((league) => {
            return league.league_id == searchParams.get('league_id');
        });
        setSelectedLeague(tempSelectedLeague);

        fetchStandingsData();
    }, [searchParams.get('league_id')]);

    useEffect(() => {
        fetchFanzineFootballLeague();
        fetchYouMightLike();
    }, []);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const fetchFanzineFootballLeague = async () => {
        await get(FANZINE.LeaguesEndPoint + '?web=1').then((response) => {
            let responseLeagues = response?.data?.leagues;
            setLeagues(responseLeagues);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchStandingsData = () => {

        let params = '?league_id='

        if (searchParams.get('league_id')) {
            params += searchParams.get('league_id')
            for (let i = 0; i < leagues; i++) {
                if (params == leagues[i].league_id) {
                    setSelectedLeague(leagues[i])
                }
            }
        } else {
            params += (selectedLeague?.league_id || '1204')
        }

        get(HomepageStandings + params).then((res) => {
            setStanding(res.data.standings);
            setSelectLeagueData(res.data);
            setLoading(false);
        }).catch(error => console.error('error =', error));
    };
    const fetchYouMightLike = (isNew) => {
        let searchParams = "?main_site=1&snack=1";
        let apiPage = 1;
        let page = `&page=${apiPage}`;
        let limit = `&limit=20`;

        let newsRes = get(NewsEndPoint + searchParams + limit + page);
        let videosRes = get(HomePageVideoEndPoint + searchParams + limit + page);

        axios.all([newsRes, videosRes])
            .then(axios.spread((responseNews, responseVideos) => {
                let tempNewsData = [];
                let tempVideosData = [];
                let tempData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });
                responseVideos.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.id,
                        news_image: data.preview_image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.published_at.date, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.published_at.date).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'videos',
                    };
                    tempVideosData.push(news);
                });

                for (let i = 0; i < tempNewsData.length; i++) {
                    let ctr = i + 1;
                    if (ctr % 2 === 0) {
                        tempData.push(tempVideosData[i]);
                    } else {
                        tempData.push(tempNewsData[i]);
                    }
                }

                if (isNew) {
                    setYouMightLike(tempData);
                } else {
                    setYouMightLike([...youMightLike, ...tempData]);
                }
                setLoading(false);
            }))
            .catch(error => console.log(error));
    }

    function renderYouMightLike() {
        return (
            <div className="row">
                <span
                    className={'bebasFont font-weight-bold h3'}>You Might Like</span>
                {
                    youMightLike.length ?
                        (
                            chunk(youMightLike, 2).map((value, index) => (
                                <div className={'col-12'}>
                                    <News
                                        isNewDesign={true}
                                        moreStories={true}
                                        news={value}
                                        isSingleNewsPage={true}
                                    />
                                    {
                                        (index + 1) % 2 === 0 && (
                                            <div
                                                style={{
                                                    border: '1px black solid',
                                                }}
                                                className="d-flex justify-content-center align-items-center h1 mt-3">
                                                <GoogleAd id={'4009154580'}/>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        )
                        : null
                }

                <div
                    style={{
                        border: '1px black solid',
                        height: '200px'
                    }}
                    className="d-flex justify-content-center align-items-center h1 mt-3">
                    <GoogleAd id={'4009154580'}/>
                </div>
            </div>
        );
    }

    const renderLeagueSelectDropDown = () => {

        const handleLeagueSelect = (e) => {
            const to = {pathname: location.pathname, search: 'league_id=' + e};
            navigate(to, {replace: true});

            setLoading(true);
        };

        let src = 'https://cdn.fanzine.com/img/leagues/icons_new/3dRFqBUtMN5C2JfH2tUI3yt9XqFBCDEiCndE4kPQ.png';

        // let tmp;
        // for (let i = 0; i < leagues?.length; i++) {
        //   if (leagues[i].league_id === parseInt(e)) {
        //     tmp = leagues[i];
        //   }
        // }
        // setSelectedLeague(tmp);


        return (
            <div>
                <Dropdown
                    style={{
                        borderRadius: '32px',
                        background: '#f3f3f3',
                        width: 'fit-content',
                        height: '63px',
                        color: 'black',
                        padding: '10px 26.2px 10px 16px',
                        margin: '16px 6px 18px',
                        border: '0px',
                    }}
                    onSelect={handleLeagueSelect}
                >
                    <Dropdown.Toggle variant="none"
                                     className={'sfProDisplayFontBold d-flex align-items-center w-fit-content'}
                                     id="dropdown-basic">
                        <img alt={'league'} src={(selectedLeague?.icon || src)}
                             style={{
                                 marginLeft: '-10px',
                                 width: '20px',
                                 height: '20px',
                             }}
                        >
                        </img>
                        &nbsp;
                        &nbsp;
                        <span>
                            {(selectedLeague?.name || 'Premier League').toUpperCase()}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'shadow-nav'}>
                        {
                            leagues?.map((league, index) => (
                                <Dropdown.Item key={index}
                                               eventKey={league?.league_id}>
                                    <img alt={'league'} src={(league?.icon || src)}
                                         style={{
                                             marginRight: '10px',
                                             width: '20px',
                                             height: '20px',
                                         }}
                                    >
                                    </img>
                                    {league?.name}
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>

            </div>
        );

    };

    const renderRightAds = () => {

        let numberOfAds = [1, 2, 3, 4];

        return (
            <div className={'col-4'}>
                <div className={`row container pt-2`}>
                    {youMightLike.map((news, index) => {

                        return (
                            <div
                                className={`mb-3 col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                            >
                                <NewsCard
                                    isMobile={isMobile}
                                    classProps={""}
                                    newsItem={news}
                                    isNewDesign={true}
                                    isNewsPage={false}
                                    sport={"football"}
                                />
                                {index % 2 === 0 && <div
                                    style={{
                                        border: '1px black solid',
                                    }}
                                    className="d-flex justify-content-center align-items-center h1 mt-3">
                                    <GoogleAd id={'4009154580'}/>
                                </div>}
                            </div>
                        );
                    })}
                </div>
            </div>);
    };

    const renderMobilePartialTable = (standingBgColor, textColor, color) => {
        return (
            <>
                <div className={` rounded-custom-1 text-center p-1`}>
                    <span className={`${textColor} text-header`}>
                        <span
                            className={'sfProDisplayFont font-16 text-black'}
                            style={{fontWeight: '600'}}
                        >
                            {renderLeagueSelectDropDown()}
                        </span>
                        {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                    </span>
                </div>
                <div className={`mt-3`}>

                </div>
                <table className={`table table-responsive`}>
                    <thead>
                    <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
                        <td></td>
                        <td style={{width: '130px'}}>Teams</td>
                        <td>P</td>
                        <td>W</td>
                        <td>D</td>
                        <td>L</td>
                        <td>F/A</td>
                        <td>GD</td>
                        <td>Pts</td>
                    </tr>
                    </thead>
                    <tbody>
                    {standings?.map((value) => (
                        <>
                            <tr className={`text-light-black sfProDisplayFont small`}
                                style={{backgroundColor: standingBgColor}}>
                                <td className={`text-center`}><span
                                    className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                                </td>
                                <td className={`p-2`}>
                                    <img src={value.team.icon} height={30} alt=""/>
                                    <span
                                        className={'sfProDisplayFont ms-2'}>&nbsp; {value.team.short_name}</span>
                                </td>
                                <td className={`text-center`}>{value.pl}</td>
                                <td className={`text-center`}>{value.win}</td>
                                <td className={`text-center`}>{value.draw}</td>
                                <td className={`text-center`}>{value.lose}</td>
                                <td className={`text-center`}>{value.gd2}</td>
                                <td className={`text-center`}>{value.gd}</td>
                                <td className={`text-center`}>{value.pts}</td>
                            </tr>
                        </>
                    ))}
                    </tbody>
                </table>
                {moreStandings?.length &&
                    <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                        onClick={() => {
                            setFullTable(!fullTable);
                        }}
                    >
                        {fullTable ? 'Collapse' : 'Full Table'} &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                         color={color} size={'1x'}/>
                    </li>}
            </>
        );
    };

    const renderMobileFullTable = (standingBgColor, textColor, color) => {
        return (
            <>
                <div className={` rounded-custom-1 text-center p-1`}>
                    <span className={`${textColor} text-header`}>
                        <span
                            className={'sfProDisplayFont font-16 text-black'}
                            style={{fontWeight: '600'}}
                        >
                            {renderLeagueSelectDropDown()}
                        </span>
                        {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                    </span>
                </div>
                <div className={`mt-3`}>

                </div>
                <table className={`table table-responsive`}>
                    <thead>
                    <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
                        <td></td>
                        <td style={{width: '130px'}}>Teams</td>
                        <td>P</td>
                        <td>W</td>
                        <td>D</td>
                        <td>L</td>
                        <td>F/A</td>
                        <td>GD</td>
                        <td>Pts</td>
                    </tr>
                    </thead>
                    <tbody>
                    {moreStandings?.length &&
                        moreStandings?.map(((value, index, array) => (
                            <tr className={`text-light-black sfProDisplayFont small`}
                                style={value.background}>
                                <td className={`ps-1 text-center`}><span
                                    className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                                </td>
                                <td className={`p-2`}>
                                    <img src={value.team.icon} height={20}
                                         alt=""/>
                                    <span className={'sfProDisplayFont ms-2'}>
                                        &nbsp; {value.team.short_name}
                                    </span>
                                </td>
                                <td className={`text-center`}>{value.pl}</td>
                                <td className={`text-center`}>{value.win}</td>
                                <td className={`text-center`}>{value.draw}</td>
                                <td className={`text-center`}>{value.lose}</td>
                                <td className={`text-center`}>{value.gd2}</td>
                                <td className={`text-center`}>{value.gd}</td>
                                <td className={`text-center`}>{value.pts}</td>
                            </tr>
                        )))}

                    </tbody>
                </table>
                {moreStandings?.length &&
                    <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                        onClick={() => {
                            setFullTable(!fullTable);
                            window.scroll({
                                top: 1850,
                                behavior: 'smooth',
                            });
                        }}
                    >
                        {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                         color={color}
                                         size={'1x'}/>
                    </li>}
                {/**/}
            </>
        );
    };

    const renderEmblems = () => {
        return (
            <li

                style={{
                    color: '#767676',
                    borderLeft: '5px solid transparent',
                }}
                className={'p-2 mt-2 bg-white'}>
                <div className={'row p-1'}>
                    <div
                        style={{color: '#999999'}}
                        className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
                        <div style={{
                            background: '#009245'
                            , height: '10px', width: '10px',
                        }}/>
                        &nbsp;
                        Champions League
                    </div>
                    <div
                        style={{color: '#999999'}}
                        className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
                        <div style={{
                            background: '#034694', height: '10px', width: '10px',
                        }}/>
                        &nbsp;
                        Europa League
                    </div>
                    <div
                        style={{color: '#999999'}}
                        className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
                        <div style={{

                            background: '#ef0107', height: '10px', width: '10px',
                        }}/>
                        &nbsp;
                        Relegation
                    </div>
                </div>
            </li>
        );
    };

    const renderLeaugeTableHeading = (value) => {
        let groupName = ''
        if (value?.length < 10) {
            groupName = 'Group ' + value[0].group
        }

        return (
            <li
                style={{
                    background: '#f3f3f3',
                    color: '#767676',
                    borderLeft: '5px solid transparent',
                }}
                className={'p-2 mt-4'}>
                <p className={'sfProDisplayFontBold p-0 m-0 text-start border-bottom'}>
                    {groupName}
                </p>
                <div className={'row p-1'}>
                    <div
                        className={'col-1 text-center  sfProDisplayFont text-nowrap font-14'}>
                        Position
                    </div>
                    <div
                        className={'col-3 text-start sfProDisplayFont text-nowrap font-14'}>
                        Team
                    </div>
                    <div
                        className={'col-1 text-center  sfProDisplayFont font-14 m-0 p-0'}>Played
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>Won
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>Drawn
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>Lost
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>For
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>Against
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>GD
                    </div>
                    <div
                        className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>Points
                    </div>
                </div>
            </li>
        )
    }

    const renderLeagueTable = (Value) => {

        console.log('selectLeagueData =', (selectLeagueData?.standings?.length - parseInt(selectLeagueData?.bands?.relegation_quantity)))

        return (Value || standings)?.map(((value, index, array) => {
            let bgColor = '';
            if (value?.team?.id === singleMatchData?.home_team?.id) {
                bgColor = singleMatchData?.home_team?.hex_color;
            }

            if (value?.team?.id === singleMatchData?.guest_team?.id) {
                bgColor = singleMatchData?.guest_team?.hex_color;
            }
            return (
                <li key={index} className={`pt-1 pb-1 ps-3 pe-1`}
                    style={{
                        background: (index !== 0 && index % 2 !== 0) ?
                            '#f3f3f3' :
                            '#fff',
                    }}
                >
                    <div className={'row p-1'}
                         style={{
                             borderLeft: `5px solid ${value?.position <= parseInt(selectLeagueData?.bands?.promotion_quantity) ? '#009245' :
                                 value?.position <= (parseInt(selectLeagueData?.bands?.promotion_quantity) + parseInt(selectLeagueData?.bands?.below_promotion_quality)) ?
                                     '#034694' :
                                     value?.position >= (selectLeagueData?.standings?.length - parseInt(selectLeagueData?.bands?.relegation_quantity)) ?
                                         '#ef0107' :
                                         'transparent'} `,
                         }}
                    >
                        <div
                            className={`col-1 ${bgColor === '' &&
                            'text-dark'} text-center text-nowrap font-16`}>
                            {value?.position}.
                        </div>
                        <div className={'col-3 text-start'}>
                            <img
                                width={27}
                                style={{objectFit: 'contain'}}

                                src={value.team.icon} height={20} alt=""/>
                            &nbsp;
                            <span className={'sfProDisplayFont font-16'}
                                  style={{fontWeight: '500'}}
                            >
                                {value?.team.name}
                            </span>
                        </div>

                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.pl}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.win}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 ps-0`}>{value?.draw}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.lose}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.overall_gs}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 ps-0`}>{value?.overall_ga}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.gd}</div>
                        <div
                            className={`col-1 text-center ${bgColor === '' &&
                            'text-light-black'} sfProDisplayFont font-16 m-0 ps-0`}>{value?.pts}</div>
                    </div>
                </li>
            );
        }))
    }

    const renderLaptopTable = (
        standingBgColor, textColor, color, navColor, fontColor, Value, groupData) => {

        return (
            <div className={'row container ms-auto me-auto'}>


                <div className="col-8">
                    <div className={` rounded-custom-1 text-center p-1`}>
                        <span className={`${fontColor} text-header`}>
                            <span
                                className={'sfProDisplayFont font-16 text-black'}
                                style={{fontWeight: '600'}}
                            >
                                {renderLeagueSelectDropDown()}
                            </span>
                            {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
                    </div>
                    <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0'}>
                        {!groupData && renderLeaugeTableHeading()}
                        {
                            loading ?
                                <ItemLoader loading={loading}/>
                                :
                                !groupData && renderLeagueTable(Value)}

                        {!loading && groupData && standings && groupData.map((value, index) => (
                            <div className={`mb-2 mt-2`}>

                                {renderLeaugeTableHeading(value)}
                                {renderLeagueTable(value)}
                            </div>
                        ))}

                        {
                            moreStandings?.length &&
                            moreStandings?.map(((value, index, array) => {
                                return (
                                    <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                                        <div className={'row'}>
                                            <div
                                                className={'col-4 text-start text-dark text-nowrap font-14'}>
                                                <span
                                                    className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                                                <img src={value.team.icon} height={20} alt=""/>
                                                <span className={'sfProDisplayFont'}>
                                                    &nbsp; {value.team.name}
                                                </span>
                                            </div>
                                            <div className={'col text-end text-light-black'}>
                                                {() => {
                                                    if (value.arrow === 'up') {
                                                        return (<FontAwesomeIcon icon={faAngleUp}
                                                                                 color={'#0e7d45'}
                                                                                 size={'1x'}/>);
                                                    }
                                                    if (value.arrow === 'down') {
                                                        return (
                                                            <FontAwesomeIcon icon={faAngleDown}
                                                                             color={'#e02020'}
                                                                             size={'1x'}/>);
                                                    }
                                                }}
                                            </div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                                            <div
                                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                                        </div>
                                    </li>
                                );
                            }))
                        }
                        {renderEmblems()}
                    </ul>
                    {!isMobile && renderYouMightLike()}
                </div>
                {!isMobile && renderRightAds()}
            </div>
        );
    };

    const withData = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const textColor = TEXT_COLOR[target];
        const fontColor = FONT_COLOR[target];
        const color = COLORS[target];
        const standingBgColor = COLORS_RGBA[target] + '0.3)';

        let isGroup = standings && standings[0]?.group !== '';
        let tmp = [];
        if (standings !== null && standings !== undefined) {
            if (standings && isGroup) {
                let arr = [];
                let allGroups = standings.map((item) => item?.group); //gets all groups if any
                for (let i = 0; i < standings?.length; i++) {
                    if (standings[i]?.group === allGroups[i]) {
                        arr.push(standings[i]);
                        if (arr.length === 4) {
                            tmp.push(arr);
                            arr = [];
                        }
                    }
                }
            } else if (standings && !isGroup) {
                let arr = [];
                for (let i = 0; i < standings?.length; i++) {

                    arr.push(standings[i]);
                }
                tmp.push(arr);
            }

        }

        const renderDefault = () => {
            return (
                <div>
                    {isMobile ?
                        !fullTable && standings ?
                            renderMobilePartialTable(standingBgColor, textColor,
                                color) :
                            renderMobileFullTable(standingBgColor, textColor, color) :
                        renderLaptopTable(standingBgColor, textColor, color, navColor,
                            fontColor, '', tmp)
                    }
                </div>
            );
        };

        const renderGroups = () => {
            return (
                standings && tmp.map((value, index) => (
                    <>
                        {renderLaptopTable(standingBgColor, textColor, color, navColor,
                            fontColor, value)
                        }
                    </>
                ))
            );
        };

        return (
            <>
                {renderDefault()}
                {/*{tmp !== [] && renderGroups()}*/}
            </>
        );
    };

    const render = () => {
        return (
            <div className={'bg-white'}>
                <SubNavbar isMobile={isMobile}/>
                {
                    (standings === null) ? ''
                        :
                        withData()
                }
            </div>
        );
    };

    return render();
};

export default FanzineAllFootballTable;
