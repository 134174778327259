import React, {useEffect, useState} from "react";
import '../../Components/Cards/styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import '../styles/fanzine.css';
import PropTypes from "prop-types";
import FootballMatchCard from "./SportsMatchCardData/FootballMatchCard";
import CricketMatchCard from "./SportsMatchCardData/CricketMatchCard";
import MLBMatchCard from "./SportsMatchCardData/MLBMatchCard";

const MatchCard = (props) => {

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    const renderCard = (match, sport) => {
        if (sport === 'football') {
            return (<FootballMatchCard match={match} isMobile={props.isMobile}/>);
        }
        if (sport === 'cricket') {
            return (<CricketMatchCard match={match} isMobile={props.isMobile}/>);
        }
        if (['mlb', 'nba', 'nfl'].includes(sport)) {
            return (<MLBMatchCard match={match} isMobile={props.isMobile}/>);
        }
    }

    const render = () => {

        let sport = props.sport;
        let match = props.singleMatch;

        return (<div className={`slider-mobile-match-card`}>
            {renderCard(match, sport)}
        </div>);
    }

    return render();
}

MatchCard.propTypes = {
    singleMatch: PropTypes.object.isRequired,
    sport: PropTypes.string.isRequired,
}

export default MatchCard;
