import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";

export const Cricket = () => {
    return (
        <div className={`container mt-5`}>
            <SiteMetaHelmet sport={'Cricket'} urlEndpoint={'cricket'}/>
            <h1 className={`bebasFont`}>
                Latest Cricket News Headlines in the UK and Across the Globe
            </h1>
            <div className={`sfProDisplayFont mt-3`}>
                We bring you breaking cricket headlines, live transfer news, rumours, results and more. If you’re
                looking for 24/7 updates from the world of cricket, including the latest World Cup and T20
                headlines, then you’re in the right place.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Here at Fanzine, we aim to bring our readers top-quality original content, including exclusive stories
                involving World’s biggest tours and ashes, and great insight from our writers, researchers, and
                pundits.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                Find out what’s happening in the sport you love
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Our writers love delivering their views on what’s happening in the game we love. In short, we strive
                to provide original cricket journalism our readers love and give an up-to-date, comprehensive
                understanding of what is happening in the sport you love and issues that you care about.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Whether it’s the latest tour related cricket headlines, World Cup matches, T20 previews, or the
                county cricket, we’ve got you covered with worldwide coverage of all the latest major cricket headlines.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Our cricket news feed updates continuously 24/7, so make sure you check back regularly. Our
                talented team focuses on quality journalism and uses their experience and insight to provide
                inspiring, entertaining and quality cricket coverage to millions of sports lovers in the UK and around
                the world.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                The ultimate online UK cricket platform
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                We go beyond the game and find the cricket stories that matter. We aren’t here to respin the
                hundreds of latest cricket headlines you’ve already read. We deliver the information you need to be
                ahead of the curve, before, during and after kick-off.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                As the ultimate online UK cricket platform, our mission is to bring fans everything they want when
                they want it when it comes to the latest cricket news headlines, updates, rumours, and previews.
                Our content is written and edited by professionals with the aim of bringing you the latest cricket
                headlines and the best comment and analysis on the stories that matter.
            </div>
            <div className={`sfProDisplayFont mt-3 mb-5`}>
                We provide daily updates to our readers and tend to stay on top of the trends when it comes to
                cricket news. Looking for footie stats, scores, analysis, and more? Then look no further. Fanzine is
                the website where you can find all recent news in cricket from the UK and all around the world.
            </div>
        </div>
    )
}
