import '../styles/SingleMatchItem.css';
import moment from "moment/moment";
import {MATCH_STATES} from "../../../../Configs/Constants";
import MobileNewsMenuItem
    from '../../../../Components/Navbar/MobileNewsMenuItem';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const SingleFootballMatchItem = (props) => {
    const queryParams = useLocation();
    const {addDefaultSrc,matchData, isMobile} = props
    const [isMatchLive,setIsMatchLive] = useState(false);
    const [currentActive, setCurrentActive] = useState(queryParams.pathname.split('/')[4])
    let  match

    if(matchData)
    {
        match = matchData.match
    }

    const SetIsMatchLiveStatus = ()=>
    {
        if(MATCH_STATES.FINISHED.includes(match?.state) || MATCH_STATES.IN_PLAY.includes(match?.state))
        {
            setIsMatchLive(true);
        }
        else
        {
            setIsMatchLive(false);
        }
    }

    useEffect(()=>{
        SetIsMatchLiveStatus()
    },[matchData])

    const fetchURL = (paramsToAdd) => {
        let newUrl = '';

        if (queryParams.pathname.split('/')[4] === '') {
            newUrl = queryParams.pathname + '/' + paramsToAdd
        } else {
            queryParams.pathname.split('/')[4] = ''
            newUrl = '/' + queryParams.pathname.split('/')[1] + '/' + queryParams.pathname.split('/')[2]
                + '/' + queryParams.pathname.split('/')[3] + '/' + paramsToAdd
        }
        return newUrl
    }

    let dataOverview = [
        {
            title: 'Overview',
            uri: ('overview'),
            selected: queryParams.pathname.split('/')[4] === 'overview'
        },
        {
            title: 'Stats',
            uri: 'stats',
            selected: queryParams.pathname.split('/')[4] === 'stats'
        },
        {
            title: 'Lineups',
            uri: 'lineups',
            selected: queryParams.pathname.split('/')[4] === 'lineups'
        },
        {
            title: 'Commentary',
            uri: 'commentary',
            selected: queryParams.pathname.split('/')[4] === 'commentary'
        },
        {
            title: 'h2h',
            uri: 'h2h',
            selected: queryParams.pathname.split('/')[4] === 'h2h'
        },
        {
            title: 'Social',
            uri: 'social',
            selected: queryParams.pathname.split('/')[4] === 'social'
        }
    ]
    let dataPreview = [
        {
            title: 'Preview',
            uri: ('preview'),
            selected: queryParams.pathname.split('/')[4] === 'preview'
        },
        {
            title: 'h2h',
            uri: 'h2h',
            selected: queryParams.pathname.split('/')[4] === 'h2h'
        },
        {
            title: 'Social',
            uri: 'social',
            selected: queryParams.pathname.split('/')[4] === 'social'
        }
    ]

    const renderMobileMenu = () => {
        return (
            <div className={'slider-mobile-news-container '}>
                { isMatchLive ?
                    dataOverview.map((item) => {
                        return (
                            <MobileNewsMenuItem isMobile={isMobile} setCurrentActive={setCurrentActive} title={item.title} uri={fetchURL(item.uri)} selected={item.selected}/>
                        )
                    })
                    :
                    dataPreview.map((item) => {
                        return (
                            <MobileNewsMenuItem isMobile={isMobile} setCurrentActive={setCurrentActive} title={item.title} uri={fetchURL(item.uri)} selected={item.selected}/>
                        )
                    })
                }
            </div>
        );
    }



    return (
        <div className={isMobile ? 'ms-3' : 'container'} >
            <div className={`row border-radius-10px  ${isMobile ? 'pt-5 mb-2' : 'm-1'} center-items`}>
                <div className={'w-100'}>
                    <h6 className={`text-center font-14 ${isMobile && 'text-white'}`}>
                        {/*{  'Full - Time'}*/}
                        {MATCH_STATES.IN_PLAY.includes((match?.state)) && (match?.current_time_match)}
                    </h6>
                </div>
                <div className={`col-lg-5 col-md-5 col-xs-5 ${ isMobile ? 'col font-12 text-white' : 'ps-5' } justify-content-end text-start sfProDisplayFontBold`} style={{display: 'flex', alignItems:'center', justifyContent: !isMobile ? 'end' : ''}}>
                    {match?.home_team?.name}
                    <img
                        className={` ms-1 `}
                        height={40}
                        width={60}
                        style={{objectFit:'contain'}}
                        alt={"Host team badge"}
                        src={match?.home_team?.club_logo}
                        onError={addDefaultSrc}
                    ></img>
                </div>
                <div className={`col-lg-2 col-md-2 col-xs-2 mt-2 ${isMobile &&'col-2'} text-center`}>
                    <h4 className={`sfProDisplayFontBold ${isMobile ? 'font-14 text-white' : 'font-20'}`}>
                        {(match?.state) === 3 ? moment.utc(match?.datetime).local().format('HH:mm') :
                                <div className={'col pb-1 pt-1 justify-content-center d-flex align-items-center'} style={{background: [1,2,10,11].includes(match?.state) ? 'green' :
                                        (match?.state === 9) ? "yellow" : "#f1f1f1",
                                    color: [1,2,10,11].includes(match?.state) ? 'white' :'black'
                                }}>
                                    {match?.total}
                                </div>
                        }
                        {
                            MATCH_STATES.POSTPONED.includes((match?.state))
                            && "Postponed"
                            // (liveMatchResult?.current_time_match || matches.current_time_match)
                        }
                        {
                            MATCH_STATES.ABANDONED.includes((match?.state))
                            && "Abandoned"
                            // (liveMatchResult?.current_time_match || matches.current_time_match)
                        }
                        {
                            MATCH_STATES.CANCELLED.includes((match?.state))
                            && "Cancelled"
                            // (liveMatchResult?.current_time_match || matches.current_time_match)
                        }
                        {
                            MATCH_STATES.SUSPENDED.includes((match?.state))
                            && "Suspended"
                            // (liveMatchResult?.current_time_match || matches.current_time_match)
                        }
                        {
                            MATCH_STATES.INTERRUPTED.includes((match?.state))
                            && "Interrupted"
                            // (liveMatchResult?.current_time_match || matches.current_time_match)
                        }



                    </h4>
                </div>
                <div className={`col-lg-5 col-md-5 col-xs-5 ${ isMobile &&'col font-12 text-white'} justify-content-start text-end sfProDisplayFontBold `} style={{display: 'flex', alignItems:'center'}}>
                    <img
                        className={`me-1`}
                        height={40}
                        width={60}
                        style={{objectFit:'contain'}}
                        alt={"Guest team badge"}
                        src={match?.guest_team?.club_logo}
                        onError={addDefaultSrc}
                    ></img>
                    {match?.guest_team?.name}
                </div>
            </div>
            {isMobile && renderMobileMenu()}
        </div>
    )
}

export default SingleFootballMatchItem;

