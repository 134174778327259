import {useEffect, useState} from "react";
import {get} from "../Axios/get";
import {FANZINE} from "../Axios/EndPoints";
import {withRouter} from "../CustomFunctions/withRouter";
import axios from "axios";
import Loader from "../Components/Loader/Loader";
import {Card} from "react-bootstrap";
import {SCREEN_SIZE} from "../Configs/Constants";

const NFTDetail = (props) => {
    document.body.style = 'padding-top: 40px !important';

    const [nft, setNft] = useState(null);
    const [loading, setLoading] = useState(true);
    const [videoTitle, setVideoTitle] = useState('');
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [isMobile, setIsMobile] = useState(false);

    const fetchNft = () => {
        get(FANZINE.NFTEndPoint + props.match.params.id)
            .then((response) => {
                setNft(response.data.data);

                let url = 'https://www.youtube.com/watch?v=' + response.data.data.video.videoId;
                axios.get('https://noembed.com/embed?url=' + url).then(r => (
                    setVideoTitle(r.data.title)
                ));

                setLoading(false);
            });
    }

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    useEffect(() => {
        fetchNft();
    }, [props.match.params.id]);

    const renderHeader = () => {
        return (
            <>
                <img src={require('../assets/images/nft/listings/Component 96 – 1@3x.png')} alt={'Fanzine Football NFTs'} className={'img-fluid'}/>
                <div className={`container`}>
                    <img src={require('../assets/images/nft/listings/Uruguay Dog #1.png')} alt={'Fanzine Football NFT Uruguay'}
                         className={'img-fluid rounded-custom shadow bg-white p-2 mt-n-nft-banner'}
                    />
                </div>
                <div className={`container`}>
                    <div className={`mt-5 sfProDisplayFontBold font-30`}>
                        World Cup Dogs
                    </div>
                    <div className={`mt-5 font-16`}>
                        <span className={`sfProDisplayFont`}>By </span>
                        <span className={`sfProDisplayFontBold`}> Fanzine</span>
                    </div>
                    <div className={`mt-5 sfProDisplayFont font-16`}>
                        Fanzine’s mission is to become the biggest sports fan community in the world, with the FANS at the very centre of everything we do.
                    </div>
                </div>
            </>
        );
    }

    const renderVideo = (videoData, extraClass, videoHeight) => {
        return (
            <div className={extraClass}>
                <Card className="border-0 bg-transparent">
                    <div className="video-responsive">
                        <iframe
                            style={{width: '100%'}}
                            height={videoHeight}
                            src={videoData.video.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Card>
                <span className={`sfProDisplayFontBold h3 w-100 d-inline-block mt-3 mb-5`}
                      dangerouslySetInnerHTML={{__html: videoTitle}}/>
            </div>
        );
    }

    const renderNFT = () => {
        return (
            <div className={`row`}>
                <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                    <img src={nft.image} alt={nft.name} className={`img-fluid`}/>
                </div>
                <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                    <div className={`sfProDisplayFontBold font-30`}>
                        {nft.name}
                    </div>
                    <div className={`font-16 mt-3 mb-3`}>
                        <span className={`sfProDisplayFont`}>Nationality: </span>
                        <span className={`sfProDisplayFontBold font-fanzine`}>{nft.team.name}</span>
                    </div>
                    <div className={`sfProDisplayFont font-16 mt-4`} style={{color: '#6f757b'}}>Current Price</div>
                    <div className={`sfProDisplayFontBold font-30`}>{parseFloat(nft.current_price)} ETH</div>
                    <div className={`mt-3`}>
                        <a href={nft.url} target={"_blank"} className={`btn btn-fanzine text-white ${isMobile ? 'w-100' : 'w-50'}`}>Buy Now</a>
                    </div>
                    <div className={`mt-3 mb-3 p-3`} style={{backgroundColor: '#efefef !important'}}>
                        <div className={`sfProDisplayFontBold font-20`}>Description</div>
                        <div className={`sfProDisplayFont font-16 mt-3`}>{nft.description}</div>
                    </div>
                </div>
            </div>
        );
    }

    const render = () => {
        return (
            <div className={`mb-5`}>
                <Loader loading={loading}/>
                {/*{renderHeader()}*/}
                <div className={`container mt-5`}>
                    {nft && renderNFT()}
                    {nft && videoTitle!=='' && renderVideo(nft, 'row mt-5', '480')}
                </div>
            </div>
        );
    }

    return render();

}

export default withRouter(NFTDetail);
