import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {get} from "../../../../../Axios/get";
import {FANZINE} from "../../../../../Axios/EndPoints";
import {GiWhistle} from "react-icons/gi";

const SingleFootballMatchContentCommentary = (props) => {

    const {isMobile} = props;
    const params = useParams();
    const queryParams = useLocation();
    const [matchCommentary, setMatchCommentary] = useState(null)
    const isCurrent = props.currentActive === 'commentary';


    useEffect(() => {
        isCurrent && fetchMatchCommentary()
    }, [isCurrent])

    const fetchMatchCommentary = () => {
        let parameter = queryParams.search;

        if (!parameter) {
            parameter = '?match_id=' + params.id;
        }
        get(FANZINE.MatchCommentary + parameter)
            .then((res) => {
                let tmpData = res.data.reverse();

                setMatchCommentary(tmpData)
            })
            .catch((error) => console.error(error))
    }

    const renderCommentary = () => {
        let data = []

        return (
            <div>
                {matchCommentary != null && matchCommentary?.map((value, index) => (
                    <div key={index}
                         className={`${index === 0 ? 'mt-2' : 'mt-2'} ${isMobile && 'm-1' } p-2 row border-radius-10px d-flex align-items-center justify-content-center`}
                         style={{background: value.text.includes('Goal') ? '#34c759' : (value.text.includes('Red Card') || value.text.includes('red card')) ? '#e02020'
                                 :
                                 value.text.includes('Yellow Card') || value.text.includes('yellow card') ? '#f7b500' :
                                 isMobile ? '#fff' : '#e4e4e4', height: '-webkit-fit-content',
                            color: (value.text.includes('Goal') || value.text.includes('red card'))? 'white' : "black"
                         }}
                    >
                        <div className={'col-2'}>
                            {(value.minute !== 'FT' && value.minute !== 'HT' && value.minute !== '-') && <div className={'bebasFont text-start font-16'}>
                                {value.minute}
                            </div>
                            }
                            {(value.minute === 'FT' || value.minute === 'HT' || value.minute === '-') && <GiWhistle size={25}/>}
                            {value.text.includes(' VAR ') && <img src={require('../../../../../assets/images/var.png')} alt={'var'} height={'15'}/>}
                            {value.text.includes('Goal') && <img src={require('../../../../../assets/images/FOOTBALL SELECTED@2x.png')}  alt={'goal'} height={'18'}/>}
                            {(value.text.includes('Yellow Card') || value.text.includes('yellow card')) && <img src={require('../../../../../assets/images/cyellowcard.png')}  alt={'ycard'} height={'18'}/>}
                            {(value.text.includes('Red Card') || value.text.includes('red card')) && <img src={require('../../../../../assets/images/credcard.png')}  alt={'rcard'} height={'18'}/>}
                            {value.text.includes('Substitution') && <img src={require('../../../../../assets/images/csub.png')}  alt={'rcard'} height={'18'}/>}
                            {value.text.includes('Missed Penalty') && <img src={require('../../../../../assets/images/cmisspenalty.png')}  alt={'rcard'} height={'18'}/>}
                        </div>
                        <div className={' col-10 align-items-center'} >
                            <div className={'sfProDisplayFontBold font-16 row ps-1'}>
                                {value.minute === '-' ? 'Kick Off' : value.minute === 'HT' ? 'Half Time' : value.minute === 'FT' ? 'Full Time' : '' }
                                {value.text.includes('Goal') && 'Goal!'}
                                {(value.text.includes('Yellow Card') || value.text.includes('yellow card')) && 'Yellow Card!'}
                                {(value.text.includes('Red Card') || value.text.includes('red card')) && 'Red Card!'}
                                {value.text.includes('Substitution') && 'Substitution!'}
                            </div>
                            <div className={'font-12 sfProDisplayFont row ps-1'} style={{lineHeight:'12px'}}>
                                {value?.text}
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        )
    }

    return (
        <div>
            {renderCommentary()}
        </div>
    )


}
export default SingleFootballMatchContentCommentary;
