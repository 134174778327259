import React from "react";
import DynamicSitemap from "react-dynamic-sitemap";
import AppRoutes from "../AppRoutes";

export default function Sitemap(props) {
    return (
        <DynamicSitemap
            routes={AppRoutes}
            prettify={true}
            sitemapIndex={true}
            changefreq={'always'}
            priority={1}
            {...props}/>
    );
}