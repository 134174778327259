import {MiscActionTypes} from "../constants/MiscActionTypes";

export const setAppInit = (data) => {
  return {
      type: MiscActionTypes.SET_APP_INIT,
      payload: data,
  }
}
export const setSingleVideo = (data) => {
  return {
      type: MiscActionTypes.SET_SINGLE_VIDEO,
      payload: data,
  }
}
export const setCampaignAnswered = (data) => {
  return {
      type: MiscActionTypes.SET_CAMPAIGN_ANSWERED,
      payload: data,
  }
}