import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {get} from '../../Axios/get';
import {FOOTBALL, MLB, NBA, NFL} from '../../Axios/EndPoints';
import Slider from 'react-slick';
import HomeMatchTabContainerItem from './HomeMatchTabContainerItem';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/styles/fanzineMatchSlider.css";

let initValue = null;
let initDate;
let displayArray = [];

const HomeMatchTabContainer = (props) => {
    const {sport, isMobile} = props;
    let [slidesToSkip, setSlidesToSkip] = useState(1)
    const [matchesData, setMatchesData] = useState([]);
    const [currentDisplayDate, setCurrentDisplayDate] = useState();
    const [staticData, setStaticData] = useState([]);
    const [initialValue, setInitialValue] = useState(null);
    const [data, setData] = useState(null);
    const slider = useRef(null);

    const fetchHomePageMatches = () => {

        let URL = '';

        switch (sport) {
            case 'football':
                URL = FOOTBALL.HomepageMatchesEndPoint;
                break;
            case 'nfl':
                URL = NFL.HomepageMatchesEndPoint;
                break;
            case 'nba':
                URL = NBA.HomepageMatchesEndPoint;
                break;
            case 'mlb':
                URL = MLB.HomepageMatchesEndPoint;
                break;
            default :
                URL = FOOTBALL.HomepageMatchesEndPoint;
        }

        get(URL).then((res) => {
            console.log('res.data =', res.data.data);
            let fetchData = [];
            Object.values(res.data.data).map((value) => {
                fetchData.push(value);
            });
            console.log('fwefwefewf', fetchData[0]?.date);
            setCurrentDisplayDate(fetchData[0]);
            setData(fetchData);
            setMatchesData(fetchData);
        }).catch((error) => console.error('error while getting matchesData =',
            error));
    };

    useEffect(() => {
        matchesData?.length === 0 && fetchHomePageMatches();

    }, []);

    function TargetNextArrow(props) {
        const {className, style, onClick, arrowColor, showArrows} = props;
        return (
            <div
                className={`${className} ${arrowColor}`}
                style={{...style, display: showArrows}}
                onClick={onClick}
            />
        );

        const rightClick = () => {
            onClick()

        }

        // return (
        //     <div
        //         style={{
        //           width: '100%',
        //           // marginLeft: '-10px',
        //           cursor: 'pointer',
        //           background: 'transparent',
        //           flex: '0 0 0%',
        //         }}
        //         onClick={() => rightClick()}
        //         className={`d-flex ${isMobile ?
        //             'col-1' :
        //             ''} justify-content-start`}>
        //       <div
        //           style={{width: '28px', marginLeft: '-10px'}}
        //           className={'shadow bg-fanzine-dark text-white d-flex justify-content-center align-items-center'}>
        //         <AiOutlineRight/>
        //       </div>
        //     </div>
        // );

    }

    function TargetPrevArrow(props) {
        const {className, style, onClick, arrowColor, showArrows} = props;

        /*return (
            <div
                className={`${className} ${arrowColor} `}
                style={{...style, display: showArrows}}
                onClick={onClick}
            >
            </div>
        );*/


        return (
            <div
                className={`${className} ${arrowColor} `}
                style={{...style}}
                onClick={onClick}
            >
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    className={'col-4 bg-yellow d-flex text-white justify-content-center align-items-center bg-fanzine-dark'}>
                    <AiOutlineLeft/>
                </div>
                <div
                    className={`${isMobile ?
                        'mt-1' :
                        'mt-3'} col-8 ps-2 robotoCondensedBold text-center text-white justify-content-center`}>
                    <h6 className={`robotoCondensedBold ${isMobile ?
                        'font-9' :
                        'font-11'}`}>
                        {moment(matchesData[0]?.[0]?.date).format('MMM') || 'Dec'}
                    </h6>
                    <h3 className={`robotoCondensedBold ${isMobile ?
                        'font-11' :
                        'font-16'}`}>
                        {moment(matchesData[0]?.[0]?.date).format('DD') || '26'}
                    </h3>
                    <h6 className={`robotoCondensedBold ${isMobile ?
                        'font-9' :
                        'font-11'}`}>
                        {moment(matchesData[0]?.[0]?.date).format('ddd') || 'Mon'}
                    </h6>
                    <h3 className={`robotoCondensedBold ${isMobile ?
                        'font-9' :
                        'font-11'}`}>
                        {'GW ' + matchesData[0]?.[0]?.week || '11'}
                    </h3>
                </div>
            </div>
        );
    }

    const customArrows = () => {
        return (
            <div className=" slider-arrow">
                {customLeftArrow()}
                {customRightArrow()}
            </div>
        );
    }

    const customLeftArrow = () => {

        console.log('currentDisplayDate =', currentDisplayDate)

        return (
            <div className="slider-arrow left shadow">
                <div
                    className="arrow-btn prev d-flex"
                    onClick={() => {
                        console.log('key =',slider?.current)
                        slider?.current?.slickPrev();
                    }}
                >
                    <div
                        style={{
                            borderRight:'15px solid black',
                            cursor: 'pointer',
                        }}
                        className={'d-flex text-white justify-content-center align-items-center bg-black'}>
                        <AiOutlineLeft/>
                    </div>
                    <div
                        className={`row container pt-1 ps-2 robotoCondensedBold text-center text-white justify-content-center bg-fanzine-dark`}>
                        <div className={'col-12'}>
                            <h6 className={`robotoCondensedBold ${isMobile ?
                                'font-9' :
                                'font-14'}`}>
                                {moment(currentDisplayDate?.date).format('ddd') || 'Mon'}
                            </h6>
                        </div>
                        <div className={'col-12'}>
                            <h3 className={`robotoCondensedBold ${isMobile ?
                                'font-11' :
                                'font-30'}`}>
                                {moment(currentDisplayDate?.date).format('DD') || '26'}
                            </h3>
                        </div>
                        <div className={'col-12'}>
                            <h6 className={`robotoCondensedBold ${isMobile ?
                                'font-9' :
                                'font-14'}`}>
                                {moment(currentDisplayDate?.date).format('MMM') || 'Dec'}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const customRightArrow = () => {
        return (
            <div
                className="shadow arrow-btn next d-flex"
                onClick={() => slider?.current?.slickNext()}
            >
                <div
                    style={{
                        cursor: 'pointer',
                        borderLeft:'4px solid black',
                        zIndex:1
                    }}
                    className={'d-flex text-white justify-content-center align-items-center bg-black'}
                >
                    <AiOutlineRight/>
                </div>
            </div>
        );
    }

    const container = () => {

        let settings = {
            infinite: true,
            dots: false,
            slidesToShow: isMobile ? 2.5 : 7,
            cssEase: 'linear',
            centerPadding: '10',
            arrows: false,
            slidesToScroll: slidesToSkip,
            lazyLoad: false,
            autoplay: false,
            prevArrow: <TargetPrevArrow arrowColor={'bg-fanzine-before'}
                                        showArrows={false}/>,
            nextArrow: <TargetNextArrow arrowColor={'bg-fanzine-before'}
                                        showArrows={false}/>,
            afterChange: state => setCurrentDisplayDate( matchesData[state]),
            // beforeChange: (current, next) => console.log('before change =', current, next)

            className:'shadow'
        };

        let currDate;
        if (displayArray?.length === 0) {
            currDate = matchesData[0]?.date;

            for (let i = 0; i < matchesData?.length; i++) {
                if (matchesData[i]?.date === currDate) {
                    displayArray.push(false);
                } else {
                    displayArray.push(true);
                    currDate = matchesData[i]?.date;
                }
            }
        }

        return (
            <div
                className={'justify-content-center w-100 pt-4 ms-5 '}
            >
                {customArrows()}
                <Slider ref={slider} {...settings}>
                    {
                        (matchesData || staticData)?.map((value, index) => (
                                (
                                    <HomeMatchTabContainerItem displayArray={displayArray}
                                                               display={value?.date !==
                                                                   currentDisplayDate}
                                                               dateValue={value}
                                                               dateIndex={index}
                                                               currentDate={currentDisplayDate}
                                                               setCurrentDate={setCurrentDisplayDate}
                                                               isMobile={isMobile}>
                                    </HomeMatchTabContainerItem>)
                            ),
                        )
                    }
                </Slider>
                {/*{containerItemRight()}*/}
            </div>
        );
    };

    const render = () => {
        return (
            <div className={`container ps-5 pe-5`}>
                {container()}
            </div>
        );
    };

    return render();

};

export default HomeMatchTabContainer;
