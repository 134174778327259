import React, {useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../../assets/styles/styles.css';
import '../../assets/styles/responsive/stylesLaptop.css';
import '../../assets/styles/responsive/stylesTablet.css';
import '../../assets/styles/responsive/stylesMobile.css';
import '../../assets/styles/footballTargets.css';
import '../styles/fanzine.css';
import {COLORS, FONT_COLOR, LOGO, NAV_CLASS, TEAM_NAME} from "../../Configs/Targets";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {Col, NavDropdown, NavItem, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {getHostname} from "../../CustomFunctions/getHostname";
import useComponentVisible from "../../CustomFunctions/useComponentVisible";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {slide as Menu} from 'react-burger-menu'

const FanzineNav = () => {
    const store = useSelector((state) => state);
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(true);

    const [width, setWidth] = useState(window.innerWidth);
    const [showCart, setShowCart] = useState(false);
    const [navShadow, setNavShadow] = useState('navShadow');

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const route = window.location.pathname;
    const navColor = NAV_CLASS[target];
    const navColorDark = NAV_CLASS[target] + '-dark';
    const navColorHover = NAV_CLASS[target] + '-hover-color';
    const fontColor = FONT_COLOR[target];
    const navName = TEAM_NAME[target];
    const color = COLORS[target];

    // let showCart = false;

    // let navShadow = "shadow-nav";


    let [navLogo, setNavLogo] = useState(LOGO[target]);
    let [imageProps, setImageProps] = useState({});
    let [isMobile, setIsMobile] = useState(false);
    let [showDownloadBanner, setShowDownloadBanner] = useState(false);
    let [isCollapsed, setIsCollapsed] = useState(true);
    let topNavBarDropdown = {};
    if (isMobile) {
        topNavBarDropdown = {
            width: "100%"
        }
    }

    useEffect(() => {
        setNavShadow("navShadow");
        setShowCart(false);
        if (route.includes("shop")) {
            setNavShadow("shadow-nav-dark");
            setShowCart(true);
        }
    }, [route])

    useEffect(() => {
        const navLogoSmall = LOGO[target];
        const navLogoBig = LOGO[target];

        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            setShowDownloadBanner(true);
        }
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setNavLogo(navLogoSmall);
            setImageProps({
                height: 50
            });
            setIsMobile(true);
        } else {
            setNavLogo(navLogoBig);
            if (['the-toffees', 'unitedreds', 'coys', 'playuppompey', 'foresttilidie', 'comeonderby', 'fanzinesports'].includes(target)) {
                setImageProps({
                    style: {marginTop: '-2em'}, height: 85,
                });
            } else if (['fanzine'].includes(target)) {
                setImageProps({
                    style: {marginTop: '-1.1em'}, height: 70,
                });
            } else {
                setImageProps({
                    height: 75,
                });
            }
            setIsMobile(false);
        }
        if (target !== 'fanzinesports' && !document.getElementById('react-burger-menu-btn').classList.contains('collapsed')) {
            document.getElementById('react-burger-menu-btn').click();
        }
    }, [window.innerWidth]);

    useEffect(() => {
        if (isMobile && target !== 'fanzinesports') {
            if (!isCollapsed) {
                document.getElementById('react-burger-menu-btn').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 fa-2x"' + ' role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
                document.getElementById('responsive-navbar-nav-fanzine').style = "display: block !important;position: fixed; right: 0px; z-index: 1100; width: 50%; height: 100%; transition: all 0.5s ease 0s;";
                document.getElementById('overlay').style = "display: block !important;";
            } else {
                document.getElementById('react-burger-menu-btn').innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-11 fa-2x' + '" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163' + ' 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>';
                document.getElementById('responsive-navbar-nav-fanzine').style = 'display: none !important;';
                document.getElementById('overlay').style = 'display: none !important;';
                // document.getElementById('react-burger-menu-btn').innerHTML = "<FontAwesomeIcon icon={'bars'} className={fontColor}/>";
            }
        }
    }, [isCollapsed]);

    const handleCollapse = () => {
        // document.getElementsByClassName('nav-mega-menu-fanzine')[0].closest('.dropdown-menu').previousSibling.click();
        document.getElementById('react-burger-menu-btn').click();
    };

    const handleMainCollapse = () => {
        document.getElementById('react-burger-menu-btn').click();
    };

    const renderMainNav = () => {
        return (<Navbar.Collapse id="responsive-navbar-nav-fanzine" className={`${navColor} w-100 responsive-navbar-nav-fanzine`}
                                 style={{zIndex: '10000'}}>
            {/*<Nav className="me-auto"/>*/}
            <Nav
                className={'justify-content-end float-xxl-end float-xl-end float-lg-end float-md-end float-sm-end p-xxl-0 p-xl-0 p-lg-0 p-md-0 p-sm-0 pe-2 nav-content nav-content-fanzine' +
                    ' float-sm-start align-content-sm-start mt-xxl-2 mt-xl-2 mt-lg-2'}>
                <NavDropdown className={`${isMobile ? 'pt-2' : 'pt-2'} `}
                             title={<span className={`${fontColor} ${isMobile ? 'bebasFont ps-4 pt-2' : 'sfProDisplayFontBold font-16'}`}>Football</span>}
                             id="top-menu-dropdown" renderMenuOnMount={true} style={topNavBarDropdown}>
                    <div className="nav-mega-menu-fanzine pt-0 mt-0 bg-dark">
                        <Row className={`p-0 m-0`}>
                            {store.football.fanzineTeamsLeagues.length && store.football.fanzineTeamsLeagues.map((item, index) => {
                                return (<Col key={index} xs={item.league ? '12' : '3'}
                                             className={`text-left d-flex flex-column justify-content-center p-0 ${item.league ? 'bg-light-grey' : 'bg-dark'}`}>
                                    {item.external ? <a href={item.link}
                                                        className={`dropdown-item text-white text-decoration-none font-14 h-100 d-flex flex-column justify-content-center`}>
                                            {' ' + item.name}
                                        </a> :
                                        <NavItem
                                            className={`d-flex flex-column justify-content-center mt-2`}>
                                            <NavLink to={item.link}
                                                     className={`dropdown-item text-white text-decoration-none font-14 h-100 d-flex flex-column justify-content-center bg-light-dark`}
                                                     onClick={handleCollapse}>
                                                <div className={'flex-row justify-content-start align-middle text-white'}>
                                                    {/*<img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                                    {item.name}
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Col>);
                            })}
                        </Row>
                    </div>
                </NavDropdown>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/cricket'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Cricket
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/nba'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        NBA
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/nfl'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        NFL
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/mlb'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        MLB
                    </NavLink>
                </NavItem>
                {/*<NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/news?teamType=team'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        More Sports
                    </NavLink>
                </NavItem>*/}
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/nfts'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        NFTs
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/shop'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Shop
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 ${isMobile ? 'ps-4 pt-2' : 'ps-5'}`}>
                    <NavLink to={'/contact'}
                             className={`${fontColor} text-decoration-none ${isMobile ? 'bebasFont' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Contact
                    </NavLink>
                </NavItem>
            </Nav>

            {isMobile && renderSocialNav()}
        </Navbar.Collapse>);
    }

    const renderMobileNav = () => {
        return (<Menu right className={`bg-white responsive-navbar-nav-fanzine`} width={'50%'} id={'responsive-navbar-nav-fanzine'}>
            <NavItem
                className={`d-flex flex-column justify-content-center nav-link mt-2 ${isMobile ? 'pt-2' : ''} border-bottom`}>
                <NavLink to={'/'}
                         className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                    Home
                </NavLink>
            </NavItem>
            <NavDropdown className={`${isMobile && 'pt-2'} fanzine-nav-responsive border-bottom`}
                         title={<span className={`text-black ${isMobile ? 'pt-2 sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`}>Sports</span>}
                         id="top-menu-dropdown" renderMenuOnMount={true}>
                <div className="pt-0 mt-n10 bg-white">
                    <Row className={`p-0 m-0`}><Col xs="12" md="12"
                                                    className={`text-left d-flex flex-column justify-content-center p-0`}>
                        <NavDropdown className={`${isMobile && 'pt-2'} pt-3 border-0 fanzine-nav-responsive`}
                                     style={{border: 'none !important'}}
                                     title={<span className={`text-black ${isMobile ? 'pt-2 sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`}>Football</span>}
                                     id="top-menu-dropdown" renderMenuOnMount={true}>
                            <div className="pt-0 mt-n10 bg-white">
                                <Row className={`p-0 m-0`}>
                                    {store.football.fanzineTeamsLeagues.length && store.football.fanzineTeamsLeagues.map((item,index) => {
                                        return (<Col key={index} xs="12" md="12"
                                                     className={`text-left d-flex flex-column justify-content-center p-0`}>
                                            {item.external ? <a href={item.link}
                                                                className={`dropdown-item bg-white text-black text-decoration-none ${isMobile ? 'pt-2 sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'} h-100 d-flex flex-column justify-content-center`}>
                                                {' ' + item.name}
                                            </a> : <NavLink to={item.external ? '' : item.link}
                                                            className={`dropdown-item text-black bg-white text-decoration-none ${isMobile ? 'pt-2 sfProDisplayFontBold font-13' : 'sfProDisplayFontBold' +
                                                                ' font-16'} h-100 d-flex flex-column justify-content-center`}
                                                            onClick={handleCollapse}>
                                                <div className={'flex-row justify-content-start align-middle text-black'}>
                                                    {/*<img src={navLogo} alt="" style={{width: "50px", height: "auto"}}/>*/}
                                                    {item.name}
                                                </div>
                                            </NavLink>}
                                        </Col>);
                                    })}
                                </Row>
                            </div>
                        </NavDropdown>
                    </Col>
                    </Row>
                </div>
                <NavItem
                    className={`d-flex flex-column justify-content-center nav-link mt-2 ${isMobile ? 'pt-2' : ''}`}>
                    <NavLink to={'/cricket'}
                             className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        Cricket
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 nav-link ${isMobile ? 'pt-2' : ''}`}>
                    <NavLink to={'/nba'}
                             className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        NBA
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 nav-link ${isMobile ? 'pt-2' : ''}`}>
                    <NavLink to={'/nfl'}
                             className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        NFL
                    </NavLink>
                </NavItem>
                <NavItem
                    className={`d-flex flex-column justify-content-center mt-2 nav-link ${isMobile ? 'pt-2' : ''}`}>
                    <NavLink to={'/mlb'}
                             className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        MLB
                    </NavLink>
                </NavItem>
                {/*<NavItem
                    className={`d-flex flex-column justify-content-center mt-2 nav-link ${isMobile ? 'pt-2' : ''}`}>
                    <NavLink to={'/news?teamType=team'}
                             className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                        More Sports
                    </NavLink>
                </NavItem>*/}
            </NavDropdown>
            <NavItem
                className={`d-flex flex-column justify-content-center mt-2 nav-link border-bottom ${isMobile ? 'pt-2' : ''}`}>
                <NavLink to={'/nfts'}
                         className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                    NFTs
                </NavLink>
            </NavItem>
            <NavItem
                className={`d-flex flex-column justify-content-center mt-2 nav-link border-bottom ${isMobile ? 'pt-2' : ''}`}>
                <NavLink to={'/shop'}
                         className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                    Shop
                </NavLink>
            </NavItem>
            <NavItem
                className={`d-flex flex-column justify-content-center mt-2 nav-link ${isMobile ? 'pt-2' : ''} border-bottom`}>
                <NavLink to={'/contact'}
                         className={`text-black text-decoration-none ${isMobile ? 'sfProDisplayFont font-13' : 'sfProDisplayFontBold font-16'}`} onClick={handleMainCollapse}>
                    Contact
                </NavLink>
            </NavItem>
        </Menu>);
    }

    function renderSocialNav() {
        return (<div className={`p-4 mt-5 w-100 border-top`}>
            <div className={`${fontColor} sfProDisplayFont`}>
                Follow us for the best {navName} content
            </div>
            <div className={'mt-3'}>
                <a href={store.misc.appInit && store.misc.appInit.facebook_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faFacebook} className={fontColor} size={'3x'}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.twitter_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faTwitter} size={'3x'} className={`ms-4 ${fontColor}`}/>
                </a>
                <a href={store.misc.appInit && store.misc.appInit.instagram_url} className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faInstagram} size={'3x'} className={`ms-4 ${fontColor}`}/>
                </a>
            </div>
        </div>);
    }

    const render = () => {
        return (<div className={`fixed-top`} ref={ref}>
            <Navbar collapseOnSelect expand="md" className={`nav-bar navbar-dark ${navColor} ${navShadow} ${fontColor}`}>
                <div className={`${isMobile ? 'w-100' : 'container'}`}>
                    {isMobile ? (<div className={`${isMobile ? "row" : ""}`}>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 ${isMobile && 'col-5 text-center'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                            </NavLink>
                        </Navbar.Brand>
                        <div className="bm-overlay" id={'overlay'}></div>
                        <div className={`bm-burger-button ${isMobile && 'col-5 d-inline-flex float-end justify-content-end'} ${fontColor}`}>
                            <button id="react-burger-menu-btn" type="button" aria-label="Toggle navigation"
                                    className={`${fontColor} float-end text-end navbar-toggler collapsed`}
                                    onClick={() => {
                                        setIsComponentVisible(true);
                                        setIsCollapsed(!isCollapsed);
                                    }}>
                                <FontAwesomeIcon icon={faBars} className={fontColor} size={'2x'}/>
                            </button>
                        </div>
                        {isComponentVisible && isMobile && renderMobileNav()}
                        {!isMobile && renderMainNav()}
                    </div>) : (<>
                        <div className={` ${isMobile && 'col-2 d-inline-flex'}`}>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                           className={`${fontColor} float-start text-start`}
                                           id={'react-burger-menu-btn'}
                                           onClick={() => {
                                               setIsComponentVisible(true);
                                               setIsCollapsed(!isCollapsed);
                                           }}/>
                        </div>

                        <Navbar.Brand className={`mt-xxl-3 mt-xl-3 mt-lg-3 pb-0 ${isMobile && 'col-5 text-end'}`}
                                      style={{zIndex: 1}}>
                            <NavLink to={'/'} className={'text-decoration-none ps-4'}>
                                <img src={navLogo}
                                     alt={navName}
                                     {...imageProps}
                                />
                            </NavLink>
                        </Navbar.Brand>
                        {isComponentVisible && isMobile && renderMobileNav()}
                        {!isMobile && renderMainNav()}
                    </>)}
                </div>
            </Navbar>
        </div>)
    }

    return render();
}

export default FanzineNav;
