import NewsCard from '../../../NewsCard';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Loader from '../../../../../Components/Loader/Loader';
import {get} from '../../../../../Axios/get';
import {
  FANZINE,
  HomePageVideoEndPoint,
  NewsEndPoint,
} from '../../../../../Axios/EndPoints';
import moment from 'moment-timezone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import PreviewNews from './Components/PreviewNews';

const SingleFootballMatchContentOverview = (props) => {
  const params = useParams();
  const {isMobile} = props;
  const [isLoading, setIsLoading] = useState(true);
  const [latestVideos, setLatestVideos] = useState([]);
  const queryParams = useLocation();
  const [apiPage, setApiPage] = useState(1);
  const [isNew, setIsNew] = useState(false);
  const [eventsData, setEventData] = useState(null);
  const [eventsOverview, setEventsOverview] = useState(null);
  const [overview, setOverview] = useState(null);

  const addDefaultSrc = (ev) => {
    ev.target.src = require('../../../../../assets/images/default-league.png');
  };

  const isCurrent = props.currentActive === 'overview';

  const fetchMatchEvent = () => {
    let searchParams = queryParams.search;

    if (!searchParams) {
      searchParams = '?main_site=1&snack=1';
    }
    let limit = 2;
    let page = `&page=${apiPage}`;

    get(HomePageVideoEndPoint + searchParams + limit + page).then((res) => {
      let tempVideosData = [];

      res.data.map((data) => {
        let news = {
          news_id: data.news_id,
          slug: data.id, // slug: slugify(data.title),
          news_image: data.preview_image,
          news_title: data.title,
          news_url: data.url,
          news_time: moment.tz(data.published_at.date, 'Europe/London').
              local().
              startOf('seconds').
              fromNow(),
          news_date: moment(data.published_at.date).format('D MMM YYYY, H:mm'),
          news_publisher: {
            publisher_name: data.publisher.name,
            publisher_icon: data.publisher.icon,
          },
          is_snack: data.is_snack,
          main_team: false,
          type: 'videos',
        };
        tempVideosData.push(news);
      });

      if (isNew) {
        setLatestVideos(tempVideosData);
      } else {
        setLatestVideos([...latestVideos, ...tempVideosData]);
      }
      setIsLoading(false);
    }).catch((error) => console.log(error));
  };

  const fetchMatchOverview = () => {

    let id = params.id;
    let parameter = queryParams.search;
    if (parameter === '') {
      parameter = '?match_id=' + id;
    }

    get(FANZINE.MatchOverviewEvents + parameter).then((res) => {
      let tmp = res.data.events.reverse();
      setEventData(tmp);
      setEventsOverview(res.data);
    }).catch((error) => {
      console.error('error =', error);
    });
  };

  const fetchOverview = () => {
    let id = params.id;
    let parameter = queryParams.search;
    if (parameter === '') {
      parameter = '?match_id=' + id;
    }

    get(FANZINE.SingleMatchEndPoint + params.id +
        FANZINE.MatchesOverviewSubEndPoint).then((res) => {
      // console.log('res =', res.data)
      setOverview(res.data.data);
    }).catch((error) => {
      console.error('error =', error);
    });
  };

  useEffect(() => {
    isCurrent && fetchMatchEvent();
    isCurrent && fetchOverview();
    eventsData == null && fetchMatchOverview();
    setIsLoading(false);
  }, [isCurrent]);

  const renderSubs = (value) => {

    return value?.type === 'subst' &&
        <div
            className={`col-7 ${value.team === 'localteam' ?
                'me-auto' :
                'ms-auto'} mt-2 rounded-custom bg-background d-flex justify-content-end align-items-center`}>
          {value.team !== 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/csub.png')}
                   alt={'sub'}
                   height={'35'} width={'35'}/>
          }
          <div className={`container mt-2`}>
            <div className={'row'}>
              <div className={'col'} style={{color: '#2b2b2b'}}>
                <p className={`sfProDisplayFont font-14 ${value.team ===
                'localteam' ? 'text-end' : 'text-start'}`}>
                  {value?.minute}'
                  <p className={`sfProDisplayFontBold ${value.team ===
                  'localteam' ?
                      'text-end' :
                      'text-start'} font-14`}>
                    {value?.player}
                    <p className={`sfProDisplayFont ${value.team ===
                    'localteam' ?
                        'text-end' :
                        'text-start'} font-12`}>
                      {value?.assist}
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
          {value.team === 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/csub.png')}
                   alt={'subs'}
                   height={'35'} width={'35'}/>
          }
        </div>;

  };

  const renderMissedPenalty = (value) => {

    return value?.type === 'subst' &&
        <div
            className={`col-7 ${value.team === 'localteam' ?
                'me-auto' :
                'ms-auto'} mt-2 rounded-custom bg-background d-flex justify-content-end align-items-center`}>
          {value.team !== 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/csub.png')}
                   alt={'sub'}
                   height={'35'} width={'35'}/>
          }
          <div className={`container mt-2`}>
            <div className={'row'}>
              <div className={'col'} style={{color: '#2b2b2b'}}>
                <p className={`sfProDisplayFont font-14 ${value.team ===
                'localteam' ? 'text-end' : 'text-start'}`}>
                  {value?.minute}'
                  <p className={`sfProDisplayFontBold ${value.team ===
                  'localteam' ?
                      'text-end' :
                      'text-start'} font-14`}>
                    {value?.player}
                    <p className={`sfProDisplayFont ${value.team ===
                    'localteam' ?
                        'text-end' :
                        'text-start'} font-12`}>
                      {value?.assist}
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
          {value.team === 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/csub.png')}
                   alt={'subs'}
                   height={'35'} width={'35'}/>
          }
        </div>;

  };

  const renderYellowCard = (value) => {
    return value?.type === 'yellowcard' &&
        <div
            className={`col-7 ${value.team === 'localteam' ?
                'me-auto pe-2' :
                'ms-auto ps-2'} mt-2 rounded-custom bg-background d-flex justify-content-end align-items-center`}>
          {value.team !== 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/cyellowcard.png')}
                   alt={'yellow'}
                   height={'35'} width={'35'}/>
          }
          <div className={` container mt-2`}>
            <div className={'row'}>
              <div className={'col'} style={{color: '#2b2b2b'}}>
                <p className={`sfProDisplayFont font-14 ${value.team ===
                'localteam' ? 'text-end' : 'text-start'}`}>
                  {value?.minute}'
                  <p className={`sfProDisplayFontBold ${value.team ===
                  'localteam' ?
                      'text-end' :
                      'text-start'} font-14`}>
                    {value?.player}
                    <p className={`sfProDisplayFont ${value.team ===
                    'localteam' ?
                        'text-end' :
                        'text-start'} font-12`}>
                      {value?.assist}
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
          {value.team === 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/cyellowcard.png')}
                   alt={'yellow'}
                   height={'35'} width={'35'}/>
          }
        </div>;
  };

  const renderYellowRedCard = (value) => {
    return value?.type === 'yellowred' &&
        <div
            className={`col-7 ${value.team === 'localteam' ?
                'me-auto pe-2' :
                'ms-auto ps-2'} mt-2 rounded-custom bg-background d-flex justify-content-end align-items-center`}>
          {value.team !== 'localteam' &&
              <img src={require('../../../../../assets/images/yellowred.png')}
                   alt={'yellow'}
                   height={'35'} width={'35'}/>
          }
          <div className={` container mt-2`}>
            <div className={'row'}>
              <div className={'col'} style={{color: '#2b2b2b'}}>
                <p className={`sfProDisplayFont font-14 ${value.team ===
                'localteam' ? 'text-end' : 'text-start'}`}>
                  {value?.minute}'
                  <p className={`sfProDisplayFontBold ${value.team ===
                  'localteam' ?
                      'text-end' :
                      'text-start'} font-14`}>
                    {value?.player}
                    <p className={`sfProDisplayFont ${value.team ===
                    'localteam' ?
                        'text-end' :
                        'text-start'} font-12`}>
                      {value?.assist}
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
          {value.team === 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/cyellowcard.png')}
                   alt={'yellow'}
                   height={'35'} width={'35'}/>
          }
        </div>;
  };

  const renderRedCard = (value) => {
    return value?.type === 'redcard' &&
        <div
            className={`col-7 ${value.team === 'localteam' ?
                'me-auto pe-2' :
                'ms-auto ps-2'} mt-2 rounded-custom bg-background d-flex justify-content-end align-items-center`}>
          {value.team !== 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/credcard.png')}
                   alt={'red'}
                   height={'35'} width={'35'}/>
          }
          <div className={`container mt-2`}>
            <div className={'row'}>
              <div className={'col'}
                   style={{color: '#2b2b2b'}}>
                <p className={`sfProDisplayFont font-14 ${value.team ===
                'localteam' ? 'text-end' : 'text-start'}`}>
                  {value?.minute}'
                  <p className={`sfProDisplayFontBold ${value.team ===
                  'localteam' ?
                      'text-end' :
                      'text-start'} font-14`}>
                    {value?.player}
                    <p className={`sfProDisplayFont ${value.team ===
                    'localteam' ?
                        'text-end' :
                        'text-start'} font-12`}>
                      {value?.assist}
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
          {value.team === 'localteam' &&
              <img src={require(
                  '../../../../../assets/images/credcard.png')}
                   alt={'red'}
                   height={'35'} width={'35'}/>
          }
        </div>;
  };

  const renderGoals = (value) => {
    return value.type === 'goal' && <div
        className={`col-8 p-2 ${value.team === 'localteam' ?
            'me-auto' :
            'ms-auto'} mt-2 rounded-custom justify-content-end align-items-center`}
        style={{
          background: `url(` +
              require('../../../../../assets/images/goal_bg.png') + `)`,
          backgroundSize: 'cover',
        }}>
      <div className={`${!isMobile && 'container'} mt-2`}

           style={{color: '#ffff'}}>
        <div
            className={`d-flex ${value.team === 'localteam' ?
                'justify-content-start' :
                'justify-content-end'}  `}>
          <img
              src={value.team === 'localteam' ?
                  eventsOverview?.localteam?.localteam_icon :
                  eventsOverview?.visitorteam?.visitorteam_icon}
              style={{
                background: 'white',
                borderRadius: '50px',
              }}
              onError={addDefaultSrc} alt={'team'}
              height={'25'} width={'25'}/>
          <p className={'ms-2 bebasFont text-end font-18'}>
            GOAL !
          </p>
        </div>

        <div className={'d-flex'}>
          {value.team === 'localteam' &&
              <img src={value?.playerImage}
                   className={'me-auto'}
                   style={{
                     background: 'white',
                     borderRadius: '50px',
                   }}
                   onError={addDefaultSrc} alt={'player'}
                   height={'50'}
                   width={'50'}/>
          }
          {value.team !== 'localteam' &&
              <img src={'#'} style={{
                background: 'white',
                borderRadius: '50px',
              }}
                   onError={addDefaultSrc} alt={'ball'}
                   height={'35'}
                   width={'35'}
                   className={` ${value.team === 'localteam' ?
                       'ms-auto' :
                       'me-auto'} `}
              />
          }
          <p className={`sfProDisplayFont font-14 ${value.team ===
          'localteam' ?
              'text-end me-2' :
              'me-auto text-start'}`}>
            {value?.minute}'
            <p className={`sfProDisplayFontBold ${value.team ===
            'localteam' ?
                'text-end' :
                'text-start'} font-12`}>
              {value?.player}
            </p>
          </p>
          {value.team === 'localteam' &&
              <img src={'#'} style={{
                background: 'white',
                borderRadius: '50px',
              }}
                   onError={addDefaultSrc} alt={'ball'}
                   height={'35'}
                   width={'35'}/>}

          {value.team !== 'localteam' &&
              <img src={value?.playerImage}
                   className={'ms-auto'}
                   style={{
                     background: 'white',
                     borderRadius: '50%',
                     objectFit: 'cover',
                   }}
                   onError={addDefaultSrc} alt={'player'}
                   height={'60'}
                   width={'60'}
              />
          }
        </div>

        {value?.has_video === true &&
            <div className={'d-flex mb-2'}>
              <div
                  className={' d-flex align-items-center justify-content-center '}
                  style={{
                    border: '1px solid white',
                    borderRadius: '18px',
                    height: '36px',
                    marginLeft: (value.team === 'localteam' &&
                        !isMobile) ?
                        '-23px' :
                        '',
                    width: isMobile ? '120px' : '150px',
                  }}>
                <FontAwesomeIcon
                    className={` ${value.team === 'localteam' ?
                        '' :
                        ''} `}
                    icon={faYoutube} color={'white'}
                    size={'2x'}/>
                {<a
                    href={value?.video_link}
                    target={'_blank'}
                    className={`text-decoration-none sfProDisplayFont ms-2 text-center text-white ${isMobile ?
                        'font-10' :
                        'font-14'} d-flex align-items-center`}
                    style={{lineHeight: 'normal'}}>
                  Watch Goal
                </a>
                }
              </div>
              {value?.assistImage !== '' && <div
                  className={'d-flex text-end font-10 align-items-center justify-content-center'}>
                &nbsp;
                {value?.assist}
                &nbsp;
                <img src={value?.assistImage}
                     className={'ms-1 me-auto'}
                     style={{
                       background: 'white',
                       borderRadius: '50%',
                       objectFit: 'cover',
                     }}
                     onError={addDefaultSrc} alt={'assist'}
                     height={'23'}
                     width={'23'}
                />
              </div>
              }          </div>
        }

      </div>
    </div>;

  };

  const renderHighlights = () => {
    return (
        <div className={'container-fluid'}>
          <div className={'row'}>

            {overview?.highlight && <video
                controls className={'p-4'}
                style={{borderRadius: '33px'}}
            >
              <source src={overview?.highlight} type="video/webm"></source>
              <source src={overview?.highlight} type="video/ogg"></source>
              <source src={overview?.highlight} type="video/mp4"></source>
            </video>
            }          </div>

        </div>
    );
  };

  const renderMain = () => {
    return (
        <divs>
          <div
              className={`mt-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ${isMobile &&
              'bg-white rounded-custom pb-1'}`}
          >
            <div className={'mt-3 mb-3'}>
              <p className={'sfProDisplayFontLight font-22 text-center'}>
                Key Events
              </p>
              {renderHighlights()}

              <div className={'container-fluid'}>
                {overview != null &&
                    overview?.events?.events?.reverse()?.map((value, index) => (
                        <div key={index} className={'container-fluid'}>
                          {renderSubs(value)}
                          {renderYellowCard(value)}
                          {renderYellowRedCard(value)}
                          {renderRedCard(value)}
                          {renderGoals(value)}
                        </div>
                    ))
                }
              </div>
            </div>
          </div>
          <PreviewNews isCurrent={isCurrent}/>
        </divs>
    );
  };

  return (<>
    {isLoading ? <Loader loading={isLoading}/> :
        renderMain()
    }
  </>);

};

export default SingleFootballMatchContentOverview;