import {MATCH_STATES} from '../../Configs/Constants';
import moment from 'moment/moment';
import DateOnlyItem from './HomeMatchTabContainerDateOnlyItem';
import {AiOutlineCaretRight} from 'react-icons/ai';
import React, {useEffect, useState} from 'react';

const ContainerItem = (props) => {

  const {displayArray,dateValue, dateIndex, currentDate,isMobile}= props

  let matchPreparedData =
      {
        homeIcon: dateValue?.home_team?.icon,
        awayIcon: dateValue?.guest_team?.icon,
        homeTeam: dateValue?.home_team?.abbr || dateValue?.home_team?.name.slice(0, 3),
        awayTeam: dateValue?.guest_team?.abbr || dateValue?.guest_team?.name.slice(0, 3),
        homeScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ? dateValue?.total.slice(0, 1) : '',
        awayScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ? dateValue?.total.slice(-1) : '',
        currentTime: dateValue?.current_time_match,
        isLive: (dateValue?.state !== 1 && MATCH_STATES.IN_PLAY.includes(dateValue?.state)) ? 'Live' :
            MATCH_STATES.FINISHED.includes(dateValue?.state) ? 'Full-Time' :
                MATCH_STATES.UPCOMING.includes(dateValue?.state) ? moment(dateValue?.datetime).format('ddd HH:mm') + ' KO' : ''
      }

  return (
      <div
          style={{
            height: '120px',
            flex: isMobile ? '' : '0.7 0',
          }}
          className={`d-flex slide shadow ms-2 me-2 bg-white d-flex `}>
        {currentDate?.date !== dateValue?.date && displayArray[dateIndex] && <DateOnlyItem isMobile={isMobile} currentDate={currentDate} value={dateValue} index={dateIndex} />}
        <div className={displayArray[dateIndex]  ? ' shadow-bottom' : ' shadow-bottom'}>
          <div className={'row ms-0 w-100'}>
            <div className={''}>
              <div className={'row'}>
                <div
                    className={`${matchPreparedData?.isLive !== 'Live' ?
                        'col-12 justify-content-center' :
                        'col-5 pt-1'} ${matchPreparedData?.isLive === 'Live' &&
                    'bg-yellow'}`}
                    style={{height: isMobile ? '15px' : '20px'}}
                >
                  <p className={`d-flex robotoCondensedBold ${(matchPreparedData?.isLive !==
                      'Live' || matchPreparedData?.isLive !== 'Full-Time') ?
                      isMobile ? 'font-8' : 'font-11' :
                      'font-12'} text-center`}>
                    {matchPreparedData?.isLive}
                    &nbsp;
                    {/*<AiOutlineCaretRight size={14}/>*/}
                  </p>
                </div>
                {
                  matchPreparedData.isLive === 'Live' ?
                      (
                          <div
                              className={`col pt-1 text-end`}
                              style={{height: isMobile ? '15px' : '20px'}}
                          >
                            <p className={`d-flex robotoCondensedBold font-11 justify-content-end`}>
                              {dateValue?.currentTime}
                            </p>
                          </div>
                      ) : (<></>)
                }
              </div>
            </div>
            <div className={`col-12 ${'mt-2'} `}>
              <div className={'row'}>
                <div className={'col-4 d-flex justify-content-center'}>
                  <img
                      style={{
                        width: isMobile ? '20px' : '35px',
                        height: isMobile ? '20px' : '35px',
                      }}
                      alt={'homeTeam'}
                      src={matchPreparedData?.homeIcon}
                  />
                </div>
                <div className={`col-4 ${isMobile ?
                'font-12' : 'font-20'} robotoCondensedBold text-center`}>
                  {matchPreparedData?.homeTeam}
                </div>
                <div
                    className={`col-4 robotoCondensedBold text-center ${isMobile ?
                    'font-12' : 'font-20'}`}>
                  {matchPreparedData?.homeScore}
                </div>
              </div>
            </div>
            <div className={'col-12 pb-2 mt-2'}>
              <div className={'row'}>
                <div className={'col-4 d-flex justify-content-center'}>
                  <img
                      style={{
                        width: isMobile ? '20px' : '35px',
                        height: isMobile ? '20px' : '35px',
                      }}
                      alt={'awayTeam'}
                      src={matchPreparedData?.awayIcon}
                  />
                </div>
                <div className={`col-4 ${isMobile ?
                'font-12' : 'font-20'} robotoCondensedBold text-center`}>
                  {matchPreparedData?.awayTeam}
                </div>
                <div
                    className={`col-4 robotoCondensedBold text-center ${isMobile ?
                    'font-12' : 'font-20'}`}>
                  {matchPreparedData?.awayScore}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ContainerItem;
