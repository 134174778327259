import PropTypes from "prop-types";
import {Form, FormControl, InputGroup} from "react-bootstrap";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import countries from '../../countries.json';
import "react-select-search/style.css";
import SelectSearch, {fuzzySearch} from 'react-select-search';
import {useEffect, useState} from "react";
import moment from "moment";
import {SCREEN_SIZE} from "../../Configs/Constants";

const CampaignQuestion = (props) => {

    const question = props.question;
    const teams = ['Team', 'Teams', 'Club', 'Clubs', 'Team you support'];

    const [phoneCode, setPhoneCode] = useState('44');
    const [date, setDate] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(teams.includes(question.question) ? props.currentTeamName : null);
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        updateScreenWidth();
    }, []);

    useEffect(() => {
        if (width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [width]);

    useEffect(() => {
        setSelectedTeam(props.currentTeamName);
    }, [props.currentTeamName]);

    useEffect(() => {
        let event = {
            target: {
                value: props.currentTeamName
            }
        }
        if (props.answers && teams.includes(question.question)) {
            setAnswer(event);
        }
    }, [props.answers]);

    const setAnswer = (event) => {
        props.setAnswers((prevState) => {
                let tempAnswer = prevState;
                tempAnswer[question.id]['answer'] = event.target.value;

                return tempAnswer;
            }
        );
    }

    const setDateAnswer = (value) => {
        if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
            alert('Please select correct date');
            setDate('');
            return false;
        }
        props.setAnswers((prevState) => {
                let tempAnswer = prevState;
                tempAnswer[question.id]['answer'] = value.format('YYYY-MM-DD');

                return tempAnswer;
            }
        );
    }

    const setPhoneAnswer = (event) => {
        props.setAnswers((prevState) => {
                let tempAnswer = prevState;
                tempAnswer[question.id]['answer'] = '+' + phoneCode + '' + event.target.value;

                return tempAnswer;
            }
        );
    }

    const renderTextInput = (placeholder, name, email = false) => {
        return (
            <input type={email ? 'email' : 'text'} placeholder={placeholder} className={'form-control input-lg'} onChange={setAnswer} name={name}/>
        );
    }

    const renderTextArea = (placeholder, name) => {
        return (
            <textarea placeholder={placeholder} className={'form-text'} onChange={setAnswer} name={name}/>
        );
    }

    const renderDropdown = (placeholder, options, name) => {
        if (placeholder === 'Title') {
            return (
                <select className={`form-select ${placeholder === 'Title' ? (isMobile ? 'w-50' : 'w-25') : ''}`} onChange={setAnswer} name={name}>
                    <option disabled={true} selected={true}>{placeholder}</option>
                    {
                        options.map((value) => {
                            return (
                                <option>{value.option}</option>
                            );
                        })
                    }
                </select>
            );
        }
        return (
            <select className={`form-select ${placeholder === 'Title' ? 'w-25' : ''}`}
                    onChange={(event) => {
                        setAnswer(event);
                        setSelectedTeam(event.target.value);
                    }}
                    name={name}
                    value={selectedTeam}
            >
                <option selected={true}>{placeholder}</option>
                {
                    options.map((value) => {
                        return (
                            <option>{value.option}</option>
                        );
                    })
                }
            </select>
        );
    }

    const renderCheckboxes = (placeholder, options, name) => {
        return (
            <div>
                {placeholder}
                {
                    options.map((value) => {
                        return (
                            <Form.Check
                                inline
                                label={value.option}
                                name={name}
                                type={'checkbox'}
                                id={`inline-${value.option}-1`}
                                onSelect={setAnswer}
                            />
                        );
                    })
                }
            </div>
        );
    }

    const renderRadioButton = (placeholder, options, name) => {
        return (
            <div>
                <span className={`sfProDisplayFontBold`}>{placeholder}</span>
                &nbsp;&nbsp;
                {
                    options.map((value) => {
                        return (
                            <Form.Check
                                inline
                                label={value.option}
                                name={name}
                                type={'radio'}
                                id={`inline-${value.option}-1`}
                            />
                        );
                    })
                }
            </div>
        );
    }

    const renderDate = (placeholder, name) => {
        let inputProps = {
            placeholder: placeholder,
            disabled: false,
        };
        return (
            /*<input type={'date'} placeholder={placeholder} className={'form-control'}/>*/
            <Datetime
                closeOnSelect={true}
                closeOnClickOutside={true}
                inputProps={inputProps}
                timeFormat={false}
                readonly
                onChange={setDateAnswer}
                name={name}
                value={date}
            />
        );
    }

    const renderPhone = (placeholder, name) => {
        const options = [];

        countries.map((value) => (
            options.push({
                name: value.phonecode + ' ( ' + value.nicename + ' ) ',
                value: value.phonecode,
            })
        ));

        return (
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1" className={`p-0 ${isMobile ? 'w-100' : 'w-35'}`}>
                    <SelectSearch options={options} value={phoneCode} name="countries" placeholder={'Country Code'}
                                  closeOnSelect={true}
                                  search
                                  filterOptions={fuzzySearch}
                                  emptyMessage="Not found"
                                  onChange={setPhoneCode}
                                  className={`select-search ${isMobile ? 'w-100' : ''}`}
                    />
                </InputGroup.Text>
                <FormControl
                    placeholder={placeholder}
                    aria-label={placeholder}
                    aria-describedby="basic-addon1"
                    type={'number'}
                    onChange={setPhoneAnswer}
                    name={name}
                    className={`col-sm-12 ${isMobile ? '' : 'ms-3'}`}
                />
            </InputGroup>
        );
    }

    const render = () => {
        let typeId = question.type_id;
        let placeholder = question.question;
        let options = question.options;

        switch (typeId) {
            case 'TI':
                return ['Phone', 'Country code'].includes(placeholder) ? renderPhone(placeholder) : renderTextInput(placeholder);
            case 'TA':
                return renderTextArea(placeholder, question.id);
            case 'DD':
                return renderDropdown(placeholder, options, question.id);
            case 'CB':
                return renderCheckboxes(placeholder, options, question.id);
            case 'RB':
                return renderRadioButton(placeholder, options, question.id);
            case 'DATE':
                return renderDate(placeholder, question.id);
            default:
                return renderTextInput(placeholder, question.id);
        }
    }

    return render();

}

CampaignQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    setAnswers: PropTypes.func.isRequired,
    currentTeamName: PropTypes.string,
};

export default CampaignQuestion;