import {useEffect, useState} from "react";
import {get} from "../Axios/get";
import {FANZINE} from "../Axios/EndPoints";
import {Card} from "react-bootstrap";
import {withRouter} from "../CustomFunctions/withRouter";
import {SCREEN_SIZE} from "../Configs/Constants";
import Loader from "../Components/Loader/Loader";
import {Link} from "react-router-dom";
import SingleAccordian from "../Components/Accordian/Accordian";
import faqs from "../assets/data/faqs.json";

const NFTs = () => {

    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [isMobile, setIsMobile] = useState(false);

    document.body.style = isMobile ? 'padding-top: 70px !important' : 'padding-top: 10px !important';

    const fetchNfts = () => {
        get(FANZINE.NFTEndPoint)
            .then((response) => {
                setNfts(response.data.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    useEffect(() => {
        fetchNfts();
    }, []);

    const renderHeader = () => {
        return (
            <>
                <img src={require('../assets/images/nft/listings/Component 96 – 1@3x.png')} alt={'Fanzine Football NFTs'} className={'img-fluid'}/>
                <div className={`container`}>
                    <div className={`${isMobile ? 'col-3' : 'col-12'}`}>
                        <img src={require('../assets/images/nft/listings/Uruguay Dog #1.png')} alt={'Fanzine Football NFT Uruguay'}
                             className={`mt-n-nft-banner img-fluid rounded-custom shadow bg-white p-2`}
                        />
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`mt-3 sfProDisplayFontBold font-30`}>
                        World Cup Dogs
                    </div>
                    <div className={`mt-3 font-16`}>
                        <span className={`sfProDisplayFont`}>By </span>
                        <span className={`sfProDisplayFontBold`}> Fanzine</span>
                    </div>
                    <div className={`mt-3 sfProDisplayFont font-16`}>
                        Fanzine’s mission is to become the biggest sports fan community in the world, with the FANS at the very centre of everything we do.
                    </div>
                </div>
            </>
        );
    }

    const renderNFT = (nft) => {
        return (

            <Card className={`rounded-custom border-0 shadow-sm`}>
                <Card.Img variant="top" src={nft.image} className={`rounded-top-custom-1`}/>
                {
                    nft.sold_out && <Card.ImgOverlay
                        className={`rounded-custom d-flex align-items-start flex-column justify-content-center`}
                        style={{backgroundColor: 'rgb(27,27,27, 0.7)'}}
                    >
                        <Card.Text
                            className={`text-white text-center bebasFont font-nft-overlay-title w-100`}
                            style={{opacity: '.75'}}
                        >
                            SOLD OUT
                        </Card.Text>
                    </Card.ImgOverlay>
                }
                <Card.Body>
                    <Card.Text>
                        <div className={`sfProDisplayFontBold font-18`}>{nft.name}</div>
                        <div className={`sfProDisplayFontBold font-18 mt-1`}>{parseFloat(nft.current_price)} ETH</div>
                        <div className={`font-16 mt-1 mb-1 text-nowrap`}>
                            <span className={`sfProDisplayFont`}>Nationality: </span>
                            <span className={`sfProDisplayFontBold font-fanzine`}>{nft.team.name}</span>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

    const renderNFTList = () => {
        return (
            <div className={`container mt-5`}>
                <div className={`row`}>
                    {
                        nfts.map((nft, index) => (
                            <div className={`mb-4 ${isMobile ? 'col-6' : 'col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6'} ${isMobile && index % 2 === 0 ? 'pe-1' : 'ps-1'}`}>
                                {/*<a href={nft.url} target={'_blank'} rel="noreferrer" className={`text-decoration-none text-black`}>*/}
                                <Link to={`${nft.sold_out ? '#' : `/nft/${nft.id}`}`} className={`text-decoration-none text-black`}>
                                    {renderNFT(nft)}
                                    {/*</a>*/}
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const renderFAQ = () => {

        return (<div
            // id={'nft-faq'}
            className={'container pb-5 pt-5'}>
            <div>
                <h1
                    style={{
                        fontSize: isMobile ? '30px' : '40px',
                        textTransform: 'uppercase',
                        lineHeight: isMobile ? '200%' : '120px',
                        fontWeight: 'bold'
                    }}
                    className={"text-white text-center azo-sans"}>
                    Frequently Asked Questions (FAQ)
                </h1>
            </div>
            {faqs.map((faq, index) => (
                <SingleAccordian index={index} isMobile={isMobile} open={false} value={faq}/>))}
        </div>)
    }

    const render = () => {
        return (
            <div className={``}>
                <Loader loading={loading}/>
                {renderHeader()}
                <div className={`container`}>
                    <span className={`w-100 float-end text-end sfProDisplayFontBold font-16`}>{nfts.length} Items</span>
                </div>
                {renderNFTList()}
                <div id={'nft-faq'} className={'nft-fanzine-bg mt-5'}>
                    {renderFAQ()}
                </div>
            </div>
        );
    }

    return render();

}

export default withRouter(NFTs);
