import './App.css';
import {get} from './Axios/get';
import {AppsList, FANZINE, ProductColors, ShippingZonesEndPoint, ShopImagesEndpoint, SportsList, StoreCategories, WebLinks,} from './Axios/EndPoints';
import {setDefaultLeagues, setFanzineLeagues, setFootballLeagues, setNavbarFanzineTeamsLeagues, setNavbarLeagues, setNavbarTeamTypes,} from './redux/actions/FooballLeagueActions';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {graphql} from './Axios/graphql';
import {ShopifyGraphQlQueries} from './Configs/ShopifyGraphQlQueries';
import {SHOPIFY} from './Configs/Constants';
import {setBannerImages, setCategories, setContainerImages, setProductColors, setProductTags, setProductTypes,} from './redux/actions/StoreActions';
import {setTimeZone} from './redux/actions/TimeZoneActions';
import {setAppInit} from './redux/actions/MiscActions';
import {getHostname} from './CustomFunctions/getHostname';
import AppRoutes from './AppRoutes';
import {FIREBASE_CONFIG} from "./Configs/Targets";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

function App() {
    let store = useSelector((state) => state);
    let dispatch = useDispatch();

    let [reduxStore, setReduxStore] = useState(false);
    let [loading, setLoading] = useState(true);

    const fetchFanzineFootballLeaguesTeams = () => {
        get(AppsList).then((response) => {
            let apps = response.data.data;
            let currentLeague = '';
            let previousLeague = '';
            let tempApps = [
                {
                    name: 'All Football',
                    link: '/football',
                    external: false,
                },
            ];
            apps.map((data) => {
                currentLeague = data.league_gs_id;
                if (currentLeague !== previousLeague) {
                    tempApps.push({
                        name: data.league_name,
                        link: `/football?league_id=${data.league_gs_id}`,
                        external: false,
                        league: true,
                    });
                }
                if (data.web_default_app_url !== '') {
                    tempApps.push({
                        name: data.team_name,
                        link:
                            data.web_default_app_url !== ''
                                ? '//' +
                                data.web_default_app_url.replace('https://', '').replace('http://', '')
                                : '/football',
                        external: data.web_default_app_url !== '',
                        league: false,
                    });
                }
                previousLeague = data.league_gs_id;
            });
            for (let i = 0; i < tempApps.length - 1; i++) {
                if (tempApps[i].league && tempApps[i + 1].league) {
                    tempApps.splice(i, 1);
                    i--;
                }
            }
            // tempApps.sort((a, b) => a.team_name - b.team_name)
            dispatch(setNavbarFanzineTeamsLeagues(tempApps));
            setReduxStore(true);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchFanzineFootballLeague = async () => {
        await get(FANZINE.LeaguesEndPoint + '?web=1').then((response) => {
            console.log("response in App: ", response.data.leagues);
            let leagues = response.data.leagues;
            dispatch(setFanzineLeagues(leagues));
            setReduxStore(true);
            console.log('store =', store);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchFootballLeaguesTeamTypes = () => {
        get(SportsList).then((response) => {
            let leagues = response.data[0].leagues;
            let teamTypes = response.data[0].teamTypes;
            dispatch(setFootballLeagues(leagues));
            let tempNewsLeagues = [];
            leagues.map((data) => {
                if (data.show_on_news) {
                    tempNewsLeagues.push(data);
                    if (data.default) {
                        dispatch(setDefaultLeagues(data));
                    }
                }
            });
            let tempNewsTeamTypes = [];
            teamTypes.map((data) => {
                tempNewsTeamTypes.push(data);
                if (data.default) {
                    dispatch(setDefaultLeagues(data));
                }
            });
            tempNewsLeagues.sort((a, b) => a.sort - b.sort);
            dispatch(setNavbarLeagues(tempNewsLeagues));
            dispatch(setNavbarTeamTypes(tempNewsTeamTypes));
            setReduxStore(true);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchProductTags = () => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_TAGS)).then((response) => {
            let productTags = response.data.data.productTags.edges;
            if (productTags.length) {
                let tempProductTags = [];
                productTags.map((value) => {
                    if (value.node.toLowerCase() !== 'accessories') {
                        tempProductTags.push(value.node, value.node.toLowerCase());
                    }
                });
                dispatch(setProductTags(tempProductTags));
            }
        });
    };

    const fetchProductTypes = () => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_TYPES)).then((response) => {
            let productTypes = response.data.data.productTypes.edges;
            if (productTypes.length) {
                let tempProductTypes = [];
                productTypes.map((value) =>
                    tempProductTypes.push(value.node, value.node.toLowerCase()),
                );
                dispatch(setProductTypes(tempProductTypes));
            }
        });
    };

    const fetchCategories = () => {
        get(StoreCategories).then((response) => {
            dispatch(setCategories(response.data));
        });
    };

    const fetchProductColors = () => {
        get(ProductColors).then((response) => {
            let tempColors = {};
            response.data.map((value) => (tempColors[value.name] = value.hex));
            dispatch(setProductColors(tempColors));
        });
    };

    const fetchShippingZones = () => {
        get(ShippingZonesEndPoint).then((response) => {
            if (response.data.code === 200) {
                let tempShippingZone = [];
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (timezone === 'Europe/London') {
                    tempShippingZone = response.data.body.shipping_zones.filter(
                        (value) => value.name === 'UK Mainland',
                    );
                } else {
                    tempShippingZone = response.data.body.shipping_zones.filter(
                        (value) => value.name === 'Rest of World',
                    );
                }
                dispatch(setTimeZone(tempShippingZone[0]));
            }
        }).catch((err) => {
            console.error('Shipping Zone API error: ', err);
        });
    };

    const fetchShopImages = () => {
        get(ShopImagesEndpoint).then((response) => {
            if (response.data.success) {
                dispatch(setContainerImages(response.data.data.containerImages));
                dispatch(setBannerImages(response.data.data.bannerImages));
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLinkDetails = () => {
        get(WebLinks).then((response) => {
            if (response.data.success) {
                dispatch(setAppInit(response.data.data));
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        if (['fanzinesports', 'fanzine'].indexOf(target) <= -1) {
            if (
                typeof store.football.footballLeagues === 'undefined' ||
                store.football.footballLeagues.length === 0 ||
                store.football === 0
            ) {
                setLoading(true);
                fetchFootballLeaguesTeamTypes();
            }
            if (
                typeof store.shopify.productTags === 'undefined' ||
                store.shopify.productTags.length === 0
            ) {
                fetchProductTags();
            }
            if (
                typeof store.shopify.productTypes === 'undefined' ||
                store.shopify.productTypes.length === 0
            ) {
                fetchProductTypes();
            }
            if (
                typeof store.shopify.categories === 'undefined' ||
                store.shopify.categories.length === 0
            ) {
                fetchCategories();
            }
            if (
                typeof store.shopify.productColors === 'undefined' ||
                store.shopify.productColors.length === 0
            ) {
                fetchProductColors();
            }
            if (
                typeof store.timezone.timezone === 'undefined' ||
                store.timezone.timezone.length === 0
            ) {
                fetchShippingZones();
            }
            if (
                typeof store.misc.appInit === 'undefined' ||
                store.misc.appInit === null
            ) {
                fetchLinkDetails();
            }
            if (
                typeof store.shopify.containerImages === 'undefined' ||
                store.shopify.containerImages.length === 0
            ) {
                fetchShopImages();
            }
        }
        if (target === 'fanzine') {
            initFanzine();
        }
        // localStorage.clear();
        if (FIREBASE_CONFIG[target]) {
            const firebaseConfig = FIREBASE_CONFIG[target];

            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
        }
    }, []);

    const initFanzine = () => {
        if (
            typeof store.football.fanzineTeamsLeagues === 'undefined' ||
            store.football.fanzineTeamsLeagues.length === 0 ||
            store.football === 0
        ) {
            setLoading(true);
            fetchFanzineFootballLeaguesTeams();
            fetchFanzineFootballLeague();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
    };

    return <AppRoutes loading={loading} reduxStore={reduxStore}/>;
}

export default App;
