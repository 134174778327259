import {Col, FormControl, FormLabel, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import './styles/fanzine.css';
import Loader from "../Components/Loader/Loader";
import {post} from "../Axios/post";
import {ContactUsEndpoint} from "../Axios/EndPoints";
import {SCREEN_SIZE} from "../Configs/Constants";
import validator from "validator/es";

const ContactUs = () => {

    let [loading, setLoading] = useState(false);
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [subject, setSubject] = useState('');
    let [message, setMessage] = useState('');
    let [isMobile, setIsMobile] = useState('');

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorSubject, setErrorSubject] = useState(false);

    function onSubmit() {
        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }
        if (subject === '') {
            canBeSubmitted = false;
            setErrorSubject(true);
        }

        if (canBeSubmitted) {
            setLoading(true);
            let formData = {
                to: ['support@fanzine.com', 'junaid@codestudio.com.pk',],
                name: name,
                phone: phone,
                email: email,
                subject: subject,
                body: message,
            };

            post(ContactUsEndpoint, formData).then((response) => {
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfWvMAhAAAAAPr6XLLaX05ZmCrmMtAJhkZXpC2z"
        // script.src = "https://www.google.com/recaptcha/api.js"
        // script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const render = () => {
        return (
            <>
                <div className={`${!isMobile ? 'container-fluid mt-n4' : ''} pt-n150`}>
                    <div
                        className={`col-md-10 col-lg-10 col-xl-10 offset-md-1 offset-lg-1 offset-xl-1 contact-top text-center font-48 sfProDisplayFontBold text-white flex-column justify-content-center d-flex`}>
                        Get in Touch
                    </div>
                    {/*<img src={'https://cdn.fanzine.com/assets/images/fanzine/contact-us-bg.jpg'} className={`img-fluid`} alt={''}/>*/}
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d130408.67698281583!2d0.10411383990483748!3d51.57911971605853!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8bb258cb6e0dd%3A0x12d684212a30a2d5!2sHornchurch%20Rd%2C%20Hornchurch%20RM12%204TQ!5e0!3m2!1sen!2suk!4v1639649386978!5m2!1sen!2suk"
                    width="100%" height="500" allowFullScreen="" loading="lazy"/>
                <div className={`contact-bottom mt-n3 pt-5`}>
                    <div className={`container-fluid`}>
                        <div className={`${!isMobile ? 'row' : ''} mt-n3 pt-5`}>
                            <div className={`col-md-6 col-sm-12 offset-md-2 offset-sm-0 font-24 sfProDisplayFontBold text-white`}>
                                <div className={`container`}>
                                    <Loader loading={loading}/>
                                    <form className={`p-3 pb-5`}>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl
                                                    isInvalid={errorName}
                                                    type="text"
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder="Name"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={name}
                                                    onChange={(e) => {
                                                        setErrorName(false);
                                                        setName(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input name</FormControl.Feedback>
                                            </Col>
                                            <Col>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl
                                                    isInvalid={errorEmail}
                                                    type="email"
                                                    id="email"
                                                    aria-describedby="email"
                                                    placeholder="Email"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setErrorEmail(false);
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid email</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Telephone</FormLabel>
                                                <FormControl
                                                    isInvalid={errorPhone}
                                                    type="number"
                                                    id="phone"
                                                    aria-describedby="phone"
                                                    placeholder="Telephone"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={phone}
                                                    onChange={(e) => {
                                                        setErrorPhone(false);
                                                        setPhone(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid phone number</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Subject</FormLabel>
                                                <FormControl
                                                    isInvalid={errorSubject}
                                                    id="subject"
                                                    aria-describedby="subject"
                                                    placeholder="Subject"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={subject}
                                                    onChange={(e) => {
                                                        setErrorSubject(false);
                                                        setSubject(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input subject</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <label>
                                                    Message
                                                </label>
                                                <textarea className={`form-control`} rows={10} value={message} onChange={(e) => {
                                                    setMessage(e.target.value)
                                                }}>{message}</textarea>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <button type={"button"} className={`btn float-start text-white btn-lg btn-secondary`} onClick={onSubmit}>Submit</button>
                                            </Col>
                                            <Col>
                                                <div
                                                    className="g-recaptcha float-end"
                                                    data-sitekey="6LfWvMAhAAAAAPr6XLLaX05ZmCrmMtAJhkZXpC2z"
                                                    data-size="invisible"
                                                >
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                            <div className={`${isMobile ? 'container ps-4 pb-3' : 'col-md-4'} text-white text-start`}>
                                <div className={`sfProDisplayFontBold font-32 mb-4`}>
                                    Find Us
                                </div>
                                <div className={`sfProDisplayFont font-26 mb-4`}>
                                    Fanzine Limited
                                </div>
                                <div className={`sfProDisplayFont font-26 mb-4`}>
                                    319a Hornchurch Road
                                </div>
                                <div className={`sfProDisplayFont font-26 mb-4`}>
                                    Hornchurch
                                </div>
                                <div className={`sfProDisplayFont font-26 mb-4`}>
                                    RM12 4TQ
                                </div>
                                <div className={`sfProDisplayFontBold font-32 mt-3 mb-5`}>
                                    Contact Us
                                </div>
                                <div className={`sfProDisplayFontBold font-26 mt-3 mb-5`}>
                                    Email: <a href={'mailto:support@fanzine.com'}>support@fanzine.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
            ;
    }

    return render();
}

export default ContactUs;
