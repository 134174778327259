import React, {useEffect, useRef, useState} from "react";
import {get} from "../../../Axios/get";
import {HomePageVideoEndPoint} from "../../../Axios/EndPoints";
import VideoCard from "../../Cards/VideoCard";
import Divider from "../Divider";
import {SCREEN_SIZE} from "../../../Configs/Constants";
import moment from "moment";
import {getHostname} from "../../../CustomFunctions/getHostname";
import {LOADING_GIF} from "../../../Configs/Targets";
import Billboard from "../../SnackAds/Billboard";
import MPU from "../../SnackAds/MPU";

const VideoSection = (props) => {

    const mainVideoRef = useRef(null);

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [video, setVideo] = useState(null);
    let [videos, setVideos] = useState([]);
    let [homePageVideoLimit] = useState(5);
    let [topVideosLimit] = useState(5);
    let [moreVideosPage, setMoreVideosPage] = useState(topVideosLimit - 3);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let loadingGif = LOADING_GIF[target];

    let isMobile = props.isMobile;

    const fetchVideos = () => {
        let limit = "?limit=" + homePageVideoLimit;
        get(HomePageVideoEndPoint + limit)
            .then((response) => {
                let tempVideoData = [];
                response.data.map((data) => {

                    let videoData = {
                        id: data.id,
                        title: data.title,
                        url: data.url,
                        preview_image: data.preview_image,
                        video_time: moment.utc(data.published_at.date).local().startOf('seconds').fromNow(true),
                        publisher: {
                            icon: data.publisher.icon,
                            name: data.publisher.name,
                        },
                    };
                    return tempVideoData.push(videoData);
                });
                setVideos(tempVideoData.slice(1));
                setVideo(tempVideoData[0]);


            })
            .catch((err) => {
                console.error(err);
            })
    }

    const fetchMoreVideos = () => {
        let limit = "&limit=2";
        let page = "?page=" + moreVideosPage;
        get(HomePageVideoEndPoint + page + limit)
            .then((response) => {
                let tempMoreVideos = [];
                response.data.map((data) => {
                    let videoData = {
                        id: data.id,
                        title: data.title,
                        url: data.url,
                        preview_image: data.preview_image,
                        video_time: moment.utc(data.published_at.date).local().startOf('seconds').fromNow(true),
                        publisher: {
                            icon: data.publisher.icon,
                            name: data.publisher.name,
                        },
                    };
                    return tempMoreVideos.push(videoData);
                });
                // setVideos([...videos, ...tempMoreVideos]);
                setVideos([...videos, ...tempMoreVideos]);
                setMoreVideosPage(moreVideosPage + 1);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        fetchVideos();
    }, []);

    const handleScrollEnd = (event) => {
        const bottom = event.target.scrollWidth - event.target.scrollLeft === event.target.clientWidth;
        if (bottom) {
            fetchMoreVideos();
        }
    }

    const withData = () => {

        let isMobile = screenWidth < SCREEN_SIZE.MOBILE;

        return (
            <div className={'mt-2'} ref={mainVideoRef}>
                {video && <VideoCard videoHeight={"480"} main={true} extraClass={'col'} video={video} isMobile={!isMobile}/>}
                <div className={'row'}>
                    <div
                        className={'col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 mt-3'}>
                        {!isMobile ? renderBig() : renderMobile()}
                    </div>
                    <div
                        className={'mt-3 col-xxl-3 col-xl-3 col-lg-4 col-sm-12 single-mpu d-flex justify-content-center flex-column align-items-center d-none d-lg-inline-flex d-xl-inline-flex' +
                            ' d-xxl-inline-flex'}>
                        {isMobile ? <MPU id={'snack_mex7'}/> : <MPU id={'snack_dex7'}/>}
                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center h1 d-none d-md-flex mt-2">
                        {isMobile ? <Billboard id={'snack_mex6'}/> : <Billboard id={'snack_dex6'}/>}
                    </div>
                </div>
            </div>
        );
    }

    const withoutData = () => {
        return (
            <div className={'bebasFont'}>
                No Data Found
            </div>
        );
    }

    const renderMobile = () => {
        return (
            <>
                <div className={`${isMobile && 'mt-5'} slider-mobile-container`} onScroll={handleScrollEnd}>
                    {
                        videos && videos.map((value) => (
                            <VideoCard videoHeight={"200"} extraClass={'slider-mobile-card'} video={value} mainVideo={video} setMainVideo={setVideo} mainVideoRef={mainVideoRef}/>
                        ))
                    }
                    <div className={`slider-mobile-card d-flex flex-column justify-content-center align-middle text-center`} style={{height: '164px'}}>
                        <img src={loadingGif}
                             className={`align-self-center`}
                             alt="loader" width="50"/>
                    </div>
                </div>
                {/*<div onClick={fetchMoreVideos}>
                    <Divider text={'More Videos'}/>
                </div>*/}
            </>
        );
    }

    const renderBig = () => {
        return (
            <>
                <div className={'col-12'}>
                    <div className={'mt-3 row'}>
                        {
                            videos && videos.map((value) => (
                                <VideoCard videoHeight={"200"} extraClass={'col-xxl-6 col-xl-6 col-lg-6 col-md-6'} video={value} mainVideo={video} setMainVideo={setVideo} mainVideoRef={mainVideoRef}/>
                            ))
                        }
                    </div>
                </div>
                <div onClick={fetchMoreVideos}>
                    <Divider text={'See more video news'}/>
                </div>
            </>
        );
    }

    const render = () => {
        if (videos.length) {
            return withData();
        }
        return withoutData();
    }

    return render();
}

export default VideoSection;