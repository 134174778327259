import {useEffect, useState} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import '../styles/MatchItem.css';
import {MATCH_STATES} from '../../../../Configs/Constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {FaCircle} from 'react-icons/fa';

const MatchItem = (props) => {
  const {
    matches,
    liveMatchData,
    selectedDate,
    setIsSingleMatchSelected,
    setSingleMatchData,
    isMobile,
  } = props;
  const [isMatchLive, setIsMatchLive] = useState(false);
  const [liveMatchResult, setLiveMatchResult] = useState(null);

  const [homeTeamIcon, setHomeTeamIcon] = useState(
      (matches?.home_team.icon.includes('resource.gunners') &&
          matches?.home_team.icon.replace('resource.gunners',
              'testcdn.fanzine').replace('storage/', ''))
      ||
      (matches?.home_team.icon.includes('testcdn') &&
          matches?.home_team.icon.replace('testcdn', 'cdn')),
  );

  const [guestTeamIcon, setGuestTeamIcon] = useState(
      (matches?.guest_team.icon.includes('resource.gunners') &&
          matches?.guest_team.icon.replace('resource.gunners',
              'testcdn.fanzine').replace('storage/', ''))
      ||
      (matches?.guest_team.icon.includes('testcdn') &&
          matches?.guest_team.icon.replace('testcdn', 'cdn')),
  );

  let match;
  if (matches) {
    match = matches;
  }

  const SetIsMatchLiveStatus = () => {
    if (MATCH_STATES.FINISHED.includes(match?.state) || MATCH_STATES.IN_PLAY.includes(match?.state)) {
      setIsMatchLive(true);
    } else {
      setIsMatchLive(false);
    }
  };

  useEffect(() => {
    SetIsMatchLiveStatus();
  }, [matches]);

  const UpdateState = (value) => {
    setLiveMatchResult(value);
  };

  useEffect(() => {

    if (selectedDate === moment(Date.now()).format('YYYY-MM-DD')) {
      // eslint-disable-next-line array-callback-return
      liveMatchData?.map((value) => {
        if (matches?.id === value.id) {
          UpdateState(value);
        }
      });

      // setLiveMatchResult(liveMatchData);
    }

  }, [liveMatchData]);

  const handleSingleMatchSelect = (value) => {
    setIsSingleMatchSelected(true);
    setSingleMatchData(value);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = require('../../../../assets/images/default-league.png');
  };

  let SingleMatchPageSection = '';
  if (isMatchLive) {
    SingleMatchPageSection = 'overview';
  } else {
    SingleMatchPageSection = 'preview';

  }

  return (
      <Link
          to={`/football/matches/${matches?.id}/${SingleMatchPageSection}`}
          className="row  bg-white mt-2 mb-2 p-4 center-items text-decoration-none text-black"
          key={matches?.id}
          style={{cursor: 'pointer'}}
          onClick={() => {
            handleSingleMatchSelect(matches);
          }}
      >
        <div
            className={matches?.home_team.name.length > 13 ?
                `col-4 text-end fixed d-flex align-items-center justify-content-end sfProDisplayFont font-14` :
                `font-14 ${isMobile &&
                'd-flex align-items-center'} sfProDisplayFont col-4 text-end fixed`}
            style={{color: '#252525'}}>
          {matches?.home_team.name}
          <img
              className={`ms-2`}
              height={25}
              width={27}
              style={{objectFit:'contain'}}
              alt={'team badge'}
              src={homeTeamIcon ||
                  matches?.home_team.icon.replace('testcdn', 'cdn')}
              onError={addDefaultSrc}
          ></img>
        </div>
        <div className="col-3 text-center font-14 sfProDisplayFontBold">
          {(liveMatchResult?.state || matches?.state) === 3 ?
              moment.utc(matches?.datetime || Date.now()).local().format('HH:mm')
              :
              <div className={'row ms-auto me-auto'}
                   style={{width: 'max-content',
                     display: MATCH_STATES.POSTPONED.includes(
                         (liveMatchResult?.state || matches?.state)) && 'none',
              }}>
                <div
                    className={'col-3 me-1 justify-content-center d-flex h-25px w-25px align-items-center'}
                    style={{
                      background: [1, 2, 10, 11].includes(
                          liveMatchResult?.state || matches?.state) ?
                          'green' :
                          'yellow',
                      color: [1, 2, 10, 11].includes(
                          liveMatchResult?.state || matches?.state) ?
                          'white' :
                          'black',
                    }}>
                  {liveMatchResult?.total.slice(0, -3) || matches?.total.slice(0, -3)}
                </div>
                <div
                    className={'col-3 justify-content-center d-flex h-25px w-25px align-items-center'}
                    style={{
                      background: [1, 2, 10, 11].includes(
                          liveMatchResult?.state || matches?.state) ? 'green' :
                          (liveMatchResult?.state == 9 || matches?.state == 9) ?
                              'yellow' :
                              '#f1f1f1',
                      color: [1, 2, 10, 11].includes(
                          liveMatchResult?.state || matches?.state) ?
                          'white' :
                          'black',
                    }}>
                  {liveMatchResult?.total.slice(3) || matches?.total.slice(3)}
                </div>
              </div>
          }
          {
              MATCH_STATES.UPCOMING.includes(
                  (liveMatchResult?.state || matches?.state))
              && (liveMatchResult?.current_time_match ||matches?.current_time_match)
          }
          {
              MATCH_STATES.POSTPONED.includes(
                  (liveMatchResult?.state || matches.state))
              && 'Postponed'
            // (liveMatchResult?.current_time_match || matches.current_time_match)
          }
          {
              MATCH_STATES.ABANDONED.includes(
                  (liveMatchResult?.state || matches.state))
              && 'Abandoned'
            // (liveMatchResult?.current_time_match || matches.current_time_match)
          }
          {
              MATCH_STATES.CANCELLED.includes(
                  (liveMatchResult?.state || matches.state))
              && 'Cancelled'
            // (liveMatchResult?.current_time_match || matches.current_time_match)
          }
          {
              MATCH_STATES.SUSPENDED.includes(
                  (liveMatchResult?.state || matches.state))
              && 'Suspended'
            // (liveMatchResult?.current_time_match || matches.current_time_match)
          }
          {
              MATCH_STATES.INTERRUPTED.includes(
                  (liveMatchResult?.state || matches.state))
              && 'Interrupted'
            // (liveMatchResult?.current_time_match || matches.current_time_match)
          }
        </div>
        <div className={`col-4 sfProDisplayFont font-14 ${isMobile &&
        'd-flex align-items-center'}`}
             style={{display: 'flex', alignItems: 'center', color: '#252525'}}>
          <img
              className={`me-2`}
              height={25}
              width={27}
              style={{objectFit:'contain'}}
              alt={'team badge'}
              src={guestTeamIcon ||
                  matches?.guest_team.icon.replace('testcdn', 'cdn')}
              onError={addDefaultSrc}
          ></img>
          {matches?.guest_team.name}
        </div>
        <div className={'col-1 text-center font-14 sfProDisplayFontBold'}
            /*   style={{
              color: ([1, 2].includes(matches?.state) || [1, 2].includes(liveMatchResult?.state)) ? "white" : "black",
              background: (([1, 2].includes(matches?.state) || [1, 2].includes(liveMatchResult?.state))
                  && (liveMatchResult?.current_time_match != '' || matches?.current_time_match != '')
              ) ? "green" : (liveMatchResult?.state == 9 || matches?.state == 9) ? "yellow" : "#f1f1f1",
              borderRadius: '50%',
              padding: '2%',
          }}*/
        >

          {/*<span className="fa-layers fa-fw" style={{background: 'green'}}>*/}
          {/*  <i className="fas fa-calendar" />*/}
          {/*  <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-8 down-3" style={{fontWeight: 900}}>27</span>*/}
          {/*</span>*/}

          <span
              className="fa-layers fa-fw d-flex justify-content-center align-items-center"
              style={{
                color: ([1, 2].includes(matches?.state) ||
                    [1, 2].includes(liveMatchResult?.state)) ?
                    'white' :
                    'black',
              }}
          >
            <p
                className={'d-flex align-items-center justify-content-center ps-0 ms-2 mt-3'}
                style={{zIndex: '2'}}
            >
            {MATCH_STATES.IN_PLAY.includes(
                    (liveMatchResult?.state || matches?.state))
                && (liveMatchResult?.current_time_match ||
                    matches?.current_time_match)
            }
            </p>
          <FontAwesomeIcon icon={faCircle}
                           size={'2x'}
                           style={{
                             color: (([1, 2].includes(matches?.state) ||
                                     [1, 2].includes(liveMatchResult?.state))
                                 &&
                                 (liveMatchResult?.current_time_match != '' ||
                                     matches?.current_time_match != '')
                             ) ?
                                 'green' :
                                 (liveMatchResult?.state == 9 ||
                                     matches?.state == 9) ?
                                     'yellow' :
                                     '#fff',
                           }}
          >
          </FontAwesomeIcon>
          </span>

        </div>
      </Link>
  );
};

export default MatchItem;

