import {useEffect} from "react";
import {getHostname} from "../../CustomFunctions/getHostname";
import {VIDEO_ADS} from "../../Configs/Targets";

const VideoAd = () => {
    useEffect(() => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const unitId = VIDEO_ADS[target];

        const script = document.createElement('script');

        script.src = `https://live.primis.tech/live/liveView.php?s=${unitId}&schain=1.0,1!snack-media.com,SNM_2536,1`;
        script.async = true;

        document.getElementById('snackVideoAd').appendChild(script);

        return () => {
            document.getElementById('snackVideoAd').removeChild(script);
        }
    }, []);

    return (
        <div id={'snackVideoAd'}>

        </div>
    );
}

export default VideoAd;