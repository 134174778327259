import {getCountryForTimezone} from "countries-and-timezones";
import {get} from "../Axios/get";
import {OneLinkEndPoint} from "../Axios/EndPoints";
import {useEffect} from "react";
import OpaqueLoader from "../Components/Loader/OpaqueLoader";

export const OneLink = () => {

    const getAppLink = () => {
        let timezone = getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        console.log("countryCode: ", timezone);
        get(OneLinkEndPoint + "/" + timezone.id)
            .then((response) => {
                console.log(response);
                window.location.href = response.data.data.redirect_uri;
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getAppLink();
    }, [])

    return (

        <OpaqueLoader loading={true}/>);
}