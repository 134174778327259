import React, {useState} from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {NAV_CLASS} from "../../Configs/Targets";
import {getHostname} from "../../CustomFunctions/getHostname";
import {ColorIcon} from "../ColorIcon/ColorIcon";

const ProductComponent = (props) => {
    const store = useSelector(state => state);

    const [imageSrc, setImageSrc] = useState(props.item.src);

    const render = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let arrowColor = NAV_CLASS[target] + '-before';
        let showArrows = 'block';
        let item = props.item;
        let showDetail = props.showDetail;
        let showColors = props.showColors;
        let showPriceFirst = props.showPriceFirst;
        let priceColor = props.priceColor ? props.priceColor : '#000000';
        let productColorHex = store.shopify.productColors;

        return (
            <>
                <NavLink to={`/shop/product/${item.handle}`} className={'text-decoration-none text-black'}>
                    <div key={item.id} className={'slide ms-1 me-1'}>
                        {item.onSale && <div className="slick-slide-title-right">
                                <span className={'bg-sale text-dark rounded-circle small p-3 bebasFont'}>
                                    Sale
                                </span>
                        </div>}
                        <img src={imageSrc} alt={item.alt} className={'img-fluid'}/>
                        {item.bestSeller && <div className="slick-slide-label-left">
                                <span className={'bg-yellow text-blue small pt-0 ps-1 pe-1 pb-0 sfProDisplayFont'}>
                                    Best Seller
                                </span>
                        </div>}
                    </div>
                </NavLink>
                {
                    showDetail &&
                    (<div className={'container'}>
                        {showPriceFirst &&
                            (<div className={'text-center sfProDisplayFont font-weight-800'}>
                                {item.oldPrice && (<span className={'text-decoration-line-through me-3 small'}
                                                         style={{color: '#929191'}}>
                                                £{item.oldPrice}
                                            </span>)}
                                <span style={{color: priceColor}}>
                                                £{item.price || "£0.00"}
                                            </span>
                            </div>)
                        }
                        <div className={'text-center sfProDisplayFont'}>
                            {item.name}
                        </div>
                        {!showPriceFirst && (
                            <div className={'text-center sfProDisplayFont font-weight-800'} style={{color: priceColor}}>
                                £{item.price}
                            </div>)}
                        {
                            props.showColors &&
                            (
                                <div className={'text-center sfProDisplayFont font-weight-800'}>
                                    {
                                        item.colors && item.colors.map((value) => {
                                            console.log("product color: ", value);
                                            return (
                                                /*<FontAwesomeIcon icon={faCircle} color={productColorHex[value]} className={`bg-dark rounded-circle shadow-nav ms-1 me-1`} size={'1x'}
                                                                 onClick={() => {
                                                                     setImageSrc(item.colorImages[value])
                                                                 }} style={{cursor: "pointer"}}
                                                                 title={value}/>*/
                                                <div onClick={() => {
                                                    setImageSrc(item.colorImages[value])
                                                }} style={{display: "inline-block"}}>
                                                    <ColorIcon color={value} colorHex={productColorHex} filters={false}/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>)
                }
            </>

        );
    }

    return render();
}

ProductComponent.propTypes = {
    item: PropTypes.object,
    showColors: PropTypes.bool,
};

export default ProductComponent;