import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPlay} from "@fortawesome/free-solid-svg-icons";
import NewsCard from "../../NewsCard";
import Billboard from "../../../../Components/SnackAds/Billboard";
import React, {useEffect, useState} from "react";
import AllFootballMatchesNews from "./AllFootballMatchesNews";

const RightbarNews = (props) =>
{
    const {isMobile,isFanzine} = props;

    return(
        <Container
            className={`mb-3`}
        >
            <div className={"customContainer"}>
                {/*<div className="row ">*/}
                {/*            <span className={'mt-lg-2'}>*/}
                {/*                <h5 className={'sfProDisplayFontBold font-16'}>*/}
                {/*                    <FontAwesomeIcon*/}
                {/*                        icon={faClock}*/}
                {/*                        size={"30"}*/}
                {/*                        color={"green"}*/}
                {/*                    />*/}
                {/*                    &nbsp;&nbsp;*/}
                {/*                    Latest News*/}
                {/*                </h5>*/}
                {/*            </span>*/}
                {/*</div>*/}
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className={`row`}>
                        <AllFootballMatchesNews isFanzine={isFanzine} isMobile={isMobile} />
                    </div>
                </div>
            </div>
        </Container>
    )

}

export default RightbarNews;
