import React from 'react';

export default class GoogleAd extends React.Component {
    componentDidMount() {
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <div className={'billboard'}>
                {/*<ins className={'adsbygoogle'}
                     style={{display: 'block', textAlign: 'center'}}
                     data-ad-client={'ca-pub-1362215158154361'}
                     data-ad-layout="in-article"
                     data-ad-slot={this.props.id}
                     data-ad-format={'auto'}
                     data-adtest={'on'}
                     data-full-width-responsive="true"
                />*/}
            </div>
        );
    }
}
