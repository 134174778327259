import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import './styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import YoutubeEmbed from '../YoutubeEmbed';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html.modern';
import {getHostname} from '../../CustomFunctions/getHostname';
import {TEXT_COLOR} from '../../Configs/Targets';
import {NavLink} from 'react-router-dom';
import {setSingleVideo} from '../../redux/actions/MiscActions';
import {useDispatch} from 'react-redux';



const VideoCard = (props) => {

  let dispatch = useDispatch();

  let [isOpen, setIsOpen] = useState(false);
  let [stopMain, setStopMain] = useState(true);
  let [videoData, setVideoData] = useState(props.video);

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const textColor = props.isFanzineAllFootball ? props?.league?.font_color ? props?.league?.font_color  : 'text-black' : TEXT_COLOR[target];

  const openModal = () => {
    setIsOpen(true);
    setStopMain(false);
  };

  const updateMainVideo = () => {
    let mainVideo = props.mainVideo;
    let setMainVideo = props.setMainVideo;
    let mainVideoRef = props.mainVideoRef;
    let newMainVideo = videoData;

    newMainVideo['url'] = newMainVideo['url'] + '?autoplay=1';

    setMainVideo(newMainVideo);
    setVideoData(mainVideo);
    mainVideoRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    window.scrollBy(0, -100);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = require('../../assets/images/default_publisher_icon.png');
  };

  useEffect(() => {
    setVideoData(props.video);
  }, [props.video]);


  const render = () => {
    let extraClass = props.extraClass;
    let videoHeight = props.videoHeight;
    let isMain = typeof props.main !== 'undefined';
    let isMobile = typeof props.isMobile !== 'undefined';
    let titleClass = isMain && !isMobile ? 'h3' : 'small';
    let bottomClass = isMain && !isMobile ? 'h5' : 'small';
    let publisherImageHeight = isMain && props.isMobile ? 30 : 15;
    let titleWidth = isMobile && isMain ? 'w-100' : 'w-100';
    if (!videoData) {
      return (<></>);
    }

    const handleClick = () =>
    {
      console.log('videoData =', videoData)
      dispatch(setSingleVideo(videoData));
      window.location.replace(`/${props.sport}/video/${videoData?.id}`)
    }


    return (
        <div className={extraClass} onClick={props.isFanzineAllFootball ? handleClick : updateMainVideo}>
          <Card
              style={{
                width: props.isFanzineAllFootball && '360px'}}
              className="col border-0 bg-transparent">
            {
              isMain ?
                  <YoutubeEmbed videoUrl={videoData.url}
                                videoHeight={videoHeight} stopVideo={stopMain}
                                autoplay={videoData.autoplay}/> :
                  <img src={videoData.preview_image}
                       style={{objectFit: props.isFanzineAllFootball && 'none'}}
                       className={`img-fluid`} alt={'Fanzine'}/>
            }

            {
                !isMain &&
                (<Card.ImgOverlay
                    className={`d-flex align-items-start flex-column justify-content-end ${props.isFanzineAllFootball ? 'video-overlay-slider'  : 'news-overlay'} `}
                    onClick={openModal}>
                  <Card.Text className={`text-white ${props?.isFanzineAllFootball ? 'robotoCondensedBold font-18'  :  'robotoCondensedBold'}`}>
                    {
                        !props?.isFanzineAllFootball &&
                       <> <img onError={addDefaultSrc}
                             src={videoData.publisher?.icon} alt="News"
                             height={20}/> &nbsp; </>
                    }
                    {props?.isFanzineAllFootball ?
                      videoData?.title.slice(0,40)+'...' :
                      videoData.publisher?.name}


                  </Card.Text>
                </Card.ImgOverlay>)
            }
          </Card>
          <div className="row p-2">
            <div className={`justify-content-between ${isMain ?
                'col-9' :
                'col-12'}`}>
                        <span
                            className={`robotoCondensedBold ${titleClass} ${titleWidth} d-flex justify-content-between`}
                            /*dangerouslySetInnerHTML={{__html: videoData.title}}*/>
                            <HTMLEllipsis
                                unsafeHTML={props?.isFanzineAllFootball ?
                                    videoData.publisher?.name :
                                    videoData?.title}
                                maxLine="3"
                                ellipsis="..."
                                basedOn="letters"
                                style={{height: '2em', color: props.isFanzineAllFootball ? props?.league?.font_color ? props?.league?.font_color  : 'text-black' : TEXT_COLOR[target]

                                  , className:'roboto-medium'}}
                            />
                          {
                              props.isFanzineAllFootball &&
                              <span
                                  className={`font-16 robotoCondensedBold ${textColor}`}>
                          {(videoData?.video_time || videoData?.duration) ==
                          null ? ( videoData?.publisher?.name.length + ' min') : (videoData?.video_time || videoData?.duration)}
                                        </span>}
                        </span>
              <span className={`text-muted robotoCondensedBold ${bottomClass}`}>
                            {
                              isMain ?
                                  (
                                      <>
                                        <div className={`row`}>
                                          <div
                                              className={`col-9 d-flex justify-content-start`}>
                                                    <span
                                                        className={`d-flex align-items-center`}>
                                                        <img
                                                            onError={addDefaultSrc}
                                                            src={videoData.publisher.icon}
                                                            alt="News"
                                                            height={publisherImageHeight}/>
                                                    </span>
                                            &nbsp; <span
                                              className={`d-flex align-items-center font-16`}>{videoData.publisher.name}</span>
                                          </div>
                                        </div>
                                      </>
                                  ) :
                                  (
                                      <span className={`${isMobile ?
                                          'float-start' :
                                          'float-end'} text-muted font-16 font-weight-800 robotoCondensedBold ${textColor}`}>
                                        {(videoData?.video_time || videoData?.duration)  }
                                        </span>
                                  )
                            }

                        </span>
            </div>

            {
                isMain && (
                    <div className={`col-3 d-flex justify-content-end p-2`}>
                                                    <span
                                                        className={`d-flex align-items-start robotoCondensedBold font-16 font-weight-800 ${textColor}`}>
                                                        {videoData.video_time}
                                                    </span>
                    </div>
                )
            }
          </div>
        </div>
    );
  };

  return render();
};

VideoCard.propTypes = {
  videoHeight: PropTypes.string.isRequired,
  main: PropTypes.bool,
  extraClass: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default VideoCard;
