import moment from 'moment/moment';
import React from 'react';

const DateOnlyItem = (props) => {
    const {value, index, currentDate, isMobile} = props

    return (
        <div
            key={index}
            className={`col-4 pt-1 shadow bg-fanzine-dark text-white robotoCondensedBold text-center`}>
            <div className={'col-12'}>
                <h6 className={`robotoCondensedBold ${isMobile ?
                    'font-9' :
                    'font-14'}`}>
                    {moment(value?.date).format('ddd') || 'Mon'}
                </h6>
            </div>
            <div className={'col-12'}>
                <h3 className={`robotoCondensedBold ${isMobile ?
                    'font-11' :
                    'font-30'}`}>
                    {moment(value?.date).format('DD') || '26'}
                </h3>
            </div>
            <div className={'col-12'}>
                <h6 className={`robotoCondensedBold ${isMobile ?
                    'font-9' :
                    'font-14'}`}>
                    {moment(value?.date).format('MMM') || 'Dec'}
                </h6>
            </div>
        </div>
    );
};

export default DateOnlyItem;
