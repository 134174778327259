import React, {useEffect, useState} from "react";
import {withRouter} from "../CustomFunctions/withRouter";
import {get} from "../Axios/get";
import {Card, Container} from "react-bootstrap";
import moment from "moment";
import {TEXT_COLOR} from "../Configs/Targets";
import {getHostname} from "../CustomFunctions/getHostname";
import {SCREEN_SIZE, SPORTS} from "../Configs/Constants";
import Loader from "../Components/Loader/Loader";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import NewsCard from "../FanzineCom/Components/NewsCard";
import VideoAd from "../Components/SnackAds/VideoAd";
import GoogleAd from "../Components/SnackAds/GoogleAd";

const SingleVideoPage = (props) => {
    let newsId = props.match.params.id;
    let sport = props.match.params.sport;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let textColor = TEXT_COLOR[target];

    const store = useSelector((state) => state);

    let [latestNews, setLatestNews] = useState([]);
    let [relatedStories, setRelatedStories] = useState([]);
    let [news, setNews] = useState(null);
    let [video, setVideo] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../assets/images/football-shots-you-need-to-know.jpg");
    }

    const fetchSingleNews = () => {
        get(`/api-almet/v2.0/${SPORTS[sport]}/videos/${newsId}`)
            .then((response) => {
                let data = response.data;
                setNews({
                    id: data.id,
                    title: data.title,
                    url: data.url,
                    preview_image: data.preview_image,
                    date: moment(data.datetime).format('D MMMM YYYY'),
                    time: moment(data.datetime).format('HH:mm'),
                    video_time: moment.utc(data.datetime).local().startOf('seconds').fromNow(true),
                    publisher: {
                        icon: data.publisher.icon,
                        name: data.publisher.name,
                    },
                });
                setLoading(false);
            })
    }

    const fetchLatestNews = () => {
        let searchParams = '?main_site=1';
        let limit = `&limit=27`;

        let newsEndpoint = `/api-almet/v2.0/${SPORTS[sport]}/news` + searchParams + limit;
        let videosEndpoint = `/api-almet/v2.0/${SPORTS[sport]}/videos` + searchParams + limit;

        let newsRes = get(newsEndpoint);
        let videosRes = get(videosEndpoint);

        axios.all([newsRes, videosRes])
            .then(axios.spread((responseNews, responseVideos) => {
                let tempNewsData = [];
                let tempVideosData = [];
                let tempData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });
                responseVideos.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.id,
                        // slug: slugify(data.title),
                        news_image: data.preview_image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.published_at.date, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.published_at.date).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'videos',
                    };
                    tempVideosData.push(news);
                });

                for (let i = 0; i < tempNewsData.length; i++) {
                    let ctr = i + 1;
                    if (ctr % 3 === 0) {
                        tempData.push(tempVideosData[i]);
                    } else {
                        tempData.push(tempNewsData[i]);
                    }
                }
                let tempLatestNews = tempData.slice(0, 3);
                let tempRelatedStories = tempData.slice(3, 27);
                setLatestNews(tempLatestNews);
                setRelatedStories(tempRelatedStories);
                setLoading(false);
            }))
            .catch(error => console.log(error));
        /*get(NewsEndPoint + `?limit=31`)
            .then((response) => {
                let prepareLatestNews = response.data.slice(0, 3);
                let prepareRelatedStories = response.data.slice(3, 31);
                let tempLatestNews = [], tempRelatedStories = [];
                prepareLatestNews.map((data) => {
                    let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                    };
                    return tempLatestNews.push(news);
                });
                prepareRelatedStories.map((data) => {
                    let timeDifference = moment.utc(data.datetime).local().startOf('seconds').fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                    };
                    return tempRelatedStories.push(news);
                });
                setLatestNews(tempLatestNews);
                setRelatedStories(tempRelatedStories);
            });*/
    }

    useEffect(() => {
        setLoading(true);
        fetchLatestNews();
        fetchSingleNews();
    }, [newsId]);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const renderBillboardBanner = () => {
        return (
            <div className="d-flex justify-content-center">
                <GoogleAd id={'4009154580'}/>
            </div>
        );
    }

    function renderRecommendationUnits() {
        return (<GoogleAd id={'9014053341'}/>);
    }

    function renderRelatedStories() {
        return (
            <div className="row">
                <span className={'bebasFont font-weight-bold h3'}>Related Stories</span>
                {
                    relatedStories.length ?
                        (
                            relatedStories.map((value, index) => (
                                <>
                                    <NewsCard newsItem={value} sport={sport} classProps={'col-6 mb-3'} isNewsPage={true}/>
                                    {(index + 1) % 4 === 0 ?
                                        (<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                            ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                            <GoogleAd id={'4009154580'}/>
                                        </div>) : null}
                                </>
                            ))
                        )
                        : null
                }
            </div>
        );
    }

    function renderRightContent() {
        return (
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <GoogleAd id={'4009154580'}/>
                <div className={`bebasFont mt-2 mb-2 h1`}>
                    Latest Articles
                </div>
                {
                    latestNews.length ?
                        (
                            <>
                                <NewsCard classProps={`mb-3`} newsItem={latestNews[0]} sport={sport} isNewsPage={true}/>
                                <NewsCard classProps={`mb-3`} newsItem={latestNews[1]} sport={sport} isNewsPage={true}/>
                                <NewsCard classProps={`mb-3`} newsItem={latestNews[2]} sport={sport} isNewsPage={true}/>
                            </>
                        )
                        : null
                }
                <GoogleAd id={'4009154580'}/>
            </div>
        );
    }

    const renderVideo = (videoData, extraClass, videoHeight) => {
        return (
            <div className={extraClass}>
                <Card className="border-0 bg-transparent">
                    <div className="video-responsive">
                        <iframe
                            style={{width: '100%'}}
                            height={videoHeight}
                            src={videoData.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Card>
                <span className={`sfProDisplayFontBold h3 w-100 d-inline-block mt-3 mb-5`}
                      dangerouslySetInnerHTML={{__html: videoData.title}}/>
                <hr/>
                <div className="row p-2 mb-5">
                    <div className={`justify-content-between col-9`}>
                        <span className={`text-muted sfProDisplayFont h5`}>
                            <div className={`row`}>
                                <span className={`d-flex align-items-end sfProDisplayFont font-16 ${textColor}`}>
                                    <FontAwesomeIcon icon={faClock} className={`text-gray`}/>
                                    &nbsp;
                                    {videoData.time}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {videoData.date}
                                </span>
                            </div>
                        </span>
                    </div>
                    <div className={`col-3 d-flex justify-content-end p-2`}>
                        <div className={`col-9 d-flex justify-content-end`}>
                            <span className={`d-flex align-items-center`}>
                                <img onError={addDefaultSrc} src={videoData.publisher.icon} alt="News" height={40}/>
                            </span>
                            &nbsp;
                            <span className={`d-flex align-items-center font-16`}>{videoData.publisher.name}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const render = () => {

        return (
            <>
                <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 ${!isMobile ? 'd-flex flex-row justify-content-center' : ''}`}>
                    <div className={'customContainer'}>
                        {renderBillboardBanner()}

                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-3 ">
                                {/*{renderNewsHead()}*/}
                                {renderVideo(news, 'row', '480')}

                                <div className={`single-mpu`}>
                                    <VideoAd/>
                                </div>

                                {renderRecommendationUnits()}

                                {renderRelatedStories()}
                            </div>
                            {renderRightContent()}
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    return (
        <>
            <Loader loading={loading}/>
            {
                news && render()
            }
            <div id={'htmlDiv'}/>
        </>
    );
}

export default withRouter(SingleVideoPage);
